import { AxiosResponse } from "axios";

import api from ".";
import { IAnnotatorHasAccess } from "../types";

const baseUrl: string = "annotators_has_access";

export const createAnnotatorHasAccess = async (
  _annotatorHasAccess: IAnnotatorHasAccess
) => {
  const response: AxiosResponse = await api.post(
    `/api_redsoft/v1_0/${baseUrl}`,
    _annotatorHasAccess
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotatorHasAccess(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateAnnotatorHasAccess = async (
  _id: string,
  _annotatorHasAccess: IAnnotatorHasAccess
) => {
  const response: AxiosResponse = await api.put(
    `/api_redsoft/v1_0/${baseUrl}/${_id}`,
    _annotatorHasAccess
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotatorHasAccess(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyAnnotatorHasAccess = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getAnnotatorHasAccesss = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotatorsHasAccess: IAnnotatorHasAccess[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotatorHasAccess: IAnnotatorHasAccess =
          convertApiResponseToIAnnotatorHasAccess(data);

        annotatorsHasAccess.push(annotatorHasAccess);
      }

      return annotatorsHasAccess;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotatorHasAccess = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotatorHasAccess: IAnnotatorHasAccess =
        convertApiResponseToIAnnotatorHasAccess(response.data);
      return annotatorHasAccess;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotatorHasAccessByAnnotatorId = async (
  _annotatorId: string
) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/by_annotator_id/${_annotatorId}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotatorsHasAccess: IAnnotatorHasAccess[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotatorHasAccess: IAnnotatorHasAccess =
          convertApiResponseToIAnnotatorHasAccess(data);

        annotatorsHasAccess.push(annotatorHasAccess);
      }

      return annotatorsHasAccess;
    }
  }
  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotatorHasAccessByAnnotatorAndBranch = async (
  _annotatorId: string,
  _branchId: string
) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/by_annotator_and_branch/${_annotatorId}/${_branchId}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotatorsHasAccess: IAnnotatorHasAccess[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotatorHasAccess: IAnnotatorHasAccess =
          convertApiResponseToIAnnotatorHasAccess(data);

        annotatorsHasAccess.push(annotatorHasAccess);
      }

      return annotatorsHasAccess;
    }
  }
  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIAnnotatorHasAccess = (
  _annotatorHasAccess: any
) => {
  const annotatorHasAccess: IAnnotatorHasAccess = {
    id: _annotatorHasAccess.id,
    annotatorId: _annotatorHasAccess.annotatorId,
    releasedDate: _annotatorHasAccess.releasedDate,
    releasedQuantity: _annotatorHasAccess.releasedQuantity,
    branchId: _annotatorHasAccess.branchId,
    isDeleted: _annotatorHasAccess.isDeleted,
    createdAt: _annotatorHasAccess.createdAt,
    updatedAt: _annotatorHasAccess.updatedAt,
  };

  return annotatorHasAccess;
};
