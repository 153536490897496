import React, { FC } from "react"
import IconButton from '@mui/material/IconButton';
import PaginationPrior from '@mui/icons-material/KeyboardArrowLeft';
import PaginationNext from '@mui/icons-material/KeyboardArrowRight';
import PaginationFirst from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PaginationLast from '@mui/icons-material/KeyboardDoubleArrowRight';

import { Container, Text, theme } from "../../../";
import { IPagination } from "../../../../types";

const Pagination: FC<IPagination> = (params: IPagination) => {
    return (
        <Container inline flex centered width="100%">
            <Container inline flex centered spacedEvenly width="100%" maxWidth="800px">
                <IconButton disabled={params.page === 0} onClick={() => params.setPage(0)}>
                    <Container fluid centered>
                        <PaginationFirst width="14px" height="14px" />
                    </Container>
                </IconButton>
                <IconButton disabled={params.page === 0} onClick={() => params.setPage(params.page - 1)}>
                    <Container fluid centered>
                        <PaginationPrior width="14px" height="14px" />
                    </Container>
                </IconButton>
                {Array(params.pageCount).fill(0).map((item, index) => {
                    let hideButton: boolean = true;
                    let showEllipsis: boolean = false;

                    if (index >= params.page && index - params.page <= 2) {
                        hideButton = false;
                    }
                    else if (index < params.page && params.page - index <= 2) {
                        hideButton = false;
                    }
                    else if (params.pageCount - index <= 2) hideButton = false;

                    if (hideButton) {
                        if (index > params.page && index === params.page + 3)
                            showEllipsis = true;
                        else if (index < params.page && index === params.page - 3)
                            showEllipsis = true;
                    }

                    const itemId = `${item}-${index}`;
                    if (showEllipsis) return (
                        <Container centered key={itemId}>
                            <Text medium color={theme.palette.text.disabled} size={14} style={{ userSelect: "none" }}>...</Text>
                        </Container>
                    );

                    if (hideButton) return null;
                    return (
                        <IconButton key={itemId} id={itemId} onClick={() => params.setPage(index)}>
                            <Text bold={params.page === index} medium={params.page !== index} color={params.page === index ? theme.palette.primary.main : theme.palette.text.disabled} size={14} style={{ userSelect: "none" }}>
                                {index + 1}
                            </Text>
                        </IconButton>
                    );
                })}
                <IconButton disabled={params.page === params.pageCount - 1} onClick={() => params.setPage(params.page + 1)}>
                    <Container fluid centered>
                        <PaginationNext width="14px" height="14px" />
                    </Container>
                </IconButton>
                <IconButton disabled={params.page === params.pageCount - 1} onClick={() => params.setPage(params.pageCount - 1)}>
                    <Container fluid centered>
                        <PaginationLast width="14px" height="14px" />
                    </Container>
                </IconButton>
            </Container>
        </Container>
    )
}

export default Pagination;
