import React, { FC } from 'react';
import { Grid } from '@mui/material';

import 'react-image-lightbox/style.css';

import { getCarcassClassification, getCarcassClassificationColor } from '../../../../hooks/carcasses';
import Image from '../../../atoms/image';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import { ICarcass } from '../../../../types';
import theme from '../../../theme';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';

interface LotCarcasseCardProps {
    carcass: ICarcass;
    carcass2: ICarcass;
}

const LotCarcasseCard: FC<LotCarcasseCardProps> = ({ carcass, carcass2 }) => {
    const renderClassificationText = () => {
        if(carcass?.classificationAccuracy && carcass2?.classificationAccuracy){
            if(carcass?.classificationAccuracy > carcass2.classificationAccuracy){
                return getCarcassClassification(carcass?.classificationId, false)
            }
            return getCarcassClassification(carcass2.classificationId, false)
        }
        return getCarcassClassification(carcass?.classificationId, false)
    };
    return (
        <Grid container direction="row" 
            borderRadius={BORDER_RADIUS/2} 
            boxShadow={3} 
            sx={{ backgroundColor: theme.palette.grey[100] }}
            item
            xs={12}
            >
            <Grid item xs={3} >
                <div style={{ borderRadius: `${BORDER_RADIUS*2}px 0 0 ${BORDER_RADIUS*2}px`, overflow: 'hidden' }}>
                    <Image height={400} source={carcass?.photoUri ? carcass?.photoUri : ""} ></Image> 
                </div>
            </Grid>
            <Grid item xs={3.5} >
                <div style={{ borderRadius: `0 ${BORDER_RADIUS*2}px ${BORDER_RADIUS*2}px 0`, overflow: 'hidden', }}>
                    <Image height={400} source={carcass2?.photoUri ? carcass2.photoUri : ""} ></Image> 
                </div>
            </Grid>
            <Grid item xs={5.5} >
                <Grid item paddingLeft={1} paddingRight={2}>
                    <Spacing top={PADDING}/>
                    <Grid item xs={12} justifyContent="center" alignItems="center" textAlign="center" >
                        <Text size={15} semiBold color={getCarcassClassificationColor(carcass?.classificationId)}>
                            {renderClassificationText()}
                        </Text>
                    </Grid>
                    <Spacing top={PADDING}/>
                    <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                        <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                            <Text size={15}>Sequência </Text>
                        </Grid>
                        <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                            <Text semiBold size={15}>{carcass?.partnerId}</Text>
                        </Grid>
                        
                    </Grid>
                    {   
                        carcass?.typification !== undefined &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                        <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                            <Text size={13}>Tipificação: </Text>
                        </Grid>
                        <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                            <Text semiBold size={12}> {carcass?.typification} </Text>
                        </Grid>

                    </Grid>
                    }
                    {   
                        carcass?.bestPlaced !== undefined &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>Bem pos: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left">
                                <Text semiBold size={12}> {carcass?.bestPlaced ? "Sim": "Não" } </Text>
                            </Grid>
                        </Grid>
                    }
                    <Spacing top={PADDING}/>
                    <Grid item xs={12} textAlign="left">
                        <Text size={13} semiBold style={{color: theme.palette.primary.main}}>
                            Dados do parceiro:
                        </Text>
                    </Grid>
                    <Spacing top={PADDING}/>
                    {   
                        carcass?.sex &&
                        <Grid container borderTop={2.75} borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>Sexo: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.sex} </Text>
                            </Grid>
                        </Grid>
                    }
                    {   
                        carcass?.weight &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>Peso: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.weight} kg </Text>
                            </Grid>
                        </Grid>
                    }
                    {   
                        carcass?.dentition !== undefined &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>Dentição: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.dentition} </Text>
                            </Grid>
                        </Grid>
                    }
                    {   

                        carcass?.cnpj &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>CNPJ: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.cnpj} </Text>
                            </Grid>
                        </Grid>
                    }
                    {   
                        carcass?.farm &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>Fazenda: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.farm} </Text>
                            </Grid>
                        </Grid>
                    }    
                    {   
                        carcass?.uf && carcass?.city &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text size={13}>Cidade: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.city} - {carcass?.uf} </Text>
                            </Grid>
                        </Grid>
                    }
                    {   
                        carcass?.docNum &&
                        <Grid container borderBottom={2.75} sx={{borderColor: "white"}}>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25} >
                                <Text size={13}>Contrato: </Text>
                            </Grid>
                            <Grid item xs={6} textAlign="left" paddingTop={0.25} paddingBottom={0.25}>
                                <Text semiBold size={12}> {carcass?.docNum} </Text>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>   
    );
}

export default LotCarcasseCard;


