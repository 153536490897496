import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { FilterAltOutlined as FilterOutlined, FilterAltOffOutlined as FilterOffOutlined } from '@mui/icons-material';
import { Grid, FormGroup, FormControlLabel, Switch, IconButton  } from '@mui/material';

import './classes.css'

import { IDashboardPage, COLORS } from './consts';
import { Container, DashboardClassificationPieChart, DashboardDentitionBarChart, DashboardSexPieChart, DashboardWeightPieChart, DashboardFrigobomTipificationAreaChart, DashboardLotsTable, Page, Spacing, Text, theme } from '../../components'
import { useDashboard } from '../../hooks/dashboard'
import { VERY_PADDING } from '../../utils/consts';
import BranchesSystemsHealthCard from '../../components/molecules/dashboard/systemsHealthCard';
import DashboardFilter from '../../components/molecules/dashboard/filter';
import { DashboardChartCard, DashboardNumberCard } from '../../components';
import { IDashboardBarChartData, IDashboardFilterData, ILotsFilterParams } from '../../types';
import {PhotoCameraBack as PhotoCameraBackIcon, ScaleOutlined as ScaleOutlinedIcon, WbCloudyOutlined as WbCloudyOutlinedIcon} from '@mui/icons-material/';
import { ReactComponent as CowIcon } from "../../assets/icons/animal_cow.svg";
import { useLoading } from '../../hooks/loading';
import { useAuth } from '../../hooks/auth';
import { useBranches } from '../../hooks/branches';

const RADIAN = Math.PI / 180;

const DashboardFrigobom: FC<IDashboardPage>  = (params: IDashboardPage) => {
    const { carcassAllTimeCount, carcassAllTimeBestPlacedCount, carcassAverageWeight, carcassIntegratedWithERPCount, 
        carcassesCountByClassification, carcassesCountByDentition, carcassesCountByWeight, carcassesCountBySex, carcassesCountByFrigobomTipification,
        fetchDashboardData, filter, setFilter } = useDashboard();
    
    const { hasRights, roles } = useAuth();

    const [isFullCarcassCountChecked, setIsFullCarcassCountChecked] = useState<boolean>(true);
    const [fullCarcassLabel, setFullCarcassLabel] = useState<string>("Carcaças inteiras")
    const [carcassAverageWeightAux, setCarcassAverageWeightAux] = useState<number>(carcassAverageWeight);
	
    const [carcassAllTimeCountHalf, setCarcassAllTimeCountHalf] = useState<number>();
    const [carcassAllTimeBestPlacedCountHalf, setCarcassAllTimeBestPlacedCountHalf] = useState<number>();
    const [carcassIntegratedWithERPCountHalf, setCarcassIntegratedWithERPCountHalf] = useState<number>();
    const [carcassesCountByClassificationHalf, setCarcassesCountByClassificationHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByDentitionHalf, setCarcassesCountByDentitionHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByWeightHalf, setCarcassesCountByWeightHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountBySexHalf, setCarcassesCountBySexHalf] = useState<IDashboardBarChartData[]>();
    const [carcassesCountByFrigobomTipificationHalf, setCarcassesCountByFrigobomTipificationHalf] = useState<IDashboardBarChartData[]>();

    useEffect(() => {
        if(params.isKgChecked) {
            params.setWeightUnitLabel('kg');
            setCarcassAverageWeightAux(carcassAverageWeight);
        }else{
            params.setWeightUnitLabel('@');
            setCarcassAverageWeightAux(carcassAverageWeight/15);
        }
        
    }, [carcassAverageWeight])
    
    useEffect(() => {
        setCarcassAllTimeCountHalf(Math.floor(carcassAllTimeCount/2));
    }, [carcassAllTimeCount])

    useEffect(() => {
        setCarcassAllTimeBestPlacedCountHalf(Math.floor(carcassAllTimeBestPlacedCount/2));
    }, [carcassAllTimeBestPlacedCount])

    useEffect(() => {
        setCarcassIntegratedWithERPCountHalf(Math.floor(carcassIntegratedWithERPCount/2));
    }, [carcassIntegratedWithERPCount])

    useEffect(() => {
        const newCount = carcassesCountByClassification.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            return newItem
        })
        const sum = newCount.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassAllTimeCountHalf ? carcassAllTimeCountHalf : Math.floor(carcassAllTimeCount/2);
        setCarcassesCountByClassificationHalf(adjustDifference(newCount, sum, desiredSum));
    }, [carcassesCountByClassification])

    useEffect(() => {
        const newCount = carcassesCountByDentition.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            return newItem
        })
        const sum = newCount.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        setCarcassesCountByDentitionHalf(adjustDifference(newCount, sum, desiredSum));
    }, [carcassesCountByDentition])
    
    useEffect(() => {
        const newCount = carcassesCountByWeight.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            return newItem
        })
        const sum = newCount.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        setCarcassesCountByWeightHalf(adjustDifference(newCount, sum, desiredSum));
    }, [carcassesCountByWeight])

    useEffect(() => {
        const newCount = carcassesCountBySex.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            return newItem
        })
        const sum = newCount.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        setCarcassesCountBySexHalf(adjustDifference(newCount, sum, desiredSum));
    }, [carcassesCountBySex])

    useEffect(() => {
        const newCount = carcassesCountByFrigobomTipification.map((item) => {
            const newItem = JSON.parse(JSON.stringify(item));
            newItem.value = Math.floor(newItem.value/2);
            return newItem
        })
        const sum = newCount.reduce((total, item) => {
            if(item.name == 'Sem cobertura' || item.name == 'Desuniforme' || item.name == 'Padrão'){
                total = total + item.value
            }
            return total
        }, 0);
        
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        setCarcassesCountByFrigobomTipificationHalf(adjustDifference(newCount, sum, desiredSum));
    }, [carcassesCountByFrigobomTipification])


    const adjustDifference = (countObject: IDashboardBarChartData[], sum: number, desiredSum: number) => {
        const adjustedObject = countObject.slice()
        if (sum !== desiredSum) {
            const difference = desiredSum - sum;
            const adjustment = Math.floor(difference / adjustedObject.length);
            var leftover = difference % adjustedObject.length;

            for (let i = 0; i < adjustedObject.length; i++) {
                adjustedObject[i].value += Math.max(0, adjustment);
                if (leftover > 0) {
                    adjustedObject[i].value += 1;
                    leftover--;
                }
                else{
                    break;
                }
            }
        }
        return adjustedObject
    } 

    const convertClassificationToFrigobomNomenclature = (classificationData: IDashboardBarChartData[] | undefined) => {
        if(!classificationData)
            return

        var convertedData: IDashboardBarChartData[] = JSON.parse(JSON.stringify(classificationData));

        var padraoValue = 0;
        for (let i = 0; i < classificationData.length; i++){
            if(classificationData[i].name == 'Mediana' || classificationData[i].name == 'Uniforme' || classificationData[i].name == 'Excessiva'){
                padraoValue += classificationData[i].value
            }
        }
        convertedData = convertedData.filter(item => item.name != 'Mediana' && item.name != 'Uniforme' && item.name != 'Excessiva');
        convertedData.push({
            name: 'Padrão',
            value: padraoValue
        })

        const sum = convertedData.reduce((total, item) => total + item.value, 0);
        const desiredSum = carcassIntegratedWithERPCountHalf ? carcassIntegratedWithERPCountHalf : Math.floor(carcassIntegratedWithERPCount/2);
        convertedData = adjustDifference(convertedData, sum, desiredSum)
        return convertedData;
    }

    const onFilterIconClick = () => {
        params.setFilterExpanded(!params.filterExpanded);
    }

    const handleKgSwitchChange = () => {
        params.setIsKgChecked(!params.isKgChecked);
        if(params.isKgChecked) {
            params.setWeightUnitLabel('@');
            setCarcassAverageWeightAux(carcassAverageWeight/15);
        }else{
            params.setWeightUnitLabel('kg');
            setCarcassAverageWeightAux(carcassAverageWeight);
        }
    }

    const handleFullCarcassCountSwitchChange = () => {
        setIsFullCarcassCountChecked(!isFullCarcassCountChecked);
        if(isFullCarcassCountChecked) {
            setFullCarcassLabel('Meias carcaças');
        }else{
            setFullCarcassLabel('Carcaças inteiras');
        }
    }

    return (
        <Page width='100%' height='auto' style={{ minHeight: '95vh', paddingLeft: "60px", paddingRight: "60px"}} >
            <Container fluid flex color={theme.palette.background.default} width="100%" >
                <Container fluid sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING, paddingTop: VERY_PADDING}}>
                    <Grid container rowSpacing={4} columnSpacing={4} sx={{alignItems: "center"}}>
                        <Grid item xl={2}  xs={12} >
                            <Text semiBold color={theme.palette.primary.main} size={25}>Dashboard</Text>
                        </Grid>
                        <Grid item xl={roles.includes('view_dashboard__half_carcasses') ? 6 : 8}  xs={roles.includes('view_dashboard__half_carcasses') ? 8 : 10} >
                            <Text semiBold color={theme.palette.primary.main} size={23}>
                                { (filter.initialDate && filter.finalDate) ? 
                                    (
                                        filter.initialDate.toString() === filter.finalDate.toString() ? 
                                            `${moment(filter.initialDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}` : 
                                            `${moment(filter.initialDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')} - ${moment(filter.finalDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}`
                                    ): ''
                                }
                            </Text>
                        </Grid>
                        <Grid item xl={1} xs={1}>
                            <Container inline flex flexEnd horizontalCentered verticalCentered>
                                <IconButton size='medium' style={{}} onClick={onFilterIconClick}>
                                    {
                                        params.filterExpanded ? <FilterOffOutlined style={{ color: theme.palette.primary.dark }}/>:<FilterOutlined style={{ color: theme.palette.primary.dark }}/>
                                    }
                                </IconButton>
                            </Container>
                        </Grid>
                        <Grid item xl={1} xs={1}>
                            <Container inline flex flexEnd horizontalCentered verticalCentered>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <Switch checked={params.isKgChecked} onChange={handleKgSwitchChange}/>
                                    } 
                                    label={params.weightUnitLabel} />
                                </FormGroup>
                            </Container>
                        </Grid>
                        {
                            roles.includes('view_dashboard__half_carcasses') &&
                            <Grid item xl={2} xs={2}>
                                <Container inline flex flexEnd horizontalCentered verticalCentered>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Switch checked={isFullCarcassCountChecked} onChange={handleFullCarcassCountSwitchChange}/>
                                        } 
                                        label={fullCarcassLabel} />
                                    </FormGroup>
                                </Container>
                            </Grid>
                        }
                        <Grid item xl={12} xs={12}>
                            {
                                params.filterExpanded && 
                                <DashboardFilter
                                    initialDate={filter.initialDate}
                                    finalDate={filter.finalDate}
                                    branches={filter.branches}
                                    farms={filter.farms}
                                    lots={filter.lotNums}
                                    dentitions={filter.dentitions}
                                    weightIntervals={filter.weightIntervals}
                                    sexes={filter.sexes}
                                    isUnitKg={params.isKgChecked}
                                    onApplyFilter={(_filter: IDashboardFilterData) => {
                                        fetchDashboardData(_filter);
                                        setFilter(_filter);
                                    }}
                                />
                            }
                        </Grid>
                    </Grid>
                </Container>
                <Container fluid flex flexGrow flexStart sx={{paddingLeft: VERY_PADDING, paddingRight: VERY_PADDING}}>
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                        <Grid container item lg={8} md={12} sm={12} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                            <Grid item md={6} sm={6} xs={6}>
                                <DashboardNumberCard 
                                    label={'Quantidade de carcaças'} 
                                    value={isFullCarcassCountChecked ? carcassAllTimeCountHalf : carcassAllTimeCount} 
                                    size='medium'
                                    icon={<CowIcon style={{scale: '0.85', color: 'white'}}></CowIcon>}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={6}>
                                <DashboardNumberCard 
                                    label={'Carcaças bem posicionadas'} 
                                    value={isFullCarcassCountChecked ? carcassAllTimeBestPlacedCountHalf : carcassAllTimeBestPlacedCount}
                                    size='medium'
                                    icon={<PhotoCameraBackIcon style={{ width: '35px', height: '35px', color: 'white'}}></PhotoCameraBackIcon>}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item lg={4} md={12} sm={12} xs={12} sx={{alignContent: "center"}} columnSpacing={4} rowSpacing={2} height="100%">
                            <Grid item lg={12} md={6} sm={6} xs={6}>
                                <DashboardNumberCard 
                                    label={'Peso médio das carcaças'} 
                                    value={carcassAverageWeightAux}
                                    valueUnit={params.weightUnitLabel} 
                                    size='small'
                                    icon={<ScaleOutlinedIcon style={{ width: '32px', height: '32px', color: 'white'}}></ScaleOutlinedIcon>}
                                />
                            </Grid>
                            <Grid item lg={12} md={6} sm={6} xs={6}>
                                <DashboardNumberCard 
                                    label={'Carcaças integradas com o ERP'} 
                                    value={isFullCarcassCountChecked ? carcassIntegratedWithERPCountHalf : carcassIntegratedWithERPCount}
                                    size='small'
                                    icon={<WbCloudyOutlinedIcon style={{ width: '35px', height: '35px', color: 'white'}}></WbCloudyOutlinedIcon>}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Spacing top={VERY_PADDING} />
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                        <Grid container item  xl={6} lg={6} md={12} sm={12} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                            <Grid item xs={12}>
                                <DashboardChartCard 
                                    label={'Classificação Redsoft'} 
                                    size='medium'
                                    chart={
                                        <DashboardClassificationPieChart
                                            data={isFullCarcassCountChecked ? carcassesCountByClassificationHalf : carcassesCountByClassification}
                                            colors={COLORS}
                                        />

                                    }
                                />
                            </Grid>
                            
                        </Grid>
                        <Grid container item xl={6} lg={6} md={12} sm={12} xs={12} sx={{alignContent: "center"}} rowSpacing={2} height="100%">
                            <Grid item xs={12}>
                                <DashboardChartCard 
                                    label={'Cronologia dentária'} 
                                    size='medium'
                                    chart={
                                        <DashboardDentitionBarChart
                                            data={isFullCarcassCountChecked ? carcassesCountByDentitionHalf : carcassesCountByDentition} 
                                            colors={COLORS}
                                        />
                                    }
                                />
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Spacing top={VERY_PADDING} />
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                        <Grid container item  xl={5} lg={5} md={12} sm={12} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                            <Grid item xs={12}>
                                <DashboardChartCard 
                                    label={'Classificação Redsoft - Terminologia Frigobom'} 
                                    size='medium'
                                    chart={
                                        <DashboardClassificationPieChart
                                            data={isFullCarcassCountChecked ? convertClassificationToFrigobomNomenclature(carcassesCountByClassificationHalf) : convertClassificationToFrigobomNomenclature(carcassesCountByClassification)}
                                            colors={COLORS}
                                        />

                                    }
                                />
                            </Grid>
                            
                        </Grid>
                        <Grid container item xl={7} lg={7} md={12} sm={12} xs={12} sx={{alignContent: "center"}} rowSpacing={2} height="100%">
                            <Grid item xs={12}>
                                <DashboardChartCard 
                                    label={'Tipificação Frigobom (ERP)'} 
                                    size='medium'
                                    chart={
                                        <DashboardFrigobomTipificationAreaChart
                                            data={isFullCarcassCountChecked ? carcassesCountByFrigobomTipificationHalf : carcassesCountByFrigobomTipification} 
                                            colors={COLORS}
                                            height={200}
                                            isKgChecked={params.isKgChecked}
                                        />
                                    }
                                />
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Spacing top={VERY_PADDING} />
                    <Grid container columns={{ xs: 1, sm: 6, md: 8, lg: 10, xl: 12 }} rowSpacing={4} columnSpacing={4} alignItems="center" minHeight='100%'>
                        <Grid container item  xl={7.5} lg={12} md={12} sm={12} xs={12} sx={{alignContent: "center"}} columnSpacing={4} height="100%">
                            <Grid item xs={12} >
                                {/* LOTS TABLE */}
                                <DashboardChartCard 
                                        label={'Listagem de lotes'} 
                                        size='medium'
                                        chart={
                                            <DashboardLotsTable    
                                                filter={filter}
                                                isKgChecked={params.isKgChecked}
                                                isFullCarcassCountChecked={isFullCarcassCountChecked}
                                            />
                                        }
                                    />    
                            </Grid>

                        </Grid>
                        <Grid container item xl={4.5} lg={12} md={12} sm={12} xs={12} sx={{alignContent: "center"}} rowSpacing={6} columnSpacing={4} height="100%">
                            <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
                                <DashboardChartCard 
                                    label={'Carcaças por peso'} 
                                    size='medium'
                                    chart={
                                        <DashboardWeightPieChart
                                            data={isFullCarcassCountChecked ? carcassesCountByWeightHalf : carcassesCountByWeight}
                                            colors={COLORS}
                                            height={230}
                                            isKgChecked={params.isKgChecked}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xl={12} lg={6} md={12} sm={12} xs={12}>
                                <DashboardChartCard 
                                    label={'Carcaças por sexo e idade'} 
                                    size='medium'
                                    chart={
                                        <DashboardSexPieChart
                                            data={isFullCarcassCountChecked ? carcassesCountBySexHalf : carcassesCountBySex}
                                            colors={COLORS}
                                            height={230}
                                        />
                                        
                                    }
                                />
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Container>
            </Container>
        </Page >
    )
}

export default DashboardFrigobom;