import { FC, useEffect } from 'react';

import { MonitoringRecipientRegister, Container, Page, theme, MonitoringRecipientList } from '../../../components';
import { useInformation } from '../../../hooks/information';
import { useMonitoringRecipients } from '../../../hooks/monitoringRecipient';
import { BORDER_RADIUS } from '../../../utils/consts'
import { IListHeader } from '../../../types';
import { useUsers } from '../../../hooks/users';
import { useBranches } from '../../../hooks/branches';
import { useMonitoringTypes } from '../../../hooks/monitoringTypes';

const MonitoringRecipent: FC = () => {
    
    const { showInformation } = useInformation();
    const { fetchMonitoringRecipients, loading, paginate, monitoringRecipients, monitoringRecipientPerRow, setPaginate } = useMonitoringRecipients();
    const { fetchUsers } = useUsers();
    const { fetchBranches } = useBranches();
    const { fetchMonitoringTypes } = useMonitoringTypes();

    useEffect(() => {
        if (!loading) {
          fetchMonitoringRecipients();
          fetchUsers();
          fetchBranches();
          fetchMonitoringTypes();
        }
    }, []);

    const headers: IListHeader[] = [
        { label: "Usuário", fieldName: "user", xs: 2, isString: true, sortable: true },
        { label: "E-mail", fieldName: "email", xs: 10, isString: true, sortable: true },
    ];

    const convertDataToItem = (_data: any) => {
        return {
            "userId": _data.userId,
            "email": _data.email,
            "user": _data.user,
        }
    }
    
    return (
        <Page
        width='auto'
        height='auto'
        color={theme.palette.background.default}>
            <Container inline flex fluid hasShadow width="100%" minHeight="80vh" color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}>
                <MonitoringRecipientList
                    headers={headers}
                    data={monitoringRecipients}
                    loading={loading}
                    dataToItem={(_data: any) => {
                        return convertDataToItem(_data)
                    }}
                />
                    <MonitoringRecipientRegister
                        onConfirm={(_informationTitle: string, _informationMessage: string) => {
                            showInformation(_informationMessage, _informationTitle);
                        }}
                    />
            </Container>
        </Page >
    )
}

export default MonitoringRecipent;