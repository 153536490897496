import { AxiosResponse } from "axios";

import api from ".";
import { IAnnotator, IUser } from "../types";
import { convertApiResponseToIUser, getUser } from "./users";

const baseUrl: string = "annotators";

export const createAnnotator = async (_annotator: IAnnotator) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, _annotator);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotator(response.data);
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      if ((response as any).response.data.error) throw new Error((response as any).response.data.error as string);
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um anotador");
};

export const updateAnnotator = async (_id: string, _annotator: IAnnotator) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    userId: _annotator.userId,
    fullAccess: _annotator.fullAccess,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotator(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyAnnotator = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getAnnotators = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotators: IAnnotator[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        let annotator: IAnnotator = convertApiResponseToIAnnotator(data);
        if (annotator.userId) {
          const user: IUser = await getUser(annotator.userId);
          annotator.user = user;
        }
        annotators.push(annotator);
      }
      return annotators;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotator = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let annotator: IAnnotator = convertApiResponseToIAnnotator(response.data);
      if (annotator.userId) {
        const user: IUser = await getUser(annotator.userId);
        annotator.user = user;
      }
      return annotator;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotatorByUserId = async (_userId: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/user/${_userId}`);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      let annotator: IAnnotator = convertApiResponseToIAnnotator(response.data);
      if (annotator.userId) {
        const user: IUser = await getUser(annotator.userId);
        annotator.user = user;
      }
      return annotator;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotatorAnnotates = async (
  _id: string,
  _initialDate: string,
  _finalDate: string,
  _companyId: string,
  _onlyBestPlaced: boolean,
  _onlyWithPartnerId: boolean
) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/annotates/${_id}/${_initialDate}/${_finalDate}/${_companyId}/${_onlyBestPlaced}/${_onlyWithPartnerId}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const count: number = response.data.count;
      return count;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIAnnotator = (_annotator: any) => {
  let user: IUser | undefined = undefined;
  if (_annotator.user) user = convertApiResponseToIUser(_annotator.user);

  const annotator: IAnnotator = {
    id: _annotator.id,
    userId: _annotator.userId,
    user,
    fullAccess: _annotator.fullAccess,
    countAnnotations: _annotator.countAnnotations,
    countRevisions: _annotator.countRevisions,
    isDeleted: _annotator.isDeleted,
    createdAt: _annotator.createdAt,
    updatedAt: _annotator.updatedAt,
  };

  return annotator;
};
