import React, { FC, createContext, useContext, useState } from 'react'
import {
    createCarcassLocalClassification, getCarcassesLocalClassifications, getCarcassLocalClassification, updateCarcassLocalClassification,
    deleteCarcassLocalClassification as deleteCarcassLocalClassificationService
} from '../services/carcassesLocalClassifications';

import { ICarcassLocalClassification, ICarcassLocalClassificationContext } from '../types'

const CarcassesLocalClassificationsContext = createContext<ICarcassLocalClassificationContext>({} as ICarcassLocalClassificationContext);

export const CarcassesLocalClassificationsProvider: FC<any> = ({ children }) => {
    const [carcassesLocalClassifications, setCarcassesLocalClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchCarcassLocalClassification, setSearchCarcassLocalClassification] = useState<string>('');
    const [carcassesLocalClassificationsPerRow, setCarcassesLocalClassificationsPerRow] = useState<string>('15');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchCarcassesLocalClassifications = async () => {
        setLoading(true);
        try {
            const carcassesLocalClassifications: ICarcassLocalClassification[] = await getCarcassesLocalClassifications();
            setCarcassesLocalClassifications([...carcassesLocalClassifications]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            setLoading(false);
        }
    }

    const fetchCarcassLocalClassification = async (_id: string) => {
        const privilege: ICarcassLocalClassification = await getCarcassLocalClassification(_id);
        return privilege;
    }

    const createNewCarcassLocalClassification = async (_payload: ICarcassLocalClassification) => {
        const privilege = await createCarcassLocalClassification(_payload);
        return privilege;
    }

    const editCarcassLocalClassification = async (_privilegeId: string, _payload: ICarcassLocalClassification) => {
        const privilege = await updateCarcassLocalClassification(_privilegeId, _payload);
        return privilege;
    }

    const deleteCarcassLocalClassification = async (_privilegeId: string) => {
        await deleteCarcassLocalClassificationService(_privilegeId);
    }

    return (
        <CarcassesLocalClassificationsContext.Provider value={{
            carcassesLocalClassifications, loading,
            fetchCarcassesLocalClassifications, fetchCarcassLocalClassification, createNewCarcassLocalClassification, editCarcassLocalClassification, deleteCarcassLocalClassification,
            searchCarcassLocalClassification, setSearchCarcassLocalClassification, carcassesLocalClassificationsPerRow, setCarcassesLocalClassificationsPerRow, paginate, setPaginate,
        }}>{children}</CarcassesLocalClassificationsContext.Provider>
    )
}

export function useCarcassesLocalClassifications() {
    const context = useContext(CarcassesLocalClassificationsContext);

    if (!context) {
        throw new Error('useCarcassesLocalClassifications must be used within an CarcassesLocalClassificationsProvider')
    }

    return context;
}