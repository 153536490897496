import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fab, Grow, IconButton, TextField } from '@mui/material';
import { Business as CompanyIcon, Search as SearchIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import { CompaniesList, CompanyRegisterModal, Container, List, Page, Spacing, Text, theme } from '../../components';
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information';
import { useCompanies } from '../../hooks/companies';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IListAction, IListHeader, ICompany } from '../../types';
import Input from '../../components/atoms/input';
import { normalizeString } from '../../utils/stringFormatters';

const Companies: FC = () => {
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const navigate = useNavigate();
    const { fetchCompanies, loading, searchCompany, setSearchCompany, deleteCompany } = useCompanies();

    const [companyToEdit, setCompanyToEdit] = useState<ICompany | undefined>(undefined);
    const [newCompanyModalVisible, setNewCompanyModalVisible] = useState<boolean>(false);
    const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>([]);
    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        if (!loading) {
            fetchCompanies();
        }
    }, []);

    useEffect(() => {
        if (!newCompanyModalVisible)
            setCompanyToEdit(undefined);
    }, [newCompanyModalVisible]);

    const editCompany = (_companyId: string) => {
        navigate(`/companies/${_companyId}`)
    }

    const onDeleteCompany = (_id: string) => {
        showConfirmation("Deseja realmente exluir a empresa?", "Atenção", "Confirmar", "Cancelar",
            async () => {
                await deleteCompany(_id);

                hideConfirmation();
                showInformation('Empresa excluída com sucesso.', 'Exclusão de empresa');
            },
            () => {
                hideConfirmation();
            });
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <Text semiBold color={theme.palette.primary.main} size={23}>Empresas</Text>
                </Container>
                <Spacing top={VERY_PADDING} />
                <TextField id="search-companies" autoFocus placeholder="Pesquisar empresas" value={searchCompany}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCompany(event.target.value)}
                    InputProps={{ startAdornment: <SearchIcon color='primary' /> }} sx={{ width: "100%" }} />
                <Spacing top={VERY_PADDING} />
                <CompaniesList
                    onOpenBranches={(_id: string) => navigate(`/companies/${_id}/branches`)}
                    onEditCompany={(_id: string) => editCompany(_id)}
                    onDeleteCompany={(_id: string) => onDeleteCompany(_id)} />
            </Container>

            <Grow in={!fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/companies/register`)}
                    variant="circular"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    <CompanyIcon />
                </Fab>
            </Grow>
            <Grow in={fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/companies/register`)}
                    variant="extended"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        height: 56,
                        borderRadius: '28px'
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    {fabHover &&
                        <Container inline>
                            <Text size={14} color={theme.palette.background.paper}>Nova empresa</Text>
                            <Spacing left={PADDING} />
                        </Container>}
                    <CompanyIcon />
                </Fab>
            </Grow>

            <CompanyRegisterModal
                companyToEdit={companyToEdit}
                open={newCompanyModalVisible}
                onClose={() => setNewCompanyModalVisible(false)}
                onCancel={() => setNewCompanyModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    showInformation(_informationMessage, _informationTitle);
                }} />
        </Page >
    )
}

export default Companies;