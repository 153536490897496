import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, ButtonBase, Collapse, Grow, IconButton, Paper } from "@mui/material";
import {
    Apps as AppsIcon,
    AppRegistration as AnnotationIcon,
    AssignmentInd as AnnotatorIcon,
    Business as BusinessIcon,
    Dashboard as DashboardIcon,
    Escalator as EscalatorIcon,
    Gavel as GavelIcon,
    Link as LinkIcon,
    ModelTraining as ModelTrainingIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    People as PeopleIcon,
    Badge as BranchesHasUserIcon,
    RateReview as RevisionIcon,
    StarHalf as StarHalfIcon,
    Store as StoreIcon,
    Analytics as AnalyticsIcon,
    ArticleRounded as ArticleRoundedIcon,
} from "@mui/icons-material";

import { DrawerHeader, Drawer, drawerWidth } from "./drawer";
import Container from "../../atoms/container";
import Spacing from "../../atoms/spacing";
import Text from "../../atoms/text";
import theme from "../../theme";
import logoBrand from "../../../assets/logo_brand.png";
import { ReactComponent as CarcassesIcon } from "../../../assets/icons/carcassesIcon.svg";
import { useDrawer } from "../../../hooks/drawer";
import { useAuth } from "../../../hooks/auth";
import { IDrawerMolecule } from "../../../types";
import DrawerButton from "./button";
import { PADDING } from "../../../utils/consts";
import Image from "../../atoms/image";

const AppDrawer: FC<IDrawerMolecule> = (params: IDrawerMolecule) => {
    const location = useLocation();

    const { hasRights, roles, user } = useAuth();
    const { isOpen } = useDrawer();

    const [showAnimals, setShowAnimals] = useState<boolean>(false);
    const [showAnnotations, setShowAnnotations] = useState<boolean>(false);

    const getColor = (pathname: string) => {
        if (location.pathname == pathname) return theme.palette.primary.main;
        return "#000";
    };

    const checkRole = (routes: string[]) => {
        for (let i = 0; i < routes.length; i++)
            if (roles.indexOf(routes[i]) >= 0)
                return true;
        return false;
    }

    return (
        <Box sx={{ height: '100%', display: "flex" }}>
            <Drawer
                variant="permanent"
                open={isOpen}
                style={{ zIndex: 0 }}
                elevation={10}
                PaperProps={{
                    sx: {
                        borderRight: "0px",
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
            >
                <DrawerHeader />

                <Container fluid flex flexGrow spacedBetween>
                    <Container fluid>
                        <Grow in={isOpen}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: 2,
                                    marginBottom: 2,
                                }}
                            >
                                <Image
                                    source={logoBrand}
                                    height={35}
                                    width={35 * 4.16}
                                />
                                <Spacing top={PADDING} />
                                <Text medium size={15}>
                                    Bem vindo(a)
                                </Text>
                                <Text
                                    bold
                                    color={theme.palette.primary.main}
                                    size={15}
                                >
                                    {user && user.firstName}
                                </Text>
                            </Box>
                        </Grow>

                        <Container fluid flex flexStart>
                            <DrawerButton
                                name="Dashboard"
                                color={getColor("/")}
                                route="/"
                                icon={
                                    <DashboardIcon
                                        fontSize="large"
                                        style={{ color: getColor("/") }}
                                    />
                                }
                            />
                            {hasRights!("view_companies") && <DrawerButton name="Empresas" color={getColor("/companies")} route="/companies"
                                icon={<BusinessIcon fontSize="large" style={{ color: getColor("/companies") }} />} />}
                            {checkRole(['view_carcasses', 'view_lots']) &&
                                <Container inline width="90%" flexStart>
                                    <Container inline width="90%" flexStart>
                                        <ButtonBase onClick={() => setShowAnimals(!showAnimals)}>
                                            <Box
                                                sx={{
                                                    backgroundColor: "transparent",
                                                    padding: "12px",
                                                    alignItems: "center",
                                                    display: "flex"
                                                }}
                                            >
                                                <>
                                                    <AppsIcon
                                                        fontSize="large"
                                                        style={{ color: getColor("") }}
                                                    />
                                                    {!isOpen && (!showAnimals ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />)}
                                                </>
                                            </Box>
                                            <Container fluid width='150px'>
                                                <Grow in={isOpen}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            cursor: "pointer",
                                                            marginRight: PADDING,
                                                        }}
                                                    >
                                                        <Text bold size={16} color={getColor("")}>
                                                            Animais
                                                        </Text>
                                                        {!showAnimals ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />}
                                                    </Box>
                                                </Grow>
                                            </Container>
                                        </ButtonBase>
                                    </Container>
                                </Container>
                            }
                            <Collapse in={showAnimals}>
                                <Container fluid flex flexStart sx={{ paddingLeft: PADDING }}>
                                    <DrawerButton
                                        name="Carcaças"
                                        color={getColor("/carcasses")}
                                        route="/carcasses"
                                        icon={
                                            <CarcassesIcon
                                                fill={getColor("/carcasses")}
                                            />
                                        }
                                    />
                                    {
                                        checkRole(['view_lotes']) &&
                                        <DrawerButton
                                            name="Lotes"
                                            color={getColor("/lotes")}
                                            route="/lotes"
                                            icon={
                                                <ArticleRoundedIcon
                                                    fontSize="large"
                                                    style={{
                                                        color: getColor(
                                                            "/lotes",
                                                        ),
                                                    }}
                                                />
                                            }
                                        />
                                    }
                                </Container>
                            </Collapse>


                            {checkRole(['view_analysis_annotations', 'view_annotators', 'view_annotations', 'view_revisions', 'view_verdicts']) &&
                                <Container inline width="90%" flexStart>
                                    <ButtonBase onClick={() => setShowAnnotations(!showAnnotations)}>
                                        <Box
                                            sx={{
                                                backgroundColor: "transparent",
                                                padding: "12px",
                                                alignItems: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <>
                                                <ModelTrainingIcon
                                                    fontSize="large"
                                                    style={{ color: getColor("") }}
                                                />
                                                {!isOpen && (!showAnnotations ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />)}
                                            </>
                                        </Box>
                                        <Container fluid width='150px'>
                                            <Grow in={isOpen}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        cursor: "pointer",
                                                        marginRight: PADDING,
                                                    }}
                                                >
                                                    <Text bold size={16} color={getColor("")}>
                                                        Treinamento
                                                    </Text>
                                                    {!showAnnotations ? <KeyboardArrowDownIcon sx={{ color: '#000' }} /> : <KeyboardArrowUpIcon sx={{ color: '#000' }} />}
                                                </Box>
                                            </Grow>
                                        </Container>
                                    </ButtonBase>
                                </Container>
                            }

                            <Collapse in={showAnnotations}>
                                <Container fluid flex flexStart sx={{ paddingLeft: PADDING }}>
                                    {hasRights!("view_annotators") && <DrawerButton
                                        name="Anotadores"
                                        color={getColor("/annotators")}
                                        route="/annotators"
                                        icon={
                                            <AnnotatorIcon
                                                fontSize="large"
                                                style={{
                                                    color: getColor("/annotators"),
                                                }}
                                            />
                                        }
                                    />}
                                    {hasRights!("view_analysis_annotations") && <DrawerButton
                                        name="Análise"
                                        color={getColor("/analysis-annotations")}
                                        route="/analysis-annotations"
                                        icon={
                                            <AnalyticsIcon
                                                fontSize="large"
                                                style={{
                                                    color: getColor(
                                                        "/analysis-annotations",
                                                    ),
                                                }}
                                            />
                                        }
                                    />}
                                    {hasRights!("view_annotations") && <DrawerButton
                                        name="Anotações"
                                        color={getColor("/annotations")}
                                        route="/annotations"
                                        icon={
                                            <AnnotationIcon
                                                fontSize="large"
                                                style={{
                                                    color: getColor("/annotations"),
                                                }}
                                            />
                                        }
                                    />}
                                    {hasRights!("view_revisions") && <DrawerButton
                                        name="Revisões"
                                        color={getColor("/revisions")}
                                        route="/revisions"
                                        icon={
                                            <RevisionIcon
                                                fontSize="large"
                                                style={{
                                                    color: getColor("/revisions"),
                                                }}
                                            />
                                        }
                                    />}
                                    {hasRights!("view_verdicts") && <DrawerButton
                                        name="Arbitragem"
                                        color={getColor("/verdicts")}
                                        route="/verdicts"
                                        icon={
                                            <GavelIcon
                                                fontSize="large"
                                                style={{
                                                    color: getColor("/verdicts"),
                                                }}
                                            />
                                        }
                                    />}
                                </Container>
                            </Collapse>
                        </Container>
                    </Container>

                    <Container fluid padded centered>
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9 }}
                        >
                            RedSoft©
                        </Text>
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9 }}
                        >
                            2022
                        </Text>
                        <Text
                            size={12}
                            bold
                            color={theme.palette.primary.main}
                            style={{ opacity: 0.9 }}
                        >
                            v{process.env.REACT_APP_VERSION}
                        </Text>
                    </Container>
                </Container>
                {/* <Container style={{ paddingLeft: 10 }}>
                    <Text size={10} bold color={theme.palette.primary.main}>v{process.env.REACT_APP_VERSION}</Text>
                </Container> */}
            </Drawer>

            <Paper component="main" sx={{ flexGrow: 1, width: "100%", margin: 0 }} square elevation={0}>
                <DrawerHeader />
                {params.children}
            </Paper>
        </Box>
    );
};

export default AppDrawer;
