import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { Container, Input, Page, Spacing, Text, theme } from '../../../components';
import { useBranches } from '../../../hooks/branches';
import { useBranchesHasUser } from '../../../hooks/branchesHasUser';
import { useUsers } from '../../../hooks/users';
import { IBranch, IBranchHasUser } from '../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';
import { useInformation } from '../../../hooks/information';
import UserSelect from '../../../components/molecules/users/select';
import BranchSelect from '../../../components/molecules/branches/select';

const BranchHasUserRegister: FC<any> = () => {
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { fetchBranches } = useBranches();
    const { createNewBranchHasUser, editBranchHasUser, fetchBranchHasUser, fetchBranchesHasUser } = useBranchesHasUser();
    const { fetchUsers } = useUsers();

    const [branchHasUser, setBranchHasUser] = useState<IBranchHasUser | undefined>(undefined);
    const [userId, setUserId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userError, setUserError] = useState<string | undefined>(undefined);
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        loadBranchHasUser();
    }, []);

    useEffect(() => {
        if (branchHasUser) {
            if (branchHasUser.user && branchHasUser.user.id) {
                setUserId(branchHasUser.user.id);
                setUserName(branchHasUser.user.firstName);
            }
            if (branchHasUser.branch && branchHasUser.branch.id) {
                setUserId(branchHasUser.branch.id);
                setUserName(branchHasUser.branch.name);
            }
        }
    }, [branchHasUser]);

    const loadBranchHasUser = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxBranchHasUser: IBranchHasUser = await fetchBranchHasUser(id);
                setBranchHasUser(auxBranchHasUser);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setUserId((event as any).target.value);
            setUserName('');
        } else {
            if ((event as any).id) {
                setUserId((event as any).id);
            }
            if ((event as any).label) {
                setUserName((event as any).label);
            }
        }
        setError(null);
    }
    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (userId == '') {
            setUserError('Selecione o usuário');
            isValid = false;
        }
        if (branchId == '') {
            setBranchError('Selecione a filial');
            isValid = false;
        }


        if (isValid) {
            setUserError(undefined);
            setBranchError(undefined);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let payload: IBranchHasUser = {
                userId,
                branchId
            };
            if (branchHasUser && branchHasUser.id) {
                await editBranchHasUser(branchHasUser.id, payload);
            } else {
                await createNewBranchHasUser(payload, true);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!branchHasUser) informationMessage = 'O novo vínculo foi cadastrado com sucesso.';

            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    fetchBranchesHasUser();
                    setLoading(false);
                    hideInformation();
                    cleanData();
                    navigate('/branches-has-users');
                });
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setUserId('');
        setUserName('');
        setUserError(undefined);
        setBranchId('');
        setBranchName('');
        setBranchError(undefined);
        setError(null);
    }

    return (
        <Page width='auto' height='auto' color={theme.palette.background.default} style={{ minHeight: '95vh' }}>
            <Container fluid flex flexGrow color={theme.palette.background.default} width="100%">
                <Container fluid flex padded verticalCentered color={theme.palette.gray.main} maxHeight="30px">
                    <Text bold size={15}>{'Acessos - Vínculo de filial com usuário > Novo vínculo'}</Text>
                </Container>


                <Container fluid veryPadded centered>
                    <Container fluid width="50%" height="100%" hasShadow color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}>
                        <Container fluid veryPadded>
                            <Text size={35}>Informações do vínculo de filial com usuário</Text>
                            <Spacing top={PADDING} />
                            <Text size={16}>Os campos com asterisco são de preenchimento obrigatório</Text>
                            <Spacing top={VERY_PADDING * 2} />
                            <Container fluid width={'100%'}>
                                <Text size={12} style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: '400', lineHeight: '1.4375em' }}>Usuário *</Text>
                                <UserSelect
                                    value={userId}
                                    valueName={userName}
                                    error={userError}
                                    handleChangeValue={handleChangeUser}
                                    variant="standard" />
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text size={12} style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: '400', lineHeight: '1.4375em' }}>Filial *</Text>
                                <BranchSelect
                                    value={branchId}
                                    valueName={branchName}
                                    error={branchError}
                                    handleChangeValue={handleChangeBranch}
                                    variant="standard" />
                            </Container>

                            <Spacing top={VERY_PADDING * 2} />
                            {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                            <Container inline spacedAround veryPadded>
                                <LoadingButton
                                    variant="outlined"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : 'transparent', width: 150, height: 35, borderColor: theme.palette.primary.dark }}
                                    onClick={() => navigate('/branches-has-users')}>
                                    <Text size={16} color={theme.palette.primary.dark}>Cancelar</Text>
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : theme.palette.primary.dark, width: 150, height: 35 }}
                                    onClick={() => confirmRegistration()}>
                                    <Text size={16} color={theme.palette.background.paper}>Confirmar</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page >
    );
}

export default BranchHasUserRegister;