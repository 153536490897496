import React, { FC, createContext, useContext, useState } from 'react'

import { createBranchHasUser, updateBranchHasUser, getBranchesHasUser, destroyBranchHasUser, getBranchHasUser, getBranchesHasUserByBranch, destroyBranchHasUserBranchUser } from '../services/branchesHasUser';
import { IBranchHasUser, IBranchHasUserContext } from '../types'

const BranchesHasUserContext = createContext<IBranchHasUserContext>({} as IBranchHasUserContext);

export const BranchesHasUserProvider: FC<any> = ({ children }) => {
    const [branchesHasUser, setBranchesHasUser] = useState<IBranchHasUser[]>([]);
    const [branchesHasUserByBranch, setBranchesHasUserByBranch] = useState<IBranchHasUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchBranchHasUser, setSearchBranchHasUser] = useState<string>('');
    const [branchesHasUserPerRow, setBranchesHasUserPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchBranchesHasUser = async () => {
        setLoading(true);
        try {
            const branchesHasUser: IBranchHasUser[] = await getBranchesHasUser();
            setBranchesHasUser([...branchesHasUser]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchBranchHasUser = async (_id: string) => {
        const branchHasUser: IBranchHasUser = await getBranchHasUser(_id);
        return branchHasUser;
    }

    const fetchBranchesHasUserByBranch = async (_branchId: string) => {
        setLoading(true);
        try {
            setBranchesHasUserByBranch([]);
            const branchesHasUser: IBranchHasUser[] = await getBranchesHasUserByBranch(_branchId);
            setBranchesHasUserByBranch(branchesHasUser);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }


    const createNewBranchHasUser = async (_branchHasUser: IBranchHasUser, _reload: boolean = true) => {
        const branchHasUser = await createBranchHasUser(_branchHasUser);
        if (_reload)
            fetchBranchesHasUser();
        return branchHasUser;
    }

    const editBranchHasUser = async (_id: string, _payload: IBranchHasUser) => {
        try {
            const branchHasUser = await updateBranchHasUser(_id, _payload);

            fetchBranchesHasUser();
            return branchHasUser;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteBranchHasUser = async (_id: string) => {
        const branchHasUser = await destroyBranchHasUser(_id);
        fetchBranchesHasUser();
        return branchHasUser;
    }

    const deleteBranchHasUserBranchUser = async (_branchId: string, _userId: string) => {
        const branchHasUser = await destroyBranchHasUserBranchUser(_branchId, _userId);
        fetchBranchesHasUser();
        return branchHasUser;
    }

    return (
        <BranchesHasUserContext.Provider value={{
            branchesHasUser, branchesHasUserByBranch, loading, fetchBranchesHasUser, fetchBranchHasUser, fetchBranchesHasUserByBranch,
            createNewBranchHasUser, editBranchHasUser, deleteBranchHasUser,
            searchBranchHasUser, setSearchBranchHasUser, branchesHasUserPerRow, setBranchesHasUserPerRow, paginate, setPaginate,
            deleteBranchHasUserBranchUser
        }}>{children}</BranchesHasUserContext.Provider>
    )
}

export function useBranchesHasUser() {
    const context = useContext(BranchesHasUserContext);

    if (!context) {
        throw new Error('useBranchesHasUser must be used within an BranchesHasUserProvider')
    }

    return context;
}
