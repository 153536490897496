import 'react-image-lightbox/style.css';
import moment from 'moment';
import React, { FC, useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, IconButton } from '@mui/material';
import Lightbox from 'react-image-lightbox';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

import Container from '../../../atoms/container';
import Spacing from '../../../atoms/spacing';
import { ICarcass, ICarcassDetailedItem } from '../../../../types';
import { PADDING } from '../../../../utils/consts';
import { BodyText, Title2 } from '../../../atoms/text';


/* Displays carcass images and has a selector to toggle between external,
    internal or identifier pictures.
*/
const CarcassImages: FC<ICarcassDetailedItem> = (params: ICarcassDetailedItem) => {
    if (params.carcass === undefined)
        return null;

    const [openPhoto, setOpenPhoto] = useState<boolean>(false);
    const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
    const [selectedView, setSelectedView] = useState<'external' | 'internal' | 'identifier'>('external');
    const [inputCarcass, setInputCarcass] = useState<string>(String(partnerId_or_sequence(params.carcass)));

    const photos = {
        sideA: selectedView === 'identifier' ? params.carcass.ocrUri :
               selectedView === 'external' ? params.carcass.photoUri :
               params.carcass.internalUri,
        sideB: selectedView === 'identifier' ? params.carcass.ocrUriB :
               selectedView === 'external' ? params.carcass.photoUriB :
               params.carcass.internalUriB,
    }

    const carcassIndex = partnerId_or_sequence(params.carcass) ? params.carcassesIds.findIndex(
        (item) => String(item.value) === String(partnerId_or_sequence(params.carcass))) : -1;

    useEffect(() => {
        setInputCarcass(String(partnerId_or_sequence(params.carcass)));
    }, [params.carcass]);

    const selectPhoto = (_photoUri: string) => {
        setSelectedPhoto(_photoUri);
        setOpenPhoto(true);
    }

    const handleChangeCarcassNumber = (event: React.FocusEvent<HTMLInputElement>) => {
        if (inputCarcass !== String(partnerId_or_sequence(params.carcass))) {
            const newCarcass = params.carcassesIds.find((item) => String(item.value) === inputCarcass);
            if (newCarcass)
                params.setCurrCarcass(newCarcass.key)
            else
                setInputCarcass(String(partnerId_or_sequence(params.carcass)))
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter')
            event.currentTarget.blur();
    };

    function partnerId_or_sequence(carcass: ICarcass | undefined) {
        if(carcass){
            if(carcass.partnerId && !carcass.partnerId.includes('SV')){
                return carcass.partnerId;
            }else if(carcass.sequence){
                return carcass.sequence;
            }else if(carcass.partnerId){
                return carcass.partnerId;
            }
        }
        return ''
    }

    return (<>
        <Container inline style={{justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
            <Title2 style={{ margin: '0' }}>
                <IconButton
                    color='primary'
                    onClick={() => params.setCurrCarcass(
                        params.carcassesIds[carcassIndex - 1].key
                    )}
                    disabled={carcassIndex <= 0}
                >
                    <ChevronLeftIcon />
                </IconButton>
                <input
                    value={inputCarcass}
                    onChange={(e) => setInputCarcass(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleChangeCarcassNumber}
                    style={{ width: 'auto', background: 'none', border: 'none', textAlign: 'center' }}
                    size={4}
                />
                <IconButton
                    color='primary'
                    onClick={() => params.setCurrCarcass(
                        params.carcassesIds[carcassIndex + 1].key
                    )}
                    disabled={carcassIndex >= params.carcassesIds.length - 1}
                >
                    <ChevronRightIcon />
                </IconButton>
                {params.carcass.date ?
                    moment(params.carcass.date).format('DD/MM/YYYY')
                    : moment(params.carcass.classificationDate).format('DD/MM/YYYY')
                }
            </Title2>
            <Box sx={{ flexWrap: 'nowrap', textAlign: 'right' }}>
                {params.carcass.photoUri && <Button color='primary'
                    variant={selectedView === 'external' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('external')}
                >
                    EXTERNA
                </Button>}
                {params.carcass.internalUri && <Button color='primary'
                    variant={selectedView === 'internal' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('internal')}
                >
                    INTERNA
                </Button>}
                {params.carcass.ocrUri && <Button color='primary'
                    variant={selectedView === 'identifier' ? 'contained' : 'outlined'}
                    disableElevation
                    size='large'
                    style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
                    onClick={() => setSelectedView('identifier')}
                >
                    ETIQUETA
                </Button>}
            </Box>
        </Container>
        <Card sx={{
                background: 'white',
                borderRadius: '16px',
                opacity: params.loading ? 0.5 : 1,
                cursor: params.loading ? 'not-allowed' : 'default',
                borderTopRightRadius: '0',
            }}
            elevation={4}
        >
            <CardContent>
                <Grid container direction={selectedView === 'identifier' ? 'column' : 'row'}
                        alignItems='center' justifyContent='center'
                        height='100%' sx={{ xs: { height: 'auto !important', marginTop: '16px' } }}
                        rowSpacing={2} key={`carcasses-image-detail-${selectedView}`}
                >
                    <Grid item container xs alignItems='center'>
                        {photos.sideA &&
                        <>
                            <Container fluid centered maxWidth={selectedView === 'identifier' ? '76%' : '60%'}
                                style={{ margin: 'auto' }}
                                onClick={() => selectPhoto(photos.sideA!)} sx={{ cursor: 'pointer' }} relative>
                                <img
                                    src={photos.sideA}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                        overflow: 'hidden'
                                    }} />
                                <BodyText bold center>
                                    Lado A
                                    {params.carcass.bestPlaced !== undefined &&
                                    <><br />{params.carcass.bestPlaced ? 'Bem' : 'Mal'} posicionado</>}
                                </BodyText>
                            </Container>
                        </>}
                    </Grid>
                    <Grid item container xs alignItems='center'>
                        {photos.sideB &&
                        <>
                            <Spacing left={PADDING / 2} />
                            <Container fluid centered maxWidth={selectedView === 'identifier' ? '76%' : '60%'}
                                style={{ margin: 'auto', overflow: 'hidden' }}
                                onClick={() => selectPhoto(photos.sideB!)} sx={{ cursor: 'pointer' }} relative>
                                <img
                                    src={photos.sideB}
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden'
                                    }} />
                                <BodyText bold center>
                                    Lado B
                                    {params.carcass.bestPlacedB !== undefined &&
                                    <><br />{params.carcass.bestPlacedB ? 'Bem' : 'Mal'} posicionado</>}
                                </BodyText>
                            </Container>
                        </>}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        {openPhoto && selectedPhoto &&
            <Lightbox
                mainSrc={selectedPhoto}
                onCloseRequest={() => setOpenPhoto(false)}
                zoomInLabel="Mais zoom"
                zoomOutLabel="Menos zoom" />
        }
    </>);
}

export default CarcassImages;