export const parseJwt = (_token: string) => {
  try {
    var base64Url = _token.split(".")[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};

export function parseIntegerFromString(input: string) {
  const regex = /\d+/;
  const match = input.match(regex);

  if (match) {
    const number = parseInt(match[0], 10);
    return number;
  }

  return null;
}