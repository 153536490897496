import { AxiosResponse } from "axios";

import api from ".";
import { getCarcassClassificationClassName } from "../hooks/carcasses";
import { IAnnotation, IAnnotator, IRevision, IRevisionCount } from "../types";
import { convertApiResponseToIAnnotation } from "./annotations";
import { convertApiResponseToIAnnotator } from "./annotators";

const baseUrl: string = "revisions";

export const createRevision = async (_revision: IRevision) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    annotatorId: _revision.annotatorId,
    annotationId: _revision.annotationId,
    classificationId: _revision.classificationId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIRevision(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateRevision = async (_id: string, _revision: IRevision) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    annotatorId: _revision.annotatorId,
    annotationId: _revision.annotationId,
    classificationId: _revision.classificationId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIRevision(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyRevision = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getRevisions = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const revisions: IRevision[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const revision: IRevision = convertApiResponseToIRevision(data);

        revisions.push(revision);
      }

      return revisions;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getRevisionCount = async (_query: string) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/count_revised${_query}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const revision: IRevisionCount = convertApiResponseToIRevisionCount(
        response.data
      );
      return revision;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIRevisionCount = (_revisionCount: any) => {
  const revisionCount: IRevisionCount = {
    countRevised: _revisionCount.countRevised,
    countUnrevised: _revisionCount.countUnrevised,
  };
  return revisionCount;
};

export const getRevision = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const revision: IRevision = convertApiResponseToIRevision(response.data);
      return revision;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getRevisionDownload = async (_query: string) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/download_photos${_query}`,
    { responseType: "blob" }
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      // const revision: IRevision = convertApiResponseToIRevision(response.data);
      return response;
    } else if (status == 204) {
      throw new Error("Não foi encontrada nenhuma revisão na data selecionada");
    }
  }

  throw new Error("Não foi possível buscar os dados");
};
export const getRevisionDownloadFilePath = async (_filePath: string) => {
  const response: AxiosResponse = await api.post(
    `/api_redsoft/v1_0/${baseUrl}/download_photos`,
    { filePath: _filePath },
    { responseType: "blob" }
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      // const revision: IRevision = convertApiResponseToIRevision(response.data);
      return response;
    } else if (status == 204) {
      throw new Error("Não foi encontrada nenhuma carcaça na data selecionada");
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getRevisionForVerdicts = async (_query: string) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/for_verdict${_query}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotations: IRevision[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotation: IRevision = convertApiResponseToIRevision(data);

        annotations.push(annotation);
      }

      return annotations;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIRevision = (_revision: any) => {
  let annotator: IAnnotator | undefined = undefined;
  if (_revision.annotator)
    annotator = convertApiResponseToIAnnotator(_revision.annotator);
  let annotation: IAnnotation | undefined = undefined;
  if (_revision.annotation)
    annotation = convertApiResponseToIAnnotation(_revision.annotation);

  let classificationCarcass: string | undefined = undefined;
  if (annotation && annotation.carcass && annotation?.carcass.branch)
    classificationCarcass = getCarcassClassificationClassName(
      parseInt(_revision.classificationCarcassId)
    );
  let classificationAnnotation: string | undefined = undefined;
  if (annotation && annotation.carcass && annotation?.carcass.branch)
    classificationAnnotation = getCarcassClassificationClassName(
      parseInt(_revision.classificationAnnotationId)
    );
  let classificationRevision: string | undefined = undefined;
  if (annotation && annotation.carcass && annotation?.carcass.branch)
    classificationRevision = getCarcassClassificationClassName(
      parseInt(_revision.classificationRevisionId)
    );

  const revision: IRevision = {
    id: _revision.id,
    annotatorId: _revision.annotatorId,
    annotator,
    annotationId: _revision.annotationId,
    annotation,
    classificationId: _revision.classificationId,
    isDeleted: _revision.isDeleted,
    createdAt: _revision.createdAt,
    updatedAt: _revision.updatedAt,

    classificationCarcassId: _revision.classificationCarcassId,
    classificationCarcass: classificationCarcass,
    classificationAnnotationId: _revision.classificationAnnotationId,
    classificationAnnotation: classificationAnnotation,
    classificationRevisionId: _revision.classificationRevisionId,
    classificationRevision: classificationRevision,
  };

  return revision;
};
