import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Divider } from '@mui/material'
import moment from 'moment'

import { Container, Image, Page, Spacing, Text, theme } from '../../components'
import { useCarcass } from '../../hooks/carcasses'
import api from '../../services'
import { fetchCarcassById as fetchCarcassByIdService } from '../../services/carcasses'
import { ICarcass } from '../../types'
import { capitalizeTheFirstLetterOfEachWord } from '../../utils/stringFormatters';


const CarcassesFlow: FC = () => {
  const { id } = useParams()
  const { getCarcassClassification } = useCarcass()

  const [carcass, setCarcass] = useState<ICarcass | null>(null)

  useEffect(() => {
    fetchCarcass()
  }, [])

  const fetchCarcass = async () => {
    setCarcass(null)

    if (id) {
      const carcass: ICarcass | null = await fetchCarcassByIdService(id);
      if (carcass) setCarcass(carcass)
    }
  }


  if (!carcass) return null
  return (
    <Page
      width='auto'
      height='auto'
      veryPadded
      style={{ minHeight: '89.5vh' }}>
      <Container
        color={theme.palette.background.paper}
        borderRadius={20}
        height='auto'>

        <Container fluid veryPadded >
          <Text bold size={20}>Informações Individuais</Text>
          <Spacing top={5} />
          <Container inline veryPadded hasShadow spacedAround borderRadius={10}>
            <Container fluid padded centered>
              <Image source={`${api.defaults.baseURL}carcasses/images/${carcass.photoUri}`} height={500} width={350} />
              <Text size={13}>{`Banda ${carcass.sideCarcass} (${moment(carcass.date).format('HH:mm:ss')})`}</Text>
            </Container>

            <Container fluid padded hasShadow color={theme.palette.secondary.main} style={{ minWidth: '30%' }}>
              <Container color={theme.palette.background.default} style={{ marginBottom: 3 }}>
                <Text bold>Informações da fazenda</Text>
              </Container>
              <Text>Fazenda: <Text bold>{capitalizeTheFirstLetterOfEachWord(carcass.farm)}</Text></Text>
              <Text>Cidade: <Text bold>{capitalizeTheFirstLetterOfEachWord(carcass.city)}</Text></Text>
              <Text>UF: <Text bold>{carcass.uf}</Text></Text>

              <Divider style={{ marginTop: 3, marginBottom: 3 }} />

              <Container color={theme.palette.background.default} style={{ marginBottom: 3 }}>
                <Text bold>Informações da carcaça</Text>
              </Container>
              <Text>Data de abate: <Text bold>{moment(carcass.slaughterDate).format('DD/MM/YYYY')}</Text></Text>
              <Text>Lote: <Text bold>{carcass.lotNum}</Text></Text>
              <Text>Raça: <Text bold>{capitalizeTheFirstLetterOfEachWord(carcass.breed)}</Text></Text>
              <Text>Sexo: <Text bold>{carcass.sex}</Text></Text>
              {carcass.weight && <Text>Peso: <Text bold>{carcass.weight} kg</Text></Text>}
              <Text>Maturidade: <Text bold>{carcass.maturityId}</Text></Text>
              <Text>Lado carcaça: <Text bold>{carcass.sideCarcass}</Text></Text>
              <Text>Cronologia dentária: <Text bold>{carcass.dentition} dentes</Text></Text>
              <Text>Documentação: <Text bold>{carcass.docId}</Text></Text>
              <Text>Rastreamento: <Text bold>{carcass.isTracked ? 'Sim' : 'Não'}</Text></Text>
              <Text>Sisbov: <Text bold>{carcass.sibovId}</Text></Text>

              <Divider style={{ marginTop: 3, marginBottom: 3 }} />

              <Container color={theme.palette.background.default} style={{ marginBottom: 3 }}>
                <Text bold>Informações da IA</Text>
              </Container>
              {carcass.classificationId && <Text>Classificação: <Text bold>{getCarcassClassification(carcass.classificationId)}</Text></Text>}
              {carcass.syncAt && <Text>Última sincronização: <Text bold>{moment(carcass.syncAt).format("D/MM/YY HH:m:ss")}</Text></Text>}
            </Container>
          </Container>
        </Container>
      </Container>
    </Page>
  )
}

export default CarcassesFlow;