import { FC } from "react";
import { Button, Divider, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { IDashboardDentitionMultiSelect } from "../../../../types";
import Text from '../../../atoms/text';
import { theme } from '../../../../components'


interface IDentition {
    label: string;
    value: number;
}

const DashboardDentitionMultiSelect: FC<IDashboardDentitionMultiSelect> = (params: IDashboardDentitionMultiSelect) => {
    
    const dentitionOptions: IDentition[] = [
        {
            label: '0 dentes',
            value: 0,
        },
        {
            label: '2 dentes',
            value: 2,
        },
        {
            label: '4 dentes',
            value: 4,
        },
        {
            label: '6 dentes',
            value: 6,
        },
        {
            label: '8 dentes',
            value: 8,
        },
    ]

    return (
        <Select
            multiple
            displayEmpty
            value={params.dentitions}
            onChange={params.onChangeDentitions}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (selected.length === 0) {
                return <p style={{color: '#C8C8C8'}}>Dentição</p>;
                }
                return selected.join(', ');
            }}
            sx={{
                minWidth:"100%",
                height: '37px',
                backgroundColor: '#FFF',
                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                borderRadius: '4px',
                marginTop: '0px'
            }}
        >   
            <MenuItem disabled value="">
                <em>Dentição</em>
            </MenuItem>
            {dentitionOptions.map((dentition) => (
                <MenuItem key={dentition.label} value={dentition.value}>
                    <Checkbox checked={params.dentitions.indexOf(dentition.value) > -1} />
                    <ListItemText primary={dentition.label} />
                </MenuItem>
            ))}
            {params.dentitions.length > 0 && (
                <>
                    <Divider sx={{ width: "100%" }} />
                    <MenuItem value="">
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setDentitions([])}
                            sx={{width: '100%', justifyContent: 'end'}}
                        >
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </MenuItem>
                </>
            )}
        </Select>
    )
}

export default DashboardDentitionMultiSelect