import { FC } from 'react'
import { Grid, IconButton } from '@mui/material'

import Container from '../../../../atoms/container'
import Spacing from '../../../../atoms/spacing'
import Text from '../../../../atoms/text'
import theme from '../../../../theme'
import { IListColumn, } from '../../../../../types'
import { Delete as DeleteIcon } from '@mui/icons-material';
import { BORDER_RADIUS, PADDING } from '../../../../../utils/consts';
import { useConfirmation } from '../../../../../hooks/confirmation'
import { useInformation } from '../../../../../hooks/information'
import { useMonitoringRecipients } from '../../../../../hooks/monitoringRecipient'

const MonitoringRecipientListItem: FC<IListColumn> = (params: any) => {

    const { deleteMonitoringRecipient } = useMonitoringRecipients();
    const { showInformation } = useInformation();
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const onDeleteMonitoringRecipient = async (_monitoringRecipientId: string) => {

        showConfirmation("Deseja excluir monitoramento", "Excluir Monitoramento", "Confirma", "Cancelar",
        async () => {
            await deleteMonitoringRecipient(_monitoringRecipientId);
    
            hideConfirmation();
            showInformation('Monitoramento excluído com sucesso.', 'Exclusão de monitoramento');
        },
        () => {
            hideConfirmation();
        });        
    }

    return (
        <Container fluid flex padded>
            <Container fluid flex padded centered>                
                    <Container fluid hasShadow padded borderRadius={BORDER_RADIUS} color={theme.palette.grey[100]} width="95%">
                        <Grid container>
                            <Grid item xs={6}>
                                <Text bold size={14} color={theme.palette.grey[600]}>Filial:
                                    <Text bold size={14} color={theme.palette.grey[500]}>
                                        {` ${params.branch.name}`}
                                    </Text>
                                </Text>
                            </Grid>
                            <Grid item xs={4}>
                                <Text bold size={14} color={theme.palette.grey[600]}>Tipo:
                                    <Text bold size={14} color={theme.palette.grey[500]}>
                                        {` ${params.monitoringType.name}`}
                                    </Text>
                                </Text>
                            </Grid>
                            <Grid item xs={2}>
                                <Container inline horizontalCentered>
                                    <IconButton size="small" color="error" style={{ backgroundColor: 'transparent' }} onClick={() => onDeleteMonitoringRecipient(params.id)}>
                                        <DeleteIcon fontSize="small" color="error" />
                                    </IconButton>
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                    <Spacing top={PADDING} />
            </Container>
        </Container>
    )
}

export default MonitoringRecipientListItem