import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Box, Stack, Divider } from '@mui/material';
import 'react-image-lightbox/style.css';
import moment from 'moment';

import Container from '../../../atoms/container';
import CarcassImages from './CarcassImages';
import theme from '../../../theme';

import { capitalizeTheFirstLetterOfEachWord } from '../../../../utils/stringFormatters';
import { colors } from '../../../theme/styles';
import { ICarcass } from '../../../../types';
import { ICarcassDetailedItem } from '../../../../types';
import { Title1, Title2, Title3, Title4, LargeText, BodyText } from '../../../atoms/text';

/* Card displaying detailed information about a carcass (i.e. data and pictures) */
const CarcassDetailedItem: FC<ICarcassDetailedItem> = (params: ICarcassDetailedItem) => {
    const renderSequenceItemTitle = (carcass: ICarcass) => {
        if(carcass.partnerId && !carcass.partnerId.includes('SV'))
            return `Sequencial ${carcass.partnerId}`
        else if(carcass.sequence)
            return `Ordinal ${carcass.sequence}`
        return `Sequencial ${carcass.partnerId}`
    }

    const data = params.carcass ? [
        {
            include: params.carcass.partnerId,
            title: 'Sequencial ERP',
            value: <>{params.carcass.partnerId}</>,
        },
        {
            include: (params.carcass.date || params.carcass.classificationDate),
            title: 'Data de abate',
            value: <>{params.carcass.date ?
                moment(params.carcass.date).add(1, 'h').format('DD/MM/YYYY HH:mm:ss') //Essa vem no horario do mato-grosso
                : (moment(params.carcass.classificationDate).format('HH:mm:ss') === '00:00:00' ?
                    moment(params.carcass.classificationDate).format('DD/MM/YYYY') :
                    moment(params.carcass.classificationDate).subtract(3, 'h').format('DD/MM/YYYY HH:mm:ss') //Essa vem no UTC
            )
                
                }
            </>,
        },
        {
            include: params.carcass.typification,
            title: 'Tipificação',
            value: <>{params.carcass.typification}</>,
        },
        {
            include: params.carcass.breed,
            title: 'Raça',
            value: <>{
                params.carcass.breed &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.breed)
            }</>,
        },
        {
            include: params.carcass.verdicTypification,
            title: 'Classificação VAR',
            value: <>{params.carcass.verdicTypification}</>,
        },
        {
            include: params.carcass.complement,
            title: 'Acabamento',
            value: <>{
                params.carcass.complement &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.complement)
            }</>,
        },
        {
            include: params.carcass.conformation,
            title: 'Conformação',
            value: <>{
                params.carcass.conformation &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.conformation)
            }</>,
        },
        {
            include: params.carcass.dif,
            title: 'Registro DIF',
            value: <>{params.carcass.dif !== ' ' ? params.carcass.dif : 'Não'}</>,
        },
        {
            include: params.carcass.destination,
            title: 'Destino de abate',
            value: <>{params.carcass.destination !== ' ' ? params.carcass.destination : 'Não'}</>,
        },
        {
            include: params.carcass.city,
            title: 'Cidade',
            value: <>{
                params.carcass.city &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.city)
            }</>,
        },
        {
            include: params.carcass.uf,
            title: 'UF',
            value: <>{params.carcass.uf}</>,
        },
        {
            include: typeof params.carcass.dentition === 'number',
            title: 'Dentição',
            value: <>{params.carcass.dentition} dentes</>,
        },
        {
            include: params.carcass.lotNum,
            title: 'Lote',
            value: <>{params.carcass.lotNum}</>,
        },
        {
            include: params.carcass.lotNum,
            title: 'Contrato',
            value: <>{params.carcass.docNum}</>,
        },
        {
            include: params.carcass.farm,
            title: 'Fazenda',
            value: <>{
                params.carcass.farm &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.farm)
            }</>,
        },
        {
            include: params.carcass.farm,
            title: 'Proprietário',
            value: <>{
                params.carcass.rancherName ?
                capitalizeTheFirstLetterOfEachWord(params.carcass.rancherName) : '-'
            }</>,
        },
        {
            include: params.carcass.producerName,
            title: 'Produtor',
            value: <>{
                params.carcass.producerName &&
                capitalizeTheFirstLetterOfEachWord(params.carcass.producerName)
            }</>,
        },
        {
            include: params.carcass.sex,
            title: 'Sexo',
            value: <>{params.carcass.sex === 'M' ? 'Macho' : params.carcass.sex == 'F' ? 'Fêmea' : 'Não informado'}</>,
        },
        {
            include: params.carcass.troop,
            title: 'Tropa',
            value: <>{params.carcass.troop}</>,
        },
        {
            include: params.carcass.maturityId,
            title: 'Maturidade',
            value: <>{params.carcass.maturityId}</>,
        },
        {
            include: params.carcass.weight,
            title: 'Peso',
            value: <>
                {
                    params.carcass.weight ?
                    `${(params.carcass.weight).toFixed(2)} kg (${(params.carcass.weight / 15).toFixed(2)} @)`
                    : '-'
                }
            </>,
        },
    ] : [];

    return (<>
        {params.carcass && <>
            <Container inline>
                <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} md={8} sx={{ md: { height: '100%' } }}>
                        <CarcassImages
                            carcass={params.carcass}
                            loading={params.loading}
                            carcassesIds={params.carcassesIds}
                            setCurrCarcass={params.setCurrCarcass}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} height='100%' justifyContent='center' sx={{ padding: 2 }}>
                        <div style={{ marginBottom: 16 }}>
                            <Title1 style={{ marginBottom: 8 }}>{renderSequenceItemTitle(params.carcass)}</Title1>
                            {params.carcass.iaTypification && <>
                                <Title2>
                                    <div>
                                        <span style={{ color: colors.textColor }}>
                                            Classificação Redsoft:&nbsp;
                                        </span>
                                        {params.carcass.iaTypification}
                                    </div>
                                </Title2>
                            </>}
                            {params.carcass.sequence && 
                                <Title3>
                                    <div>
                                        <span style={{ color: colors.textColor }}>
                                            Ordinal Redsoft:&nbsp;
                                        </span>
                                        {params.carcass.sequence}
                                    </div>
                                </Title3>
                            }
                        </div>
                        <Divider sx={{ margin: '8px auto 16px', borderColor: theme.palette.primary.light, opacity: 0.7 }} />
                        <BodyText bold style={{ paddingTop: '8px', marginBottom: '16px' }} >Dados do ERP</BodyText>
                        <Stack spacing={1} divider={<Divider />}>
                            {data.map((item, index) => (
                                item.include &&
                                <Container fluid inline key={index} style={{ justifyContent: 'space-between' }}>
                                    <LargeText>{item.title}:&nbsp;</LargeText>
                                    <LargeText bold right>{item.value}</LargeText>
                                </Container>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            </>}
            {params.carcass === undefined &&
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Title4 center style={{ paddingTop: '40px', paddingBottom: '32px' }}>
                        Nenhuma carcaça selecionada, utilize a listagem à esquerda e os filtros para visualizar os detalhes de uma carcaça.
                    </Title4>
                </Box>
            }
    </>);
}

export default CarcassDetailedItem;