import { AxiosResponse } from "axios";

import api from ".";
import { IBranch, IBranchHasUser, IUser } from "../types";
import { convertApiResponseToIBranch } from "./branches";
import { convertApiResponseToIUser } from "./users";

const baseUrl: string = "branches_has_users";

export const createBranchHasUser = async (_branchHasUser: IBranchHasUser) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, _branchHasUser);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIBranchHasUser(response.data);
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      if ((response as any).response.data.error) throw new Error((response as any).response.data.error as string);
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateBranchHasUser = async (_id: string, _branchHasUser: IBranchHasUser) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    branchId: _branchHasUser.branchId,
    userId: _branchHasUser.userId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIBranchHasUser(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyBranchHasUser = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const destroyBranchHasUserBranchUser = async (_branchId: string, _userId: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/branch/${_branchId}/user/${_userId}`);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getBranchesHasUser = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const branchesHasUser: IBranchHasUser[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const branchHasUser: IBranchHasUser = convertApiResponseToIBranchHasUser(data);

        branchesHasUser.push(branchHasUser);
      }

      return branchesHasUser;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getBranchHasUser = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const branchHasUser: IBranchHasUser = convertApiResponseToIBranchHasUser(response.data);
      return branchHasUser;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getBranchesHasUserByBranch = async (_branchId: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/branch/${_branchId}`);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const users: IUser[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const user: IUser = convertApiResponseToIUser(data);
        users.push(user);
      }

      return users;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIBranchHasUser = (_branchHasUser: any) => {
  let branch: IBranch | undefined = undefined;
  if (_branchHasUser.branch) branch = convertApiResponseToIBranch(_branchHasUser.branch);
  let user: IUser | undefined = undefined;
  if (_branchHasUser.user) user = convertApiResponseToIUser(_branchHasUser.user);

  const branchHasUser: IBranchHasUser = {
    id: _branchHasUser.id,
    branchId: _branchHasUser.branchId,
    branch,
    userId: _branchHasUser.userId,
    user,
    isDeleted: _branchHasUser.isDeleted,
    createdAt: _branchHasUser.createdAt,
    updatedAt: _branchHasUser.updatedAt,
  };

  return branchHasUser;
};
