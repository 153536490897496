import React, { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Divider, IconButton, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import logo from "../../assets/logo_dark.png";
import logoBrand from '../../assets/logo_brand.png'
import logoIcon from '../../assets/logo.png'
import { Container, Image, Page, Spacing, Text, theme } from "../../components";
import { useAuth } from "../../hooks/auth";
import { useInformation } from "../../hooks/information";
import { IAuthLogin, IAuthPasswordRecovery } from "../../types/hooks/auth";
import { PADDING, VERY_PADDING } from "../../utils/consts";
import Modal from "../../components/atoms/modal";

const PasswordRecovery: FC = () => {
    const authHooks = useAuth();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const { hideInformation, showInformation } = useInformation();
    const navigate = useNavigate();
    const { token, key } = useParams();

    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [confirmShowPassword, setConfirmShowPassword] = useState<boolean>(false);
    const [loadingToken, setLoadingToken] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleChangeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickConfirmShowPassword = () => setConfirmShowPassword(!confirmShowPassword);

    const fetchData = async () => {
        if (token) {
            const passwordRecovery: IAuthPasswordRecovery = await authHooks.getPasswordRecovery(token);

            if (passwordRecovery.used)
                showInformation("Esse token de recuperação já foi utilizado. Se você não o utilizou, favor entrar em contato com a nossa equipe pelo e-mail contato@pixforce.com.br.",
                    undefined, undefined, () => {
                        hideInformation();
                        navigate("/");
                    })
            setLoadingToken(false);
        } else {
            setLoadingToken(false);
        }
    }

    const onPasswordRecovery = async () => {
        if (!token && !key) return;

        try {
            setLoading(true);

            if (token) {
                const passwordRecovery: IAuthLogin = await authHooks.putPasswordRecovery(token, password);
                if (passwordRecovery.accessToken)
                    showInformation("Senha alterada com sucesso.",
                        undefined, undefined, () => {
                            hideInformation();
                            navigate("/");
                        })
                else showInformation("Ocorreu um erro ao tentar recuperar a senha. Por favor, tente novamente mais tarde.");
            } else if (key) {
                await authHooks.updateRecoveryPasswordKey(key, password);
                showInformation("Senha alterada com sucesso.",
                    undefined, undefined, () => {
                        hideInformation();
                        navigate("/");
                    });
            }
            setLoading(false);
        } catch (_err) {
            setLoading(false);
            showInformation("Ocorreu um erro ao tentar recuperar a senha. Por favor, tente novamente mais tarde.");
        }
    }

    return (
        <Page>
            <Container fluid flex padded color={theme.palette.background.default}>
                <Container inline horizontalCentered>
                    <Image source={logoIcon} height={40} width={40 * 1.666} />
                    <Image source={logoBrand} height={35} width={35 * 4.16} />
                </Container>
                <Divider />
                <Container fluid flex horizontalCentered veryVerticalPadded sx={{ marginTop: 50 }}>
                    <Container fluid flex padded minWidth="40%" maxWidth="500px">
                        <Container fluid flex centered spacedEvenly width="100%">
                            <Container fluid centered width="80%">
                                <Container fluid centered>
                                    <Container fluid>
                                        <Image source={logo} height={200} width={200 * 1.4857} />
                                        <Spacing top={VERY_PADDING} />
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                    {token &&
                                        <>
                                            <Text medium size={16} color={theme.palette.text.primary}>
                                                Recuperação de senha
                                            </Text>
                                            <Spacing top={PADDING} />
                                            <Container fluid centered>
                                                <Text center light size={14} color={theme.palette.text.primary}>
                                                    Por favor, preencha e confirme sua nova senha.
                                                </Text>
                                            </Container>
                                        </>
                                    }
                                    {key &&
                                        <>
                                            <Text medium size={16} color={theme.palette.text.primary}>
                                                Atualização de senha
                                            </Text>
                                            <Spacing top={PADDING} />
                                            <Container fluid centered>
                                                <Text center light size={14} color={theme.palette.text.primary}>
                                                    Estamos melhorando nossa segurança no gerenciamento de acessos. Por favor, preencha e confirme uma nova senha.
                                                </Text>
                                            </Container>
                                        </>
                                    }
                                </Container>
                                <Spacing top={VERY_PADDING * 2} />

                                <form onSubmit={handleSubmit(onPasswordRecovery)} style={{ width: "100%" }}>
                                    <Container fluid flex centered>
                                        <Container width="100%">
                                            <Container fluid>
                                                <TextField id="login-passw" label="Senha" size="small" disabled={loading}
                                                    type={showPassword ? "text" : "password"} value={password}
                                                    {
                                                    ...register("password", {
                                                        onChange: (
                                                            event: React.ChangeEvent<HTMLInputElement>
                                                        ) => handleChangePassword(event),
                                                        required: "Informe sua senha",
                                                    })
                                                    }
                                                    InputProps={{
                                                        endAdornment: <IconButton
                                                            size="small"
                                                            aria-label="passw-togle-visibility"
                                                            onClick={() => { handleClickShowPassword() }}
                                                            edge="end"                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    }}
                                                />
                                                {errors.password && (
                                                    <Text
                                                        size={13}
                                                        color={theme.palette.error.main}
                                                        style={{ marginBottom: 3 }}
                                                    >
                                                        {(errors.password as any).message}
                                                    </Text>
                                                )}
                                            </Container>
                                            <Spacing top={PADDING} />
                                            <Container fluid>
                                                <TextField id="login-passw-confirm" label="Confirmar senha" size="small" disabled={loading}
                                                    type={confirmShowPassword ? "text" : "password"} value={confirmPassword}
                                                    {
                                                    ...register("confirmPassword", {
                                                        onChange: (
                                                            event: React.ChangeEvent<HTMLInputElement>
                                                        ) => handleChangeConfirmPassword(event),
                                                        required: "Confirme sua senha",
                                                    })
                                                    }
                                                    InputProps={{
                                                        endAdornment: <IconButton
                                                            size="small"
                                                            aria-label="passw-togle-visibility"
                                                            onClick={() => { handleClickConfirmShowPassword() }}
                                                            edge="end"                        >
                                                            {confirmShowPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    }}
                                                />
                                                {errors.confirmPassword && (
                                                    <Text
                                                        size={13}
                                                        color={theme.palette.error.main}
                                                        style={{ marginBottom: 3 }}
                                                    >
                                                        {(errors.confirmPassword as any).message}
                                                    </Text>
                                                )}
                                                {password != "" && confirmPassword != "" && password != confirmPassword &&
                                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>
                                                        As senhas não coincidem
                                                    </Text>
                                                }
                                            </Container>

                                            <Container fluid width="100%" centered>
                                                <Text
                                                    size={13}
                                                    color={theme.palette.error.main}
                                                    style={{ marginBottom: 3 }}>
                                                    {errorMessage || ""}
                                                </Text>
                                            </Container>
                                        </Container>
                                        <Spacing top={VERY_PADDING} />
                                        <Container inline spacedEvenly width="100%">
                                            <Button id="login-button" type="submit" size="medium" disabled={loading || (password == "" && confirmPassword == "" && password != confirmPassword)}
                                                sx={{
                                                    width: "180px",
                                                    backgroundColor: loading ? theme.palette.grayLight.main : theme.palette.primary.main,
                                                }}>
                                                <Text size={14} color={loading ? theme.palette.grayLight.light : theme.palette.background.default}>
                                                    Redefinir senha
                                                </Text>
                                            </Button>
                                        </Container>
                                    </Container>
                                </form>
                            </Container>
                            {/* <PasswordRecoveryInformations /> */}
                        </Container>
                    </Container>
                </Container>

                <Modal isOpened={loadingToken} >
                    <Container fluid flex centered>
                        <Spacing top={VERY_PADDING * 5} />
                        <CircularProgress sx={{ color: theme.palette.primary.light }} />
                    </Container>
                </Modal>
            </Container>
        </Page >
    );
}

export default PasswordRecovery;
