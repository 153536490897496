import React, { FC } from 'react';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import theme from '../../../theme';
import { useMonitoringTypes } from '../../../../hooks/monitoringTypes';
import { IMonitoringTypeSelect } from '../../../../types/components/molecules/monitoringTypes';


const MonitoringTypeSelect: FC<IMonitoringTypeSelect> = (params: IMonitoringTypeSelect) => {
    const { monitoringTypes } = useMonitoringTypes();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <Autocomplete
                id="monitoringTypes"
                required
                variant={params.variant || 'outlined'}
                options={params.options || monitoringTypes as any[]}
                value={params.value}
                defaultValue={params.valueName}
                onChange={params.handleChangeValue}
                placeholder={params.placeholder || 'Escolha o tipo'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists={params.value != ''} />
        </Container>
    );
}

export default MonitoringTypeSelect;