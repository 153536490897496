import React, { FC, useEffect, useState } from 'react';
import { Divider, Grid, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import Container from '../../../atoms/container';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import Input from '../../../atoms/input';
import { useCompanies } from '../../../../hooks/companies';
import theme from '../../../theme';
import { ICompany, ICompanyRegisterModal } from '../../../../types';
import { BORDER_RADIUS, VERY_PADDING } from '../../../../utils/consts';

const CompanyRegisterModal: FC<ICompanyRegisterModal> = (params: ICompanyRegisterModal) => {
    const isEdit = params.companyToEdit != undefined;

    const { createNewCompany, editCompany } = useCompanies();

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [fridge, setFridge] = useState<string>('');
    const [fridgeError, setFridgeError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [cnpj, setCnpj] = useState<string>('');
    const [cnpjError, setCnpjError] = useState<string | null>(null);
    const [token, setToken] = useState<string>('');
    const [tokenError, setTokenError] = useState<string | null>(null);
    const [prefix, setPrefix] = useState<string>('');
    const [prefixError, setPrefixError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (params.companyToEdit) {
            setName(params.companyToEdit.name);
            setFridge(params.companyToEdit.fridge);
            setEmail(params.companyToEdit.email || '');
            setCnpj(params.companyToEdit.cnpj || '');
            setToken(params.companyToEdit.token);
            setPrefix(params.companyToEdit.prefix);
        }
    }, [params.companyToEdit]);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }
    const handleChangeFridge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFridge(event.target.value);
        setFridgeError(null);
        setError(null);
    }
    const handleChangeCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCnpj(event.target.value);
        setCnpjError(null);
        setError(null);
    }
    const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
        setTokenError(null);
        setError(null);
    }
    const handleChangePrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrefix(event.target.value);
        setPrefixError(null);
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (name == '') {
            setNameError('Informe o nome completo da empresa');
            isValid = false;
        }
        if (fridge == '') {
            setFridgeError('Informe o nome do frigorífico');
            isValid = false;
        }
        if (prefix == '') {
            setNameError('Informe o prefixo da empresa');
            isValid = false;
        }


        if (isValid) {
            setNameError(null);
            setEmailError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let payload: ICompany = {
                name,
                email,
                fridge,
                cnpj,
                token,
                prefix
            };
            if (isEdit && params.companyToEdit && params.companyToEdit.id) {
                await editCompany(params.companyToEdit.id, payload);
            } else {
                await createNewCompany(payload, true);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!isEdit) informationMessage = 'A nova empresa foi cadastrada com sucesso.';
            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setFridge('');
        setFridgeError(null);
        setCnpj('');
        setCnpjError(null);
        setToken('');
        setTokenError(null);
        setPrefix('');
        setPrefixError(null);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid relative hasShadow centered color={theme.palette.background.default} maxWidth="800px">
                <Container inline centered width="100%" color={theme.palette.background.default} borderTopRadius={BORDER_RADIUS}>
                    <Container fluid flex veryPadded>
                        <Text size={30} thin color={theme.palette.primary.main}>{!isEdit ? 'Nova empresa' : 'Editar empresa'}</Text>
                    </Container>

                    <Container absolute sx={{ top: 0, right: 0 }}>
                        <IconButton style={{}} onClick={close}>
                            <CloseIcon style={{ color: theme.palette.primary.light }} />
                        </IconButton>
                    </Container>
                </Container>
                <Divider sx={{ width: "95%" }} />
                <Spacing top={VERY_PADDING} />
                <Container fluid flex flexGrow width="95%" minHeight="100px">
                    <Grid container rowSpacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Nome:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="name"
                                    autoFocus
                                    required
                                    variant="outlined"
                                    value={name}
                                    onChange={handleChangeName}
                                    placeholder='Informe o nome da empresa'
                                    error={nameError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {nameError && <Text size={13} color={theme.palette.error.main} >{nameError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Frigorífico:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="fridge"
                                    required
                                    variant="outlined"
                                    value={fridge}
                                    onChange={handleChangeFridge}
                                    placeholder='Informe o nome do frigorífico'
                                    error={fridgeError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {fridgeError && <Text size={13} color={theme.palette.error.main} >{fridgeError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>E-mail:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={handleChangeEmail}
                                    placeholder='Informe o e-mail da empresa'
                                    error={emailError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>CNPJ:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="cnpj"
                                    variant="outlined"
                                    value={cnpj}
                                    onChange={handleChangeCnpj}
                                    placeholder='Informe o CNPJ da empresa'
                                    error={cnpjError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {cnpjError && <Text size={13} color={theme.palette.error.main} >{cnpjError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Token:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="token"
                                    variant="outlined"
                                    value={token}
                                    onChange={handleChangeToken}
                                    placeholder='Informe o Token de sincronização com o frigorífico'
                                    error={tokenError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {tokenError && <Text size={13} color={theme.palette.error.main} >{tokenError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Prefixo:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="prefix"
                                    variant="outlined"
                                    value={prefix}
                                    onChange={handleChangePrefix}
                                    placeholder='Informe o prefixo da empresa'
                                    error={prefixError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {prefixError && <Text size={13} color={theme.palette.error.main} >{prefixError}</Text>}
                            </Container>
                        </Grid>
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.info.main, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default CompanyRegisterModal;