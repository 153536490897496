import React, { FC } from 'react';
import { Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import moment from 'moment';

import Container from '../../../atoms/container';
import { IAnnotatorAccessCard } from '../../../../types';
import Text from '../../../atoms/text';
import Spacing from '../../../atoms/spacing';
import theme from '../../../theme';
import { useBranches } from '../../../../hooks/branches';
import { PADDING } from '../../../../utils/consts';

const AnnotatorAccessCard: FC<IAnnotatorAccessCard> = (params: IAnnotatorAccessCard) => {
    const { branches } = useBranches();

    return (
        <Container inline hasShadow horizontalPadded centered height="40px" color={theme.palette.background.paper}>
            <Grid container>
                <Grid item xs={3}>
                    <Spacing top={PADDING / 2} />
                    <Container inline>
                        <Text light size={13}>Data:</Text>
                        <Spacing left={PADDING} />
                        <Text medium size={13}>{moment(params.access.releasedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                    </Container>
                </Grid>
                <Grid item xs={3}>
                    <Spacing top={PADDING / 2} />
                    <Container inline>
                        <Text light size={13}>Qtd liberada:</Text>
                        <Spacing left={PADDING} />
                        <Text medium size={13}>{params.access.releasedQuantity}</Text>
                    </Container>
                </Grid>
                <Grid item xs={3}>
                    <Spacing top={PADDING / 2} />
                    <Container inline>
                        <Text light size={13}>Empresa:</Text>
                        <Spacing left={PADDING} />
                        <Text medium size={13}>{branches.find(item => item.id == params.access.branchId)?.name}</Text>
                    </Container>
                </Grid>
                <Grid item xs={3}>
                    <Container inline flexEnd>
                        <IconButton size="small" color="error" style={{ backgroundColor: 'transparent' }} onClick={() => params.onRemove(params.access.id)}>
                            <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AnnotatorAccessCard;