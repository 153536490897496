import React, { FC, createContext, useContext, useState } from 'react'

import { createMonitoringType, updateMonitoringType, getMonitoringTypes, destroyMonitoringType, getMonitoringType } from '../services/monitoringTypes';
import { IMonitoringType, IMonitoringTypeContext } from '../types'

const MonitoringTypesContext = createContext<IMonitoringTypeContext>({} as IMonitoringTypeContext);

export const MonitoringTypesProvider: FC<any> = ({ children }) => {
    const [monitoringTypes, setMonitoringTypes] = useState<IMonitoringType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchMonitoringType, setSearchMonitoringType] = useState<string>('');
    const [monitoringTypesPerRow, setMonitoringTypesPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchMonitoringTypes = async () => {
        setLoading(true);
        try {
            const monitoringTypes: IMonitoringType[] = await getMonitoringTypes();
            setMonitoringTypes([...monitoringTypes]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }



    const fetchMonitoringType = async (_id: string) => {
        const monitoringType: IMonitoringType = await getMonitoringType(_id);
        return monitoringType;
    }

    const createNewMonitoringType = async (_monitoringType: IMonitoringType, _reload: boolean = true) => {
        const monitoringType = await createMonitoringType(_monitoringType);
        if (_reload)
            fetchMonitoringTypes();
        return monitoringType;
    }

    const editMonitoringType = async (_id: string, _payload: IMonitoringType) => {
        try {
            const monitoringType = await updateMonitoringType(_id, _payload);

            fetchMonitoringTypes();
            return monitoringType;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteMonitoringType = async (_id: string) => {
        const monitoringType = await destroyMonitoringType(_id);
        fetchMonitoringTypes();
        return monitoringType;
    }

    return (
        <MonitoringTypesContext.Provider value={{
            monitoringTypes, loading, fetchMonitoringTypes, fetchMonitoringType,
            createNewMonitoringType, editMonitoringType, deleteMonitoringType,
            searchMonitoringType, setSearchMonitoringType, monitoringTypesPerRow, setMonitoringTypesPerRow, paginate, setPaginate
        }}>{children}</MonitoringTypesContext.Provider>
    )
}

export function useMonitoringTypes() {
    const context = useContext(MonitoringTypesContext);

    if (!context) {
        throw new Error('useMonitoringTypes must be used within an MonitoringTypesProvider')
    }

    return context;
}
