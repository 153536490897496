import React, { FC, useEffect, useState } from 'react';
import { Container, Page, theme, Text, Spacing, VerdictCard, Input } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab'
import { Avatar, Button } from '@mui/material'
import { IUser } from '../../../types';
import { useAuth } from '../../../hooks/auth';
import { useInformation } from '../../../hooks/information';
import { useUsers } from '../../../hooks/users';
import { PADDING, VERY_PADDING } from '../../../utils/consts';
import { convertBase64 } from '../../../utils/converters';
import { API_URL } from '../../../services';
import { REDSOFT_USER } from '../../../plugins/localStorage.consts';

const ProfileSettings: FC<any> = () => {
    const { user } = useAuth();
    const { editUser } = useUsers();
    const [error, setError] = useState<string | null>(null);
    const [user_t, setUser_t] = useState<IUser | undefined>(undefined);
    const [name, setName] = useState<string>('');
    const { showInformation, hideInformation } = useInformation();
    const [nameError, setNameError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [phone, setPhone] = useState<string>('');
    const [phoneError, setPhoneError] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            setName(`${user.firstName} ${user.lastName}`);
            setEmail(user.email || '');
            //  setPhone(user.attributes[0].phone || '');
            setLoading(false);
        }
    }, [user]);




    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setPhone('');
        setPhoneError(null);
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
        setPhoneError(null);
        setError(null);
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (name == '') {
            setNameError('Informe o nome completo do usuário');
            isValid = false;
        }
        if (email == '') {
            setEmailError('Informe o email do usuário');
            isValid = false;
        }

        if (isValid) {
            setNameError(null);
            setEmailError(null);
        }
        return isValid;
    }



    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let userId: string = '';
            let names: string[] = name.split(" ");
            let payload: IUser = {
                firstName: names.shift() || "",
                lastName: names.join(" "),
                username: email,
                email,
                enabled: true,
                emailVerified: false
            };

            if (user && user.id) {
                const editedUser: IUser = await editUser(user.id, payload);
                if (editedUser.id) userId = editedUser.id;
            }


            const informationTitle: string = 'Edição efetuada'
            let informationMessage: string = 'Informações alteradas com sucesso.'

            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    setLoading(false);
                    hideInformation();
                });
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    return (
        <Page width='auto' height='100%' color={theme.palette.background.default} style={{ minHeight: '95vh' }}>
            <Container fluid flex flexGrow color={theme.palette.background.default} width="60%">
                <Container>
                    <Spacing bottom={VERY_PADDING * 4}></Spacing>
                    <Container>
                        <Text bold size={20}>Configurações de perfil</Text>
                        <Spacing bottom={PADDING}></Spacing>
                        <Text size={14}>Alterar detalhes de identificação da sua conta</Text>
                    </Container>
                    <Spacing bottom={VERY_PADDING}></Spacing>
                    <Container>
                        <Container color={theme.palette.background.paper} width="100%" veryPadded>
                            <Container>
                                <Container inline flex>
                                    <Container width="288px">
                                        <Text bold size={14}>Nome completo</Text>
                                    </Container>
                                    <Container fluid width={"100%"}>
                                        <Container fluid width={"100%"}>
                                            <Input
                                                id="name"
                                                autoFocus
                                                required
                                                variant="standard"
                                                value={name}
                                                onChange={handleChangeName}
                                                placeholder='Nome completo do usuário'
                                                error={nameError != null}
                                                backgroundColor={theme.palette.grayLight.light}
                                                disabled={loading}
                                            />
                                            {nameError && <Text size={13} color={theme.palette.error.main}>{nameError}</Text>}
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                            <Spacing bottom={VERY_PADDING}></Spacing>
                        </Container>
                        <Container color={theme.palette.background.paper} width="100%" veryPadded sx={{ 'borderTop': '1px solid rgba(0,0,0,.08)' }}>
                            <Container>
                                <Container inline flex>
                                    <Container width="288px">
                                        <Text bold size={14}>E-mail de acesso</Text>
                                    </Container>
                                    <Container fluid width={"100%"}>
                                        <Container fluid width={"100%"}>
                                            <Input
                                                id="email"
                                                label="E-mail de acesso"
                                                value={email}
                                                onChange={handleChangeEmail}
                                                placeholder='Informe o e-mail de acesso do usuário'
                                                error={emailError != null}
                                                backgroundColor={theme.palette.grayLight.light}
                                                disabled={loading}
                                            />
                                            {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                            <Spacing bottom={VERY_PADDING}></Spacing>
                        </Container>
                        <Container color={theme.palette.background.paper} width="100%" veryPadded sx={{ 'borderTop': '1px solid rgba(0,0,0,.08)' }}>
                            <Container>
                                <Container inline flex>
                                    <Container width="288px">
                                        <Text bold size={14}>Telefone de contato</Text>
                                    </Container>
                                    <Container fluid width={"100%"}>
                                        <Container fluid width={"100%"}>
                                            <Input
                                                id="phone"
                                                autoFocus
                                                variant="standard"
                                                value={phone}
                                                onChange={handleChangePhone}
                                                label="Telefone de contato"
                                                placeholder='Telefone de contato do usuário'
                                                error={phoneError != null}
                                                backgroundColor={theme.palette.grayLight.light}
                                                disabled={loading}
                                            />
                                            {phoneError && <Text size={13} color={theme.palette.error.main}>{phoneError}</Text>}
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                            <Spacing bottom={VERY_PADDING}></Spacing>
                        </Container>
                        <Container veryPadded color={"#e5e5ea"}>
                            <Container fluid flexEnd>
                                <LoadingButton variant="contained" color="primary" size="small" sx={{ width: '130px' }} disabled={user == null} loading={loading} component="label" onClick={() => confirmRegistration()}>
                                    <Text color={theme.palette.background.paper} size={13}>Salvar alterações</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                    <Spacing bottom={PADDING}></Spacing>
                </Container>
            </Container>
        </Page >
    );
}

export default ProfileSettings;