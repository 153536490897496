import React, { FC, useState } from "react";
import { Button, Collapse, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, MonitorHeart as MonitorHeartIcon, People as UsersIcon } from "@mui/icons-material";

import Container from "../../../atoms/container";
import theme from "../../../theme";
import Text from "../../../atoms/text";
import { useResponsive } from "../../../../hooks/responsive";
import { IBranchListItem } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import Spacing from "../../../atoms/spacing";
import BranchesSystemsHealth from "../systemsHealth";

const BranchListItem: FC<IBranchListItem> = (params: IBranchListItem) => {
    const { mdDown } = useResponsive();

    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Container fluid flex padded hasShadow color={theme.palette.background.default} sx={{ border: `solid 2px ${theme.palette.grayLight.light}` }} borderRadius={2}>
            <Grid container rowSpacing={2} columnSpacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} md={5} display="flex" justifyContent="center">
                    <Container fluid centered={mdDown} width="100%">
                        <Text bold size={17} color={theme.palette.primary.main}>
                            {params.branch.name}
                        </Text>
                        {params.branch.email &&
                            <Text size={12} color={theme.palette.text.disabled}>
                                E-mail: {params.branch.email}
                            </Text>
                        }
                        {params.branch.cnpj &&
                            <Text size={12} color={theme.palette.text.disabled}>
                                CNPJ: {params.branch.cnpj}
                            </Text>
                        }
                        <Text size={12} color={theme.palette.text.disabled}>
                            Prefixo: {params.branch.prefix}
                        </Text>
                        {params.branch.token &&
                            <Text size={12} color={theme.palette.text.disabled}>
                                Token: {params.branch.token}
                            </Text>
                        }
                    </Container>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="flex-end" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Container fluid centered={mdDown}>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Criada em: {params.branch.createdAt}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Atualizada em: {params.branch.updatedAt}
                        </Text>
                    </Container>
                </Grid>
                <Grid item xs={12} md={3} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Grid container>
                        <Grid item xs={12} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                            <Button variant="contained" color="primary" size='medium' sx={{ maxHeight: "35px" }}
                                startIcon={<UsersIcon fontSize="medium" sx={{ color: theme.palette.background.paper }} />}
                                onClick={() => params.branch.id && params.onOpenUsers(params.branch.id)}>
                                <Text color={theme.palette.background.paper}>Usuários</Text>
                            </Button>
                            <Spacing left={PADDING} />
                            <Tooltip title="Editar empresa">
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => params.branch.id && params.onEditBranch(params.branch.id)}
                                    disableFocusRipple>
                                    <EditIcon color="info" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Spacing left={PADDING} />
                            <Tooltip title="Excluir empresa">
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => params.branch.id && params.onDeleteBranch(params.branch.id)}>
                                    <DeleteIcon color="error" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                            <Tooltip title="Saudabilidade dos microserviços">
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => setExpanded(!expanded)}>
                                    <MonitorHeartIcon color="success" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Collapse in={expanded}>
                <BranchesSystemsHealth branch={params.branch} />
            </Collapse>
        </Container>
    );
}

export default BranchListItem;