import React, { FC, useEffect, useState } from 'react';
import { Box, Collapse, Divider, Grid, IconButton, SelectChangeEvent } from '@mui/material';
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from "moment";

import AnnotatorAccessCard from '../accessCard';
import UserSelect from '../../users/select';
import Container from '../../../atoms/container';
import DatePicker from '../../../atoms/datePicker';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import Input from '../../../atoms/input';
import theme from '../../../theme';
import { useAnnotatorHasAccesss } from '../../../../hooks/annotatorsHasAccess';
import { useAuth } from '../../../../hooks/auth';
import { useCompanies } from '../../../../hooks/companies';
import { useBranches } from '../../../../hooks/branches';
import { useConfirmation } from '../../../../hooks/confirmation';
import { useInformation } from '../../../../hooks/information';
import { useUsers } from '../../../../hooks/users';
import { IUser, IAnnotatorAccessModal, IAnnotatorHasAccess } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import BranchSelect from '../../branches/select';
import DateRangePicker from '../../../atoms/dateRangePicker';
import { getDatesRange } from '../../../../utils/dates';

const AnnotatorAccessModal: FC<IAnnotatorAccessModal> = (params: IAnnotatorAccessModal) => {
    const { fetchAnnotatorHasAccessByAnnotatorId, createNewAnnotatorHasAccess, deleteAnnotatorHasAccess } = useAnnotatorHasAccesss();
    const { fetchBranches } = useBranches();
    const { hideConfirmation, showConfirmation, } = useConfirmation();
    const { showInformation } = useInformation();
    const { users } = useUsers();

    const [accesses, setAccesses] = useState<IAnnotatorHasAccess[]>([]);
    const [userId, setUserId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [initialDate, setInitialDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    const [dateError, setDateError] = useState<string | null>(null);
    const [quantityReleased, setQuantityReleased] = useState<string>('');
    const [quantityReleasedError, setQuantityReleasedError] = useState<string | null>(null);
    const [branchId, setCompanyId] = useState<string>('');
    const [branchName, setCompanyName] = useState<string>('');
    const [branchError, setCompanyError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [accessIsOpen, setAccessIsOpen] = useState<boolean>(true);

    useEffect(() => {
        fetchBranches();
    }, []);

    useEffect(() => {
        if (params.open == true) {
            loadAccesses();
        }
    }, [params.open]);

    useEffect(() => {
        if (params.annotator?.user && params.annotator?.user.id) {
            setUserId(params.annotator?.user.id);
            setUserName(`${params.annotator.user.firstName} ${params.annotator.user.lastName}`);
        }
    }, [params.annotator]);

    const loadAccesses = async () => {
        if (params.annotatorId) {
            const auxAccesses: IAnnotatorHasAccess[] = await fetchAnnotatorHasAccessByAnnotatorId(params.annotatorId);
            setAccesses(auxAccesses);
        }
    }

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setUserId((event as any).target.value);
            setUserName('');
        } else {
            if ((event as any).id) {
                setUserId((event as any).id);
            }
            if ((event as any).label) {
                setUserName((event as any).label);
            }
        }
        setError(null);
    }
    const handleChangeQuantityReleased = (event: SelectChangeEvent<string | number | null>) => {
        setQuantityReleased(event.target.value as string);
        setQuantityReleasedError(null);
        setError(null);
    }

    const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setCompanyId((event as any).target.value);
            setCompanyName('');
        } else {
            if ((event as any).id) {
                setCompanyId((event as any).id);
            }
            if ((event as any).label) {
                setCompanyName((event as any).label);
            }
        }
        setCompanyError(undefined);
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (initialDate == null && finalDate == null) {
            setDateError('Informe a data');
            isValid = false;
        }
        if (finalDate && initialDate && (initialDate > finalDate)) {
            setDateError('Data inicial não pode ser maior do que data final');
            isValid = false;
        }
        if (isValid && initialDate && finalDate) {
            const rangeDates = getDatesRange(initialDate, finalDate);
            for (let i = 0; i < rangeDates.length; i++) {
                const date = moment(rangeDates[i], "YYYY-MM-DD");
                for (let j = 0; j < accesses.length; j++) {
                    if (moment(date).format('DD/MM/YYYY') == moment(accesses[j].releasedDate, 'YYYY-MM-DD').format('DD/MM/YYYY') &&
                        branchId == accesses[j].branchId) {
                        setDateError(`Data ${moment(date).format('DD/MM/YYYY')} já está cadastrada.`);
                        isValid = false;
                        break;
                    }
                }
            }
        }
        if (quantityReleased == '') {
            setQuantityReleasedError('Informe a quantidade de anotações');
            isValid = false;
        }
        if (branchId == '') {
            setCompanyError('Selecione a empresa');
            isValid = false;
        }


        if (isValid) {
            setDateError(null);
            setQuantityReleasedError(null);
            setCompanyError(undefined);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;
        if (!initialDate || !finalDate) return;

        setLoading(true);
        try {
            const rangeDates = getDatesRange(initialDate, finalDate);
            for (let i = 0; i < rangeDates.length; i++) {
                const date = rangeDates[i];
                let payload: IAnnotatorHasAccess = {
                    annotatorId: params.annotatorId,
                    releasedDate: moment(date).format('YYYY-MM-DD'),
                    releasedQuantity: quantityReleased == '' ? 0 : parseInt(quantityReleased),
                    branchId
                }
                await createNewAnnotatorHasAccess(payload);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Acesso cadastrado com sucesso.'
            setTimeout(() => {
                setLoading(false);
                cleanData();
                loadAccesses();
                showInformation(informationMessage, informationTitle);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setUserId('');
        setUserName('');
        setInitialDate(null);
        setFinalDate(null);
        setDateError(null);
        setQuantityReleased('');
        setQuantityReleasedError(null);
        setCompanyId('');
        setCompanyName('');
        setCompanyError(undefined);
        setError(null);

        setAccessIsOpen(true);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    const removeAccess = (_id?: string) => {
        if (!_id) return;

        showConfirmation('Deseja realmente apagar esse acesso? Esse processo não poderá ser desfeito.', 'Atenção', undefined, undefined,
            async () => {
                await deleteAnnotatorHasAccess(_id);
                await loadAccesses();
                hideConfirmation();
            });
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid relative hasShadow centered color={theme.palette.background.default} minWidth="500px" maxWidth="800px">
                <Container inline centered width="100%" color={theme.palette.background.default} borderTopRadius={BORDER_RADIUS}>
                    <Container fluid flex veryPadded>
                        <Text size={30} thin color={theme.palette.primary.main}>Acessos do anotador</Text>
                    </Container>

                    <Container absolute sx={{ top: 0, right: 0 }}>
                        <IconButton style={{}} onClick={close}>
                            <CloseIcon style={{ color: theme.palette.primary.light }} />
                        </IconButton>
                    </Container>
                </Container>
                <Divider sx={{ width: "95%" }} />
                <Spacing top={VERY_PADDING} />
                <Container fluid flex flexGrow width="95%" minHeight="100px">
                    <Grid container rowSpacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Anotador:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <UserSelect
                                    value={userId}
                                    valueName={userName}
                                    disabled
                                    handleChangeValue={handleChangeUser}
                                    variant="outlined"
                                    placeholder='Escolha o anotador' />
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={5}>
                            <Container fluid sx={{ width: "94%" }}>
                                <DateRangePicker
                                    initialDate={initialDate}
                                    finalDate={finalDate}
                                    placeholder={"Escolha o intervalo de data"}
                                    setInitialDate={setInitialDate}
                                    setFinalDate={setFinalDate}
                                    disabled={false}
                                    maxDaysSelect={0}
                                    sx={{ borderRadius: "4px" }}
                                />
                                {/* <DatePicker
                                    variant="outlined"
                                    autoFocus
                                    value={date}
                                    setValue={setDate}
                                    label=""
                                    placeholder="Data*"
                                    disabled={loading}
                                    height={23} /> */}
                                {quantityReleasedError && !dateError && <Container height="18px" />}
                                {dateError && <Text size={13} color={theme.palette.error.main} >{dateError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={5}>
                            <Container fluid sx={{ width: "94%", paddingLeft: PADDING }}>
                                <Input
                                    id="quantityReleased"
                                    value={quantityReleased}
                                    variant="outlined"
                                    onChange={handleChangeQuantityReleased}
                                    type="number"
                                    placeholder='Quantidade liberada'
                                    error={quantityReleasedError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {!quantityReleasedError && dateError && <Container height="18px" />}
                                {quantityReleasedError && <Text size={13} color={theme.palette.error.main} >{quantityReleasedError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <BranchSelect
                                    value={branchId}
                                    valueName={branchName}
                                    handleChangeValue={handleChangeCompany}
                                    variant="outlined"
                                    placeholder='Escolha a Filial'
                                    error={branchError} />
                            </Container>
                        </Grid>
                    </Grid>
                    <Container inline flexEnd veryPadded>
                        <LoadingButton
                            variant="contained"
                            size="small"
                            loading={params.loading || loading}
                            disabled={params.loading || loading}
                            style={{ backgroundColor: theme.palette.success.main, width: 100, height: 30 }}
                            onClick={() => confirmRegistration()}>
                            <Text light size={14} color={loading ? theme.palette.gray.main : theme.palette.background.paper}>Adicionar</Text>
                        </LoadingButton>
                    </Container>
                </Container>

                <Container fluid flex flexGrow width="95%" minHeight="100px">
                    <Container inline horizontalCentered horizontalPadded width="auto" color={theme.palette.grey[300]}>
                        <Text size={20}>Acessos cadastrados</Text>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton size="large" onClick={() => setAccessIsOpen(!accessIsOpen)} style={{ backgroundColor: 'transparent' }}>
                            {accessIsOpen && <ExpandMoreIcon />}
                            {!accessIsOpen && <ExpandLessIcon />}
                        </IconButton>
                        <Spacing left={PADDING} />
                    </Container>

                    <Collapse in={accessIsOpen} timeout="auto" unmountOnExit>
                        <Container fluid padded maxHeight="200px" sx={{ overflowY: 'auto' }}>
                            {loading ? <Text center size={14}>Carregando.</Text> :
                                accesses.length == 0 && <Text center size={14}>Nenhum acesso encontrado na base de dados.</Text>}
                            {accesses.sort((a, b) => {
                                if (moment(a.releasedDate).toDate() < moment(b.releasedDate).toDate())
                                    return 1
                                else if (moment(a.releasedDate).toDate() > moment(b.releasedDate).toDate())
                                    return -1
                                return 0
                            })
                                .map(item =>
                                    <Container key={item.id} fluid>
                                        <AnnotatorAccessCard
                                            access={item}
                                            onRemove={(_id?: string) => removeAccess(_id)} />
                                        <Spacing top={PADDING} />
                                    </Container>)}
                        </Container>
                    </Collapse>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        size="small"
                        color="info"
                        style={{ width: 150, height: 35 }}
                        onClick={() => params.onClose()}>
                        <Text light size={16} color={theme.palette.info.main}>Fechar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default AnnotatorAccessModal;