import { AxiosResponse } from "axios";

import api from ".";
import { convertApiResponseToIBranch } from "./branches";
import { convertApiResponseToIMonitoringType } from "./monitoringTypes";
import { convertApiResponseToIUser } from "./users";
import {
  IBranch,
  IMonitoringRecipient,
  IMonitoringType,
  IUser,
} from "../types";

const baseUrl: string = "monitoring_recipients";

export const createMonitoringRecipient = async (
  _monitoringRecipient: IMonitoringRecipient
) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    userId: _monitoringRecipient.userId,
    monitoringTypeId: _monitoringRecipient.monitoringTypeId,
    branchId: _monitoringRecipient.branchId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIMonitoringRecipient(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateMonitoringRecipient = async (
  _id: string,
  _monitoringRecipient: IMonitoringRecipient
) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    userId: _monitoringRecipient.userId,
    monitoringTypeId: _monitoringRecipient.monitoringTypeId,
    branchId: _monitoringRecipient.branchId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIMonitoringRecipient(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyMonitoringRecipient = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getMonitoringRecipients = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const monitoringRecipients: IMonitoringRecipient[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const monitoringRecipient: IMonitoringRecipient =
          convertApiResponseToIMonitoringRecipient(data);

        monitoringRecipients.push(monitoringRecipient);
      }

      return monitoringRecipients;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getMonitoringRecipient = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const monitoringRecipient: IMonitoringRecipient =
        convertApiResponseToIMonitoringRecipient(response.data);
      return monitoringRecipient;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIMonitoringRecipient = (
  _monitoringRecipient: any
) => {
  let user: IUser | undefined = undefined;
  if (_monitoringRecipient.user)
    user = convertApiResponseToIUser(_monitoringRecipient.user);
  let monitoringType: IMonitoringType | undefined = undefined;
  if (_monitoringRecipient.type)
    monitoringType = convertApiResponseToIMonitoringType(
      _monitoringRecipient.type
    );
  let branch: IBranch | undefined = undefined;
  if (_monitoringRecipient.branch)
    branch = convertApiResponseToIBranch(_monitoringRecipient.branch);

  const monitoringRecipient: IMonitoringRecipient = {
    id: _monitoringRecipient.id,
    userId: _monitoringRecipient.userId,
    user,
    monitoringTypeId: _monitoringRecipient.monitoringTypeId,
    monitoringType,
    branchId: _monitoringRecipient.branchId,
    branch,
    isDeleted: _monitoringRecipient.isDeleted,
    createdAt: _monitoringRecipient.createdAt,
    updatedAt: _monitoringRecipient.updatedAt,
  };

  return monitoringRecipient;
};
