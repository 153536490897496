import { EntityServices } from "./entities";
import { IRoute } from "../types";
import { parseStringToDate } from "../utils/dates";

export class RouteServices extends EntityServices<IRoute> {

  constructor () {
    const _baseUrl: string = "/api_user/routes";
    super("Rota", _baseUrl)
  }

  override convertApiResponseToIEntity (_data: any): IRoute {
    return {
      id: _data.id,
      path: _data.path,
      description: _data.description,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt),
      updatedAt: parseStringToDate(_data.updatedAt),
    };
  };
}
