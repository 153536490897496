import React, { FC, createContext, useState, useContext } from 'react'

import { IInformationContext } from '../types'

const InformationContext = createContext<IInformationContext>({} as IInformationContext);


export const InformationProvider: FC<any> = ({ children }) => {
    const [informationOpen, setInformationOpen] = useState<boolean>(false);
    const [informationTitle, setInformationTitle] = useState<string | undefined>(undefined);
    const [informationMessage, setInformationMessage] = useState<string | undefined>(undefined);
    const [informationConfirmLabel, setInformationConfirmLabel] = useState<string | undefined>(undefined);
    const [informationOnConfirmClick, setInformationOnConfirmClick] = useState<((() => void) | undefined)>(undefined);
    const [informationOnClose, setInformationOnClose] = useState<((() => void) | undefined)>(undefined);

    const showInformation = (_message: string, _title?: string, _confirmLabel?: string, _onConfirmClick?: () => void, _onClose?: () => void) => {
        if (_title) setInformationTitle(_title);
        setInformationMessage(_message);
        if (_confirmLabel) setInformationConfirmLabel(_confirmLabel);
        if (_onConfirmClick) setInformationOnConfirmClick(() => () => _onConfirmClick());
        if (_onClose) setInformationOnClose(() => () => _onClose());

        setInformationOpen(true);
    }

    const hideInformation = () => {
        setInformationTitle(undefined);
        setInformationMessage(undefined);
        setInformationConfirmLabel(undefined);
        setInformationOnConfirmClick(undefined);
        setInformationOnClose(undefined);

        setInformationOpen(false);
    }


    return (
        <InformationContext.Provider value={{
            informationOpen, informationTitle, informationMessage, informationConfirmLabel,
            showInformation, hideInformation,
            informationOnConfirmClick, setInformationOnConfirmClick,
            informationOnClose, setInformationOnClose
        }}>{children}</InformationContext.Provider>
    );
}

export function useInformation() {
    const context = useContext(InformationContext);

    if (!context) {
        throw new Error('useInformation must be used within an InformationProvider')
    }

    return context;
}