import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { Container, Input, Page, Spacing, Text, theme } from '../../../components';
import { useCompanies } from '../../../hooks/companies';
import { ICompany } from '../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';
import { useInformation } from '../../../hooks/information';
import { TextField } from '@mui/material';

const CompanyRegister: FC<any> = () => {
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { createNewCompany, editCompany, fetchCompany, fetchCompanies } = useCompanies();

    const [company, setCompany] = useState<ICompany | undefined>(undefined);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [tradeName, setTradeName] = useState<string>('');
    const [tradeNameError, setTradeNameError] = useState<string | null>(null);
    const [fridge, setFridge] = useState<string>('');
    const [fridgeError, setFridgeError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [cnpj, setCnpj] = useState<string>('');
    const [cnpjError, setCnpjError] = useState<string | null>(null);
    const [token, setToken] = useState<string>('');
    const [tokenError, setTokenError] = useState<string | null>(null);
    const [prefix, setPrefix] = useState<string>('');
    const [prefixError, setPrefixError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        loadCompany();
    }, []);

    useEffect(() => {
        if (company) {
            setName(company.name);
            setFridge(company.fridge);
            setEmail(company.email || '');
            setCnpj(company.cnpj || '');
            setToken(company.token);
            setPrefix(company.prefix);
        }
    }, [company]);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }
    const handleChangeTradeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTradeName(event.target.value);
        setTradeNameError(null);
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }
    const handleChangeFridge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFridge(event.target.value);
        setFridgeError(null);
        setError(null);
    }
    const handleChangeCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCnpj(event.target.value);
        setCnpjError(null);
        setError(null);
    }
    const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
        setTokenError(null);
        setError(null);
    }
    const handleChangePrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrefix(event.target.value);
        setPrefixError(null);
        setError(null);
    }

    const loadCompany = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxCompany: ICompany = await fetchCompany(id);
                setCompany(auxCompany);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    function validate() {
        let isValid = true;
        if (name == '') {
            setNameError('Informe o nome completo da empresa');
            isValid = false;
        }

        if (isValid) {
            setNameError(null);
            setEmailError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let payload: ICompany = {
                name,
                tradeName,
                email,
                fridge,
                cnpj,
                token,
                prefix
            };
            if (company && company.id) {
                await editCompany(company.id, payload);
            } else {
                await createNewCompany(payload, true);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!company) informationMessage = 'A nova empresa foi cadastrada com sucesso.';

            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    fetchCompanies();
                    setLoading(false);
                    hideInformation();
                    cleanData();
                    navigate('/companies');
                });
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setFridge('');
        setFridgeError(null);
        setCnpj('');
        setCnpjError(null);
        setToken('');
        setTokenError(null);
        setPrefix('');
        setPrefixError(null);
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{(company && company.id) ? "Editar" : "Criar"} Empresa</Text>
                </Container>
                <Spacing top={VERY_PADDING} />

                <Container fluid veryPadded centered>
                    <Container fluid width="50%" height="100%">
                        <Container fluid veryPadded>
                            <Text size={35}>Informações da empresa</Text>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Nome:</Text>
                                <TextField id="name" required
                                    value={name} onChange={handleChangeName}
                                    placeholder='Informe a razão social da empresa'
                                    error={nameError != null} disabled={loading} />
                                {nameError && <Text size={13} color={theme.palette.error.main}>{nameError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>E-mail (opcional):</Text>
                                <TextField id="email"
                                    value={email} onChange={handleChangeEmail}
                                    placeholder='Informe o e-mail da empresa'
                                    error={emailError != null} disabled={loading} />
                                {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>CNPJ (opcional):</Text>
                                <TextField id="cnpj" autoFocus
                                    value={cnpj} onChange={handleChangeCnpj}
                                    placeholder='Informe o CNPJ da empresa'
                                    error={cnpjError != null} disabled={loading} />
                                {cnpjError && <Text size={13} color={theme.palette.error.main} >{cnpjError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Nome fantasia (opcional):</Text>
                                <TextField id="name"
                                    value={tradeName} onChange={handleChangeTradeName}
                                    placeholder='Informe o nome fantasia da empresa'
                                    error={tradeNameError != null} disabled={loading} />
                                {tradeNameError && <Text size={13} color={theme.palette.error.main}>{tradeNameError}</Text>}
                            </Container>

                            <Spacing top={VERY_PADDING * 2} />
                            {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                            <Container inline spacedAround veryPadded>
                                <LoadingButton
                                    variant="outlined"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : 'transparent', width: 150, height: 35, borderColor: theme.palette.primary.dark }}
                                    onClick={() => navigate('/companies')}>
                                    <Text size={16} color={theme.palette.primary.dark}>Cancelar</Text>
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : theme.palette.primary.dark, width: 150, height: 35 }}
                                    onClick={() => confirmRegistration()}>
                                    <Text size={16} color={theme.palette.background.paper}>Confirmar</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page >
    );
}

export default CompanyRegister;