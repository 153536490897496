import React, { FC, createContext, useContext, useState } from 'react'

import { createVerdict, updateVerdict, getVerdicts, destroyVerdict, getVerdict } from '../services/verdicts';
import { IVerdict, IVerdictContext, IVerdictFilter } from '../types'

const VerdictsContext = createContext<IVerdictContext>({} as IVerdictContext);

export const VerdictsProvider: FC<any> = ({ children }) => {
    const [verdicts, setVerdicts] = useState<IVerdict[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchVerdict, setSearchVerdict] = useState<string>('');
    const [verdictsPerRow, setVerdictsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const [filter, setFilter] = useState<IVerdictFilter>({
        initialDate: new Date(),
        finalDate: new Date(),
        annotators: [],
        branches: [],
        classificationsId: [],
        limit: 10,
    });

    const fetchVerdicts = async () => {
        setLoading(true);
        try {
            const verdicts: IVerdict[] = await getVerdicts();
            setVerdicts([...verdicts]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchVerdict = async (_id: string) => {
        const verdict: IVerdict = await getVerdict(_id);
        return verdict;
    }

    const createNewVerdict = async (_verdict: IVerdict, _reload: boolean = true) => {
        const verdict = await createVerdict(_verdict);
        if (_reload)
            fetchVerdicts();
        return verdict;
    }

    const editVerdict = async (_id: string, _payload: IVerdict) => {
        try {
            const verdict = await updateVerdict(_id, _payload);

            fetchVerdicts();
            return verdict;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteVerdict = async (_id: string) => {
        const verdict = await destroyVerdict(_id);
        fetchVerdicts();
        return verdict;
    }

    return (
        <VerdictsContext.Provider value={{
            verdicts, loading, fetchVerdicts, fetchVerdict,
            createNewVerdict, editVerdict, deleteVerdict,
            searchVerdict, setSearchVerdict, verdictsPerRow, setVerdictsPerRow, paginate, setPaginate,

            filter, setFilter
        }}>{children}</VerdictsContext.Provider>
    )
}

export function useVerdicts() {
    const context = useContext(VerdictsContext);

    if (!context) {
        throw new Error('useVerdicts must be used within an VerdictsProvider')
    }

    return context;
}
