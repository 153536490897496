import React, { FC, useState } from "react";
import { Button, Collapse, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, MonitorHeart as MonitorHeartIcon, People as UsersIcon } from "@mui/icons-material";

import Container from "../../../atoms/container";
import theme from "../../../theme";
import Text from "../../../atoms/text";
import { useResponsive } from "../../../../hooks/responsive";
import { IUserListItem } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import Spacing from "../../../atoms/spacing";
import { useAuth } from "../../../../hooks/auth";

const UserListItem: FC<IUserListItem> = (params: IUserListItem) => {
    const { hasRights } = useAuth();
    const { mdDown } = useResponsive();

    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Container fluid flex padded hasShadow color={theme.palette.background.default} sx={{ border: `solid 2px ${theme.palette.grayLight.light}` }} borderRadius={2}>
            <Grid container rowSpacing={2} columnSpacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} md={9} display="flex" justifyContent="center">
                    <Container fluid centered={mdDown} width="100%">
                        <Container inline horizontalCentered>
                            <Text bold size={17} color={theme.palette.primary.main}>
                                {params.user.firstName} {params.user.lastName}
                            </Text>
                            {!params.user.enabled &&
                                <>
                                    <Spacing left={PADDING} />
                                    <Text size={14} color={theme.palette.text.disabled}>
                                        Usuário(a) desabilitado(a)
                                    </Text>
                                </>
                            }
                        </Container>
                        {params.user.email &&
                            <Text size={12} color={theme.palette.text.disabled}>
                                E-mail: {params.user.email}
                            </Text>
                        }
                    </Container>
                </Grid>
                {/* <Grid item xs={12} md={4} display="flex" alignItems="flex-end" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Container fluid centered={mdDown}>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Criada em: {params.user.createdAt}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Atualizada em: {params.user.updatedAt}
                        </Text>
                    </Container>
                </Grid> */}
                <Grid item xs={12} md={3} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Grid container>
                        <Grid item xs={12} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                            {hasRights!("manage_users") && <Tooltip title="Editar usuário">
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => params.user.id && params.onEditUser(params.user.id)}
                                    disableFocusRipple>
                                    <EditIcon color="info" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            }
                            <Spacing left={PADDING} />
                            {hasRights!("manage_users") &&
                                <Tooltip title="Excluir acesso">
                                    <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                        onClick={() => params.user.id && params.onDeleteUser(params.user.id)}>
                                        <DeleteIcon color="error" fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UserListItem;