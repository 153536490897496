import React, { FC, createContext, useCallback, useState, useContext, useEffect } from 'react'

import api from '../services'
import {
    convertParsedTokenToIAuthUserLogged, convertToIAuthUserLogged,
    forgotPassword as forgotPasswordService,
    getPasswordRecovery as getPasswordRecoveryService,
    putPasswordRecovery as putPasswordRecoveryService,
    refreshToken as refreshTokenService,
    updatePasswordWithKey as updatePasswordWithKeyService
} from "../services/auth";
import { IUser, IAuthContext, IPrivilege, IAuthUserLogged, IAuthResetPassword, IAuthLogin, IHookProvider } from '../types'
import {
    REDSOFT_ACCESS_TOKEN,
    REDSOFT_REFRESH_TOKEN,
    REDSOFT_TOKEN_EXPIRES_IN,
    REDSOFT_USER,
} from '../plugins/localStorage.consts'
import { getUser } from '../services/users';
import { IAuthPasswordRecovery, instanceOfIAuthLogin } from '../types/hooks/auth';
import { parseJwt } from '../utils/parses';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const [user, setUser] = useState<IAuthUserLogged | undefined>(undefined);
    const [loadingStorage, setLoadingStorage] = useState<boolean>(true);
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [refreshToken, setRefreshToken] = useState<string | undefined>(undefined);
    const [expiresIn, setExpiresIn] = useState<number | undefined>(undefined);
    const [privilege, setPrivilege] = useState<string | undefined>(undefined);
    const [routes, setRoutes] = useState<string[]>([]);
    const [groups, setGroups] = useState<string[]>([]);
    const [roles, setRoles] = useState<string[]>([]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (accessToken && refreshToken) {
                const auth: IAuthLogin | IAuthResetPassword = await refreshTokenService(refreshToken);
                if (instanceOfIAuthLogin(auth))
                    postLogin(auth);
            }
        }, 1000 * 60 * 5); // 5 minutes
        return () => clearInterval(interval);
    }, [accessToken, refreshToken]);

    const loadStorage = async () => {
        setLoadingStorage(true);
        const accessToken: string | null = localStorage.getItem(REDSOFT_ACCESS_TOKEN);
        const refreshToken: string | null = localStorage.getItem(REDSOFT_REFRESH_TOKEN);
        const expiresInStorage: string | null = localStorage.getItem(REDSOFT_TOKEN_EXPIRES_IN);
        if (accessToken && refreshToken && expiresInStorage) {
            const parsedToken: any = parseJwt(accessToken);
            if (parsedToken.exp) {
                const now: Date = new Date();
                const expDate: Date = new Date(parsedToken.exp * 1000);
                if (expDate < now) {
                    const auth: IAuthLogin | IAuthResetPassword = await refreshTokenService(refreshToken);
                    if (instanceOfIAuthLogin(auth)) {
                        postLogin(auth);
                        return;
                    }
                }
            }

            saveGroups(parsedToken.user_groups);
            saveRoles(parsedToken.resource_access)

            const userStorage: any | null = localStorage.getItem(REDSOFT_USER);
            const userParsed: any = JSON.parse(userStorage);
            const user: IAuthUserLogged = convertToIAuthUserLogged(userParsed);
            saveUser(user);

            const expiresIn: number = parseInt(expiresInStorage);
            saveToken(accessToken, refreshToken, expiresIn);
        }
        setLoadingStorage(false);
    };


    const postLogin = async (_authLogin: IAuthLogin) => {
        saveToken(_authLogin.accessToken, _authLogin.refreshToken, _authLogin.expiresIn);

        const parsedToken: any = parseJwt(_authLogin.accessToken);
        if (parsedToken) {
            saveGroups(parsedToken.user_groups);
            saveRoles(parsedToken.resource_access);
            const user: IAuthUserLogged = convertParsedTokenToIAuthUserLogged(parsedToken);
            saveUser(user);
        }
    };

    const saveToken = (_accessToken: string, _refreshToken: string, _expiresIn: number) => {
        setAccessToken(_accessToken);
        setRefreshToken(_refreshToken);
        setExpiresIn(_expiresIn);

        localStorage.setItem(REDSOFT_ACCESS_TOKEN, _accessToken);
        localStorage.setItem(REDSOFT_REFRESH_TOKEN, _refreshToken);
        localStorage.setItem(REDSOFT_TOKEN_EXPIRES_IN, _expiresIn.toString());

        api.defaults.headers.common["Authorization"] = `Bearer ${_accessToken}`;
    };

    const saveUser = (_user: IAuthUserLogged) => {
        setUser(_user);

        localStorage.setItem(REDSOFT_USER, JSON.stringify(_user));
    };

    const saveGroups = (_userGroups: any) => {
        const redsoftGroups = _userGroups;
        setGroups(redsoftGroups);
    }

    const saveRoles = (_resourceAccess: any) => {
        const redsoftRoles = _resourceAccess.redsoft.roles;
        setRoles(redsoftRoles);
    }

    const clearSession = () => {
        clearToken();
        clearUser();
    };

    const clearToken = () => {
        setAccessToken(undefined);
        setRefreshToken(undefined);
        setExpiresIn(undefined);

        localStorage.removeItem(REDSOFT_ACCESS_TOKEN);
        localStorage.removeItem(REDSOFT_REFRESH_TOKEN);
        localStorage.removeItem(REDSOFT_TOKEN_EXPIRES_IN);
        localStorage.removeItem(REDSOFT_USER);

        api.defaults.headers.common["Authorization"] = "";
    };

    const clearUser = () => {
        setUser(undefined);

        localStorage.removeItem(REDSOFT_USER);
    };

    const logout = useCallback(async () => {
        clearSession();
    }, []);

    function isSuperAdmin() {
        return groups && groups.indexOf("/super-admin") >= 0;
    }

    const forgotPassword = async (_email: string) => {
        try {
            const forgotPasswordCreated: boolean = await forgotPasswordService(_email);
            return forgotPasswordCreated
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const getPasswordRecovery = async (_token: string) => {
        try {
            const passwordRecovery: IAuthPasswordRecovery = await getPasswordRecovery(_token);
            return passwordRecovery
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    const putPasswordRecovery = async (_token: string, _password: string) => {
        try {
            const authLogin: IAuthLogin = await putPasswordRecoveryService(_token, _password);
            return authLogin
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    const updateRecoveryPasswordKey = async (_key: string, _newPassword: string) => {
        try {
            await updatePasswordWithKeyService(_key, _newPassword);
        } catch (_err) {
            throw _err;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                loadingStorage,
                accessToken,
                refreshToken,
                expiresIn,

                roles,
                privilege, routes,

                loadStorage,

                postLogin,
                logout,

                isSuperAdmin,

                forgotPassword,
                getPasswordRecovery,
                putPasswordRecovery,
                updateRecoveryPasswordKey,
            }}
        >
            {_params.children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    const hasRights = (path: string): boolean => {
        return !!(context.roles.indexOf(path) >= 0);
    };

    context.hasRights = hasRights;
    return context;
}
