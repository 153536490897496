import React, { FC, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, SelectChangeEvent } from '@mui/material';
import { Close as CloseIcon, PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';


import BranchSelect from '../../branches/select';
import UserSelect from '../../users/select';
import Container from '../../../atoms/container';
import DatePicker from '../../../atoms/datePicker';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import Input from '../../../atoms/input';
import Select from '../../../atoms/select';
import theme from '../../../theme';
import { useBranches } from '../../../../hooks/branches';
import { useBranchesHasUser } from '../../../../hooks/branchesHasUser';
import { useUsers } from '../../../../hooks/users';
import { IBranchHasUser, IBranchHasUserRegisterModal } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';

const BranchHasUserRegisterModal: FC<IBranchHasUserRegisterModal> = (params: IBranchHasUserRegisterModal) => {
    const isEdit = params.branchHasUserToEdit != undefined;

    const { fetchBranches } = useBranches();
    const { createNewBranchHasUser, editBranchHasUser } = useBranchesHasUser();
    const { fetchUsers } = useUsers();

    const [userId, setUserId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userError, setUserError] = useState<string | undefined>(undefined);
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchBranches();
        fetchUsers();
    }, []);

    useEffect(() => {
        if (params.branchHasUserToEdit) {
            if (params.branchHasUserToEdit.user && params.branchHasUserToEdit.user.id) {
                setUserId(params.branchHasUserToEdit.user.id);
                setUserName(params.branchHasUserToEdit.user.firstName);
            }
            if (params.branchHasUserToEdit.branch && params.branchHasUserToEdit.branch.id) {
                setUserId(params.branchHasUserToEdit.branch.id);
                setUserName(params.branchHasUserToEdit.branch.name);
            }
        }
    }, [params.branchHasUserToEdit]);

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setUserId((event as any).target.value);
            setUserName('');
        } else {
            if ((event as any).id) {
                setUserId((event as any).id);
            }
            if ((event as any).label) {
                setUserName((event as any).label);
            }
        }
        setError(null);
    }
    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (userId == '') {
            setUserError('Selecione o usuário');
            isValid = false;
        }
        if (branchId == '') {
            setBranchError('Selecione a empresa');
            isValid = false;
        }


        if (isValid) {
            setUserError(undefined);
            setBranchError(undefined);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let payload: IBranchHasUser = {
                userId,
                branchId
            };
            if (isEdit && params.branchHasUserToEdit && params.branchHasUserToEdit.id) {
                await editBranchHasUser(params.branchHasUserToEdit.id, payload);
            } else {
                await createNewBranchHasUser(payload, true);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!isEdit) informationMessage = 'O novo vínculo foi cadastrado com sucesso.';
            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setUserId('');
        setUserName('');
        setUserError(undefined);
        setBranchId('');
        setBranchName('');
        setBranchError(undefined);
        setError(null);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid relative hasShadow centered color={theme.palette.background.default} width="600px">
                <Container inline centered width="100%" color={theme.palette.background.default} borderTopRadius={BORDER_RADIUS}>
                    <Container fluid flex veryPadded>
                        <Text size={30} thin color={theme.palette.primary.main}>{!isEdit ? 'Novo vínculo' : 'Editar vínculo'}</Text>
                    </Container>

                    <Container absolute sx={{ top: 0, right: 0 }}>
                        <IconButton style={{}} onClick={close}>
                            <CloseIcon style={{ color: theme.palette.primary.light }} />
                        </IconButton>
                    </Container>
                </Container>
                <Divider sx={{ width: "95%" }} />
                <Spacing top={VERY_PADDING} />
                <Container fluid flex flexGrow width="95%" minHeight="100px">
                    <Grid container rowSpacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Usuário:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <UserSelect
                                    value={userId}
                                    valueName={userName}
                                    error={userError}
                                    handleChangeValue={handleChangeUser}
                                    variant="outlined" />
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Empresa:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <BranchSelect
                                    value={branchId}
                                    valueName={branchName}
                                    error={branchError}
                                    handleChangeValue={handleChangeBranch}
                                    variant="outlined" />
                            </Container>
                        </Grid>
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.info.main, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default BranchHasUserRegisterModal;