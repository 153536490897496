import styled from "styled-components";

import { IImage } from "../../../types";

const ImageStyled = styled.div`
  img {
    width: ${(props: IImage) =>
      (props.size && `${props.size}px`) || (props.width && `${props.width}px`)};
    height: ${(props: IImage) =>
      (props.size && `${props.size}px`) ||
      (props.height && `${props.height}px`)};
    border: ${(props: IImage) =>
      props.borderColor && `1px solid ${props.borderColor}`};
  }
`;

export default ImageStyled;
