import React, { FC, useEffect, useState } from "react";
import { Grid, Input } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Container from "../../../atoms/container";
import Modal from "../../../atoms/modal";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { ILot } from "../../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../../../utils/consts";

interface ILotPhotoUploadModal {
    lot: ILot;
    setLot: any;
    open: boolean;
    onClose: () => void;
    uploadLotImage: (_lot: ILot, image: File) => Promise<string>;
}
const LotPhotoUploadModal: FC<ILotPhotoUploadModal> = (params: ILotPhotoUploadModal) => {
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [uploadFinished, setUploadFinished] = useState<boolean>(false);


    useEffect(() => {
        setUploadFinished(false);
        setLoading(false);
        setError("");
        setSelectedImage(null);
    }, []);
    


    const close = () => {
        setLoading(false);
        params.onClose();
        setSelectedImage(null);
        setUploadFinished(false);
    };

    const validate = () => {
        let isValid = true;
        if (!selectedImage) {
            setError("Por favor, selecione uma imagem");
            isValid = false;
        }

        if(isValid){
            setError("");
        }
        return isValid;
    };

    const onUpload = async () => {
        if (validate() == false)
        setLoading(true);
        
        if(selectedImage){
            const image_url: string = await params.uploadLotImage(params.lot, selectedImage);
            const new_lot:ILot = {...params.lot, photoUri: image_url};
            params.setLot(new_lot);
        } 

        setLoading(false);
        
        setUploadFinished(true);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
          setSelectedImage(files[0]);
        }
        setUploadFinished(false);
    };
    return (
    <Modal isOpened={params.open} onClose={close}>
        <Container
            fluid
            color={theme.palette.background.default}
            hasShadow
            centered
            borderRadius={BORDER_RADIUS}
            style={{ maxWidth: 540 }}
        >
            <Container
                inline
                color={theme.palette.grayLight.main}
                centered
                width="100%"
                borderTopRadius={BORDER_RADIUS}
            >
                <Container padded>
                <Text size={16} bold color={theme.palette.primary.light}>
                    Upload de foto do lote
                </Text>
                </Container>
            </Container>

            <Container fluid centered spacedAround veryPadded width="90%" >
                <Text>Insira uma imagem para upload abaixo</Text>
                <Spacing top={PADDING} />
                <Spacing top={VERY_PADDING} />

                <Grid container rowSpacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Text light >Arquivo da imagem:</Text>
                    </Grid>
                    <Grid item xs={8}>
                        <Input id="image-input" type="file" inputProps={{"accept": "image/*"}} onChange={handleImageChange} ></Input>
                    </Grid>
        
                </Grid>
                <Spacing top={PADDING} />
                {error && (
                    <Grid item xs={12}>
                        <Text size={13} color={theme.palette.error.main}>
                            {error}
                        </Text>
                    </Grid>
                )}
                <Spacing top={2*PADDING} />
                {params.lot.photoUri && params.lot.photoUri !== "" && (
                    <Grid item xs={12}>
                        <Text size={13} color={theme.palette.warning.main}>
                            Fazer o upload de uma nova imagem irá substituir a atual.
                        </Text>
                    </Grid>
                    
                )}
            </Container>

            <Container inline spacedAround veryPadded>
                <LoadingButton
                variant="outlined"
                color="primary"
                size="small"
                style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
                loading={loading}
                disabled={loading}
                onClick={close}
                >
                <Text
                    bold
                    size={12}
                    color={
                    loading ? theme.palette.grey[400] : theme.palette.primary.light
                    }
                >
                    Fechar
                </Text>
                </LoadingButton>
                <Spacing left={PADDING} />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{
                        opacity: loading ? 0.5 : 1,
                        borderColor: theme.palette.primary.light,
                        width: 150,
                        height: 35,
                    }}
                    loading={loading}
                    disabled={loading}
                    onClick={() => onUpload()}
                >
                    <Text bold size={12} color={theme.palette.background.paper}>
                        Confirmar
                    </Text>
                </LoadingButton>
            </Container>
            {loading &&
                <Container fluid flex centered padded>
                    <Text medium size={12} color={theme.palette.grey[400]}>Fazendo upload da imagem.</Text>
                </Container>
            }
            {uploadFinished &&
                <>
                    <Container fluid flex centered padded>
                        <Text medium size={12} color={theme.palette.success.main}>Upload bem sucedido!</Text>
                    </Container>
                    <Spacing bottom={PADDING} />

                </>
            }
        </Container>
        
            
    </Modal>
    );
};

export default LotPhotoUploadModal;
