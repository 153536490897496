import React, { FC } from 'react'
import Lottie from 'react-lottie';

import { Container, Image, Page, theme } from '../../components'
import logo from '../../assets/logo_light.png'
import animationData from '../../assets/lotties/7684-loading-animation-grey.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const Splash: FC = () => {
    return (
        <Page centered color={theme.palette.primary.main}>
            <Container fluid  horizontalCentered padded>
                <Image
                    source={logo}
                    height={240}
                    width={240 * 1.6} />
                <Lottie options={defaultOptions}
                    height={100}
                    width={150}
                    isStopped={false}
                    isPaused={false} />
            </Container>
        </Page>
    )

}

export default Splash