import { FC, useEffect, useState } from 'react'
import { Collapse, Grid } from '@mui/material'

import Container from '../../../../atoms/container'
import Spacing from '../../../../atoms/spacing'
import Text from '../../../../atoms/text'
import theme from '../../../../theme'
import { IListHeader, IListColumn, } from '../../../../../types'
import { BORDER_RADIUS, VERY_PADDING } from '../../../../../utils/consts';
import MonitoringRecipientDetails from '../details'

const MonitoringRecipientListItem: FC<IListColumn> = (params: IListColumn) => {
    const [isOpen, setIsOpen] = useState<boolean>(params.defaultDetailIsOpen || false);
    const fontSize: number = 15;

    useEffect(() => {
        if (!params.defaultDetailIsOpen)
            setIsOpen(false);
    }, [params.data])
    const headers: IListHeader[] = [
        { label: "Usuário", fieldName: "user", xs: 4, },
        { label: "E-mail", fieldName: "email", xs: 8, },
    ];

    return (
       
        <Container style={{ marginBottom: 10, marginTop: 10}} fluid hasShadow padded borderRadius={BORDER_RADIUS} color={theme.palette.grey[200]} width="95%">
            <Grid className="user-grid-item" container direction="row" justifyContent="space-between" alignItems="center"
                style={{
                    height: '30px',
                    marginTop: 15,
                }}>
                {headers.map((header: IListHeader, index: number) => {
                    let convertedItem = params.dataToItem(params.data);
                    if (convertedItem == undefined) return null;
                    if (header.fieldName == null) return;
                    let value = (convertedItem as any)[header.fieldName]
                    let fontColor = theme.palette.text.primary;
                    return (
                        <Grid key={index} item xs={header.xs} justifyContent="space-between" alignItems="center"
                            onClick={() => !header.notOpenHeader && setIsOpen(!isOpen)}
                            style={{ minHeight: '30px', paddingBottom: VERY_PADDING, cursor: (!header.notOpenHeader && params.detail) ? 'pointer' : 'default' }}>
                            <Container inline horizontalCentered>
                                {index == 0 && <Spacing left={VERY_PADDING} />}
                                <Text color={fontColor} semiBold size={fontSize} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{header.label+": "}                                
                                    <Text color={theme.palette.grey[500]} semiBold size={fontSize} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{value}</Text>
                                </Text>
                            </Container>
                        </Grid>
                    )
                })}
            </Grid>

                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Container fluid width="100%" height="auto">
                        {
                            params.data.monitoringItems.map((item: IListColumn) => {
                                    return (<MonitoringRecipientDetails
                                        {...item}
                                    />)
                                })
                        }
                    </Container>
                </Collapse>
        </Container>
    )
}

export default MonitoringRecipientListItem