const colors = {
  primary: "#880300",
  primaryLight: "#AB0300",
  primaryDark: "#440200",
  
  secondary: "#FFFFFF",
  tertiary: "#6AB0B7",

  textColor: "#140204",
  backgroundLighter: "#F5F5F5",
  background: "#F7F7F7",

  error: "#FF482F",
  success: "#27871D",

  gray: "#C4C4C4",
  grayLight: "#E3E3E3",
  grayLighter: "#E9E9E9",
};

export { colors };
