

import React, { FC, useEffect, useState } from 'react';

import { AreaChart, Area, CartesianGrid, Legend, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { IDashboardCharts } from '../../../../types';
import { theme } from '../../..'

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const DashboardFrigobomTipificationAreaChart: FC<IDashboardCharts> = (params: IDashboardCharts) => {
    const [data, setData] = useState<any[]>([]);
    const [dataArroba, setDataArroba] = useState<any[]>([]);
    const [colors, setColors] = useState<string[]>([theme.palette.primary.main, '#875352', '#FFAE00', '#FF8042', '#E58A00', '#4D5A80']);
    
    useEffect(() => {
        const dataArrobaAux = data.map(obj => {
            const { name, name2, ...rest } = obj;
            return {
                name: name2 ? name2 : name,
                ...rest
            };
        });
        setDataArroba(dataArrobaAux);
    }, [data])

    useEffect(() => {
        if(params.data !== undefined){
            setData(params.data)
        }
    }, [params.data])

    useEffect(() => {
        if(params.colors !== undefined){
            setColors(params.colors)
        }
    }, [params.colors])
    
    const isSmallScreen = () => {
        const theme = useTheme();
        const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));

        return isSmallScreen;
    }
    return (
        <ResponsiveContainer height={params.height ? params.height : 220}>
             <AreaChart
                width={200}
                data={params.isKgChecked ? data : dataArroba}
                margin={{
                    top: 5,
                    right: 60,
                    left: 30,
                    bottom: 5,
                }}
                
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={isSmallScreen() ? 1 : 0} />
                <YAxis color="black"/>
                <Tooltip />
                <Legend />
                {/* <Label fill='white'/> */}
                <Area name='Quantidade de carcaças' dataKey="value" type="monotone" fill={colors[0]} stroke={colors[0]} strokeWidth={2}  isAnimationActive={true}/>
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default DashboardFrigobomTipificationAreaChart;

