import { AxiosResponse } from "axios";

import api from "./";
import { IPrivilege } from "../types";

export class PrivilegeServices {
  baseUrl: string = "/api_manager/groups";

  async getPrivileges(): Promise<IPrivilege[]> {
    const privileges: IPrivilege[] = [];

    const response: AxiosResponse = await api.get(`${this.baseUrl}`);
    if (response.data && response.status === 200) {
      const data: any = response.data;
      for (let i = 0; i < data.length; i++) {
        privileges.push(this.convertApiResponseToIPrivilege(data[i]));
      }
    }
    return privileges;
  }

  convertApiResponseToIPrivilege(_data: any): IPrivilege {
    return {
      id: _data.id,
      name: _data.name,
      path: _data.path,
      attributes: _data.attributes,
    };
  }
}
