import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { FilterAltOutlined as FilterOutlined } from '@mui/icons-material';

import AnnotatorsMultiSelect from '../../annotators/multiSelect';
import CarcassClassificationsMultiSelect from '../../carcasses/classificationsMultiSelect';
import BranchesMultiSelect from '../../branches/multiSelect';
import Container from '../../../atoms/container';
import Select from '../../../atoms/select';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';

import CarcassesLocalClassificationsMultiSelect from '../../carcassesLocalClassifications/multiSelect';
import { IRevisionsFilter, IBranch, IAnnotator, IRevisionFilter, ICarcassClassification, ICarcassLocalClassification } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import { useInformation } from '../../../../hooks/information';
import { useAuth } from '../../../../hooks/auth';
import DateRangePicker from "../../../atoms/dateRangePicker";

const RevisionsFilter: FC<IRevisionsFilter> = (params: IRevisionsFilter) => {
    const { hasRights } = useAuth();
    const { showInformation } = useInformation();

    const [initialDate, setInitialDate] = useState<Date | null>(params.filter.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(params.filter.finalDate);
    const [selectedAnnotators, setSelectedAnnotators] = useState<IAnnotator[]>(params.filter.annotators);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(params.filter.branches);
    const [selectedClassifications, setSelectedClassifications] = useState<ICarcassClassification[]>(params.filter.classificationsId);
    const [selectedClassificationsLocal, setSelectedClassificationsLocal] = useState<ICarcassLocalClassification[]>([]);
    const [selectedClassificationsIA, setSelectedClassificationsIA] = useState<ICarcassLocalClassification[]>([]);
    const [annotatorsExpanded, setAnnotatorsExpanded] = useState<boolean>(false);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const [classificationsLocalExpanded, setClassificationsLocalExpanded] = useState<boolean>(false);
    const [classificationsIAExpanded, setClassificationsIAExpanded] = useState<boolean>(false);
    const [limit, setLimit] = useState<string>(params.filter.limit == 10 ? '1' : params.filter.limit == 15 ? '2' : params.filter.limit == 20 ? '3' : '1');

    useEffect(() => {
        setInitialDate(params.filter.initialDate)
        setFinalDate(params.filter.finalDate)
        setSelectedAnnotators(params.filter.annotators)
        setSelectedBranches(params.filter.branches)
        setSelectedClassifications(params.filter.classificationsId)
        setLimit(params.filter.limit == 10 ? '1' : params.filter.limit == 15 ? '2' : params.filter.limit == 20 ? '3' : '1')
    }, [params.filter]);

    useEffect(() => {
        if (annotatorsExpanded) {
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setClassificationsLocalExpanded(false);
            setClassificationsIAExpanded(false);
        }
    }, [annotatorsExpanded]);

    useEffect(() => {
        if (branchesExpanded) {
            setAnnotatorsExpanded(false);
            setClassificationsLocalExpanded(false);
            setClassificationsExpanded(false);
            setClassificationsIAExpanded(false);
        }
    }, [branchesExpanded]);

    useEffect(() => {
        if (classificationsExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setClassificationsLocalExpanded(false);
            setClassificationsIAExpanded(false);
        }
    }, [classificationsExpanded]);

    useEffect(() => {
        if (classificationsLocalExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setClassificationsIAExpanded(false);
        }
    }, [classificationsLocalExpanded]);

    useEffect(() => {
        if (classificationsIAExpanded) {
            setAnnotatorsExpanded(false);
            setBranchesExpanded(false);
            setClassificationsExpanded(false);
            setClassificationsLocalExpanded(false);
        }
    }, [classificationsIAExpanded]);

    const handleChangeLimit = (event: SelectChangeEvent<string | number | null>) => {
        setLimit(event.target.value as string);
    }

    const onApplyFilter = () => {
        if (selectedClassifications.length === 0 && selectedClassificationsLocal.length === 0 && selectedClassificationsIA.length === 0) {
            showInformation('Favor informar uma classificação.', 'Atenção');
            return;
        }
        setAnnotatorsExpanded(false);
        setBranchesExpanded(false);
        setClassificationsExpanded(false);
        setClassificationsLocalExpanded(false);
        setClassificationsIAExpanded(false);

        let auxFilter: IRevisionFilter = {
            initialDate,
            finalDate,
            annotators: selectedAnnotators,
            branches: selectedBranches,
            classificationsId: selectedAnnotators.length ? selectedClassifications : localClassToClass(selectedClassificationsLocal),
            iaClassificationsId: !selectedAnnotators.length ? localClassToClass(selectedClassificationsIA) : [],
            limit: limit == '1' ? 10 : limit == '2' ? 15 : limit == '3' ? 20 : 25
        }
        params.onApplyFilter(auxFilter);
    }

    const localClassToClass = (localClassificationId: ICarcassLocalClassification[]): ICarcassClassification[] => {
        if (localClassificationId.length) {
            return localClassificationId.map(localClass => ({
                id: localClass.localId,
                label: localClass.name
            }))
        }
        else return []
    }

    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING, paddingLeft: PADDING }}>
            <Text size={15} medium>Filtros</Text>
            <Spacing top={PADDING} />
            <Container inline flex flexGrow horizontalCentered>
                <Grid container rowSpacing={2} columnSpacing={4} alignItems="center">
                    <Grid item lg={2} xl={2}>
                        <DateRangePicker
                            initialDate={initialDate}
                            finalDate={finalDate}
                            placeholder={"Escolha o intervalo de data"}
                            setInitialDate={setInitialDate}
                            setFinalDate={setFinalDate}
                            disabled={false}
                            maxDaysSelect={0}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                            }}
                        />
                    </Grid>
                    <Grid item lg={3} xl={1.5}>
                        <AnnotatorsMultiSelect
                            selectedAnnotators={selectedAnnotators}
                            setSelectedAnnotators={setSelectedAnnotators}
                            expanded={annotatorsExpanded}
                            setExpanded={setAnnotatorsExpanded}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                                marginTop: -5
                            }} />
                    </Grid>
                    <Grid item lg={3} xl={1.5}>
                        <BranchesMultiSelect
                            selectedBranches={selectedBranches}
                            setSelectedBranches={setSelectedBranches}
                            expanded={branchesExpanded}
                            setExpanded={setBranchesExpanded}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                                marginTop: -5
                            }} />
                    </Grid>
                    {!!selectedAnnotators.length &&
                        <Grid item lg={2.5} xl={1.5}>
                            <CarcassClassificationsMultiSelect
                                selectedClassifications={selectedClassifications}
                                setSelectedClassifications={setSelectedClassifications}
                                expanded={classificationsExpanded}
                                setExpanded={setClassificationsExpanded}
                                placeholder="Classificações*"
                                singleSelect
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                    marginTop: -5
                                }} />
                        </Grid>
                    }
                    {
                        !selectedAnnotators.length &&
                        <>
                            <Grid item lg={2.5} xl={1.8}>
                                <CarcassesLocalClassificationsMultiSelect
                                    placeholder="Classificação local"
                                    selectedBranchesId={selectedBranches.map(item => item.id || "")}
                                    selectedClassifications={selectedClassificationsLocal}
                                    setSelectedClassifications={setSelectedClassificationsLocal}
                                    expanded={classificationsLocalExpanded}
                                    setExpanded={setClassificationsLocalExpanded}
                                    singleSelect={false}
                                    sx={{
                                        backgroundColor: '#FFF',
                                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                        borderRadius: '4px',
                                        height: '37px',
                                        marginTop: -5
                                    }}
                                />
                            </Grid>
                            <Grid item lg={3} xl={1.5}>
                                <CarcassesLocalClassificationsMultiSelect
                                    placeholder="Classificação IA"
                                    selectedBranchesId={selectedBranches.map(item => item.id || "")}
                                    selectedClassifications={selectedClassificationsIA}
                                    setSelectedClassifications={setSelectedClassificationsIA}
                                    expanded={classificationsExpanded}
                                    setExpanded={setClassificationsExpanded}
                                    singleSelect={false}
                                    sx={{
                                        backgroundColor: '#FFF',
                                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                        borderRadius: '4px',
                                        height: '37px',
                                        marginTop: -5
                                    }}
                                />
                            </Grid>
                        </>
                    }

                    <Grid item lg={2} xl={1}>
                        <Container inline centered>
                            <Text size={14} medium >Exibição:</Text>
                            <Spacing left={PADDING} />
                            <Select
                                variant="outlined"
                                value={limit}
                                onChange={handleChangeLimit}
                                placeholder=''
                                items={[1, 2, 3].map((item: number) => ({
                                    label: item == 1 ? '10' : item == 2 ? '15' : item == 3 ? '20' : '25',
                                    value: item.toString()
                                }))}
                                backgroundColor={theme.palette.grayLight.light}
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                }} />
                        </Container>
                    </Grid>
                    <Grid style={{ marginLeft: '25px', display: 'flex', flexDirection: 'row', paddingTop: '15px', paddingLeft: '6px' }}>
                        <Button size="small" variant="contained" color="primary" sx={{ backgroundColor: theme.palette.primary.dark }} onClick={onApplyFilter} >
                            <Text size={13} color={theme.palette.background.paper}>Aplicar filtro</Text>
                        </Button>
                        <Spacing left={PADDING} />
                        <Button size="small" variant="text" color="primary" onClick={params.onCleanFilter}>
                            <Text medium size={13} color={theme.palette.primary.main}>Limpar filtro</Text>
                        </Button>
                        {hasRights!("manage_revisions__download") &&
                            <>
                                <Spacing left={PADDING} />
                                <Button size="small" variant="outlined" color="primary" onClick={params.onDownloadData}>
                                    <Text medium size={13} color={theme.palette.primary.main}>Baixar dados</Text>
                                </Button>
                            </>
                        }
                        <Spacing left={PADDING} />
                        <Button size="small" variant="outlined" color="primary" onClick={params.onAnnotationsFromIA}>
                            <Text medium size={13} color={theme.palette.primary.main}>Criar Anotações a partir de </Text>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default RevisionsFilter;