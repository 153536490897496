import React, { FC, useEffect, useState } from 'react'
import { Collapse, Grid } from '@mui/material'
import moment from 'moment'

import '../classes.css'
import Container from '../../../atoms/container'
import Spacing from '../../../atoms/spacing'
import Text from '../../../atoms/text'
import theme from '../../../theme'
import { IListHeader, IListColumn, } from '../../../../types'
import { VERY_PADDING } from '../../../../utils/consts';

const ListItem: FC<IListColumn> = (params: IListColumn) => {
    const [isOpen, setIsOpen] = useState<boolean>(params.defaultDetailIsOpen || false);

    const fontSize: number = 15;

    useEffect(() => {
        if (!params.defaultDetailIsOpen)
            setIsOpen(false);
    }, [params.data])

    let borderColor = 'transparent';
    if (params.colorKey) {
        const colorKeyHeader = params.headers.find(item => item.fieldName == params.colorKey);
        if (colorKeyHeader && colorKeyHeader.fieldName && colorKeyHeader.fontColor)
            borderColor = colorKeyHeader.fontColor((params.data as any)[colorKeyHeader.fieldName]);
        if (borderColor == theme.palette.text.primary)
            borderColor = 'transparent';
    }

    return (
        <Container fluid width="100%" sx={{ backgroundColor: params.index && (params.index - 1) % 2 == 0 ? theme.palette.grayLight.main : 'transparent' }}>
            <Grid className="user-grid-item" container direction="row" justifyContent="space-between" alignItems="center"
                style={{
                    height: '30px',
                    marginTop: 15,
                }}>
                {params.headers.map((header: IListHeader, index: number) => {
                    let convertedItem = params.dataToItem(params.data);
                    if (convertedItem == undefined) return null;
                    if (header.actions && header.showActionIfFieldIsNull && convertedItem[header.showActionIfFieldIsNull] == undefined)
                        return (
                            <Grid key={index} item xs={header.xs} justifyContent="flex-end" alignItems="flex-end"
                                style={{ minHeight: '30px', paddingBottom: VERY_PADDING }}>
                                <Container inline flexStart>
                                {params.data.id && header.actions && header.actions(params.data.id).map(item => item)}
                                </Container>
                            </Grid>);

                    if (header.fieldName == null) return;
                    let value = (convertedItem as any)[header.fieldName]
                    let fontColor = theme.palette.text.primary;
                    if (header.fontColor)
                        fontColor = header.fontColor(value);
                    if (header.text)
                        value = header.text(value)

                    return (
                        <Grid key={index} item xs={header.xs} justifyContent="space-between" alignItems="center"
                            onClick={() => !header.notOpenHeader && setIsOpen(!isOpen)}
                            style={{ minHeight: '30px', paddingBottom: VERY_PADDING, cursor: (!header.notOpenHeader && params.detail) ? 'pointer' : 'default' }}>
                            <Container inline horizontalCentered>
                                {header.item != undefined ?
                                    header.item(value, isOpen, () => {
                                        setIsOpen(!isOpen);
                                    })
                                    :
                                    <>
                                        {index == 0 && <Spacing left={VERY_PADDING} />}

                                        {header.isString && typeof value == 'string' &&
                                            <Text color={fontColor} semiBold size={fontSize} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{value || ''}</Text>}
                                        {header.isDate &&
                                            <Text color={fontColor} semiBold size={fontSize}>{moment(value).format("DD/MM/YY")}</Text>}
                                        {header.isDateTime &&
                                            <Text color={fontColor} semiBold size={fontSize}>{moment(value).format("DD/MM/YY HH:mm:ss")}</Text>}
                                        {header.isNumber &&
                                            <Text center color={fontColor} semiBold size={fontSize}>{value}</Text>}
                                    </>
                                }
                            </Container>
                        </Grid>
                    )
                })}
            </Grid>

            {params.detail &&
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Container fluid width="100%" height="auto">
                        {params.detail(params.data)}
                    </Container>
                </Collapse>}
        </Container>
    )
}

export default ListItem