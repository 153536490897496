import api, { APP_URL } from "./";
import { IAuthLogin, IAuthPasswordRecovery, IAuthResetPassword, IAuthUserLogged, IUser } from "../types";
import { convertApiResponseToIUser } from "./users";
import { parseStringToDate } from "../utils/dates";

export const login = async (_email: string, _passw: string) => {
  const response: any = await api.post("/api_manager/auth/login", {
    username: _email,
    email: _email,
    password: _passw,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      if (response.data.action && response.data.action.indexOf("UPDATE_PASSWORD") >= 0) return convertApiResponseToIAuthResetPasword(response.data);
      return convertApiResponseToIAuthLogin(response.data);
    } else if (status == 401) {
      if (response.response.data.error == "Email or password invalid") throw new Error("Email ou senha inválidos");
      if (response.response.data.error == "Inactive user") throw new Error("Usuário inativado. Contate o seu administrador");
    }
  }

  throw new Error("Não foi possível efetuar o login");
};

export const refreshToken = async (_refreshToken: string) => {
  const response: any = await api.post("/api_manager/auth/refresh_token", {
    refreshToken: _refreshToken,
  });
  if (response?.request && response.request?.status === 200) {
    if (response.data.action && response.data.action.indexOf("UPDATE_PASSWORD") >= 0) return convertApiResponseToIAuthResetPasword(response.data);
    return convertApiResponseToIAuthLogin(response.data);
  } else if (response?.request?.status == 401) {
    if (response.response?.data?.error === "Email or password invalid") throw new Error(response.response?.data?.error);
    if (response.response?.data?.error === "Inactive user") throw new Error(response.response?.data?.error);
  }
  throw new Error("Não foi possível efetuar o atualizar o token.");
};

export const forgotPassword = async (_email: string) => {
  const payload: any = {
    email: _email,
    frontEndUrl: APP_URL,
  };
  const response: any = await api.post(`/api_manager/auth/forgot_password`, payload);
  if (response?.request && response.request?.status === 204) {
    return true;
  }
  return false;
};

export const getPasswordRecovery = async (_token: string): Promise<IAuthPasswordRecovery> => {
  const response: any = await api.get(`/api_manager/auth/forgot_password/${_token}`);

  if (response?.request && response.request?.status === 200) {
    return convertToIAuthPasswordRecovery(response.data);
  }
  throw new Error("Não foi possível obter o usuário logado.");
};

export const putPasswordRecovery = async (_token: string, _password: string): Promise<IAuthLogin> => {
  const response: any = await api.put(`/api_manager/auth/forgot_password/${_token}`, {
    password: _password,
  });

  if (response?.request && response.request?.status === 200) {
    return convertApiResponseToIAuthLogin(response.data);
  }
  throw new Error("Não foi possível obter o usuário logado.");
};

export const updatePasswordWithKey = async (_key: string, _password: string) => {
  const response = await api
    .put(`/api_manager/auth/forgot_password/${_key}`, {
      type: "password",
      value: _password,
      temporary: false,
    })
    .catch((_err: any) => {
      throw _err;
    });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) return true;
    else if (status == 400 && (response as any).response && (response as any).response.data) {
      if ((response as any).response.data.error == "E-mail not found on database") throw new Error((response as any).response.data.error);
      throw new Error((response as any).response.data as string);
    }
  }

  return false;
};

export const convertToIAuthUserLogged = (_data: any): IAuthUserLogged => {
  return {
    id: _data.id,
    emailVerified: _data.emailVerified,
    fullName: _data.fullName,
    firstName: _data.firstName,
    lastName: _data.lastName,
    preferredUserName: _data.preferredUserName,
    email: _data.email,
  };
};

export const convertParsedTokenToIAuthUserLogged = (_data: any): IAuthUserLogged => {
  return {
    id: _data.sub,
    emailVerified: _data.email_verified,
    fullName: _data.name,
    firstName: _data.given_name,
    lastName: _data.family_name,
    preferredUserName: _data.preferred_username,
    email: _data.email,
  };
};

export const convertApiResponseToIAuthLogin = (_data: any): IAuthLogin => {
  return {
    accessToken: _data.accessToken,
    expiresIn: _data.expiresIn,
    refreshExpiresIn: _data.refreshExpiresIn,
    refreshToken: _data.refreshToken,
    tokenType: _data.tokenType,
    notBeforePolicy: _data.notBeforePolicy,
    sessionState: _data.sessionState,
    scope: _data.scope,
  };
};

export const convertApiResponseToIAuthResetPasword = (_data: any): IAuthResetPassword => {
  return {
    key: _data.key,
    action: _data.action,
  };
};

export const convertToIAuthPasswordRecovery = (_data: any): IAuthPasswordRecovery => {
  return {
    id: _data.id,
    userId: _data.userId,
    token: _data.token,
    used: _data.used,
    frontEndUrl: _data.frontEndUrl,
    createdAt: parseStringToDate(_data.createdAt),
    updatedAt: parseStringToDate(_data.updatedAt),
  };
};
