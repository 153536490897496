import axios, { AxiosResponse } from "axios";
import { REDSOFT_ACCESS_TOKEN, REDSOFT_REFRESH_TOKEN, REDSOFT_TOKEN_EXPIRES_IN, REDSOFT_USER } from "../plugins/localStorage.consts";

let appURL: string = "http://localhost:3000";
let baseURL: string = "http://localhost:5000";
let baseSocketURL: string = "ws://localhost:5000";
if ((window as any)._env_ && (window as any)._env_.REACT_APP_URL) appURL = (window as any)._env_.REACT_APP_URL;
else if (process.env.REACT_APP_URL != undefined) appURL = process.env.REACT_APP_URL;
if (
  (window as any)._env_ &&
  (window as any)._env_.REACT_APP_REST_PROTOCOL &&
  (window as any)._env_.REACT_APP_SERVER_URL &&
  (window as any)._env_.REACT_APP_WEBSOCKET_URL
) {
  baseURL = `${(window as any)._env_.REACT_APP_REST_PROTOCOL}://${(window as any)._env_.REACT_APP_SERVER_URL}/`;
  baseSocketURL = `${(window as any)._env_.REACT_APP_WEBSOCKET_PROTOCOL}://${(window as any)._env_.REACT_APP_WEBSOCKET_URL}/`;
} else if (
  process.env.REACT_APP_REST_PROTOCOL != undefined &&
  process.env.REACT_APP_SERVER_URL != undefined &&
  process.env.REACT_APP_WEBSOCKET_URL != undefined
) {
  baseURL = `${process.env.REACT_APP_REST_PROTOCOL}://${process.env.REACT_APP_SERVER_URL}/`;
  baseSocketURL = `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_WEBSOCKET_URL}/`;
}

export const APP_URL = appURL;
export const API_URL = baseURL;
export const API_SOCKET_URL = baseSocketURL;

const api = axios.create();
api.defaults.baseURL = API_URL;

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    let statusCode = error?.response?.status || 401;

    if (statusCode === 401) {
      localStorage.removeItem(REDSOFT_ACCESS_TOKEN);
      localStorage.removeItem(REDSOFT_REFRESH_TOKEN);
      localStorage.removeItem(REDSOFT_TOKEN_EXPIRES_IN);
      localStorage.removeItem(REDSOFT_USER);
      window.location.href = "/";
    }
    return Promise.resolve(error);
  }
);

export default api;
