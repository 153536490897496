import { AxiosResponse } from "axios";
import moment from "moment";

import api from ".";
import { IBranch, IBranchSystemHealth, ICompany } from "../types";
import { convertApiResponseToICompany } from "./companies";

const baseUrl: string = "branches";

export const createBranch = async (_branch: IBranch) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    companyId: _branch.companyId,
    name: _branch.name,
    token: _branch.token,
    prefix: _branch.prefix,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIBranch(response.data);
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateBranch = async (_id: string, _branch: IBranch) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    companyId: _branch.companyId,
    name: _branch.name,
    token: _branch.token,
    prefix: _branch.prefix,
    email: _branch.email,
    cnpj: _branch.cnpj,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIBranch(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyBranch = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (status == 400 && (response as any).response && (response as any).response.data) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getBranchs = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const branches: IBranch[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const branch: IBranch = convertApiResponseToIBranch(data);

        branches.push(branch);
      }

      return branches;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getBranch = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const branch: IBranch = convertApiResponseToIBranch(response.data);
      return branch;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getBranchesByCompanyId = async (companyId: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/company_id/${companyId}`);
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const branches: IBranch[] = [];
      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const branch: IBranch = convertApiResponseToIBranch(data);

        branches.push(branch);
      }

      return branches;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIBranchSystemHealth = (_health: any) => {
  let company: ICompany | undefined = undefined;
  if (_health.company) company = convertApiResponseToICompany(_health.company);

  const health: IBranchSystemHealth = {
    id: _health.id,
    branchId: _health.branchId,
    name: _health.name,
    checkedAt: moment(_health.checkedAt).subtract(3, "h").format("DD/MM/YYYY HH:mm:ss"),
    isDeleted: _health.isDeleted,
    createdAt: moment(_health.createdAt).subtract(3, "h").format("DD/MM/YYYY HH:mm:ss"),
    updatedAt: moment(_health.updatedAt).subtract(3, "h").format("DD/MM/YYYY HH:mm:ss"),
  };

  return health;
};

export const convertApiResponseToIBranch = (_branch: any) => {
  const healths: IBranchSystemHealth[] = [];
  if (_branch.healths) {
    for (let i = 0; i < _branch.healths.length; i++) {
      healths.push(convertApiResponseToIBranchSystemHealth(_branch.healths[i]));
    }
  }

  let company: ICompany | undefined = undefined;
  if (_branch.company) company = convertApiResponseToICompany(_branch.company);

  const branch: IBranch = {
    id: _branch.id,
    companyId: _branch.companyId,
    company,
    name: _branch.name,
    email: _branch.email,
    cnpj: _branch.cnpj,
    token: _branch.token,
    prefix: _branch.prefix,
    healths,
    isDeleted: _branch.isDeleted,
    createdAt: moment(_branch.createdAt).subtract(3, "h").format("DD/MM/YYYY HH:mm:ss"),
    updatedAt: moment(_branch.updatedAt).subtract(3, "h").format("DD/MM/YYYY HH:mm:ss"),
  };

  return branch;
};
