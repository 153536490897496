import { FC } from 'react';
import { Container, Page, theme } from '../../../components';
import MonitoringRecipent from './monitoring-list';

const MonitoringSettings: FC<any> = () => {
    return (
        <Page width='auto' height='100%' color={theme.palette.background.default}>
            <Container fluid flex flexGrow color={theme.palette.background.default} width="100%">
                <MonitoringRecipent></MonitoringRecipent>
            </Container>
        </Page >
    );
}

export default MonitoringSettings;