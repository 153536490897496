import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Divider, Menu, IconButton } from "@mui/material";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useCompanies } from "../../../../hooks/companies";
import { ICompany, ICompanyMultiSelect } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import { normalizeString } from "../../../../utils/stringFormatters";

const CompaniesMultiSelect: FC<ICompanyMultiSelect> = (params: ICompanyMultiSelect) => {
    const companiesSelectId: string = "companies-select-id-button";

    const { companies, fetchCompanies } = useCompanies();

    const [search, setSearch] = useState<string>('')
    const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>([]);

    const [anchorCompaniesSelectId, setAnchorCompaniesSelectId] = useState<null | HTMLElement>(null);
    const isCompaniesSelectIdOpen = Boolean(anchorCompaniesSelectId)

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        setFilteredCompanies([...companies]);
    }, [companies]);

    useEffect(() => {
        filterCompanies(search);
    }, [search]);

    const handleCompaniesSelectIdMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorCompaniesSelectId(event.currentTarget);
    };

    const handleCompaniesSelectIdMenuClose = () => {
        setAnchorCompaniesSelectId(null);
    };

    const filterCompanies = (_search: string) => {
        setFilteredCompanies([])
        let auxCompanies = [...companies]

        setTimeout(() => {
            if (_search != '') {
                auxCompanies = auxCompanies.filter((item: ICompany) =>
                    (item.name && normalizeString(item.name).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredCompanies([...auxCompanies])
        }, 0)
    }

    const handleSelectCompany = (_company: ICompany, _isChecked: boolean) => {
        if (params.singleSelect) {
            params.setSelectedCompanies([_company]);
            return;
        }

        const auxCompanies: ICompany[] = [];
        for (let i = 0; i < params.selectedCompanies.length; i++) {
            const auxCompany: ICompany = params.selectedCompanies[i];
            if (auxCompany.id == _company.id && !_isChecked) continue;
            auxCompanies.push(auxCompany);
        }
        if (_isChecked) auxCompanies.push(_company);

        params.setSelectedCompanies([...auxCompanies]);
    }

    const renderCompanyItem = (_company: ICompany, key: number) => {
        const isChecked: boolean = params.selectedCompanies.find(item => item.id == _company.id) != undefined;

        return (
            <Container key={key} inline spacedBetween horizontalCentered width="100%"
                onClick={() => handleSelectCompany(_company, !isChecked)}
                sx={{ cursor: 'pointer' }}>
                <Checkbox size="small" checked={isChecked} />
                <Container fluid flexStart flex>
                    <Text>{_company.name}</Text>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth={params.minWidth || "130px"} height="30px">
            <Container inline horizontalCentered spacedBetween minWidth={params.minWidth || "130px"} height="30px" onClick={handleCompaniesSelectIdMenuOpen}
                style={{ cursor: 'pointer', paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedCompanies.length == 0 && <Text size={15}>Empresas</Text>}
                {params.selectedCompanies.length > 0 &&
                    <Text bold size={14}>
                        {`${params.selectedCompanies[0].name} ${!params.singleSelect && params.selectedCompanies.length > 1 ? ' +' + (params.selectedCompanies.length - 1) : ''}`}
                    </Text>
                }
                <IconButton style={{ backgroundColor: 'transparent' }}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Menu
                id={companiesSelectId}
                anchorEl={anchorCompaniesSelectId}
                anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                transformOrigin={{ vertical: "top", horizontal: "right", }}
                open={isCompaniesSelectIdOpen}
                onClose={handleCompaniesSelectIdMenuClose}>
                <Container fluid flex padded minWidth={params.minWidth || "130px"}  borderRadius={BORDER_RADIUS} >
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder='Pesquisar empresa'
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color='primary' />}
                        sx={{ height: '35px' }} />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: 'auto' }}>
                        {filteredCompanies.map((item, index) => renderCompanyItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSelectedCompanies([])}>
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Menu>
        </Container>
    );
}

export default CompaniesMultiSelect