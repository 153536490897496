import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, ButtonBase, Grow, IconButton } from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import { useAuth } from "../../../../hooks/auth";
import { useDrawer } from "../../../../hooks/drawer";
import { IDrawerButton } from "../../../../types";
import { PADDING } from "../../../../utils/consts";

const DrawerButton: FC<IDrawerButton> = (params: IDrawerButton) => {
    const { privilege } = useAuth();
    const { isOpen } = useDrawer();
    const navigate = useNavigate();

    return (
        <Container inline width="90%" flexStart>
            <ButtonBase onClick={() => navigate(params.route)}>
                <Box
                    sx={{
                        backgroundColor: "transparent",
                        padding: "12px",
                        alignItems: "center",
                        display: "flex"
                    }}
                >
                    {params.icon}
                </Box>
                <Grow in={isOpen}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            height: "100%",
                            cursor: "pointer",
                        }}
                    >
                        <Text bold size={16} color={params.color}>
                            {params.name}
                        </Text>
                    </Box>
                </Grow>
            </ButtonBase>
        </Container>
    );
};

export default DrawerButton;
