import React, { FC, useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Button, Grid, Chip, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/pt-br';

import 'react-image-lightbox/style.css';

import Container from '../../../atoms/container';
import { getCarcassClassification, getCarcassClassificationColor } from '../../../../hooks/carcasses';
import Lightbox from 'react-image-lightbox';
import LotClassificationPieChartModal from '../lotClassificationPieChartModal';
import LotPhotoUploadModal from '../lotPhotoUploadModal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import {  ILot, ILotClassificationData } from '../../../../types';
import theme from '../../../theme';
import { PADDING } from '../../../../utils/consts';
import { PIE_COLORS } from '../lotClassificationPieChartModal';

const FONTSIZE_DATA = 15;
const FONTSIZE_DATA_LABEL = 13;
interface ILotHeader extends ILot{
    onExpandClick: () => void; 
    expanded: boolean;
    uploadLotImage:  (_lot: ILot, image: File) => Promise<string>;
}

const LotHeader: FC<ILotHeader> = (params) => {
    
    const { onExpandClick, expanded, ...extracted_lot} = params;

    const [uploadPhotoIsOpen, setUploadPhotoIsOpen] = useState<boolean>(false);
    const [lot, setLot] = useState<ILot>({ ...extracted_lot});
    const [photoIsExpanded, setPhotoIsExpanded] = useState<boolean>(false);
    const [selectedPhoto, setSelectedPhoto] = useState<string | undefined>();
    const [LotClassificationDataPieChart, setLotClassificationDataPieChart] = useState<ILotClassificationData[]>([]);
    const [pieChartModalIsOpen, setPieChartModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const data: ILotClassificationData[] = []
        if(params.carcassCountPerClass){
            params.carcassCountPerClass.map((obj: any, index: any) => (
                data.push({name: getCarcassClassification(obj.classificationId, false), value: obj.count})
            )
            )
            setLotClassificationDataPieChart(data);
        }
        
    }, [])
    
    const onCloseModal = () => {
        setUploadPhotoIsOpen(!uploadPhotoIsOpen);
    }
    
    const onVisualizeClick = (_photoUri: string | undefined) => {
        if(_photoUri){
            setSelectedPhoto(_photoUri);
            setPhotoIsExpanded(true);
        }
    }
    const togglePieChartModal = () => {
        setPieChartModalIsOpen(!pieChartModalIsOpen);
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, percent, color }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        name: string;
        percent: number;
        color: string;
        }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + (radius + 46) * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + (radius + 46) * Math.sin(-midAngle * (Math.PI / 180));
        
        return (
            <text x={x} y={y} fill={color} textAnchor="middle" dominantBaseline="central" fontSize={12}>
                {
                    percent ? `${(percent * 100).toFixed(2)}%`: ''
                }
            </text>
        );
      };
      
    return (
        <Container fluid centered width="100%" sx={{borderBottom: `2px solid ${theme.palette.grey[200]}`}}>
            <Container fluid width="100%"  >
                <Container inline maxHeight="auto" padded>
                    <Spacing left={PADDING / 2} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={2} style={{ whiteSpace: "nowrap" }}>
                            <Spacing top={PADDING / 2} />
                            
                            {params.lotNum &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Container inline flex>
                                        <IconButton onClick={params.onExpandClick}>
                                            {params.expanded ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                        <Spacing left={PADDING / 2} />
                                        <Text size={16} bold style={{ alignSelf: "center"}}>Lote #{params.lotNum}</Text>
                                    </Container>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} style={{ whiteSpace: "nowrap" }}>                            
                            <Spacing top={PADDING / 2} />
                            {params.farm &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium> Fazenda: </Text>
                                    <Text size={FONTSIZE_DATA} bold>{params.farm} </Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {params.carcassCount &&
                                <Grid item xs={12} sm={6} md={3}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium> Quantidade de meia carcaças: </Text>
                                    <Text size={FONTSIZE_DATA} bold>{params.carcassCount} </Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {(params.averageWeight &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium>Peso médio: </Text>
                                    <Text size={FONTSIZE_DATA} bold>{params.averageWeight.toFixed(2)} kg</Text>
                                </Grid>
                            ) ||
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium>Peso médio: </Text>
                                    <Text size={FONTSIZE_DATA} bold>Indisponível</Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {(params.totalWeight &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium>Peso total: </Text>
                                    <Text size={FONTSIZE_DATA} bold>{params.totalWeight.toFixed(2)} kg</Text>
                                </Grid>
                            ) ||
                                <Grid item xs={12} sm={6} md={6}>
                                    <Text size={FONTSIZE_DATA_LABEL} medium>Peso total: </Text>
                                    <Text size={FONTSIZE_DATA} bold>Indisponível</Text>
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                            {params.uniqueDocNums &&
                                <Grid container item xs={12} sm={6} md={6} >
                                    <Text size={FONTSIZE_DATA_LABEL} medium style={{alignSelf: "center"}}>Contratos: </Text>
                                    <Spacing right={PADDING / 2} />
                                    {params.uniqueDocNums.split(',').map((docNum, index) => (        
                                        <React.Fragment key={index}>
                                            <Chip label={docNum} size="small" variant="outlined" sx={{ fontWeight: "bold", fontSize: `${FONTSIZE_DATA_LABEL}` ,backgroundColor: `${theme.palette.grey[100]}` }}/>
                                            <Spacing right={PADDING / 2} />
                                        </React.Fragment>   
                                    ))}
                                </Grid>
                            }
                            <Spacing top={PADDING / 2} />
                        </Grid>
                        <Grid item container xs={12} sm={12} md={6} lg={6}>
                            <Grid item sm={8} md={6} lg={4} xl={5} textAlign="center" style={{ display: 'flex', flexDirection: 'column' }}>
                                <Spacing top={PADDING / 2} />
                                {/* <Text size={FONTSIZE_DATA_LABEL} medium>Classificações </Text> */}
                                
                                <PieChart width={185} height={150} onClick={togglePieChartModal} style={{cursor: "pointer"}}>
                                    <Pie
                                    data={LotClassificationDataPieChart}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={50}
                                    fill="#8884d8"
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                    >
                                        {
                                            LotClassificationDataPieChart.map((entry, index) => (
                                                <Cell key={`cell-${index}`}  fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                
                                </PieChart> 
                                
                                
                            </Grid>
                            <Grid item sm={4} md={6} lg={7} xl={6}>
                                <Spacing top={PADDING / 2} />
                                {params.classificationDate &&
                                    <Grid item xs={12} textAlign="end">
                                        <Text size={FONTSIZE_DATA_LABEL} semiBold > {moment(params.classificationDate, 'YYYY-MM-DD').locale('pt-BR').format('DD [de] MMMM [de] YYYY')}</Text>
                                    </Grid>
                                }  
                                <Spacing top={PADDING*2} />
                                <Grid item xs={12} style={{textAlign: "right"}}>
                                    <Button size="small" variant="outlined" color="primary" onClick={() => setUploadPhotoIsOpen(true)}>
                                        <Text medium size={13} color={theme.palette.primary.main}>Adicionar foto do lote</Text>
                                    </Button>
                                </Grid>
                                <Spacing top={PADDING} />
                                {
                                    lot.photoUri && lot.photoUri != "" &&
                                    <Grid item xs={12} style={{textAlign: "right"}}>
                                        <Button size="small" variant="outlined" color="primary" onClick={() => onVisualizeClick(lot.photoUri)}>
                                            <Text medium size={13} color={theme.palette.primary.main}>Visualizar foto</Text>
                                        </Button>
                                    </Grid>
                                }
                                
                            </Grid>
                            <Grid item md={0} lg={1} xl={1}></Grid>
                        </Grid>
                    </Grid>
                </Container>
                <Spacing top={PADDING} />
            </Container>
            <LotClassificationPieChartModal
                data={LotClassificationDataPieChart}
                open={pieChartModalIsOpen}
                onClose={togglePieChartModal}
                dimension={500}
                radius={90}
            />

            <LotPhotoUploadModal
                open={uploadPhotoIsOpen}
                onClose={onCloseModal}
                lot={lot}
                setLot={setLot}
                uploadLotImage={params.uploadLotImage}
            />

            
            {lot.photoUri && lot.photoUri != "" && photoIsExpanded && selectedPhoto &&
                <Lightbox
                    mainSrc={selectedPhoto}
                    onCloseRequest={() => setPhotoIsExpanded(false)}
                    zoomInLabel="Mais zoom"
                    zoomOutLabel="Menos zoom" />
            }
        </Container>
    );
}

export default LotHeader;
