import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Grid, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'

import logo from '../../assets/logo_dark.png'
import logoBrand from '../../assets/logo_brand.png'
import logoIcon from '../../assets/logo.png'
import { Container, Image, Input, Page, Spacing, Text, theme } from '../../components'
import { useAuth } from '../../hooks/auth'
import { login as loginService } from '../../services/auth'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IAuthLogin, IAuthResetPassword } from '../../types'
import { instanceOfIAuthLogin, instanceOfIAuthResetPassword } from '../../types/hooks/auth'
import { useResponsive } from '../../hooks/responsive'

const Login: React.FC = () => {
    const { postLogin } = useAuth()
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { mdDown } = useResponsive();

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const onLogin = async () => {
        setLoading(true);
        setLoginErrorMessage(null);

        setTimeout(async () => {
            try {
                const authResponse: IAuthLogin | IAuthResetPassword = await loginService(email, password);
                if (instanceOfIAuthLogin(authResponse))
                    postLogin(authResponse);
                else if (instanceOfIAuthResetPassword(authResponse)) {
                    if (authResponse.action.indexOf("UPDATE_PASSWORD") >= 0)
                        navigate(`/password-recovery/key/${authResponse.key}`);
                }

                setLoading(false);
            } catch (_err) {
                if (_err instanceof Error)
                    setLoginErrorMessage(_err.message)
                else
                    setLoginErrorMessage('Ocorreu um erro ao efetuar o login')

                setLoading(false);
            }
        }, 1000)
    }

    return (
        <Page>
            <Container fluid flex padded color={theme.palette.background.default}>
                <Container inline horizontalCentered>
                    <Image source={logoIcon} height={40} width={40 * 1.666} />
                    <Image source={logoBrand} height={35} width={35 * 4.16} />
                </Container>
                <Divider />
                <Container fluid flex centered padded width="100%">
                    <Container fluid flex flexGrow centered padded maxWidth={mdDown ? "90%" : "500px"}>
                        <form onSubmit={handleSubmit(onLogin)}>
                            <Grid container rowSpacing={2} padding={2}
                                display="flex" alignItems="center" justifyContent="center">
                                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                    <Image source={logo} height={200} width={200 * 1.4857} />
                                </Grid>
                                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                    <Text color={theme.palette.primary.main} size={17}>I.A. para a indústria da Carne.</Text>
                                </Grid>
                                <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <Text semiBold size={15}>E-mail:</Text>
                                    <TextField id="email" placeholder="E-mail" value={email} error={errors.email != undefined} sx={{ width: "100%" }}
                                        {...register('email', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event),
                                            required: 'Informe seu e-mail',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Informe um endereço de e-mail válido',
                                            },
                                        })}
                                    />
                                    {errors.email &&
                                        <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.email as any).message}</Text>
                                    }
                                </Grid>
                                <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <Text semiBold size={15}>Senha:</Text>
                                    <TextField
                                        id="passw"
                                        placeholder="Senha"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        InputProps={{
                                            endAdornment:
                                                <IconButton
                                                    size="small"
                                                    aria-label="passw-togle-visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                        }}
                                        {...register('password', {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangePassword(event),
                                            required: 'Informe sua senha'
                                        })}
                                    />
                                    {errors.password &&
                                        <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{(errors.password as any).message}</Text>
                                    }
                                </Grid>
                                {loginErrorMessage &&
                                    <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" alignContent="center" sx={{ width: "100%" }}>
                                        <Text size={15} color={theme.palette.error.main} style={{ marginBottom: 3 }}>{loginErrorMessage || ''}</Text>
                                    </Grid>
                                }
                                <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        loading={loading}
                                        disabled={loading}
                                        style={{ opacity: loading ? "70%" : "100%" }}>
                                        <Text color={theme.palette.background.default}>Entrar</Text>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </Container>
            </Container>
            <Container fluid centered padded color={theme.palette.background.default}>
                <Text semiBold size={12} color={theme.palette.primary.main} style={{ opacity: 0.9 }}                    >
                    v{process.env.REACT_APP_VERSION}
                </Text>
            </Container>
        </Page>
    )
}

export default Login