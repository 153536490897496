import React, { FC } from "react";

import { ICarcass } from "../../../../types";
import { ICarcassListItem } from "../../../../types";
import { BodyText, Title3 } from "../../../atoms/text";
import { colors } from "../../../theme/styles";
import styled from "@emotion/styled/macro";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


const CarcassListItemCard = styled(Card)(({}) => ({
    borderRadius: '8px',
    background: 'none',
}));


const CarcassListItemBox = styled(CardContent)(({}) => ({
    cursor: 'pointer',
    "&:hover": {
        background: colors.grayLight,
    },
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px !important',
}));


const CarcassListActiveItemBox = styled(CarcassListItemBox)(({}) => ({
    background: colors.primary,
    cursor: 'default',
    "&:hover": {
        background: colors.primary,
    },
}));

const renderSequenceItemTitle = (carcass: ICarcass) => {
    if(carcass.partnerId && !carcass.partnerId.includes('SV'))
        return `Sequencial ${carcass.partnerId}`
    else if(carcass.sequence)
        return `Ordinal ${carcass.sequence}`
    return `Sequencial ${carcass.partnerId}`
}

const CarcassListItem: FC<ICarcassListItem> = (params: ICarcassListItem) => {
    const children = (<>
        <Title3
            semiBold={params.active}
            color={params.active ? 'white' : colors.textColor}
            style={{ whiteSpace: 'nowrap' }}
        >
            {renderSequenceItemTitle(params.carcass)}
        </Title3>
        <BodyText
            semiBold={params.active}
            color={params.active ? 'white' : colors.primary}
            style={{ justifyContent: 'right' }}
        >
            {params.carcass.classification}
        </BodyText>
    </>);

    if (params.active)
        return (
            <CarcassListItemCard variant="outlined">
                <CarcassListActiveItemBox>
                    {children}
                </CarcassListActiveItemBox>
            </CarcassListItemCard>
        )

    return (
        <CarcassListItemCard variant="outlined">
            <CarcassListItemBox onClick={() => params.setCurrCarcass()}>
                {children}
            </CarcassListItemBox>
        </CarcassListItemCard>
    );
}

export default CarcassListItem;