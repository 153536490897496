import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';

import { importXlsFileMarfrig } from '../../../../services/carcasses';
import ButtonMenu from '../../../atoms/buttonMenu';
import { ICarcassContextMenu } from "../../../../types";
import { BodyText } from '../../../atoms/text';


const CarcassContextMenu: FC<ICarcassContextMenu> = (params: ICarcassContextMenu) => {
    const onImportXlsMarfrig = async (event: any) => {
        try {
            params.setImporting(true);
            event.persist();
            const file = event.target.files[0];
            if (file == null) {
                params.setImporting(false);
                return;
            }

            let res = await importXlsFileMarfrig(file);
            if (res) params.showInformation(`Importação efetuada com sucesso. Carcaças importadas: ${res.updated_carcasses_count}`, 'Importação');
            else params.showInformation('Ocorreu um erro ao efetuar a importação', 'Aviso');
            params.setImporting(false);
        }
        catch (err) {
            params.setImporting(false);
        }
    }

    if (params.loading)
        return <CircularProgress style={{ padding: '8px' }} />

    return (
    <ButtonMenu open={(!params.downloadIsOpen && !params.loading && !params.downloadCarcassSheetIsOpen)}>
        {params.hasRights("manage_carcasses__import_xls_marfrig") &&
            <MenuItem
                onClick={() => document.getElementById('upload-xls')?.click()}
                style={{ justifyContent: 'right' }}
            >
                <BodyText muted={params.loading || params.importing} style={{ alignItems: 'center' }}>
                    Importar Xls - Marfrig&ensp;
                    {params.loading || params.importing ?
                        <CircularProgress style={{ padding: '12px', margin: '-8px auto' }} />
                        : <FileUploadIcon />
                    }
                </BodyText>
                <input
                    type='file'
                    id='upload-xls'
                    name='xls'
                    accept='Excel/xls, Excel/xlsx'
                    onChange={onImportXlsMarfrig}
                    onClick={() => {
                        params.setImporting(true);
                        document.body.onfocus = () => params.setImporting(false);
                    }}
                    hidden
                />
            </MenuItem>
        }
        {params.hasRights("manage_carcasses__download_photos") &&
            <MenuItem
                onClick={() => params.setDownloadIsOpen(true)}
                style={{ justifyContent: 'right' }}
            >
                <BodyText muted={params.loading || params.downloadIsOpen}>
                    Baixar Fotos&ensp;
                    {params.loading || params.downloadIsOpen ?
                        <CircularProgress style={{ padding: '12px', margin: '-8px auto -4px' }} />
                        : <DownloadIcon />
                    }
                </BodyText>
            </MenuItem>
        }

        <MenuItem
            onClick={() => params.setDownloadCarcassSheetIsOpen(true)}
            style={{ justifyContent: 'right' }}
        >
            <BodyText muted={params.loading || params.downloadCarcassSheetIsOpen}>
                Baixar Planilha de Carcaças&ensp;
                {params.loading || params.downloadCarcassSheetIsOpen ?
                    <CircularProgress style={{ padding: '12px', margin: '-8px auto -4px' }} />
                    : <DownloadIcon />
                }
            </BodyText>
        </MenuItem>
    </ButtonMenu>
    )
}

export default CarcassContextMenu;
