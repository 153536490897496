import { AxiosResponse } from "axios";

import api from ".";
import { IMonitoringType } from "../types";

const baseUrl: string = "monitoring_types";

export const createMonitoringType = async (
  _monitoringType: IMonitoringType
) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    name: _monitoringType.name,
    value: _monitoringType.value,
    description: _monitoringType.description,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIMonitoringType(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateMonitoringType = async (
  _id: string,
  _monitoringType: IMonitoringType
) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    name: _monitoringType.name,
    value: _monitoringType.value,
    description: _monitoringType.description,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIMonitoringType(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyMonitoringType = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getMonitoringTypes = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const monitoringTypes: IMonitoringType[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const monitoringType: IMonitoringType =
          convertApiResponseToIMonitoringType(data);

        monitoringTypes.push(monitoringType);
      }

      return monitoringTypes;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getMonitoringType = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const monitoringType: IMonitoringType =
        convertApiResponseToIMonitoringType(response.data);
      return monitoringType;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIMonitoringType = (_monitoringType: any) => {
  const monitoringType: IMonitoringType = {
    id: _monitoringType.id,
    name: _monitoringType.name,
    value: _monitoringType.value,
    description: _monitoringType.description,
    isDeleted: _monitoringType.isDeleted,
    createdAt: _monitoringType.createdAt,
    updatedAt: _monitoringType.updatedAt,
  };

  return monitoringType;
};
