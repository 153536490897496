import React, { FC, createContext, useContext, useState } from 'react'

import {
    createAnnotatorHasAccess, updateAnnotatorHasAccess, getAnnotatorHasAccesss,
    destroyAnnotatorHasAccess, getAnnotatorHasAccess, getAnnotatorHasAccessByAnnotatorId,
    getAnnotatorHasAccessByAnnotatorAndBranch
} from '../services/annotatorsHasAccess';
import { IAnnotatorHasAccess, IAnnotatorHasAccessContext } from '../types'

const AnnotatorHasAccesssContext = createContext<IAnnotatorHasAccessContext>({} as IAnnotatorHasAccessContext);

export const AnnotatorHasAccesssProvider: FC<any> = ({ children }) => {
    const [annotatorsHasAccess, setAnnotatorHasAccesss] = useState<IAnnotatorHasAccess[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchAnnotatorHasAccess, setSearchAnnotatorHasAccess] = useState<string>('');
    const [annotatorsHasAccessPerRow, setAnnotatorHasAccesssPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchAnnotatorsHasAccesses = async () => {
        setLoading(true);
        try {
            const annotatorsHasAccess: IAnnotatorHasAccess[] = await getAnnotatorHasAccesss();
            setAnnotatorHasAccesss([...annotatorsHasAccess]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchAnnotatorHasAccess = async (_id: string) => {
        const annotatorHasAccess: IAnnotatorHasAccess = await getAnnotatorHasAccess(_id);
        return annotatorHasAccess;
    }

    const fetchAnnotatorHasAccessByAnnotatorId = async (_annotatorId: string) => {
        const annotatorHasAccess: IAnnotatorHasAccess[] = await getAnnotatorHasAccessByAnnotatorId(_annotatorId);
        return annotatorHasAccess;
    }
    const fetchAnnotatorHasAccessByAnnotatorAndBranch = async (_annotatorId: string, _branchId: string) => {
        const annotatorHasAccess: IAnnotatorHasAccess[] = await getAnnotatorHasAccessByAnnotatorAndBranch(_annotatorId, _branchId);
        return annotatorHasAccess;
    }

    const createNewAnnotatorHasAccess = async (_annotatorHasAccess: IAnnotatorHasAccess, _reload: boolean = true) => {
        const annotatorHasAccess = await createAnnotatorHasAccess(_annotatorHasAccess);
        if (_reload)
            fetchAnnotatorsHasAccesses();
        return annotatorHasAccess;
    }

    const editAnnotatorHasAccess = async (_id: string, _payload: IAnnotatorHasAccess) => {
        try {
            const annotatorHasAccess = await updateAnnotatorHasAccess(_id, _payload);

            fetchAnnotatorsHasAccesses();
            return annotatorHasAccess;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteAnnotatorHasAccess = async (_id: string) => {
        const annotatorHasAccess = await destroyAnnotatorHasAccess(_id);
        fetchAnnotatorsHasAccesses();
        return annotatorHasAccess;
    }

    return (
        <AnnotatorHasAccesssContext.Provider value={{
            annotatorsHasAccess, loading, fetchAnnotatorsHasAccesses, fetchAnnotatorHasAccess,
            createNewAnnotatorHasAccess, editAnnotatorHasAccess, deleteAnnotatorHasAccess,
            searchAnnotatorHasAccess, setSearchAnnotatorHasAccess, annotatorsHasAccessPerRow, setAnnotatorHasAccesssPerRow, paginate, setPaginate,

            fetchAnnotatorHasAccessByAnnotatorId, fetchAnnotatorHasAccessByAnnotatorAndBranch
        }}>{children}</AnnotatorHasAccesssContext.Provider>
    )
}

export function useAnnotatorHasAccesss() {
    const context = useContext(AnnotatorHasAccesssContext);

    if (!context) {
        throw new Error('useAnnotatorHasAccesss must be used within an AnnotatorHasAccesssProvider')
    }

    return context;
}
