import React, { useEffect, useState } from "react";
import { ICarcassListFilterHook, ICarcassAutocompleteData } from "../../../../types";


// Hook that abstracts most of the logic behind carcass list filters
const useCarcassFilter = (params: ICarcassListFilterHook) => {
    const [farms, setFarms] = useState<ICarcassAutocompleteData<string>[]>([]);
    const [classification, setClassification] = useState<ICarcassAutocompleteData<string>[]>([]);
    const [docNums, setDocumentNumbers] = useState<ICarcassAutocompleteData<number>[]>([]);
    const [lotNums, setLotNumbers] = useState<ICarcassAutocompleteData<number>[]>([]);
    const [sequences, setSequences] = useState<ICarcassAutocompleteData<string>[]>([]);
    const [genders, setGenders] = useState<ICarcassAutocompleteData<string>[]>([]);
    const [bestPlaced, setBestPlaced] = useState<boolean[]>([]);
    
    const [submitTrigger, setSubmitTrigger] = useState<boolean>(false); // Will execute submit() on value change
    const [initialValues, setInitialValues] = useState({
        farms: Array<ICarcassAutocompleteData<string>>(),
        classification: Array<ICarcassAutocompleteData<string>>(),
        docNums: Array<ICarcassAutocompleteData<number>>(),
        lotNums: Array<ICarcassAutocompleteData<number>>(),
        sequences: Array<ICarcassAutocompleteData<string>>(),
        genders: Array<ICarcassAutocompleteData<string>>(),
        bestPlaced: Array<boolean>(),
    });

    // Check if filters should be cleared by checking if current filter values exist
    const farmListKeys = params.farmList.map((item: ICarcassAutocompleteData<string>) => item.key);
    const classificationListKeys = params.classificationList.map((item: ICarcassAutocompleteData<string>) => item.key);
    const docListKeys = params.docList.map((item: ICarcassAutocompleteData<number>) => item.key);
    const lotListKeys = params.lotList.map((item: ICarcassAutocompleteData<number>) => item.key);
    const sequenceListKeys = params.sequenceList.map((item: ICarcassAutocompleteData<string>) => item.key);
    const shouldClearFilters = (farms.length > 0 && !farms.map(
        (item: ICarcassAutocompleteData<string>) => item.key
    ).every(
        (item: string) => farmListKeys.includes(item)
    )) || (classification.length > 0 && !classification.map(
        (item: ICarcassAutocompleteData<string>) => item.key
    ).every(
        (item: string) => classificationListKeys.includes(item)
    )) || (docNums.length > 0 && !docNums.map(
        (item: ICarcassAutocompleteData<number>) => item.key
    ).every(
        (item: number) => docListKeys.includes(item)
    )) || (lotNums.length > 0 && !lotNums.map(
        (item: ICarcassAutocompleteData<number>) => item.key
    ).every(
        (item: number) => lotListKeys.includes(item)
    )) || (sequences.length > 0 && !sequences.map(
        (item: ICarcassAutocompleteData<string>) => item.key
    ).every(
        (item: string) => sequenceListKeys.includes(item)
    ));

    useEffect(() => submit(), [submitTrigger])

    const submit = () => {
        const farmFilter = farms.map((item) => item.key);
        const classificationFilter = classification.map((item) => item.key);
        const docFilter = docNums.map((item) => item.key);
        const lotFilter = lotNums.map((item) => item.key);
        const sequencesFilter = sequences.map((item) => item.key);
        const gendersFilter = genders.map((item) => item.key);

        params.setFarms(farmFilter);
        params.setClassification(classificationFilter);
        params.setDocumentNumbers(docFilter);
        params.setLotNumbers(lotFilter);
        params.setSequences(sequencesFilter);
        params.setGenders(gendersFilter);
        params.setBestPlaced(bestPlaced);

        setInitialValues({
            farms,
            classification,
            docNums,
            lotNums,
            sequences,
            genders,
            bestPlaced
        });
    }

    const handleSubmit = () => {
        setSubmitTrigger(!submitTrigger);
    }

    const clearFilters = () => {
        setFarms([]);
        setClassification([]);
        setDocumentNumbers([]);
        setLotNumbers([]);
        setSequences([]);
        setGenders([]);
        setBestPlaced([]);
    }

    const revertFilters = () => {
        setFarms(initialValues.farms);
        setClassification(initialValues.classification);
        setDocumentNumbers(initialValues.docNums);
        setLotNumbers(initialValues.lotNums);
        setSequences(initialValues.sequences);
        setGenders(initialValues.genders);
        setBestPlaced(initialValues.bestPlaced);

        handleSubmit();
    }

    if (shouldClearFilters)
        clearFilters();

    return {
        farms,
        setFarms,
        classification,
        setClassification,
        docNums,
        setDocumentNumbers,
        lotNums,
        setLotNumbers,
        sequences,
        setSequences,
        genders,
        setGenders,
        bestPlaced,
        setBestPlaced,
        handleSubmit,
        clearFilters,
        revertFilters,
    };
};

export default useCarcassFilter;