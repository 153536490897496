import React, { FC, createContext, useState, useContext } from "react";
import moment from "moment";

import { theme } from "../components";
import { fetchAnalysisAnnotationsGroupSides } from "../services/analysisAnnotations";
import {
    IAnalysisAnnotations,
    IAnalysisAnnotationsContext,
    IAnalysisAnnotationsFilterParams,
    ICompany,
} from "../types";

const AnalysisAnnotationsContext = createContext<IAnalysisAnnotationsContext>(
    {} as IAnalysisAnnotationsContext,
);

export const AnalysisAnnotationsProvider: FC<any> = ({ children }) => {
    const [analysis, setAnalysisAnnotations] = useState<IAnalysisAnnotations[]>(
        [],
    );
    const [paginate, setPaginate] = useState<number>(0);
    const [perRow, setPerRow] = useState<string>("15");
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IAnalysisAnnotationsFilterParams>({
        initialDate: moment().toDate(),
        finalDate: moment().toDate(),
        classifications: [],
        branches: [],
    });

    const fetchAnalysisAnnotations = async (
        _filter: IAnalysisAnnotationsFilterParams,
    ) => {
        setLoading(true);
        setAnalysisAnnotations([]);

        const analysisAnnotations: IAnalysisAnnotations[] =
            await fetchAnalysisAnnotationsGroupSides(_filter);
        setAnalysisAnnotations(analysisAnnotations);

        setFilter(_filter);

        setTimeout(() => {
            setAnalysisAnnotations([...analysisAnnotations]);
            setPaginate(0);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <AnalysisAnnotationsContext.Provider
            value={{
                analysis,
                loading,
                paginate,
                perRow,
                setPerRow,
                setPaginate,
                fetchAnalysisAnnotations,
                filter,
                setFilter,
            }}
        >
            {children}
        </AnalysisAnnotationsContext.Provider>
    );
};

export function useAnalysisAnnotations() {
    const context = useContext(AnalysisAnnotationsContext);

    if (!context) {
        throw new Error(
            "useAnnotationsContext must be used within an AnalysisAnnotationsContextProvider",
        );
    }

    return context;
}
