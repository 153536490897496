import React, { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import { IButtonMenu } from '../../../types';


/* Icon that pops a menu. Children should be MenuItems (from MUI) */
const ButtonMenu: FC<IButtonMenu> = ((params: IButtonMenu) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const isOpen = Boolean(anchorEl && params.open !== false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (params.onOpen) params.onOpen(event);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (params.onClose) params.onClose();
    };

    return (<>
        <IconButton
            size={params.size}
            aria-label={params.aria || "more-options-menu"}
            onClick={handleClick}
            style={{ padding: 'auto !important', ...params.style }}
        >
            {params.icon || <MoreVertIcon color="primary"/>}
        </IconButton>
        <Menu open={isOpen} anchorEl={anchorEl} onClose={handleClose}>
            {params.children}
        </Menu>
    </>);
});

export default ButtonMenu;
