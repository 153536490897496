import React from 'react';

import Container from '../container';
import Theme from '../../theme'
import { IPage } from '../../../types'

const Page: React.FC<IPage> = (params: IPage) => {
    return (
        <Container
            id={params.id}
            className={params.className}
            fluid
            height={params.height || "100vh"}
            width={params.width || "100%"}
            color={params.color || Theme.palette.background.default}
            veryPadded={params.veryPadded}
            centered={params.centered}
            style={{ ...params.style }}
        >
            {params.children}
        </Container>
    );
};

export default Page;
