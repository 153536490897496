import { FC } from 'react'
import { Backdrop, CircularProgress, Button } from '@mui/material'
import { useLoading } from '../../../hooks/loading';


const Loading: FC<any> = (params: any) => {    
    const { isVisible } = useLoading();
    
    return (
        <div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={ isVisible }
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
    )
}

export default Loading;