import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Fab, Grow, IconButton, TextField } from '@mui/material';
import { ChevronLeft as BackIcon, PersonAdd as PersonAddIcon, Search as SearchIcon } from '@mui/icons-material';

import { Container, Page, Spacing, Text, theme, UserRegisterModal } from '../../components';
import UsersList from '../../components/molecules/users/list';
import { useAuth } from '../../hooks/auth';
import { useBranches } from '../../hooks/branches';
import { useBranchesHasUser } from '../../hooks/branchesHasUser';
import { useCompanies } from '../../hooks/companies';
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information';
import { getUser } from '../../services/users';
import { IBranch, IBranchHasUser, ICompany, IUser } from '../../types';
import { PADDING, VERY_PADDING } from '../../utils/consts'
import { useUsers } from '../../hooks/users';

const Users: FC = () => {
    const { hasRights } = useAuth();
    const { branches, fetchBranches } = useBranches();
    const { fetchBranchesHasUserByBranch, deleteBranchHasUserBranchUser } = useBranchesHasUser();
    const { companies, fetchCompanies } = useCompanies();
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const navigate = useNavigate();
    const { companyId, branchId } = useParams();
    const userHooks = useUsers();

    const [company, setCompany] = useState<ICompany | undefined>(undefined);
    const [branch, setBranch] = useState<IBranch | undefined>(undefined);
    const [fabHover, setFabHover] = useState<boolean>(false);
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
    const [loadingUsersData, setLoadingUsersData] = useState<boolean>(false);
    const [newUserModalVisible, setNewUserModalVisible] = useState<boolean>(false);
    const [searchUser, setSearchUser] = useState<string>('')
    const [users, setUsers] = useState<IUser[]>([]);
    const [userToEdit, setUserToEdit] = useState<IUser | undefined>(undefined);

    useEffect(() => {
        if (companyId) {
            fetchCompanies();
            fetchBranches();
        }
    }, [companyId]);

    useEffect(() => {
        setCompany(companies.find(item => item.id == companyId));
    }, [companies]);

    useEffect(() => {
        if (branchId)
            fetchData(branchId);
    }, [branchId]);

    useEffect(() => {
        setBranch(branches.find(item => item.id == branchId));
    }, [branches]);

    useEffect(() => {
        if (!newUserModalVisible)
            setUserToEdit(undefined);
    }, [newUserModalVisible]);

    useEffect(() => {
        filterUsers(searchUser);
    }, [searchUser, users]);

    const fetchData = async (_branchId: string) => {
        try {
            setLoadingUsersData(true);
            const auxUsers: IUser[] = await userHooks.fetchUsersByBranch(_branchId);
            setUsers(auxUsers);
            setLoadingUsersData(false);
        } catch (err) {
            setLoadingUsersData(false);
        }
    }

    const filterUsers = (_searchUser: string) => {
        let auxUsers = [...users];
        if (_searchUser !== "") auxUsers = auxUsers.filter(item => item.firstName.toLowerCase().indexOf(_searchUser.toLowerCase()) >= 0 ||
            (item.lastName && item.lastName.toLowerCase().indexOf(_searchUser.toLowerCase()) >= 0) ||
            (item.email && item.email.toLowerCase().indexOf(_searchUser.toLowerCase()) >= 0));
        setFilteredUsers(auxUsers);
    }

    const editUserClick = (_userId: string) => {
        navigate(`/companies/${companyId}/branches/${branchId}/users/${_userId}`);
    }

    const onDeleteUser = async (_userId: string) => {
        if (!branchId) return;

        showConfirmation("Deseja realmente remover o acesso desse usuário nesse frigorífico?", "Atenção", "Confirma", "Cancelar",
            async () => {
                await deleteBranchHasUserBranchUser(branchId, _userId);
                await fetchBranchesHasUserByBranch(branchId);
                hideConfirmation();
                showInformation('Usuário excluído com sucesso.', 'Exclusão de usuário');
            },
            () => {
                hideConfirmation();
            });
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <IconButton onClick={() => company && branch && navigate(`/companies/${company.id}/branches`)}>
                        <BackIcon color="primary" />
                    </IconButton>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{company && `${company.name} - `} {branch && `${branch.name} - `} Usuários</Text>
                </Container>
                <Spacing top={VERY_PADDING} />
                <TextField id="search-users" autoFocus placeholder="Pesquisar usuários" value={searchUser}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchUser(event.target.value)}
                    InputProps={{ startAdornment: <SearchIcon color='primary' /> }} sx={{ width: "100%" }} />
                <Spacing top={VERY_PADDING} />
                <UsersList
                    loading={loadingUsersData}
                    users={filteredUsers}
                    onEditUser={(_id: string) => editUserClick(_id)}
                    onDeleteUser={(_id: string) => onDeleteUser(_id)} />
            </Container>

            {hasRights!("manage_users") &&
                <>
                    <Grow in={!fabHover}>
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => navigate(`/companies/${companyId}/branches/${branchId}/users/register`)}
                            variant="circular"
                            size="large"
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                right: 16,
                            }}
                            onMouseEnter={() => setFabHover(true)}
                            onMouseLeave={() => setFabHover(false)}>
                            <PersonAddIcon />
                        </Fab>
                    </Grow>
                    <Grow in={fabHover}>
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => navigate(`/companies/${companyId}/branches/${branchId}/users/register`)}
                            variant="extended"
                            size="large"
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                right: 16,
                                height: 56,
                                borderRadius: '28px'
                            }}
                            onMouseEnter={() => setFabHover(true)}
                            onMouseLeave={() => setFabHover(false)}>
                            {fabHover &&
                                <Container inline>
                                    <Text size={14} color={theme.palette.background.paper}>Novo usuário</Text>
                                    <Spacing left={PADDING} />
                                </Container>}
                            <PersonAddIcon />
                        </Fab>
                    </Grow>
                </>
            }


            <UserRegisterModal
                userToEdit={userToEdit}
                open={newUserModalVisible}
                onClose={() => setNewUserModalVisible(false)}
                onCancel={() => setNewUserModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    showInformation(_informationMessage, _informationTitle);
                }} />
        </Page >
    )
}

export default Users;