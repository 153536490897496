import React, { FC, useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Fab, Grow } from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'

import { Container, Page, Spacing, Text, theme, CarcassDownloadModal, VerdictContinuosGrid, VerdictFilter } from '../../components'
import { useAuth } from '../../hooks/auth';
import { useCarcass } from '../../hooks/carcasses';
import { useInformation } from '../../hooks/information';
import { useVerdicts } from "../../hooks/verdicts";
import { ICarcass, IVerdict, IVerdictFilter } from '../../types';
import { PADDING, VERY_PADDING } from '../../utils/consts';

const VerdictsFlow: FC = () => {
    const { user } = useAuth();
    const { fetchCarcassesForVerdicts, loadingCarcassesForVerdicts, carcassesForVerdicts } = useCarcass();
    const { hideInformation, showInformation } = useInformation();
    const { filter, setFilter, createNewVerdict } = useVerdicts();

    const [filterIsOpen, setFilterIsOpen] = useState<boolean>(true);
    const [selectedCarcass, setSelectedCarcass] = useState<ICarcass[]>([]);
    const [showContinuosGrid, setShowContinuosGrid] = useState<boolean>(true);
    const [showSeparatedClasses, setShowSeparatedClasses] = useState<boolean>(false);
    const [revisionDownloadIsOpen, setCarcassDownloadIsOpen] = useState<boolean>(false);

    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        // fetchCarcassesForVerdicts(filter);
    }, []);

    const onSelectCarcass = async (revision: ICarcass) => {
        if (!selectedCarcass.find(item => item.id == revision.id)) {
            setSelectedCarcass([...selectedCarcass, revision]);
        } else {
            const aux: ICarcass[] = [];
            for (let i = 0; i < selectedCarcass.length; i++) {
                if (selectedCarcass[i].id == revision.id) {
                    if (selectedCarcass[i].bestPlacedVerdict != revision.bestPlacedVerdict || (selectedCarcass[i].classificationId != revision.classificationId && revision.classificationId != -1))
                        aux.push(revision);
                } else
                    aux.push(selectedCarcass[i]);
            }
            setSelectedCarcass(aux);
        }
    }

    const setContinuosGridMode = () => {
        setSelectedCarcass([]);
        setShowContinuosGrid(true);
        setShowSeparatedClasses(false);
    }

    const setSeparateClassesMode = () => {
        setSelectedCarcass([]);
        setShowContinuosGrid(false);
        setShowSeparatedClasses(true);
    }

    const applyFilter = async (_filter: IVerdictFilter) => {
        setFilter(_filter);
        setSelectedCarcass([]);
        fetchCarcassesForVerdicts(_filter);
    }

    const selectAllCarcass = async () => {
        const aux: ICarcass[] = [...selectedCarcass];

        for (let i = 0; i < carcassesForVerdicts.length; i++) {
            const annotation: ICarcass = carcassesForVerdicts[i];
            if (selectedCarcass.find(item => item.id == annotation.id))
                continue;
            aux.push(annotation);
        }
        setSelectedCarcass(aux);
    }

    const sendCarcasses = async () => {
        if (!user) return;
        if (selectedCarcass.length == 0) {
            showInformation('Selecione pelo menos uma anotação.', 'Aviso', undefined, () => {
                hideInformation();
            })
            return;
        }

        for (let i = 0; i < selectedCarcass.length; i++) {
            if (!selectedCarcass[i].id) continue;

            const verdict: IVerdict = {
                userId: user.id,
                carcassId: selectedCarcass[i].id,
                classificationId: selectedCarcass[i].classificationId,
                bestPlaced: selectedCarcass[i].bestPlacedVerdict
            }
            await createNewVerdict(verdict, false);
        }

        showInformation('Vereditos criados com sucesso.', 'Aviso', undefined, () => {
            hideInformation();
            fetchCarcassesForVerdicts(filter);
            setSelectedCarcass([]);
        });
    }

    return (
        <Page width='100%' height='auto' style={{ minHeight: '95vh', }}>
            <Container fluid color={theme.palette.background.default} width="100%" minHeight="95vh">
                <Container inline flex spacedBetween horizontalCentered padded color={theme.palette.gray.main} maxHeight="25px">
                    <Text bold size={15}>Câmara de arbitragem</Text>
                </Container>

                <Container inline width="100%" height="auto">
                    <VerdictFilter
                        filter={filter}
                        onApplyFilter={applyFilter}
                        onCleanFilter={() => setFilter({ initialDate: new Date(), finalDate: new Date(), annotators: [], branches: [], classificationsId: [], limit: 10 })}
                        onDownloadData={() => setCarcassDownloadIsOpen(true)}
                    />
                </Container>

                {carcassesForVerdicts.length == 0 &&
                    <Container fluid centered height="450px">
                        <Container fluid centered width="500px" height="450px">
                            <Text bold center size={20}>Nenhuma carcaça foi encontrada para arbitragem com o filtro selecionado.</Text>
                        </Container>
                    </Container>
                }

                {carcassesForVerdicts.length > 0 &&
                    <Container fluid flex horizontalCentered color='#000' width="100%" height="100%">
                        <Container fluid flex width="99%">
                            <Container inline centered spacedBetween width="100%" sx={{ marginTop: PADDING }}>
                                <Container inline>
                                    <Spacing left={PADDING} />
                                    <Text size={13} color={theme.palette.background.paper}>Classificações selecionadas: {selectedCarcass.length}</Text>
                                </Container>
                                <Container inline>
                                    <Button size="small" variant="contained" color="info" onClick={() => selectAllCarcass()}>
                                        <Text medium size={13} color={theme.palette.background.paper}>Selecionar todas</Text>
                                    </Button>
                                    <Spacing left={PADDING} />
                                    <Button size="small" variant="contained" color="info" onClick={() => setSelectedCarcass([])}>
                                        <Text medium size={13} color={theme.palette.background.paper}>Selecionar nenhuma</Text>
                                    </Button>
                                </Container>
                            </Container>
                            <VerdictContinuosGrid
                                carcasses={carcassesForVerdicts}
                                selectedCarcasses={selectedCarcass}
                                onSelectCarcass={(auxCarcass: ICarcass) => onSelectCarcass(auxCarcass)}
                            />
                        </Container>
                    </Container>
                }

                <Grow in={!fabHover}>
                    <Fab
                        onClick={() => sendCarcasses()}
                        variant="circular"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        <CheckIcon sx={{ color: theme.palette.background.paper }} />
                    </Fab>
                </Grow>
                <Grow in={fabHover}>
                    <Fab
                        onClick={() => sendCarcasses()}
                        variant="extended"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            height: 56,
                            borderRadius: '28px',
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        {fabHover &&
                            <Container inline>
                                <Text size={14} color={theme.palette.background.paper}>Enviar veredito</Text>
                                <Spacing left={PADDING} />
                            </Container>}
                        <CheckIcon sx={{ color: theme.palette.background.paper }} />
                    </Fab>
                </Grow>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingCarcassesForVerdicts}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <CarcassDownloadModal
                open={revisionDownloadIsOpen}
                onClose={() => setCarcassDownloadIsOpen(!revisionDownloadIsOpen)}
            />
        </Page >
    )
}

export default VerdictsFlow;