import React, { FC } from 'react';

import { Container, Page, Text, theme } from '../../components';
import { useAuth } from '../../hooks/auth';
import SettingsPanel from './settings-panel';

const Settings: FC = () => {
    return (
        <Page
            width='auto'
            height='100%'
            color={theme.palette.background.default}
            style={{ minHeight: '95vh' }}>
            <Container fluid flex flexGrow color={theme.palette.background.default} width="100%" height='100%'>
                <Container fluid flex padded verticalCentered color={theme.palette.gray.main} maxHeight="30px">
                    <Text bold size={15}>Configurações</Text>
                </Container>
                <SettingsPanel></SettingsPanel>
            </Container>
        </Page >
    )
}

export default Settings;