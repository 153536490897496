import React, { FC, useEffect, useRef, useState } from 'react';
import { Checkbox, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import BranchSelect from '../../branches/select';
import CarcassClassificationsMultiSelect from '../classificationsMultiSelect';
import Container from '../../../atoms/container';
import DatePicker from '../../../atoms/datePicker';
import DateRangePicker from '../../../atoms/dateRangePicker';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { useCarcass } from '../../../../hooks/carcasses';
import { ICarcassDownloadSheet } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import { useAuth } from '../../../../hooks/auth';

const CarcassDownloadSheetModal: FC<ICarcassDownloadSheet> = (params: ICarcassDownloadSheet) => {
    const { user } = useAuth();
    const { downloadCarcassesSheet } = useCarcass();


    const [initialDate, setInitialDate] = useState<Date>(new Date());
    const [finalDate, setFinalDate] = useState<Date>(new Date());
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setBranchError(undefined);
        setError(null);
    }

    const onDownload = async () => {
        const valid = validate();

        if(valid){
            try {
                const csvData = await downloadCarcassesSheet(
                    initialDate,
                    finalDate,
                    branchId
                );
                const blob = new Blob([csvData], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
    
                const link = document.createElement('a');
                link.href = url;
                link.download = `planilha_carcacas_${moment().format('YYYY-MM-DD_HH-mm-ss')}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                URL.revokeObjectURL(url);
            } catch (error) {
                console.error(error);
            }
        }
        return

    }
    const validate = () => {
        let isValid: boolean = true;

        if (!initialDate || !finalDate) {
            setError('Favor informar a data');
            isValid = false;
        }
        if (branchId == '') {
            setBranchError('Favor informar o frigorífico')
            isValid = false;
        }

        if (isValid) {
            setError('');
            setBranchError(undefined);
        }
        return isValid;
    }
    const close = () => {
        cleanData();

        params.onClose();
    }

    const cleanData = () => {
        setInitialDate(new Date());
        setFinalDate(new Date());
        setBranchId('');
        setBranchName('');
        setBranchError(undefined);
        setError(null);
        setLoading(false);
    }

    return (
        <Modal isOpened={params.open} onClose={close}>
            <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={BORDER_RADIUS}
                style={{ maxWidth: 540 }} >
                <Container inline color={theme.palette.grayLight.main} centered width="100%" borderTopRadius={BORDER_RADIUS} >
                    <Container padded >
                        <Text size={16} bold color={theme.palette.primary.light}>Download de planilha de carcaças</Text>
                    </Container>
                </Container>

                <Container fluid centered spacedAround veryPadded width="90%">
                    <Text>Preencha o filtro para efetuar o download.</Text>
                    <Spacing top={PADDING} />
                    <Text light size={13}>Esse processo pode demorar um pouco dependendo da quantidade de carcaças.</Text>
                    <Spacing top={VERY_PADDING} />

                    <Grid container rowSpacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <Text light>Data:</Text>
                        </Grid>
                        <Grid item xs={8}>
                            <DateRangePicker
                                initialDate={initialDate}
                                finalDate={finalDate}
                                placeholder="Selecione um intervalo de datas"
                                setInitialDate={setInitialDate}
                                setFinalDate={setFinalDate}
                                disabled={loading}
                                maxDaysSelect={0}
                                sx={{
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px',
                                    height: '37px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Text light>Frigorífico:</Text>
                        </Grid>
                        <Grid item xs={8}>
                            <BranchSelect
                                value={branchId}
                                valueName={branchName}
                                handleChangeValue={handleChangeBranch}
                                variant="outlined"
                                placeholder='Escolha o frigorífico'
                                error={branchError} />
                        </Grid>
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={close}>
                        <Text bold size={12} color={loading ? theme.palette.grey[400] : theme.palette.primary.light}>Fechar</Text>
                    </LoadingButton>
                    <Spacing left={PADDING} />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ opacity: loading ? 0.5 : 1, borderColor: theme.palette.primary.light, width: 150, height: 35 }}
                        loading={loading}
                        disabled={loading}
                        onClick={() => onDownload()}>
                        <Text bold size={12} color={theme.palette.background.paper}>Confirmar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal >
    )
}

export default CarcassDownloadSheetModal;