import { AxiosResponse } from "axios";
import moment from "moment";

import api from "./";
import { convertBase64 } from "../utils/converters";
import { ILotsFilterParams, ILot, ICarcass } from "../types";

const baseUrl: string = "/api_redsoft/v1_0/carcasses";

export const fetchLotsInfos = async (_params: ILotsFilterParams, currentPage: number, elementPerPage: number) => {
    let filter = "";
    if (_params.initialDate) filter += `?initial_date=${moment(_params.initialDate).format("YYYY-MM-DD")}`;
    if (_params.finalDate) filter += (filter != "" ? "&" : "?") + `final_date=${moment(_params.finalDate).format("YYYY-MM-DD")}`;
    if (_params.branches.length > 0) filter += (filter != "" ? "&" : "?") + `branch_id=${_params.branches.map((item) => `${item.id}`).join(",")}`;
    if (_params.docNums && _params.docNums.length > 0) filter += (filter != "" ? "&" : "?") + `doc_nums=${_params.docNums.map((item) => `${item}`).join(",")}`;
    if (_params.farms && _params.farms.length > 0) filter += (filter != "" ? "&" : "?") + `farms=${_params.farms.map((item) => `${item}`).join(",")}`;
    if (_params.lotNums && _params.lotNums.length > 0) filter += (filter != "" ? "&" : "?") + `lot_nums=${_params.lotNums.map((item) => `${item}`).join(",")}`;
    if (_params.dentitions != null && _params.dentitions.length > 0) filter += ((filter == '') ? '?' : '&') + `dentitions=${_params.dentitions.map(item => `${item}`).join(',')}`;
    if (_params.weightIntervals != null && _params.weightIntervals.length > 0) filter += ((filter == '') ? '?' : '&') + `weight_intervals=${_params.weightIntervals.map(item => `${item}`).join(',')}`;
    if (_params.sexes != null && _params.sexes.length > 0) filter += ((filter == '') ? '?' : '&') + `sexes=${_params.sexes.map(item => `${item}`).join(',')}`;
    if (currentPage !== undefined) filter += (filter != "" ? "&" : "?") + `page=${currentPage}`;
    if (elementPerPage !== undefined) filter += (filter != "" ? "&" : "?") + `per_page=${elementPerPage}`;

    const response: any = await api.get(baseUrl + '/lots_info' + filter);
    const lots: ILot[] = [];
    let totalNumberOfLots: number = 0;
    if (response && response.request) {
      const status = response.request.status;
      if (status == 200) {
        const data = response.data;
        if (data !== null && data !== undefined) {
          // The index 0 is the total
          totalNumberOfLots = data[0]['total']
          for (let i = 1; i < data.length; i++) {
            lots.push(data[i]);
          }
        }
      }
    }
    return [lots, totalNumberOfLots] as [ILot[], number] ;
};
export const fetchLotsInternal = async (_lot: ILot, currentPage: number, perPage: number) => {
  let filter = "";
  if (_lot.classificationDate) filter += `?date=${moment(_lot.classificationDate).format("YYYY-MM-DD")}`;
  if (_lot.lotNum) filter += (filter != "" ? "&" : "?") + `lot_num=${_lot.lotNum}`;
  if (_lot.branchId) filter += (filter != "" ? "&" : "?") + `branch_id=${_lot.branchId}`;

  if (currentPage !== undefined) filter += (filter != "" ? "&" : "?") + `page=${currentPage}`;
  if (perPage !== undefined) filter += (filter != "" ? "&" : "?") + `per_page=${perPage}`;

  const response: any = await api.get(baseUrl + '/carcasses_from_lot' + filter);
  const carcasses: ICarcass[] = [];
  const sequenceCountMap: Map<number, number> = new Map();

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data){
        for (let i = 0; i < data.length; i++) {
          const carcass = data[i];
          const sequence = carcass.sequence;
          const count = sequenceCountMap.get(sequence) || 0;
          if (count < 2) {
            carcasses.push(carcass);
            sequenceCountMap.set(sequence, count + 1);
          }
        }
      }
    }
  }
  return carcasses;
};

export const updateLotImage = async (_lot: ILot, image: File) => {
  let baseUrl = '/api_redsoft/v1_0/lots';
  const image64 = await convertBase64(image);
  const response: any = await api.post(baseUrl + '/upload_photo', {
    lot_num: _lot.lotNum,
    date: _lot.classificationDate,
    branch_id: _lot.branchId,
    image64: image64,
  });
  let image_url: string = "";

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200) {
      const data = response.data;
      if (data){
        image_url = data;
      }
    }
  }
  return image_url;
};