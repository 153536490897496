import React from "react";
import { ThemeProvider } from "@mui/material/styles";

import "./App.css";
import "rsuite/dist/rsuite.min.css";
import Theme from "./components/theme";
import { AuthProvider } from "./hooks/auth";
import { FeedbackProvider } from "./hooks";
import Routes from "./routes";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <FeedbackProvider>
          <Routes />
        </FeedbackProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
