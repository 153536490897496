import React from 'react';

import ImageStyled from './styled';
import { IImage } from '../../../types'

const Image: React.FC<IImage> = (params: IImage) => {
    return (
        <ImageStyled
            size={params.size}
            height={params.height}
            width={params.width}
            color={params.color}
            borderColor={params.borderColor}
            style={params.style}
            source={params.source}>
            <img src={params.source} onClick={params.onClick} />
        </ImageStyled>
    );
};

export default Image;
