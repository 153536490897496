import React, { FC, createContext, useContext, useState } from "react";

import { RouteServices } from "../services/routes";
import { IHookProvider, IRoute, IRouteContext } from "../types";

const RoutesContext = createContext<IRouteContext>({} as IRouteContext);

export const RoutesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const routeServices = new RouteServices();
  const [entities, setRoutes] = useState<IRoute[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_user: IRoute) => {
    try {
      const user = await routeServices.createEntity(_user);
      fetchEntities();
      return user;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _user: IRoute) => {
    try {
      const user = await routeServices.updateEntity(_id, _user);
      fetchEntities();
      return user;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const user = await routeServices.destroyEntity(_id);
      fetchEntities();
      return user;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const routes: IRoute[] = await routeServices.getEntities();
      setRoutes([...routes]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const user: IRoute = await routeServices.getEntity(_id);
    return user;
  };

  return (
    <RoutesContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,
        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </RoutesContext.Provider>
  );
};

export function useRoutes() {
  const context = useContext(RoutesContext);

  if (!context) {
    throw new Error("useRoutes must be used within an RoutesProvider");
  }

  return context;
}
