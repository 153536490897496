import React, { FC, createContext, useState, useContext } from 'react'

interface ILoadingContext {
    isVisible: boolean;
  
    hideLoading: (() => void);
    showLoading: (() => void);
    toggleLoading: (() => void);
  }

const LoadingContext = createContext<ILoadingContext>({} as ILoadingContext);


export const LoadingProvider: FC<any> = ({ children }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const showLoading = () => {
        setIsVisible(true);
    }

    const hideLoading = () => {
        setIsVisible(false);
    }

    const toggleLoading = () => {
        setIsVisible(!isVisible);
    }

    return (
        <LoadingContext.Provider value={{
            isVisible, showLoading, hideLoading, toggleLoading,
        }}>{children}</LoadingContext.Provider>
    );
}

export function useLoading() {
    const context = useContext(LoadingContext);

    if (!context) {
        throw new Error('useLoading must be used within an LoadingProvider')
    }

    return context;
}