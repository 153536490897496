import React, { FC, useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Button } from "@mui/material";


import Container from "../../../atoms/container";
import Modal from "../../../atoms/modal";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { ILot, ILotClassificationData } from "../../../../types";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../../../utils/consts";


export const PIE_COLORS = ["#C62828", "#FF482F", "#FF9800", "#4CAF50", "#2e7d32"];

interface ILotClassificationPieChartModal {
    data: ILotClassificationData[];
    open: boolean;
    onClose: () => void;
    dimension: number;
    radius: number;
}


const LotClassificationPieChartModal: FC<ILotClassificationPieChartModal> = (params: ILotClassificationPieChartModal) => {

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent,   index }: {
        cx: number;
        cy: number;
        midAngle: number;
        innerRadius: number;
        outerRadius: number;
        percent: number;
        index: number; 
        }) => {

        const color = PIE_COLORS[index % PIE_COLORS.length];
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + (outerRadius + 45) * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + (outerRadius + 35) * Math.sin(-midAngle * (Math.PI / 180));
        return (
            <text x={x} y={y} fill={color} textAnchor="middle" dominantBaseline="middle" fontSize={12}>
                {
                    `${(percent * 100).toFixed(2)}%`
                }
            </text>
        );
    };
    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container
                fluid
                color={theme.palette.background.default}
                hasShadow
                centered
                borderRadius={BORDER_RADIUS}
                style={{ maxWidth: 540 }}
                >
                <Container
                    inline
                    color={theme.palette.grayLight.main}
                    centered
                    width="100%"
                    borderTopRadius={BORDER_RADIUS}
                >
                    <Container padded>
                        <Text size={16} bold color={theme.palette.primary.light}>
                            Distribuição de Classificações
                        </Text>
                    </Container>
                </Container>
        

                <Container fluid centered spacedAround veryPadded width="90%" >
                    <PieChart width={params.dimension} height={params.dimension} >
                        <Pie
                        data={params.data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={params.radius}
                        label={renderCustomizedLabel}
                        >
                            {
                                params.data.map((entry, index) => (
                                    <Cell key={`cell-${index}`}  fill={PIE_COLORS[index % PIE_COLORS.length]} />
                                ))
                            }
                        </Pie>
                        <Tooltip />
                        <Legend/>
                    </PieChart>
                </Container>
                <Container inline spacedAround veryPadded>
                    <Button variant="contained" color="secondary" size="small" onClick={params.onClose}>
                        <Text>Fechar</Text>
                    </Button>
                </Container>
            </Container>
        </Modal>
    );
};

export default LotClassificationPieChartModal;
