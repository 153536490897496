import React, { FC, useEffect, useState } from "react";

import UserListItem from "../listItem";
import Container from "../../../atoms/container";
import Text from "../../../atoms/text";
import { Pagination } from "../../../../components";
import { useUsers } from "../../../../hooks/users";
import { useResponsive } from "../../../../hooks/responsive";
import { IUser, IUserList } from "../../../../types";
import { LinearProgress } from "@mui/material";

const UsersList: FC<IUserList> = (params: IUserList) => {
    const usersHooks = useUsers();
    const { screenHeight } = useResponsive();

    const pageCount = Math.ceil((screenHeight * 0.6) / 80);
    const usersCount = params.users.length;

    const [currPage, setCurrPage] = useState<number>(0);

    useEffect(() => {
        setCurrPage(0);
    }, [usersHooks.searchUser]);

    return (
        <Container fluid flex width="100%" minHeight="73vh">
            <Container fluid width="100%" minHeight="73vh">
                {params.loading ? <LinearProgress color="primary" /> :
                    params.users.length == 0 && <Text center>Nenhum usuário encontrado.</Text>}
                {params.users.sort((a: IUser, b: IUser) => {
                    if (a.firstName > b.firstName) return 1;
                    if (a.firstName < b.firstName) return -1;
                    return 0;
                }).slice((currPage * pageCount), ((currPage * pageCount) + pageCount))
                    .map((item, index) =>
                        <Container key={index} fluid verticalPadded width="100%">
                            <UserListItem
                                key={index}
                                user={item}
                                loading={usersHooks.loading}
                                onEditUser={params.onEditUser}
                                onDeleteUser={params.onDeleteUser} />
                        </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(usersCount / pageCount)} setPage={setCurrPage} />
        </Container>
    );
};

export default UsersList;