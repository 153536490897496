import React, { FC, useState } from "react";
import { Button, Collapse, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, MonitorHeart as MonitorHeartIcon, People as UsersIcon } from "@mui/icons-material";

import Container from "../../../atoms/container";
import theme from "../../../theme";
import Text from "../../../atoms/text";
import { useResponsive } from "../../../../hooks/responsive";
import { IAnnotatorListItem } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import Spacing from "../../../atoms/spacing";

const AnnotatorListItem: FC<IAnnotatorListItem> = (params: IAnnotatorListItem) => {
    const { mdDown } = useResponsive();

    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Container fluid flex padded hasShadow color={theme.palette.background.default} sx={{ border: `solid 2px ${theme.palette.grayLight.light}` }} borderRadius={2}>
            <Grid container rowSpacing={2} columnSpacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} md={5} display="flex" justifyContent="center">
                    <Container fluid centered={mdDown} width="100%">
                        <Text bold size={17} color={theme.palette.primary.main}>
                            {params.annotator.user?.firstName}
                        </Text>
                        {params.annotator.user?.email &&
                            <Text size={12} color={theme.palette.text.disabled}>
                                E-mail: {params.annotator.user?.email}
                            </Text>
                        }
                        <Text size={12} color={theme.palette.text.disabled}>
                            Acesso completo: {params.annotator.fullAccess ? "Sim" : "Não"}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Anotações/Revisões: {params.annotator.countAnnotations || 0}/{params.annotator.countRevisions || 0}
                        </Text>
                    </Container>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="flex-end" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Container fluid centered={mdDown}>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Criada em: {params.annotator.createdAt}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Atualizada em: {params.annotator.updatedAt}
                        </Text>
                    </Container>
                </Grid>
                <Grid item xs={12} md={3} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Grid container>
                        <Grid item xs={12} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                            {!params.annotator.fullAccess &&
                                <>
                                    <Button variant="outlined" color="primary" size='medium' sx={{ maxHeight: "35px" }}
                                        startIcon={<UsersIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />}
                                        onClick={() => params.annotator.id && params.onOpenPermissions(params.annotator.id)}>
                                        <Text color={theme.palette.primary.main}>Permissões</Text>
                                    </Button>
                                    <Spacing left={PADDING} />
                                </>
                            }
                            <Tooltip title="Editar empresa">
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => params.annotator.id && params.onEditAnnotator(params.annotator.id)}
                                    disableFocusRipple>
                                    <EditIcon color="info" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Spacing left={PADDING} />
                            <Tooltip title="Excluir empresa">
                                <IconButton size='medium' sx={{ maxHeight: "35px" }}
                                    onClick={() => params.annotator.id && params.onDeleteAnnotator(params.annotator.id)}>
                                    <DeleteIcon color="error" fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AnnotatorListItem;