import React, { createContext, FC, useContext, useState } from "react";

import { PrivilegeServices } from "../services/privileges";
import { IHookProvider, IPrivilege, IPrivilegeContext } from "../types";

const PrivilegesContext = createContext<IPrivilegeContext>({} as IPrivilegeContext);

export const PrivilegesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const privilegeServices = new PrivilegeServices();
  const [privileges, setPrivileges] = useState<IPrivilege[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchPrivileges = async () => {
    setLoading(true);
    try {
      const privileges: IPrivilege[] = await privilegeServices.getPrivileges();
      setPrivileges([...privileges]);

      setTimeout(() => setLoading(false), 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  return (
    <PrivilegesContext.Provider value={{ privileges, fetchPrivileges, loading }}>
      {_params.children}
    </PrivilegesContext.Provider>
  );
};

export function usePrivileges() {
  const context = useContext(PrivilegesContext);

  if (!context) {
    throw new Error("usePrivileges must be used within an PrivilegesProvider");
  }

  return context;
}
