import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Divider, Menu, IconButton } from "@mui/material";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useBranches } from "../../../../hooks/branches";
import { IBranch, IBranchMultiSelect } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import { normalizeString } from "../../../../utils/stringFormatters";

const BranchesMultiSelect: FC<IBranchMultiSelect> = (params: IBranchMultiSelect) => {
    const branchesSelectId: string = "branches-select-id-button";

    const { branches, fetchBranches } = useBranches();

    const [search, setSearch] = useState<string>('')
    const [filteredBranches, setFilteredBranches] = useState<IBranch[]>([]);

    const [anchorBranchesSelectId, setAnchorBranchesSelectId] = useState<null | HTMLElement>(null);
    const isBranchesSelectIdOpen = Boolean(anchorBranchesSelectId)

    useEffect(() => {
        fetchBranches();
    }, []);

    useEffect(() => {
        setFilteredBranches([...branches]);
    }, [branches]);

    useEffect(() => {
        filterBranches(search);
    }, [search]);

    const handleBranchesSelectIdMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorBranchesSelectId(event.currentTarget);
    };

    const handleBranchesSelectIdMenuClose = () => {
        setAnchorBranchesSelectId(null);
    };

    const filterBranches = (_search: string) => {
        setFilteredBranches([])
        let auxBranches = [...branches]

        setTimeout(() => {
            if (_search != '') {
                auxBranches = auxBranches.filter((item: IBranch) =>
                    (item.name && normalizeString(item.name).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredBranches([...auxBranches])
        }, 0)
    }

    const handleSelectBranch = (_branch: IBranch, _isChecked: boolean) => {
        if (params.singleSelect) {
            params.setSelectedBranches([_branch]);
            return;
        }

        const auxBranches: IBranch[] = [];
        for (let i = 0; i < params.selectedBranches.length; i++) {
            const auxBranch: IBranch = params.selectedBranches[i];
            if (auxBranch.id == _branch.id && !_isChecked) continue;
            auxBranches.push(auxBranch);
        }
        if (_isChecked) auxBranches.push(_branch);

        params.setSelectedBranches([...auxBranches]);
    }

    const renderBranchItem = (_branch: IBranch, key: number) => {
        const isChecked: boolean = params.selectedBranches.find(item => item.id == _branch.id) != undefined;

        return (
            <Container key={key} inline spacedBetween horizontalCentered width="100%"
                onClick={() => handleSelectBranch(_branch, !isChecked)}
                sx={{ cursor: 'pointer' }}>
                <Checkbox size="small" checked={isChecked} />
                <Container fluid flexStart flex>
                    <Text>{_branch.company ? _branch.company?.name + ' - ' : ''}{_branch.name}</Text>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth={params.minWidth || "130px"} height="30px">
            <Container inline horizontalCentered spacedBetween minWidth={params.minWidth || "130px"} height="30px" onClick={handleBranchesSelectIdMenuOpen}
                style={{ cursor: 'pointer', paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedBranches.length == 0 && <Text size={15}>Filiais</Text>}
                {params.selectedBranches.length > 0 &&
                    <Text bold size={14}>
                        {`${params.selectedBranches[0].name} ${!params.singleSelect && params.selectedBranches.length > 1 ? ' +' + (params.selectedBranches.length - 1) : ''}`}
                    </Text>
                }
                <IconButton style={{ backgroundColor: 'transparent' }}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Menu
                id={branchesSelectId}
                anchorEl={anchorBranchesSelectId}
                anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                transformOrigin={{ vertical: "top", horizontal: "right", }}
                open={isBranchesSelectIdOpen}
                onClose={handleBranchesSelectIdMenuClose}>
                <Container fluid flex padded minWidth={params.minWidth || "130px"} borderRadius={BORDER_RADIUS} >
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder='Pesquisar filial'
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color='primary' />}
                        sx={{ height: '35px' }} />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: 'auto' }}>
                        {filteredBranches.map((item, index) => renderBranchItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSelectedBranches([])}>
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Menu>
        </Container>
    );
}

export default BranchesMultiSelect