import React, { FC } from 'react';

import CountUp from 'react-countup';
import Container from '../../../atoms/container';
import { Box, Grid } from '@mui/material';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { IDashboardChartCard } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';


// GENERAL DIMENSIONS
const topPadding = PADDING*2;
const bottomPadding = PADDING*2;
const leftPadding = PADDING*3;
const rightPadding = PADDING*3;

// MEDIUM SIZE CARD DIMENSIONS
const cardHeight = 250;

const labelTextSize = 22;
const labelValueSpacing = 7;

// SMALL SIZE CARD DIMENSIONS
const smallCardHeight = ((cardHeight + (topPadding + bottomPadding) - 16)/2) - 40; // [(190 + 40 - 16)/2] - 40 = 67  // (cardHeight + (cardTopPadding + cardBotPadding) - rowSpacing)/2 - outerPadding

const labelTextSizeSmallCard = 14;
const valueTextSizeSmallCard = 24;
const labelValueSpacingSmallCard = 7;


const DashboardChartCard: FC<IDashboardChartCard> = (params: IDashboardChartCard) => {
    return (
        <Container 
                flex
                hasShadow 
                padded  
                color="white" 
                borderRadius={BORDER_RADIUS*10}
                sx={params.style ? params.style : {paddingRight: rightPadding, paddingLeft: leftPadding, paddingTop: topPadding, paddingBottom: bottomPadding}}
        >
            {/* IF size="medium" */}
            {
                (
                    (params.size && params.size === "medium") 
                    || !params.size 
                ) &&
                <Grid 
                    container
                    direction="column" 
                    justifyContent="space-between" 
                    alignItems="flex-start" 
                    minHeight={cardHeight}
                    rowSpacing={2}
                    width='300'
                    height='300'
                    >
                    <Grid item xs={6} >
                        <Text semiBold size={labelTextSize} color={theme.palette.primary.main}>{params.label}</Text>
                    </Grid>
                    <Spacing top={labelValueSpacing}></Spacing>
                    <Grid item xs={6} alignSelf="center" width="100%">
                        {params.chart}
                    </Grid>
                </Grid>
            }
        </Container>
    );
}

export default DashboardChartCard;