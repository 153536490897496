import React, { FC } from 'react';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import theme from '../../../theme';
import { useCompanies } from '../../../../hooks/companies';
import { ICompanySelect } from '../../../../types';

const CompanySelect: FC<ICompanySelect> = (params: ICompanySelect) => {
    const { companies } = useCompanies();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <Autocomplete
                id="companies"
                required
                variant={params.variant || 'outlined'}
                options={params.options || companies as any[]}
                value={params.value}
                defaultValue={params.valueName}
                onChange={params.handleChangeValue}
                placeholder={params.placeholder || 'Escolha a empresa'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists={params.value != ''} />
        </Container>
    );
}

export default CompanySelect;