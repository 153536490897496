import { FC, useEffect, useState } from 'react';

import 'react-image-lightbox/style.css';

import Container from '../../../atoms/container';
import { ICarcass } from '../../../../types';
import { ILot } from '../../../../types';
import LotBody  from '../lotBody'
import LotHeader  from '../lotHeader'
import { useLots } from '../../../../hooks/lots';



const LotItem: FC<ILot> = (params: ILot) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [carcasses, setCarcasses] = useState<ICarcass[]>([]);

    const { fetchLotCarcasses, uploadLotImage, lotsCarcasses, loading } = useLots();

    useEffect(() => {
        const carcassesAux = lotsCarcasses.find((lotCarcasse) => lotCarcasse.id === params.id);
        if(carcassesAux !== undefined){
            setCarcasses(carcassesAux.carcasses);
        }
    },[lotsCarcasses]);

    const toggleExpanded = () => {
        if(!expanded){
            if (!loading) {
                updateCarcasses(1, 6);
            }
        }
        setExpanded(!expanded);
    };
    
    const updateCarcasses = (currentPage: number, perPage: number) => {  
        fetchLotCarcasses(params, currentPage, perPage);
    }

    return (
        <Container fluid centered veryHorizontalPadded width="100%" >
            <LotHeader  id={params.id}
                        branchId={params.branchId}
                        lotNum={params.lotNum} 
                        farm={params.farm}
                        carcassCount={params.carcassCount}
                        uniqueDocNums={params.uniqueDocNums} 
                        averageWeight={params.averageWeight}
                        totalWeight={params.totalWeight}
                        classificationDate={params.classificationDate}
                        carcassCountPerClass={params.carcassCountPerClass}
                        photoUri={params.photoUri}
                        onExpandClick={() => toggleExpanded()}
                        expanded={expanded}
                        uploadLotImage={uploadLotImage}
            />
            {expanded &&
                <LotBody
                    carcasses={carcasses}
                    setCarcasses={(currentPage: number, perPage: number) => updateCarcasses(currentPage, perPage)}
                    sequencesCount={params.sequencesCount}
                />
            }
        </Container>
    );
}

export default LotItem;


