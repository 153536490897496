import React, { FC } from "react";
import moment from "moment";
import ptBR from "rsuite/locales/pt_BR";

import { DateRangePicker as DataRangePickerComponent } from "rsuite";
import { IDateRangePicker } from "../../../types";
import { INITIAL_DATE, FINAL_DATE } from "../../../utils/consts";

import "./classes.css";

const DateRangePicker: FC<any> = (params: IDateRangePicker) => {
  const { allowedMaxDays } = DataRangePickerComponent;

  function setDates(arrayDate: any) {
    if(arrayDate === null){
      params.setInitialDate(moment().toDate());
      params.setFinalDate(moment().toDate());
    }else{
      params.setInitialDate(arrayDate[INITIAL_DATE]);
      params.setFinalDate(arrayDate[FINAL_DATE]);
    }
    
  }

  function clear() {
    params.setInitialDate(null);
    params.setFinalDate(null);
  }

  const styleMenu = { display: "block", zIndex: 15 };

  return (
    <DataRangePickerComponent
      placeholder={params.placeholder}
      menuStyle={styleMenu}
      appearance="subtle"
      disabledDate={params.maxDaysSelect ? allowedMaxDays?.(params.maxDaysSelect) : undefined}
      ranges={params.predefinedRanges ? params.predefinedRanges : undefined}
      block
      locale={ptBR.DateRangePicker}
      size="md"
      onOk={(newValue: any) => setDates(newValue)}
      onChange={(newValue: any) => setDates(newValue)}
      disabled={params.disabled}
      onClean={() => clear()}
      style={{
        ...params.sx,
      }}
    />
  );
};

export default DateRangePicker;
