import React, { FC } from 'react';

import CountUp from 'react-countup';
import Container from '../../../atoms/container';
import { Box, Grid } from '@mui/material';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { IDashboardNumberCard } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';


// GENERAL DIMENSIONS
const topPadding = PADDING*2;
const bottomPadding = PADDING*2;
const leftPadding = PADDING*3;
const rightPadding = PADDING*3;

// MEDIUM SIZE CARD DIMENSIONS
const cardHeight = 190;
const cardWidth = 450;

const IconBackgroundSize = '50px';
const IconBackgroundBorderRadius = '35%';
const IconBottomSpacing = 17;

const labelTextSize = 18;
const valueTextSize = 45;
const labelValueSpacing = 7;

// SMALL SIZE CARD DIMENSIONS
const smallCardHeight = ((cardHeight + (topPadding + bottomPadding) - 16)/2) - 40; // [(190 + 40 - 16)/2] - 40 = 67  // (cardHeight + (cardTopPadding + cardBotPadding) - rowSpacing)/2 - outerPadding

const labelTextSizeSmallCard = 14;
const valueTextSizeSmallCard = 24;
const labelValueSpacingSmallCard = 7;


const DashboardNumberCard: FC<IDashboardNumberCard> = (params: IDashboardNumberCard) => {
    return (
        <Container 
                flex
                hasShadow 
                padded  
                color="white" 
                borderRadius={BORDER_RADIUS*10}
                
                sx={params.style ? params.style : {paddingRight: rightPadding, paddingLeft: leftPadding, paddingTop: topPadding, paddingBottom: bottomPadding}}
        >
            {/* IF size="medium" */}
            {
                (
                    (params.size && params.size === "medium") 
                    || !params.size 
                ) &&
                
                
                <Grid 
                    container
                    direction="column" 
                    justifyContent="space-evenly" 
                    alignItems="flex-start" 
                    height="100%"
                    minHeight={cardHeight}
                    >
                    <Grid 
                        item 
                        xs={5} 
                        maxWidth={IconBackgroundSize} 
                        maxHeight={IconBackgroundSize}
                        minWidth={IconBackgroundSize} 
                        minHeight={IconBackgroundSize}
                        justifyContent='center'
                        alignItems='center'
                        sx={{display: 'flex', backgroundColor: theme.palette.primary.dark, borderRadius: IconBackgroundBorderRadius} }
                    >
                        
                        {params.icon}
                    </Grid>
                    <Spacing bottom={IconBottomSpacing}></Spacing>
                    <Grid item xs={4} >
                        {/* {params.content} */}
                        <CountUp formattingFn={num => num.toLocaleString('pt-br')} start={0} end={params.value ? params.value : 0} duration={1} decimals={(params.value !== undefined && params.value%1 === 0) ? 0 : 2}>
                            {(countUp: any) => (
                                <span ref={countUp.countUpRef} style={{ fontFamily: 'Open Sans', fontWeight: 600, fontSize: valueTextSize, color: theme.palette.primary.main}} />
                            )}
                        </CountUp>
                        <Text semiBold size={valueTextSize} color={theme.palette.primary.main}>
                            {params.valueUnit}
                        </Text>
                    </Grid>
                    <Spacing top={labelValueSpacing}></Spacing>
                    <Grid item xs={4} >
                        <Text semiBold size={labelTextSize} color={theme.palette.primary.main}>{params.label}</Text>
                    </Grid>
                </Grid>


            }
            
            {/* IF size="small" */}
            {
                (
                    (params.size && params.size === "small")
                ) &&
                
                <Grid 
                    container
                    // justifyContent="space-evenly" 
                    alignItems="center" 
                    minHeight={smallCardHeight}
                    >
                    <Grid 
                        item 
                        xs={1} 
                        maxWidth={IconBackgroundSize} 
                        maxHeight={IconBackgroundSize}
                        minWidth={IconBackgroundSize} 
                        minHeight={IconBackgroundSize}
                        justifyContent='center'
                        alignItems='center'
                        sx={{display: 'flex', backgroundColor: theme.palette.primary.dark, borderRadius: IconBackgroundBorderRadius} }
                    >
                        
                        {params.icon}
                    </Grid>
                    <Spacing left={IconBottomSpacing}></Spacing>
                    <Grid item container xs={9} >
                        <Grid container item xs={12} >
                           
                            <CountUp formattingFn={num => num.toLocaleString('pt-br')} start={0} end={params.value ? params.value : 0} duration={1} decimals={(params.value !== undefined && params.value%1 === 0) ? 0 : 2}>
                                {(countUp: any) => (
                                    <span ref={countUp.countUpRef} style={{ fontFamily: 'Open Sans', fontWeight: 600, fontSize: valueTextSizeSmallCard, color: theme.palette.primary.main}} />
                                )}
                            </CountUp>
                            <Text semiBold size={valueTextSizeSmallCard} color={theme.palette.primary.main}>
                                {params.valueUnit}
                            </Text>
                               
                        </Grid>
                        <Grid item xs={12} >
                            <Text semiBold size={labelTextSizeSmallCard} color={theme.palette.primary.main}>{params.label}</Text>
                        </Grid>
                    </Grid>
                    <Spacing top={labelValueSpacing}></Spacing>
                    
                </Grid>
               
                
            }

            
            
            
        </Container>
    );
}

export default DashboardNumberCard;