import React, { FC, useEffect, useState } from "react";
import Input from "../../components/atoms/input";

import { Search as SearchIcon } from "@mui/icons-material";
import { useAnalysisAnnotations } from "../../hooks/analysisAnnotations";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "../../utils/consts";
import { normalizeString } from "../../utils/stringFormatters";

import {
    AnalysisAnnotationsFilter,
    Container,
    List,
    Page,
    Spacing,
    Text,
    theme,
} from "../../components";

import {
    IListHeader,
    IAnalysisAnnotationsFilterParams,
    IAnalysisAnnotations,
} from "../../types";

const AnalysisAnnotations: FC = () => {
    const {
        filter,
        loading,
        paginate,
        analysis,
        perRow,
        fetchAnalysisAnnotations,
        setPerRow,
        setPaginate,
    } = useAnalysisAnnotations();

    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        if (!loading) {
            fetchAnalysisAnnotations(filter);
        }
    }, []);

    const headers: IListHeader[] = [
        {
            label: "Data",
            fieldName: "classificationDate",
            xs: 3,
            isNumber: true,
            sortable: false,
        },
        {
            label: "Empresa",
            fieldName: "branchName",
            xs: 3,
            isString: true,
            sortable: false,
            notOpenHeader: true,
        },
        {
            label: "Quantidade de carcaças",
            fieldName: "countCarcass",
            xs: 2,
            isNumber: true,
            sortable: false,
            notOpenHeader: true,
        },
        {
            label: "Quantidade anotadas",
            fieldName: "countAnnotations",
            xs: 2,
            isNumber: true,
            sortable: false,
            notOpenHeader: true,
        },
        {
            label: "Quantidade revisadas",
            fieldName: "countRevisions",
            xs: 2,
            isNumber: true,
            sortable: false,
            notOpenHeader: true,
        },
    ];

    const convertDataToItem = (_data: any) => {
        let branchName: String | undefined = undefined;
        if (_data.branch) branchName = _data.branch.name;
        if (_data.branchName) branchName = _data.branchName;

        let branchFridge: String | undefined = undefined;
        if (_data.branch) branchFridge = _data.branch.fridge;
        if (_data.branchFridge) branchFridge = _data.branchFridge;

        return {
            classificationDate: _data.classificationDate,
            branchName: _data.branch.name,
            countCarcass: _data.countCarcass,
            countAnnotations: _data.countAnnotations,
            countRevisions: _data.countRevisions,
        };
    };

    const onApplyFilter = async (filter: IAnalysisAnnotationsFilterParams) => {
        fetchAnalysisAnnotations(filter);
    };

    return (
        <Page
            width="auto"
            height="auto"
            color={theme.palette.background.default}
            style={{ minHeight: "95vh" }}
        >
            <Container
                fluid
                flex
                flexGrow
                color={theme.palette.background.default}
                width="100%"
            >
                <Container fluid flex padded verticalCentered color={theme.palette.gray.main} maxHeight="30px">
                    <Text bold size={15}>
                        Análise de Anotações
                    </Text>
                </Container>
                <Spacing top={VERY_PADDING} />
                <Container fluid flex centered width="100%">
                    <Container fluid width="95%">
                        <Spacing top={PADDING} />

                        <Container inline flex width="100%">
                            <Spacing left={PADDING} />
                            <AnalysisAnnotationsFilter
                                onApply={onApplyFilter}
                            />
                        </Container>
                    </Container>
                    <Spacing top={PADDING} />
                    <Container fluid flexStart width="95%">
                        <Text>
                            Clique no ícone ao lado do lote desejado para abrir
                            mais informações.{" "}
                        </Text>
                    </Container>
                    <Spacing top={PADDING} />
                    <Container
                        fluid
                        hasShadow
                        width="95%"
                        minHeight="75vh"
                        color={theme.palette.background.paper}
                        borderRadius={BORDER_RADIUS}
                    >
                        <Container
                            fluid
                            centered
                            width="100%"
                            height="30px"
                            color={theme.palette.primary.main}
                            borderTopRadius={BORDER_RADIUS}
                        >
                            <Text
                                semiBold
                                size={15}
                                color={theme.palette.background.paper}
                            >
                                Anotações cadastradas
                            </Text>
                        </Container>
                        <List
                            headers={headers}
                            data={analysis}
                            loading={loading}
                            total={analysis.length}
                            perPage={20}
                            paginate={paginate}
                            onPreviousPage={() => setPaginate(paginate - 1)}
                            onNextPage={() => setPaginate(paginate + 1)}
                            dataToItem={(_data: any) => {
                                return convertDataToItem(_data);
                            }}
                            onRefresh={() => {
                                fetchAnalysisAnnotations(filter);
                            }}
                            hideSearch
                            search={search}
                            searchOnChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => setSearch(event.target.value)}
                            searchplaceholder=""
                        />
                    </Container>
                </Container>
            </Container>
        </Page>
    );
};

export default AnalysisAnnotations;
