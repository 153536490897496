import { AxiosResponse } from "axios";

import api from ".";
import { IMonitoringEmail } from "../types";

const baseUrl: string = "monitoring_emails";

export const createMonitoringEmail = async (
  _monitoringEmail: IMonitoringEmail
) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    userId: _monitoringEmail.userId,
    monitoringTypeId: _monitoringEmail.monitoringTypeId,
    email: _monitoringEmail.email,
    subject: _monitoringEmail.subject,
    body: _monitoringEmail.body,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIMonitoringEmail(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateMonitoringEmail = async (
  _id: string,
  _monitoringEmail: IMonitoringEmail
) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    userId: _monitoringEmail.userId,
    monitoringTypeId: _monitoringEmail.monitoringTypeId,
    email: _monitoringEmail.email,
    subject: _monitoringEmail.subject,
    body: _monitoringEmail.body,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIMonitoringEmail(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyMonitoringEmail = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getMonitoringEmails = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const monitoringEmails: IMonitoringEmail[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const monitoringEmail: IMonitoringEmail =
          convertApiResponseToIMonitoringEmail(data);

        monitoringEmails.push(monitoringEmail);
      }

      return monitoringEmails;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getMonitoringEmail = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const monitoringEmail: IMonitoringEmail =
        convertApiResponseToIMonitoringEmail(response.data);
      return monitoringEmail;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIMonitoringEmail = (_monitoringEmail: any) => {
  const monitoringEmail: IMonitoringEmail = {
    id: _monitoringEmail.id,
    userId: _monitoringEmail.userId,
    monitoringTypeId: _monitoringEmail.monitoringTypeId,
    branchId: _monitoringEmail.branch_id,
    systemHealthId: _monitoringEmail.systemHealthId,
    email: _monitoringEmail.email,
    subject: _monitoringEmail.subject,
    body: _monitoringEmail.body,
    isDeleted: _monitoringEmail.isDeleted,
    createdAt: _monitoringEmail.createdAt,
    updatedAt: _monitoringEmail.updatedAt,
  };

  return monitoringEmail;
};
