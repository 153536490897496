import React, { FC, useRef, useState } from "react";
import { Button, ButtonGroup, Checkbox, ClickAwayListener, Grid, Grow, Paper, Popper, MenuList, MenuItem, IconButton, FormControlLabel } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon, ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';

import CarcassPhoto from "../../carcasses/carcassPhoto";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useAnnotations } from "../../../../hooks/annotations";
import { ICarcass, IVerdictCard } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import { useCarcassesLocalClassifications } from "../../../../hooks/carcassesLocalClassifications";
import { useCarcass } from "../../../../hooks/carcasses";

const VerdictCard: FC<IVerdictCard> = (params: IVerdictCard) => {
    const { handleMagnifierSize } = useAnnotations();
    const { carcassesLocalClassifications } = useCarcassesLocalClassifications();

    const [openButton, setOpenButton] = useState(false);
    const anchorButton = useRef<HTMLDivElement>(null);
    const [selectedClassification, setSelectedClassification] = useState(params.carcass.classificationId);
    const [bestPlaced, setBestPlaced] = useState(false);
    const [openPhoto, setOpenPhoto] = useState<boolean>(false);

    const { classifications } = useCarcass();
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedClassification(index);
        setOpenButton(false);

        let auxCarcass: ICarcass = params.carcass;
        params.onSelectCarcass({
            ...auxCarcass,
            classificationId: selectedClassification,
            bestPlacedVerdict: bestPlaced
        });
    };

    const bestPlaceHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBestPlaced(event.target.checked);
        let auxCarcass: ICarcass = {
            ...params.carcass,
            classificationId: selectedClassification,
            bestPlacedVerdict: bestPlaced
        }
        params.onSelectCarcass(auxCarcass);
    };

    const handleToggle = () => {
        setOpenButton((prevOpenButton) => !prevOpenButton);
    };

    const handleCorrect = () => {
        if (!params.isSelected)
            params.onSelectCarcass(params.carcass);
        else {
            params.onSelectCarcass({
                ...params.carcass,
                classificationId: -1
            });
        }
    };

    const handleClose = (event: Event) => {
        if (
            anchorButton.current &&
            anchorButton.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpenButton(false);
    };


    if (!params.carcass) {
        return (
            <Container>
                <Text>Carcaça não foi encontrada.</Text>
            </Container>
        );
    }

    return (
        <Container fluid centered hasShadow padded width="300px" height="auto">
            <Container inline centered width="108%" height="auto" color={theme.palette.primary.dark}>
                <Grid container>
                    <Grid item xs={2}>
                        <Checkbox checked={params.isSelected} style={{ color: theme.palette.background.paper }} onClick={handleCorrect} />
                    </Grid>
                    <Grid item xs={7}>
                        <Container fluid width="100%">
                            <Text size={14} color={theme.palette.background.paper}>IA: {params.carcass.classification}</Text>
                            <Text size={14} color={theme.palette.background.paper}>
                                <Text size={14} color={theme.palette.background.paper} style={{ fontStyle: 'italic' }}>In loco</Text>: {params.carcass.localClassification}
                            </Text>
                        </Container>
                    </Grid>
                    <Grid item xs={3}>
                        <Container inline flexEnd>
                            <IconButton size="small" onClick={() => handleMagnifierSize(true)} style={{ fontSize: 12, color: theme.palette.background.paper }} >
                                <ZoomInIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleMagnifierSize(false)} style={{ fontSize: 12, color: theme.palette.background.paper }} >
                                <ZoomOutIcon />
                            </IconButton>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
            <Container fluid>
                <CarcassPhoto hideZoomButtons photoUri={params.carcass.photoUri || ""} />
            </Container>
            
            <Container inline centered width="108%" height="auto" color={theme.palette.primary.dark}>
                
            <Container fluid flexStart sx={{ paddingBottom: '10px' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={bestPlaceHandleChange}
                            checked={bestPlaced}
                            style={{ color: theme.palette.background.paper }}
                        />
                    }
                    style={{ color: theme.palette.background.paper }}
                    label="Mal Posicionada"
                />
                <Container inline>
                    <ButtonGroup variant="contained" ref={anchorButton} aria-label="split button">
                        <Button onClick={handleToggle} color={params.isSelected ? 'success' : 'primary'}>
                            <Text color={theme.palette.background.paper}
                                size={12}>
                                {selectedClassification >= 0 ? classifications.find(item => item.id == selectedClassification)?.label : 'Selecione uma classificação'}
                            </Text>
                        </Button>
                        <Button
                            size="small"
                            aria-controls={openButton ? 'split-button-menu' : undefined}
                            aria-expanded={openButton ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Spacing left={PADDING} />
                    <Button variant="contained" onClick={() => setOpenPhoto(true)} sx={{ backgroundColor: theme.palette.info.dark, width: '100px' }}>
                        <Text color={theme.palette.background.paper} size={13}>Detalhar</Text>
                    </Button>
                </Container>
            </Container>
            </Container>
             <Popper
                open={openButton}
                anchorEl={anchorButton.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 100 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {classifications.map((item, index) => (
                                        <MenuItem
                                            key={item.id}
                                            selected={item.id == selectedClassification}
                                            onClick={(event) => handleMenuItemClick(event, item.id)}>
                                            <Text size={13} color={theme.palette.primary.main}>
                                                {item.label}
                                            </Text>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {openPhoto && params.carcass.photoUri &&
                <Lightbox
                    mainSrc={params.carcass.photoUri}
                    onCloseRequest={() => setOpenPhoto(false)}
                    zoomInLabel="Mais zoom"
                    zoomOutLabel="Menos zoom" />
            }
        
        </Container>
    );
}

export default VerdictCard;