import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

import { Container, Input, Page, Spacing, Text, theme } from '../../../components';
import { useBranches } from '../../../hooks/branches';
import { IBranch, ICompany } from '../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';
import { useInformation } from '../../../hooks/information';
import CompanySelect from '../../../components/molecules/companies/select';
import { TextField } from '@mui/material';
import { useCompanies } from '../../../hooks/companies';

const BranchRegister: FC<any> = () => {
    const { createNewBranch, editBranch, fetchBranch, fetchBranches } = useBranches();
    const { companies, fetchCompanies } = useCompanies();
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { companyId, id } = useParams();

    const [companyName, setCompanyName] = useState<string>('');
    const [companyError, setCompanyError] = useState<string | undefined>(undefined);
    const [branch, setBranch] = useState<IBranch | undefined>(undefined);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [cnpj, setCnpj] = useState<string>('');
    const [cnpjError, setCnpjError] = useState<string | null>(null);
    const [token, setToken] = useState<string>('');
    const [tokenError, setTokenError] = useState<string | null>(null);
    const [prefix, setPrefix] = useState<string>('');
    const [prefixError, setPrefixError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        fetchCompanies();
        loadBranch();
    }, []);

    useEffect(() => {
        const company: ICompany | undefined = companies.find(item => item.id == companyId);
        if (company && company.id) {
            setCompanyName(company.name);
        }
    }, [companies]);

    useEffect(() => {
        if (branch) {
            setName(branch.name);
            setEmail(branch.email || '');
            setCnpj(branch.cnpj || '');
            setToken(branch.token);
            setPrefix(branch.prefix);
        }
    }, [branch]);

    const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setCompanyName('');
        } else {
            if ((event as any).label) {
                setCompanyName((event as any).label);
            }
        }
        setError(null);
    }

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }
    const handleChangeCnpj = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCnpj(event.target.value);
        setCnpjError(null);
        setError(null);
    }
    const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
        setTokenError(null);
        setError(null);
    }
    const handleChangePrefix = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrefix(event.target.value);
        setPrefixError(null);
        setError(null);
    }

    const loadBranch = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxBranch: IBranch = await fetchBranch(id);
                setBranch(auxBranch);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    function validate() {
        let isValid = true;
        if (companyId == '') {
            setCompanyError('Informe a empresa');
            isValid = false;
        }
        if (name == '') {
            setNameError('Informe o nome completo do frigorífico');
            isValid = false;
        }
        if (prefix == '') {
            setPrefixError('Informe o prefixo do frigorífico');
            isValid = false;
        }


        if (isValid) {
            setNameError(null);
            setEmailError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let payload: IBranch = {
                name,
                companyId: companyId,
                email,
                cnpj,
                token,
                prefix
            };
            if (branch && branch.id) {
                await editBranch(branch.id, payload);
            } else {
                await createNewBranch(payload, true);
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!branch) informationMessage = 'O novo frigorífico foi cadastrado com sucesso.';

            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    fetchBranches();
                    setLoading(false);
                    hideInformation();
                    cleanData();
                    navigate(`/companies/${companyId}/branches`);
                });
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
        setCnpj('');
        setCnpjError(null);
        setToken('');
        setTokenError(null);
        setPrefix('');
        setPrefixError(null);
    }

    return (
        <Page width='auto' height='87%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{(branch && branch.id) ? "Editar" : "Criar"} Frigorífico</Text>
                </Container>
                <Spacing top={VERY_PADDING} />

                <Container fluid veryPadded centered>
                    <Container fluid width="50%" height="100%">
                        <Container fluid veryPadded>
                            <Text size={35}>Informações do frigorífico</Text>
                            <Spacing top={VERY_PADDING} />
                            {companyId &&
                                <>
                                    <Container fluid width={'100%'}>
                                        <Text semiBold size={15}>Empresa:</Text>
                                        <CompanySelect
                                            value={companyId}
                                            valueName={companyName}
                                            error={companyError}
                                            handleChangeValue={handleChangeCompany}
                                            variant="outlined"
                                            disabled />
                                    </Container>
                                    <Spacing top={VERY_PADDING} />
                                </>
                            }
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Nome:</Text>
                                <TextField id="name" autoFocus required
                                    value={name} onChange={handleChangeName}
                                    placeholder='Informe o nome do frigorífico'
                                    error={nameError != null} disabled={loading} />
                                {nameError && <Text size={13} color={theme.palette.error.main}>{nameError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>CNPJ (opcional):</Text>
                                <TextField id="cnpj"
                                    value={cnpj} onChange={handleChangeCnpj}
                                    placeholder='Informe o CNPJ do frigorífico'
                                    error={cnpjError != null} disabled={loading} />
                                {cnpjError && <Text size={13} color={theme.palette.error.main} >{cnpjError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>E-mail (opcional):</Text>
                                <TextField id="email"
                                    value={email} onChange={handleChangeEmail}
                                    placeholder='Informe o e-mail do frigorífico'
                                    error={emailError != null} disabled={loading} />
                                {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Token (opcional):</Text>
                                <TextField id="token"
                                    value={token} onChange={handleChangeToken}
                                    placeholder='Informe o Token de sincronização com o frigorífico'
                                    error={tokenError != null} disabled={loading} />
                                {tokenError && <Text size={13} color={theme.palette.error.main} >{tokenError}</Text>}
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Prefixo:</Text>
                                <TextField id="prefix"
                                    value={prefix} onChange={handleChangePrefix}
                                    required placeholder='Informe o prefixo do frigorífico'
                                    error={prefixError != null} disabled={loading} />
                                {prefixError && <Text size={13} color={theme.palette.error.main} >{prefixError}</Text>}
                            </Container>

                            <Spacing top={VERY_PADDING * 2} />
                            {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                            <Container inline spacedAround veryPadded>
                                <LoadingButton
                                    variant="outlined"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : 'transparent', width: 150, height: 35, borderColor: theme.palette.primary.dark }}
                                    onClick={() => navigate(`/companies/${companyId}/branches`)}>
                                    <Text size={16} color={theme.palette.primary.dark}>Cancelar</Text>
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : theme.palette.primary.dark, width: 150, height: 35 }}
                                    onClick={() => confirmRegistration()}>
                                    <Text size={16} color={theme.palette.background.paper}>Confirmar</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page >
    );
}

export default BranchRegister;