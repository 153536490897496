import { AxiosResponse } from "axios";

import api from ".";
import { IAnnotation, IAnnotator, ICarcass } from "../types";
import { IAnnotationCount } from "../types/hooks/annotations";
import { convertApiResponseToIAnnotator } from "./annotators";
import { convertApiResponseToICarcass } from "./carcasses";

const baseUrl: string = "annotations";

export const createAnnotation = async (_annotation: IAnnotation) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, {
    annotatorId: _annotation.annotatorId,
    carcassId: _annotation.carcassId,
    classificationId: _annotation.classificationId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotation(response.data);
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const updateAnnotation = async (
  _id: string,
  _annotation: IAnnotation
) => {
  const response: AxiosResponse = await api.put(`/api_redsoft/v1_0/${baseUrl}/${_id}`, {
    annotatorId: _annotation.annotatorId,
    carcassId: _annotation.carcassId,
    classificationId: _annotation.classificationId,
  });

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return convertApiResponseToIAnnotation(response.data);
    }
  }

  throw new Error("Não foi possível editar um dado");
};

export const destroyAnnotation = async (_id: string) => {
  const response: AxiosResponse = await api.delete(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      return response.data;
    } else if (
      status == 400 &&
      (response as any).response &&
      (response as any).response.data
    ) {
      throw new Error((response as any).response.data as string);
    }
  }

  throw new Error("Não foi possível criar um dado");
};

export const getAnnotations = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotations: IAnnotation[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotation: IAnnotation = convertApiResponseToIAnnotation(data);

        annotations.push(annotation);
      }

      return annotations;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotation = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotation: IAnnotation = convertApiResponseToIAnnotation(
        response.data
      );
      return annotation;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotationCount = async (_query: string) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/count_annotate${_query}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotation: IAnnotationCount = convertApiResponseToIAnnotationCount(
        response.data
      );
      return annotation;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const getAnnotationForRevisions = async (_query: string) => {
  const response: AxiosResponse = await api.get(
    `/api_redsoft/v1_0/${baseUrl}/for_revision${_query}`
  );

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const annotations: IAnnotation[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const annotation: IAnnotation = convertApiResponseToIAnnotation(data);

        annotations.push(annotation);
      }

      return annotations;
    }
  }

  throw new Error("Não foi possível buscar os dados");
};

export const convertApiResponseToIAnnotation = (_annotation: any) => {
  let annotator: IAnnotator | undefined = undefined;
  if (_annotation.annotator)
    annotator = convertApiResponseToIAnnotator(_annotation.annotator);
  let carcass: ICarcass | undefined = undefined;
  if (_annotation.carcass)
    carcass = convertApiResponseToICarcass(_annotation.carcass);

  const annotation: IAnnotation = {
    id: _annotation.id,
    annotatorId: _annotation.annotatorId,
    annotator,
    carcassId: _annotation.carcassId,
    carcass,
    classificationId: _annotation.classificationId,
    isDeleted: _annotation.isDeleted,
    createdAt: _annotation.createdAt,
    updatedAt: _annotation.updatedAt,
  };

  return annotation;
};

export const convertApiResponseToIAnnotationCount = (_annotationCount: any) => {
  const annotationCount: IAnnotationCount = {
    countAnnotate: _annotationCount.countAnnotate,
    countUnannotate: _annotationCount.countUnannotate,
  };
  return annotationCount;
};
