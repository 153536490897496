import { IEntity } from "./entities";
import { IPrivilege } from "./privileges";
import { IUser } from "./users";

interface IAuthUserLogged {
  id: string;
  emailVerified: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  preferredUserName: string;
  email: string;
}

interface IAuthLogin {
  accessToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
  refreshToken: string;
  tokenType: string;
  notBeforePolicy: number;
  sessionState: string;
  scope: string;
}

interface IAuthResetPassword {
  key: string;
  action: string;
}

interface IAuthPasswordRecovery extends IEntity {
  userId: string;
  token: string;
  used: boolean;
  frontEndUrl: string;
}

interface IAuthContext {
  user: IAuthUserLogged | undefined;
  loadingStorage: boolean;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  expiresIn: number | undefined;

  roles: string[];
  privilege: string | undefined;
  routes: string[];

  loadStorage: () => void;
  hasRights?: (path: string) => boolean;

  postLogin: (_authLogin: IAuthLogin) => void;
  logout: () => void;

  isSuperAdmin: () => boolean;

  forgotPassword: (_email: string) => Promise<boolean>;
  getPasswordRecovery: (_token: string) => Promise<IAuthPasswordRecovery>;
  putPasswordRecovery: (_token: string, _password: string) => Promise<IAuthLogin>;
  updateRecoveryPasswordKey: (_key: string, _newPassword: string) => void;
}

export function instanceOfIAuthLogin(object: any): object is IAuthLogin {
  return (
    "accessToken" in object &&
    "expiresIn" in object &&
    "refreshExpiresIn" in object &&
    "refreshToken" in object &&
    "tokenType" in object &&
    "notBeforePolicy" in object &&
    "sessionState" in object &&
    "scope" in object
  );
}

export function instanceOfIAuthResetPassword(object: any): object is IAuthLogin {
  return "key" in object && "action" in object;
}

export type { IAuthContext, IAuthUserLogged, IAuthLogin, IAuthResetPassword, IAuthPasswordRecovery };
