import moment from "moment";
import React, { FC, createContext, useContext, useState } from "react";

import {
  createRevision,
  updateRevision,
  getRevisions,
  destroyRevision,
  getRevision,
  getRevisionDownload,
  getRevisionDownloadFilePath,
  getRevisionForVerdicts,
  getRevisionCount,
} from "../services/revisions";
import {
  ICarcassClassification,
  IRevision,
  IRevisionContext,
  IRevisionCount,
  IRevisionFilter,
} from "../types";

const RevisionsContext = createContext<IRevisionContext>(
  {} as IRevisionContext,
);

export const RevisionsProvider: FC<any> = ({ children }) => {
  const [revisions, setRevisions] = useState<IRevision[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchRevision, setSearchRevision] = useState<string>("");
  const [revisionsPerRow, setRevisionsPerRow] = useState<string>("25");
  const [paginate, setPaginate] = useState<number>(0);

  const [revisionsForVerdicts, setRevisionsForVerdicts] = useState<IRevision[]>([]);
  const [loadingRevisionForVerdicts, setLoadingRevisionForVerdicts] = useState<boolean>(false);

  const [filter, setFilter] = useState<IRevisionFilter>({
    initialDate: new Date(),
    finalDate: new Date(),
    annotators: [],
    branches: [],
    classificationsId: [],
    iaClassificationsId: [],
    limit: 10,
  });

  const [revisionCount, setRevisionCount] = useState<IRevisionCount>({
    countRevised: 0,
    countUnrevised: 0
  });
  const [loadingRevisionCount, setLoadingRevisionCount] = useState<boolean>(false);

  const fetchRevisions = async () => {
    setLoading(true);
    try {
      const revisions: IRevision[] = await getRevisions();
      setRevisions([...revisions]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchRevision = async (_id: string) => {
    const revision: IRevision = await getRevision(_id);
    return revision;
  };

  const createNewRevision = async (
    _revision: IRevision,
    _reload: boolean = true,
  ) => {
    const revision = await createRevision(_revision);
    if (_reload) fetchRevisions();
    return revision;
  };

  const editRevision = async (_id: string, _payload: IRevision) => {
    try {
      const revision = await updateRevision(_id, _payload);

      fetchRevisions();
      return revision;
    } catch (_err) {
      console.log(_err);
      throw _err;
    }
  };

  const deleteRevision = async (_id: string) => {
    const revision = await destroyRevision(_id);
    fetchRevisions();
    return revision;
  };

  const downloadRevision = async (
    _initialDate: Date,
    _finalDate: Date,
    _companyId: string,
    _classificationsId: ICarcassClassification[],
  ) => {
    try {
      let query: string = `?initialDate=${moment(_initialDate).format(
        "YYYY-MM-DD",
      )}`;
      query += `&finalDate=${moment(_finalDate).format("YYYY-MM-DD")}`;
      query += `&companyId=${_companyId}`;
      if (_classificationsId.length > 0)
        query += `&classifications=${_classificationsId
          .map((item) => item.id)
          .join(",")}`;

      const responseDownload: any = await getRevisionDownload(query);
      return responseDownload;
    } catch (err) {
      throw err;
    }
  };
  const downloadRevisionFilePath = async (_filePath: string) => {
    try {
        const responseDownload: any = await getRevisionDownloadFilePath(_filePath);
        return responseDownload;
    } catch (err) {
        throw err;
    }
};
const fetchRevisionCount = async (_filter: IRevisionFilter) => {
  setLoadingRevisionCount(true);
  try {
      let today = new Date();
      let initial_date = _filter.initialDate ? moment(_filter.initialDate) : moment(today); 
      let final_date = _filter.finalDate ? moment(_filter.finalDate) : moment(today);
      let query: string = `?initial_date=${initial_date.format('YYYY-MM-DD')}`;
      query += `&final_date=${final_date.format('YYYY-MM-DD')}`;
      if (_filter.branches.length > 0)
          query += `&branches_id=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
      if (_filter.classificationsId.length > 0)
          query += `&classifications_id=${_filter.classificationsId.map(item => `'${item.id}'`).join(',')}`;
      if (_filter.iaClassificationsId.length > 0)
          query += `&ia_classifications_id=${_filter.iaClassificationsId.map(item => `'${item.id}'`).join(',')}`;
      if (_filter.annotators.length > 0)
          query += `&annotator_id=${_filter.annotators.map(item => `'${item.id}'`).join(',')}`;

      const auxAnnotationCount: IRevisionCount = await getRevisionCount(query);
      setRevisionCount(auxAnnotationCount);

      setTimeout(() => {
          setLoadingRevisionCount(false);
      }, 1000);
  } catch (_err) {
      console.log(_err);
      setLoadingRevisionCount(false);
  }
  setLoadingRevisionCount(true)
};

  const fetchRevisionsForVerdicts = async (_filter: IRevisionFilter) => {
    setLoadingRevisionForVerdicts(true);
    try {
      let today = new Date();
      let initial_date = _filter.initialDate ? moment(_filter.initialDate) : moment(today); 
      let final_date = _filter.finalDate ? moment(_filter.finalDate) : moment(today);

      let query: string = `?initial_date=${initial_date.format('YYYY-MM-DD')}`;
      query += `&final_date=${final_date.format('YYYY-MM-DD')}`;
      if (_filter.annotators.length > 0)
        query += `&annotators_id=${_filter.annotators.map(item => `'${item.id}'`).join(',')}`;
      if (_filter.branches.length > 0)
        query += `&branches_id=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
      if (_filter.classificationsId.length > 0)
        query += `&classifications=${_filter.classificationsId.map(item => item.id).join(',')}`;
      if (_filter.iaClassificationsId.length > 0)
        query += `&ia_classifications=${_filter.classificationsId.map(item => item.id).join(',')}`;
      query += `&limit=${_filter.limit}`;

      setRevisionsForVerdicts([]);
      const revisionsForVerdicts: IRevision[] = await getRevisionForVerdicts(query);
      setRevisionsForVerdicts([...revisionsForVerdicts]);

      setTimeout(() => {
        setLoadingRevisionForVerdicts(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoadingRevisionForVerdicts(false);
    }
  }

  return (
    <RevisionsContext.Provider
      value={{
        revisions,
        loading,
        fetchRevisions,
        fetchRevision,
        createNewRevision,
        editRevision,
        deleteRevision,
        searchRevision,
        setSearchRevision,
        revisionCount,
        loadingRevisionCount,
        fetchRevisionCount,
        revisionsPerRow,
        setRevisionsPerRow,
        paginate,
        setPaginate,
        filter,
        setFilter,
        downloadRevision,
        downloadRevisionFilePath,
        revisionsForVerdicts, loadingRevisionForVerdicts, fetchRevisionsForVerdicts
      }}
    >
      {children}
    </RevisionsContext.Provider>
  );
};

export function useRevisions() {
  const context = useContext(RevisionsContext);

  if (!context) {
    throw new Error("useRevisions must be used within an RevisionsProvider");
  }

  return context;
}
