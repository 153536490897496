import React, { FC, createContext, useContext, useEffect, useState } from 'react'

import { createAnnotator, updateAnnotator, getAnnotators, destroyAnnotator, getAnnotator, getAnnotatorAnnotates, getAnnotatorByUserId } from '../services/annotators';
import { IAnnotator, IAnnotatorContext } from '../types'

const AnnotatorsContext = createContext<IAnnotatorContext>({} as IAnnotatorContext);

export const AnnotatorsProvider: FC<any> = ({ children }) => {
    const [annotators, setAnnotators] = useState<IAnnotator[]>([]);
    const [filteredAnnotators, setFilteredAnnotators] = useState<IAnnotator[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchAnnotator, setSearchAnnotator] = useState<string>('');
    const [annotatorsPerRow, setAnnotatorsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    useEffect(() => {
        filterAnnotators(searchAnnotator);
    }, [searchAnnotator, annotators]);

    const filterAnnotators = (_searchAnnotator: string) => {
        let auxAnnotators = [...annotators];
        if (_searchAnnotator !== "") auxAnnotators = auxAnnotators.filter(item => (item.user?.firstName && item.user?.firstName.toLowerCase().indexOf(_searchAnnotator.toLowerCase()) >= 0) ||
            (item.user?.lastName && item.user?.lastName.toLowerCase().indexOf(_searchAnnotator.toLowerCase()) >= 0));
        setFilteredAnnotators(auxAnnotators);
    }

    const fetchAnnotators = async () => {
        setLoading(true);
        try {
            const annotators: IAnnotator[] = await getAnnotators();
            setAnnotators([...annotators]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchAnnotator = async (_id: string) => {
        const annotator: IAnnotator = await getAnnotator(_id);
        return annotator;
    }

    const fetchAnnotatorByUserId = async (_userId: string) => {
        try {
            const annotator: IAnnotator = await getAnnotatorByUserId(_userId);
            return annotator;
        } catch (err) {
            console.log(err);
        }
    }

    const createNewAnnotator = async (_annotator: IAnnotator, _reload: boolean = true) => {
        const annotator = await createAnnotator(_annotator);
        if (_reload)
            fetchAnnotators();
        return annotator;
    }

    const editAnnotator = async (_id: string, _payload: IAnnotator) => {
        try {
            const annotator = await updateAnnotator(_id, _payload);

            fetchAnnotators();
            return annotator;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteAnnotator = async (_id: string) => {
        const annotator = await destroyAnnotator(_id);
        fetchAnnotators();
        return annotator;
    }

    const fetchAnnotatorAnnotates = async (_id: string, _initialDate: string, _finalDate: string, _companyId: string, _onlyBestPlaced: boolean, _onlyWithPartnerId: boolean) => {
        const count: number = await getAnnotatorAnnotates(_id, _initialDate, _finalDate, _companyId, _onlyBestPlaced, _onlyWithPartnerId);
        return count;
    }

    return (
        <AnnotatorsContext.Provider value={{
            annotators, loading, fetchAnnotators, fetchAnnotator, fetchAnnotatorByUserId,
            filteredAnnotators,
            createNewAnnotator, editAnnotator, deleteAnnotator,
            searchAnnotator, setSearchAnnotator, annotatorsPerRow, setAnnotatorsPerRow, paginate, setPaginate,

            fetchAnnotatorAnnotates
        }}>{children}</AnnotatorsContext.Provider>
    )
}

export function useAnnotators() {
    const context = useContext(AnnotatorsContext);

    if (!context) {
        throw new Error('useAnnotators must be used within an AnnotatorsProvider')
    }

    return context;
}
