import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Container, Page, Spacing, Text, theme } from '../../../components';
import { useAnnotators } from '../../../hooks/annotators';
import { useAuth } from '../../../hooks/auth';
import { useCompanies } from '../../../hooks/companies';
import { useUsers } from '../../../hooks/users';
import { IAnnotator } from '../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../utils/consts';
import { useInformation } from '../../../hooks/information';
import UserSelect from '../../../components/molecules/users/select';

const AnnotatorRegister: FC<any> = () => {
    const { annotators, createNewAnnotator, editAnnotator, fetchAnnotator, fetchAnnotators } = useAnnotators();
    const auth = useAuth();
    const { fetchCompanies } = useCompanies();
    const { showInformation, hideInformation } = useInformation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { users, fetchUsers } = useUsers();

    const [annotator, setAnnotator] = useState<IAnnotator | undefined>(undefined);
    const [user, setUser] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userError, setUserError] = useState<string | undefined>(undefined);
    const [fullAccess, setFullAccess] = useState<boolean | undefined>(undefined);
    const [fullAccessError, setFullAccessError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        fetchAnnotators();
        fetchCompanies();
        fetchUsers();

        loadAnnotator();
    }, []);

    useEffect(() => {
        if (annotator) {
            if (annotator.user && annotator.user.id) {
                setUser(annotator.user.id);
                setUserName(annotator.user.firstName);
            }
            setFullAccess(annotator.fullAccess);
        }
    }, [annotator]);

    const loadAnnotator = async () => {
        try {
            setLoading(true);
            if (id) {
                const auxAnnotator: IAnnotator = await fetchAnnotator(id);
                setAnnotator(auxAnnotator);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setUser((event as any).target.value);
            setUserName('');
        } else {
            if ((event as any).id) {
                setUser((event as any).id);
            }
            if ((event as any).label) {
                setUserName((event as any).label);
            }
        }
        setUserError(undefined);
        setError(null);
    }
    const handleChangeFullAccess = (
        event: React.MouseEvent<HTMLElement>,
        newFullAccess: boolean,
    ) => {
        setFullAccess(newFullAccess);
    };

    function validate() {
        let isValid = true;
        if (user == '') {
            setUserError('Informe o usuário');
            isValid = false;
        }
        if (fullAccess == undefined) {
            setFullAccessError('Informe o nível de acesso do usuário');
            isValid = false;
        }


        if (isValid) {
            setUserError(undefined);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let annotatorId: string = '';
            let payload: IAnnotator = {
                userId: user,
                fullAccess: fullAccess || false
            };
            if (annotator && annotator && annotator.id) {
                const editedAnnotator: IAnnotator = await editAnnotator(annotator.id, payload);
                if (editedAnnotator.id) annotatorId = editedAnnotator.id;
            } else {
                const newAnnotator: IAnnotator = await createNewAnnotator(payload);
                if (newAnnotator.id) annotatorId = newAnnotator.id;
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!annotator) informationMessage = 'O novo anotador foi cadastrado com sucesso.';

            showInformation(informationMessage, informationTitle, undefined,
                () => {
                    fetchAnnotators();
                    setLoading(false);
                    hideInformation();
                    cleanData();
                    navigate('/annotators');
                });
            // auth.fetchUserData(auth.user, true);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setUser('');
        setUserName('');
        setUserError(undefined);
        setFullAccess(undefined);
        setFullAccessError(null);
    }

    return (
        <Page width='auto' height='95%'>
            <Container fluid flex flexGrow color={theme.palette.background.paper} height="auto" width="100%" veryPadded>
                <Container inline horizontalCentered>
                    <Text semiBold color={theme.palette.primary.main} size={23}>{(annotator && annotator.id) ? "Editar" : "Criar"} Anotador</Text>
                </Container>
                <Spacing top={VERY_PADDING} />

                <Container fluid veryPadded centered>
                    <Container fluid width="50%" height="100%">
                        <Container fluid veryPadded>
                            <Text size={35}>Informações do Anotador</Text>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text semiBold size={15}>Usuário:</Text>
                                <UserSelect
                                    value={user}
                                    valueName={userName}
                                    options={users
                                        .filter(item =>  annotators.map(item => item.user && item.user.id).indexOf(item.id) < 0)
                                        .map(item => {
                                            return {
                                                id: item.id,
                                                name: `${item.firstName} ${item.lastName}`
                                            }
                                        })}
                                    error={userError}
                                    handleChangeValue={handleChangeUser}
                                    variant="standard" />
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <Container fluid width={'100%'}>
                                <Text size={12} style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: '400', lineHeight: '1.4375em' }}>Tipo de acesso *</Text>
                                <ToggleButtonGroup color="primary" value={fullAccess} exclusive onChange={handleChangeFullAccess} disabled={loading}>
                                    <ToggleButton value={true}><Text size={13}>Completo</Text></ToggleButton>
                                    <ToggleButton value={false}><Text size={13}>Por liberação</Text></ToggleButton>
                                </ToggleButtonGroup>
                                {fullAccessError && <Text size={13} color={theme.palette.error.main} >{fullAccessError}</Text>}
                            </Container>

                            <Spacing top={VERY_PADDING * 2} />
                            {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                            <Container inline spacedAround veryPadded>
                                <LoadingButton
                                    variant="outlined"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : 'transparent', width: 150, height: 35, borderColor: theme.palette.primary.dark }}
                                    onClick={() => navigate('/annotators')}>
                                    <Text size={16} color={theme.palette.primary.dark}>Cancelar</Text>
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ backgroundColor: (loading) ? theme.palette.grayLight.light : theme.palette.primary.dark, width: 150, height: 35 }}
                                    onClick={() => confirmRegistration()}>
                                    <Text size={16} color={theme.palette.background.paper}>Confirmar</Text>
                                </LoadingButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Page >
    );
}

export default AnnotatorRegister;