import { AxiosResponse } from "axios";

import api from ".";
import { ICarcassLocalClassification } from "../types";

const baseUrl: string = "carcasses_local_classifications";

export const createCarcassLocalClassification = async (
  _payload: ICarcassLocalClassification
) => {
  const response: AxiosResponse = await api.post(`/api_redsoft/v1_0/${baseUrl}`, _payload);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const carcassLocalClassification: ICarcassLocalClassification =
        convertApiResponseToICarcassLocalClassification(response.data);
      return carcassLocalClassification;
    }
  }

  throw new Error(
    "Não foi possível criar uma nova classificação de carcaça local"
  );
};

export const updateCarcassLocalClassification = async (
  _carcassLocalClassificationId: string,
  _payload: ICarcassLocalClassification
) => {
  const response: AxiosResponse = await api.put(
    `/api_redsoft/v1_0/${baseUrl}/${_carcassLocalClassificationId}`,
    _payload
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const carcassLocalClassification: ICarcassLocalClassification =
        convertApiResponseToICarcassLocalClassification(response.data);
      return carcassLocalClassification;
    }
  }

  throw new Error("Não foi possível editar a classificação de carcaça local");
};

export const deleteCarcassLocalClassification = async (
  _carcassLocalClassificationId: string
) => {
  const response: AxiosResponse = await api.delete(
    `/api_redsoft/v1_0/${baseUrl}/${_carcassLocalClassificationId}`
  );
  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const carcassLocalClassification: ICarcassLocalClassification =
        convertApiResponseToICarcassLocalClassification(response.data);
      return carcassLocalClassification;
    }
  }

  throw new Error("Não foi possível excluir a classificação de carcaça local");
};

export const getCarcassesLocalClassifications = async () => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const carcassesLocalClassifications: ICarcassLocalClassification[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        carcassesLocalClassifications.push(
          convertApiResponseToICarcassLocalClassification(data)
        );
      }

      return carcassesLocalClassifications;
    }
  }

  throw new Error("Não foi possível buscar os privilégios");
};

export const getCarcassLocalClassification = async (_id: string) => {
  const response: AxiosResponse = await api.get(`/api_redsoft/v1_0/${baseUrl}/${_id}`);

  if (response && response.request) {
    const status = response.request.status;
    if (status == 200 && response.data) {
      const carcassLocalClassification: ICarcassLocalClassification =
        convertApiResponseToICarcassLocalClassification(response.data);
      return carcassLocalClassification;
    }
  }

  throw new Error("Não foi possível buscar o privilégio");
};

export const convertApiResponseToICarcassLocalClassification = (_data: any) => {
  const carcassLocalClassification: ICarcassLocalClassification = {
    id: _data.id,
    branchId: _data.branchId,
    localId: _data.localId,
    name: _data.name,
    createdAt: _data.createdAt,
    updatedAt: _data.updatedAt,
  };
  return carcassLocalClassification;
};
