import styled from "styled-components";

import { ISpacing } from "../../../types";

const SpacingStyled = styled.div`
  margin-top: ${(props: ISpacing) => `${props.top}px`};
  margin-bottom: ${(props: ISpacing) => `${props.bottom}px`};
  margin-right: ${(props: ISpacing) => `${props.right}px`};
  margin-left: ${(props: ISpacing) => `${props.left}px`};

  width: ${(props: ISpacing) =>
    typeof props.width === "number" ? `${props.width}px` : props.width};
`;

export default SpacingStyled;
