import React, { FC, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FilterAltOutlined from "@mui/icons-material/FilterAltOutlined";
import Autocomplete from '@mui/material/Autocomplete';

import theme from "../../../theme";
import { BodyText, Title2 } from "../../../atoms/text";
import Modal from "../../../atoms/modal";
import Container from "../../../atoms/container";
import useCarcassFilter from "./carcassFilterHook";
import { ICarcassListFilter } from "../../../../types";


const CarcassesListFilter: FC<ICarcassListFilter> = (params: ICarcassListFilter) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const {
        farms,
        setFarms,
        classification,
        setClassification,
        docNums,
        setDocumentNumbers,
        lotNums,
        setLotNumbers,
        sequences,
        setSequences,
        genders,
        setGenders,
        bestPlaced,
        setBestPlaced,
        handleSubmit,
        clearFilters,
        revertFilters,
    } = useCarcassFilter({
        farmList: params.farmList,
        classificationList: params.classificationList,
        docList: params.docList,
        lotList: params.lotList,
        sequenceList: params.sequenceList,
        genderList: params.genderList,
        setFarms: params.setFarms,
        setClassification: params.setClassification,
        setDocumentNumbers: params.setDocumentNumbers,
        setLotNumbers: params.setLotNumbers,
        setSequences: params.setSequences,
        setGenders: params.setGenders,
        setBestPlaced: params.setBestPlaced,
    });

    function onCheckBestPlaced(value: boolean) {
        // Removes "value" if it is in bestPlaced, otherwise includes it
        let includes = false;
        let res = [];
        for (let val of bestPlaced) {
            if (val === value)
                includes = true
            else
                res.push(val);
        }

        if (!includes)
            res.push(value);
        setBestPlaced(res);
    }

    const onSubmit = () => {
        handleSubmit();
        setModalOpen(false);
    }

    const onClear = () => {
        clearFilters();
    }

    const onCancel = () => {
        revertFilters();
        setModalOpen(false);
    }

    return (<>
    <IconButton color='primary' size='medium'
        onClick={() => setModalOpen(!modalOpen)}
        disabled={params.loading}
    >
        <FilterAltOutlined/>
    </IconButton>
    <Modal isOpened={modalOpen} onClose={() => setModalOpen(false)}>
        <Container fluid color={theme.palette.background.default} hasShadow centered borderRadius={8}
            style={{ maxWidth: 540 }} >
            <Container fluid flex centered padded>
                <Stack spacing={2}>
                    <span>
                        <Title2>Filtro</Title2>
                        <BodyText muted>Os filtros abaixo serão aplicados à lista de carcaças</BodyText>
                    </span>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-farms-multiselect"
                        options={params.farmList || farms}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Fazendas"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setFarms(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={farms}
                    />
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-classification-multiselect"
                        options={params.classificationList || classification}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Classificação RedSoft"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setClassification(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={classification}
                    />
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-docs-multiselect"
                        options={params.docList || docNums}
                        getOptionLabel={(item) => String(item.value)}
                        renderInput={(params) => (
                            <TextField
                                label="Contratos"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setDocumentNumbers(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={docNums}
                    />
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-lots-multiselect"
                        options={params.lotList || lotNums}
                        getOptionLabel={(item) => String(item.value)}
                        renderInput={(params) => (
                            <TextField
                                label="Lotes"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setLotNumbers(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={lotNums}
                    />
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-sequence-multiselect"
                        options={params.sequenceList || sequences}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Sequenciais ERP"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setSequences(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={sequences}
                    />
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        disableCloseOnSelect
                        id="carcass-list-filter-genders-multiselect"
                        options={params.genderList || genders}
                        getOptionLabel={(item) => item.value as string}
                        renderInput={(params) => (
                            <TextField
                                label="Sexos"
                                style={{ margin: 0, minWidth: '240px' }}
                                {...params}
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setGenders(newValue);
                        }}
                        disabled={params.disabled}
                        style={params.sx}
                        value={genders}
                    />
                    <Container inline centered>
                        <Checkbox size="small" checked={bestPlaced.includes(true)} onClick={() => onCheckBestPlaced(true)} />
                        <Container fluid flexStart flex>
                            <BodyText>Bem Posicionadas</BodyText>
                        </Container>

                        <Checkbox size="small" checked={bestPlaced.includes(false)} onClick={() => onCheckBestPlaced(false)} />
                        <Container fluid flexStart flex>
                            <BodyText>Mal Posicionadas</BodyText>
                        </Container>
                    </Container>
                    <Button variant='contained' onClick={() => onSubmit()}>Filtrar</Button>
                    <Button variant='text' onClick={() => onClear()}>Limpar Filtros</Button>
                    <Button variant='outlined' onClick={() => onCancel()}>Cancelar</Button>
                </Stack>
            </Container>
        </Container>
    </Modal>
    </>);
};

export default CarcassesListFilter;