import moment from "moment";

export const DATE_FORMAT: string = "DD/MM/YYYY";
export const DATE_TIME_FORMAT: string = `${DATE_FORMAT} HH:mm:ss`;
export const ISO_DATE_FORMAT: string = "YYYY-MM-DD";
export const ISO_DATE_TIME_FORMAT: string = `${ISO_DATE_FORMAT} HH:mm:ss`;

export const getDatesRange = (start: Date, end: Date) => {
  let fromDate: Date = new Date(start);

  const dateArr = [];
  while (fromDate <= end) {
    dateArr.push(moment(fromDate).format("YYYY-MM-DD"));
    let newDate = fromDate.setDate(fromDate.getDate() + 1);
    fromDate = new Date(newDate);
  }

  return dateArr;
};

export const parseStringToDate = (_dateStr: string, _format: string = "YYYY-MM-DD HH:mm:ss.ms") => {
  const date: Date = moment(_dateStr, _format).toDate();
  return date;
};

export const parseDateToString = (_date: Date, _format: string = "DD/MM/YYYY HH:mm:ss.ms") => {
  const dateStr: string = moment(_date).format(_format);
  return dateStr;
};
