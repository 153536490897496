import React, { FC } from 'react';
import { Grid } from '@mui/material';

import RevisionCard from '../revisionCard';
import Container from '../../../atoms/container';
import { IAnnotation, IRevisionContinuosGrid } from '../../../../types';
import { PADDING } from '../../../../utils/consts';

const RevisionContinuosGrid: FC<IRevisionContinuosGrid> = (params: IRevisionContinuosGrid) => {
    return (
        <Container fluid flex veryPadded color={params.annotations.length == 0 ? 'transparent' : '#000'}>
            {params.annotations.length > 0 &&
                <Grid container>
                    {params.annotations.map((item, index) => {
                        if (!item.carcass?.photoUri) return null;
                        let isSelected: boolean = false;
                        if (params.selectedAnnotations.find(annotation => annotation.id == item.id))
                            isSelected = true;

                        return (
                            <Grid key={index} item xs={6} md={6} lg={4} xl={3}>
                                <RevisionCard
                                    fromIA={params.fromIA}
                                    annotation={item}
                                    isSelected={isSelected}
                                    onSelectAnnotation={(auxAnnotation: IAnnotation) => params.onSelectAnnotation(auxAnnotation)} />
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </Container>
    );
}

export default RevisionContinuosGrid;