import React, { FC, useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material';

import BranchSelect from '../branches/select';
import Container from '../../atoms/container';
import DatePicker from "../../atoms/datePicker";
import { useBranches } from '../../../hooks/branches';
import { useCarcass } from '../../../hooks/carcasses';
import { ICarcassFilterParams, ICarcassFilter } from '../../../types';


const CarcassesFilter: FC<ICarcassFilter> = (params: ICarcassFilter) => {
    const { fetchBranches } = useBranches();
    const { filter } = useCarcass();

    const [dateValue, setDateValue] = useState<Date | null>(filter.initialDate);
    const [branchId, setBranchId] = useState<string>(filter.branchId || "");
    const [branchName, setBranchName] = useState<string>(filter.branchId || "");

    useEffect(() => {
        fetchBranches();
    }, []);

    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
    }

    const onApply = () => {
        const filterParams: ICarcassFilterParams = {
            initialDate: dateValue,
            finalDate: dateValue,
            branchId: branchId,
            docNums: filter.docNums,
            farms: filter.farms,
            lotNums: filter.lotNums,
        };

        setTimeout(() => {
            params.onApply(filterParams);
        }, 300);
    }

    return (
        <Grid container columnSpacing={1} padding={1} alignItems="center">
            <Grid item xs={5}>
                <DatePicker
                    value={dateValue}
                    label='Data'
                    placeholder={"Escolha a data"}
                    setValue={setDateValue}
                    disabled={params.loading}
                    variant='outlined'
                />
            </Grid>
            <Grid item xs={5}>
                <Container width="100%">
                    <BranchSelect
                        value={branchId}
                        valueName={branchName}
                        handleChangeValue={handleChangeBranch}
                        disabled={!!params.loading}
                    />
                </Container>
            </Grid>
            <Grid item xs={2}>
                <Button variant="contained" color="primary"
                    onClick={() => onApply()}
                    disabled={params.loading}>
                    Filtrar
                </Button>
            </Grid>
        </Grid>)
}

export default CarcassesFilter