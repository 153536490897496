import React, { FC, createContext, useState, useContext } from 'react'
import moment from 'moment';

import { 
    fetchClassificationControl, 
    fetchClassificationQuality, 
    fetchDentitionControl, 
    fetchSexControl, 
    fetchWeightRangeControl, 
    getAnnotationsThisWeekCount, 
    getCarcassAllTimeCount, 
    getCarcassesCountByClassification, 
    getCarcassesCountByDentition, 
    getCarcassesCountBySex,
    getCarcassesCountByFrigobomTipification,
    getCarcassThisMonthCount, 
    getCarcassThisWeekCount, 
    getBranchesCount, 
    getRevisionsThisWeekCount, 
    getUsersCount, 
    getCarcassesCountByWeight, 
    getCarcassesCountAnnotationsXRevisions, 
    getCarcassesCountPerClassificationsAnnotationsXRevisions, 
    getCarcassesCountByLocalClassification, 
    getCarcassesCountByBestPlaced, 
    getDashboardCounts 
} from '../services/dashboard'

import { IDashClassificationControl, IDashClassificationQuality, IDashDentitionControl, IDashSexControl, IDashWeightRangeControl, IDashboardContext, IDashboardBarChartData, IDashboardPieChartData, IBranch, IDashboardFilterData, IDashboardChartData } from '../types'
import { useLoading } from './loading';

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

export const DashboardProvider: FC<any> = ({ children }) => {
    const [carcassTodayCount, setCarcassTodayCount] = useState<number>(0);
    const [carcassTodayBestPlacedCount, setCarcassTodayBestPlacedCount] = useState<number>(0);
    const [carcassThisWeekCount, setCarcassThisWeekCount] = useState<number>(0);
    const [carcassThisMonthCount, setCarcassThisMonthCount] = useState<number>(0);
    const [carcassAllTimeCount, setCarcassAllTimeCount] = useState<number>(0);
    const [carcassAllTimeBestPlacedCount, setCarcassAllTimeBestPlacedCount] = useState<number>(0);
    const [carcassAverageWeight, setCarcassAverageWeight] = useState<number>(0.0);
    const [carcassIntegratedWithERPCount, setCarcassIntegratedWithERPCount] = useState<number>(0);
    const [branchesCount, setBranchesCount] = useState<number>(0);
    const [usersCount, setUsersCount] = useState<number>(0);
    const [carcassesCountByClassification, setCarcassesCountByClassification] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByLocalClassification, setCarcassesCountByLocalClassification] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByDentition, setCarcassesCountByDentition] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByWeight, setCarcassesCountByWeight] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountBySex, setCarcassesCountBySex] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountAnnotationsXRevisions, setCarcassesCountAnnotationsXRevisions] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountPerClassificationsAnnotationsXRevisions, setCarcassesCountPerClassificationsAnnotationsXRevisions] = useState<IDashboardBarChartData[]>([]);
    const [carcassesCountByBestPlaced, setCarcassesCountByBestPlaced] = useState<IDashboardChartData[]>([]);
    const [annotationsThisWeekCount, setAnnotationsThisWeekCount] = useState<number>(0);
    const [revisionsThisWeekCount, setRevisionsThisWeekCount] = useState<number>(0);

    const [carcassesCountByFrigobomTipification, setCarcassesCountByFrigobomTipification] = useState<IDashboardBarChartData[]>([]);

    const [classificationControlData, setClassificationControlData] = useState<IDashClassificationControl[]>([]);
    const [classificationQualityData, setClassificationQualityData] = useState<IDashClassificationQuality[]>([]);
    const [dentitionControlData, setDentitionControlData] = useState<IDashDentitionControl[]>([]);
    const [sexControlData, setSexControlData] = useState<IDashSexControl[]>([]);
    const [weightRangeData, setWeightRangeData] = useState<IDashWeightRangeControl[]>([]);
    const { hideLoading } = useLoading();
    const [filter, setFilter] = useState<IDashboardFilterData>(
        {   initialDate: new Date(), 
            finalDate: new Date(), 
            branches: [], 
            farms: [], 
            lotNums: [], 
            dentitions: [], 
            weightIntervals: [],
            sexes: [],
        }
    );

    const [loadingFetchingDashboardData, setLoadingFetchingDashboardData] = useState<boolean>(false);


    const fetchDashboardCounts = async (_query: string) => {
        let data = await getDashboardCounts(_query);
        setCarcassAllTimeCount(data?.carcassCountAllTime?.count || 0);
        setCarcassAllTimeBestPlacedCount(data?.carcassCountAllTimeBestPlaced?.count || 0);
        setCarcassAverageWeight(data?.carcassAverageWeight?.avgWeight || 0);
        setCarcassIntegratedWithERPCount(data?.carcassCountIntegratedWithErp?.count || 0);
    }

    const fetchCarcassThisWeekCount = async (_query: string) => {
        let count = await getCarcassThisWeekCount(_query);
        setCarcassThisWeekCount(count);
    }
    const fetchCarcassThisMonthCount = async (_query: string) => {
        let count = await getCarcassThisMonthCount(_query);
        setCarcassThisMonthCount(count);
    }
    const fetchCarcassAllTimeCount = async (_query: string) => {
        let count = await getCarcassAllTimeCount(_query);
        setCarcassAllTimeCount(count);
    }
    const fetchBranchesCount = async (_query: string) => {
        let count = await getBranchesCount(_query);
        setBranchesCount(count);
    }
    const fetchUsersCount = async (_query: string) => {
        let count = await getUsersCount(_query);
        setUsersCount(count);
    }
    const fetchCarcassesCountByClassification = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountByClassification(_query);
        setCarcassesCountByClassification(data);
    }
    const fetchCarcassesCountByLocalClassification = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountByLocalClassification(_query);
        setCarcassesCountByLocalClassification(data);
    }
    const fetchCarcassesCountByDentition = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountByDentition(_query);
        setCarcassesCountByDentition(data);
    }
    const fetchCarcassesCountByWeight = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountByWeight(_query);
        setCarcassesCountByWeight(data);
    }
    const fetchCarcassesCountBySex = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountBySex(_query);
        setCarcassesCountBySex(data);
    }
    const fetchCarcassesCountByFrigobomTipification = async (_query: string) => {
        let data: IDashboardPieChartData[] = await getCarcassesCountByFrigobomTipification(_query);
        setCarcassesCountByFrigobomTipification(data);
    }
    const fetchCarcassesCountAnnotationsXRevisions = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountAnnotationsXRevisions(_query);
        setCarcassesCountAnnotationsXRevisions(data);
    }
    const fetchCarcassesCountPerClassificationsAnnotationsXRevisions = async (_query: string) => {
        let data: IDashboardBarChartData[] = await getCarcassesCountPerClassificationsAnnotationsXRevisions(_query);
        setCarcassesCountPerClassificationsAnnotationsXRevisions(data);
    }
    const fetchCarcassesCountByBestPlaced = async (_query: string) => {
        let data: IDashboardChartData[] = await getCarcassesCountByBestPlaced(_query);
        setCarcassesCountByBestPlaced(data);
    }
    const fetchAnnotationsThisWeekCount = async (_query: string) => {
        let count = await getAnnotationsThisWeekCount(_query);
        setAnnotationsThisWeekCount(count);
    }
    const fetchRevisionsThisWeekCount = async (_query: string) => {
        let count = await getRevisionsThisWeekCount(_query);
        setRevisionsThisWeekCount(count);
    }

    const fetchDashboardData = async (_filter: IDashboardFilterData) => {
        if (loadingFetchingDashboardData)
            return;
        setLoadingFetchingDashboardData(true);
        let query: string = '';
        if (_filter.initialDate)
            query += ((query == '') ? '?' : '&') + `initial_date=${moment(_filter.initialDate).format('YYYY-MM-DD')}`;
        if (_filter.finalDate)
            query += ((query == '') ? '?' : '&') + `final_date=${moment(_filter.finalDate).format('YYYY-MM-DD')}`;
        if (_filter.branches.length > 0)
            query += ((query == '') ? '?' : '&') + `branches=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
        if (_filter.farms != null && _filter.farms.length > 0)
            query += ((query == '') ? '?' : '&') + `farms=${_filter.farms.map(item => `'${item}'`).join(',')}`;
        if (_filter.lotNums != null && _filter.lotNums.length > 0)
            query += ((query == '') ? '?' : '&') + `lot_nums=${_filter.lotNums.map(item => `'${item}'`).join(',')}`;
        if (_filter.dentitions != null && _filter.dentitions.length > 0)
            query += ((query == '') ? '?' : '&') + `dentitions=${_filter.dentitions.map(item => `${item}`).join(',')}`;
        if (_filter.weightIntervals != null && _filter.weightIntervals.length > 0)
            query += ((query == '') ? '?' : '&') + `weight_intervals=${_filter.weightIntervals.map(item => `${item}`).join(',')}`;
        if (_filter.sexes != null && _filter.sexes.length > 0)
            query += ((query == '') ? '?' : '&') + `sexes=${_filter.sexes.map(item => `${item}`).join(',')}`;
        setCarcassAllTimeCount(0);
        setCarcassAllTimeBestPlacedCount(0);
        setCarcassAverageWeight(0.0);
        setCarcassIntegratedWithERPCount(0);
        setAnnotationsThisWeekCount(0);
        setRevisionsThisWeekCount(0);

        const promiseDashboardCounts = new Promise(async function (resolve, reject) {
            try {
                fetchDashboardCounts(query);
                resolve(true);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByClassification = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByClassification(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByDentition = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByDentition(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByWeight = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByWeight(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountBySex = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountBySex(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByFrigobomTipification = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByFrigobomTipification(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });
        const promiseCarcassesCountByBestPlaced = new Promise(async function (resolve, reject) {
            try {
                setTimeout(() => {
                    fetchCarcassesCountByBestPlaced(query);
                    resolve(true);
                }, 1000);
            } catch (_err) {
                resolve(false);
            }
        });

        Promise.race([
            promiseDashboardCounts,
            promiseCarcassesCountByClassification,
            promiseCarcassesCountByDentition,
            promiseCarcassesCountByWeight,
            promiseCarcassesCountBySex,
            promiseCarcassesCountByFrigobomTipification,
            promiseCarcassesCountByBestPlaced,
        ]).then(res => {
            hideLoading();
            setLoadingFetchingDashboardData(false);
        }).catch(err => {
            setLoadingFetchingDashboardData(false);
        })
    }

    const fetchClassificationControlData = async () => {
        const data = await fetchClassificationControl();
        if (data)
            setClassificationControlData(data)
    }

    const fetchClassificationQualityData = async () => {
        const data = await fetchClassificationQuality();
        if (data)
            setClassificationQualityData(data)
    }

    const fetchDentitionControlData = async () => {
        const data = await fetchDentitionControl();
        if (data)
            setDentitionControlData(data)
    }

    const fetchSexControlData = async () => {
        const data = await fetchSexControl();
        if (data)
            setSexControlData(data)
    }

    const fetchWeightRangeControlData = async () => {
        const data = await fetchWeightRangeControl();
        if (data)
            setWeightRangeData(data)
    }


    return (
        <DashboardContext.Provider value={{
            carcassTodayCount, carcassTodayBestPlacedCount, carcassThisWeekCount, carcassThisMonthCount, 
            carcassAllTimeCount, carcassAllTimeBestPlacedCount, carcassAverageWeight, carcassIntegratedWithERPCount,
            branchesCount, usersCount,
            carcassesCountByClassification,
            carcassesCountByLocalClassification,
            carcassesCountByDentition,
            carcassesCountByWeight,
            carcassesCountBySex,
            carcassesCountByFrigobomTipification,
            carcassesCountAnnotationsXRevisions,
            carcassesCountPerClassificationsAnnotationsXRevisions,
            carcassesCountByBestPlaced,
            annotationsThisWeekCount,
            revisionsThisWeekCount,

            fetchDashboardData,

            classificationControlData,
            fetchClassificationControlData,
            classificationQualityData,
            fetchClassificationQualityData,
            dentitionControlData,
            fetchDentitionControlData,
            sexControlData,
            fetchSexControlData,
            weightRangeData,
            fetchWeightRangeControlData,

            filter,
            setFilter
        }} >
            {children}
        </DashboardContext.Provider>
    )
}

export function useDashboard() {
    const context = useContext(DashboardContext);

    if (!context) {
        throw new Error('useDashboard must be used within an DashboardProvider');
    }

    return context
}