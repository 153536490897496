import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Divider, IconButton } from "@mui/material";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useCarcass } from "../../../../hooks/carcasses";
import { ICarcassClassification, ICarcassClassificationMultiSelect } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import { normalizeString } from "../../../../utils/stringFormatters";

const CarcassClassificationsMultiSelect: FC<ICarcassClassificationMultiSelect> = (params: ICarcassClassificationMultiSelect) => {
    const { classifications } = useCarcass();

    const [search, setSearch] = useState<string>('')
    const [filteredClassifications, setFilteredClassifications] = useState<ICarcassClassification[]>([]);

    useEffect(() => {
        setFilteredClassifications([...classifications]);
    }, [classifications]);

    useEffect(() => {
        filterClassifications(search);
    }, [search]);

    const filterClassifications = (_search: string) => {
        setFilteredClassifications([])
        let auxClassifications = [...classifications]

        setTimeout(() => {
            if (_search != '') {
                auxClassifications = auxClassifications.filter((item: ICarcassClassification) =>
                    (item.label && normalizeString(item.label).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredClassifications([...auxClassifications])
        }, 0)
    }

    const handleSelectClassification = (_classification: ICarcassClassification, _isChecked: boolean) => {
        if (params.singleSelect) {
            params.setSelectedClassifications([_classification]);
            return;
        }

        const auxClassifications: ICarcassClassification[] = [];
        for (let i = 0; i < params.selectedClassifications.length; i++) {
            const auxClassification: ICarcassClassification = params.selectedClassifications[i];
            if (auxClassification.id == _classification.id && !_isChecked) continue;
            auxClassifications.push(auxClassification);
        }
        if (_isChecked) auxClassifications.push(_classification);

        params.setSelectedClassifications([...auxClassifications]);
    }

    const renderClassificationItem = (_classification: ICarcassClassification, key: number) => {
        const isChecked: boolean = params.selectedClassifications.find(item => item.id == _classification.id) != undefined;

        return (
            <Container key={key} className="container-hover" inline spacedBetween horizontalCentered width="100%"
                onClick={() => handleSelectClassification(_classification, !isChecked)}
                sx={{ cursor: 'pointer' }}>
                <Checkbox size="small" checked={isChecked} />
                <Container fluid flexStart flex>
                    <Text>{_classification.label}</Text>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth="130px" height="30px">
            <Container inline horizontalCentered spacedBetween minWidth="130px" height="30px" onClick={() => params.setExpanded(!params.expanded)}
                style={{ cursor: 'pointer', paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedClassifications.length == 0 &&
                    <Text size={15}>
                        {params.placeholder || 'Classificações'}
                    </Text>}
                {params.selectedClassifications.length > 0 &&
                    <Text bold size={14}>
                        {`${params.selectedClassifications[0].label} ${!params.singleSelect && params.selectedClassifications.length > 1 ? ' +' + (params.selectedClassifications.length - 1) : ''}`}
                    </Text>
                }
                <IconButton style={{ backgroundColor: 'transparent' }}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Collapse in={params.expanded} timeout="auto" unmountOnExit>
                <Container fluid absolute hasShadow padded borderRadius={BORDER_RADIUS} color={theme.palette.background.paper} sx={{ zIndex: 100, marginTop: 10 }}>
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder='Pesquisar classificação'
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color='primary' />}
                        sx={{ height: '35px' }} />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: 'auto' }}>
                        {filteredClassifications.map((item, index) => renderClassificationItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSelectedClassifications([])}>
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Collapse>
        </Container>
    );
}

export default CarcassClassificationsMultiSelect