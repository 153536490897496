import React, { FC, useState } from "react";
import { Button, Checkbox, Grid, } from "@mui/material";

import BranchesMultiSelect from "../../branches/multiSelect";
import CarcassesLocalClassificationsMultiSelect from "../../carcassesLocalClassifications/multiSelect";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useCarcassesLocalClassifications } from "../../../../hooks/carcassesLocalClassifications";
import { IAnnotationsFilter, ICarcassLocalClassification, IBranch } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import DateRangePicker from "../../../atoms/dateRangePicker";

const AnnotationsFilter: FC<IAnnotationsFilter> = (params: IAnnotationsFilter) => {
    const { carcassesLocalClassifications } = useCarcassesLocalClassifications();

    const [initialDate, setInitialDate] = useState<Date | null>(params.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(params.finalDate);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(params.branches);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);
    const [selectedClassifications, setSelectedClassifications] = useState<ICarcassLocalClassification[]>(params.classifications);
    const [classificationsExpanded, setClassificationsExpanded] = useState<boolean>(false);
    const [selectedIaClassifications, setSelectedIaClassifications] = useState<ICarcassLocalClassification[]>(params.iaClassifications);
    const [iaClassificationsExpanded, setIaClassificationsExpanded] = useState<boolean>(false);
    const [onlyBestPlaced, setOnlyBestPlaced] = useState<boolean>(false);
    const [onlyWithPartnerId, setOnlyWithPartnerId] = useState<boolean>(false);

    const onApplyFilter = () => {
        let auxClassifications: ICarcassLocalClassification[] = [];
        for (let i = 0; i < carcassesLocalClassifications.length; i++) {
            if (selectedClassifications.map(item => item.name).includes(carcassesLocalClassifications[i].name))
                auxClassifications.push(carcassesLocalClassifications[i]);
        }
        let auxIaClassifications: ICarcassLocalClassification[] = [];
        for (let i = 0; i < carcassesLocalClassifications.length; i++) {
            if (selectedIaClassifications.map(item => item.name).includes(carcassesLocalClassifications[i].name))
                auxIaClassifications.push(carcassesLocalClassifications[i]);
        }

        setBranchesExpanded(false);
        params.onApplyFilter(initialDate, finalDate, selectedBranches, auxClassifications, auxIaClassifications, onlyBestPlaced, onlyWithPartnerId);
    }

    return (
        <Container fluid flex verticalCentered minHeight="50px" sx={{ margin: PADDING, paddingLeft: PADDING }}>
            <Text size={15} medium>Filtros</Text>
            <Spacing top={PADDING} />
            <Container inline flex flexGrow horizontalCentered>
                <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                    <Grid item xs={4} sm={5} md={3} lg={4} xl={2.5}>
                        <DateRangePicker
                            initialDate={initialDate}
                            finalDate={finalDate}
                            placeholder={"Escolha o intervalo de data"}
                            setInitialDate={setInitialDate}
                            setFinalDate={setFinalDate}
                            disabled={false}
                            maxDaysSelect={0}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} lg={4} xl={2}>
                        <BranchesMultiSelect
                            selectedBranches={selectedBranches}
                            setSelectedBranches={setSelectedBranches}
                            expanded={branchesExpanded}
                            setExpanded={setBranchesExpanded}
                            singleSelect
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                                marginTop: -5
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} lg={4} xl={3}>
                        <CarcassesLocalClassificationsMultiSelect
                            placeholder="Classificação local"
                            selectedBranchesId={selectedBranches.map(item => item.id || "")}
                            selectedClassifications={selectedClassifications}
                            setSelectedClassifications={setSelectedClassifications}
                            expanded={classificationsExpanded}
                            setExpanded={setClassificationsExpanded}
                            singleSelect={false}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                                marginTop: -5
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} lg={4} xl={3}>
                        <CarcassesLocalClassificationsMultiSelect
                            placeholder="Classificação IA"
                            selectedBranchesId={selectedBranches.map(item => item.id || "")}
                            selectedClassifications={selectedIaClassifications}
                            setSelectedClassifications={setSelectedIaClassifications}
                            expanded={iaClassificationsExpanded}
                            setExpanded={setIaClassificationsExpanded}
                            singleSelect={false}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                                marginTop: -5
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} lg={4} xl={3}>
                        <Container inline centered>
                            <Checkbox size="small" checked={onlyBestPlaced} onClick={() => setOnlyBestPlaced(!onlyBestPlaced)} />
                            <Container fluid flexStart flex>
                                <Text size={14}>Somente bem posicionadas</Text>
                            </Container>

                            <Checkbox size="small" checked={onlyWithPartnerId} onClick={() => setOnlyWithPartnerId(!onlyWithPartnerId)} />
                            <Container fluid flexStart flex>
                                <Text size={14}>Somente com id do parceiro</Text>
                            </Container>
                        </Container>
                    </Grid>
                    <Grid item xs={1} sm={3} lg={4} xl={2}>
                        <Button size="small" variant="contained" onClick={onApplyFilter} sx={{ backgroundColor: theme.palette.primary.dark }}>
                            <Text size={13} color={theme.palette.background.paper}>Aplicar filtros</Text>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}

export default AnnotationsFilter;