import React, { FC, useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Collapse, FormGroup, FormControlLabel, Grid, IconButton, Switch, Tooltip } from '@mui/material'
import { FilterAlt as FilterIcon, FilterAltOutlined as FilterIconOutlined } from '@mui/icons-material'
import moment from 'moment';

import { AnnotationsFilter, CarcassPhoto, Container, Page, Spacing, Text, theme } from '../../components'
import { useAnnotations } from '../../hooks/annotations';
import { useAnnotators } from '../../hooks/annotators';
import { useAnnotatorHasAccesss } from '../../hooks/annotatorsHasAccess';
import { useAuth } from '../../hooks/auth';
import { useCarcass } from '../../hooks/carcasses';
import { useInformation } from '../../hooks/information';
import { IAnnotation, IAnnotationFilter, IAnnotator, IAnnotatorHasAccess, ICarcass, ICarcassLocalClassification, IBranch } from '../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts';

const AnnotationsFlow: FC = () => {
    const {
        filter, setFilter,
        getExampleJBS, getExampleRedsoft,
        annotationCount, fetchAnnotationCount,
        createNewAnnotation } = useAnnotations();
    const { fetchAnnotatorByUserId, fetchAnnotatorAnnotates } = useAnnotators();
    const { fetchAnnotatorHasAccessByAnnotatorAndBranch } = useAnnotatorHasAccesss();
    const { user } = useAuth();
    const { classifications, fetchCarcassForAnnotation, loading } = useCarcass();
    const { hideInformation, showInformation } = useInformation();

    const [annotator, setAnnotator] = useState<IAnnotator | undefined>(undefined);
    const [filterIsOpen, setFilterIsOpen] = useState<boolean>(true);
    const [carcass, setCarcass] = useState<ICarcass | null>(null);
    const [selectedClassification, setSelectedClassification] = useState<number>(-1);
    const [showExamples, setShowExamples] = useState<boolean>(true);
    const [loadingAccess, setLoadingAccess] = useState<boolean>(false);
    const [loadingCarcass, setLoadingCarcass] = useState<boolean>(false);
    const [userHasAccess, setUserHasAccess] = useState<boolean | undefined>(undefined);
    const [sending, setSending] = useState<boolean>(false);

    useEffect(() => {
        fetchAnnotatorData();
        fetchAnnotationCount(filter);
    }, []);


    useEffect(() => {
        if (userHasAccess)
            loadCarcass(filter);
        else setCarcass(null);
    }, [userHasAccess]);

    const fetchAnnotatorData = async () => {
        if (user) {
            const userId: string = user.id;
            const auxAnnotator: IAnnotator | undefined = await fetchAnnotatorByUserId(userId);
            if (auxAnnotator) setAnnotator(auxAnnotator);
        }
    }

    const loadCarcass = async (_filter: IAnnotationFilter) => {
        if (_filter.initialDate == null || _filter.finalDate == null) return;

        try {
            setLoadingCarcass(true);
            fetchAnnotationCount(_filter);

            const auxCarcass: ICarcass | null = await fetchCarcassForAnnotation(_filter);

            setTimeout(() => {
                setCarcass(auxCarcass);
                setLoadingCarcass(false);
            }, 1500);
        } catch (err) {
            setLoadingCarcass(false);
        }
    }


    const checkAccess = async (_newFilter: IAnnotationFilter | undefined) => {
        setLoadingAccess(true);
        try {
            let auxFilter: IAnnotationFilter = filter;
            if (_newFilter)
                auxFilter = _newFilter;
            await fetchAnnotationCount(auxFilter);

            if (userHasAccess)
                setUserHasAccess(false);
            let auxHasAccess: boolean = false;
            if (annotator && annotator.id && auxFilter.branches[0] && auxFilter.branches[0].id) {
                const selectedBranchId: string = auxFilter.branches[0].id;

                const annotatorId: string = annotator.id;
                const initialDate: string = moment(auxFilter.initialDate).format('YYYY-MM-DD');
                const finalDate: string = moment(auxFilter.finalDate).format('YYYY-MM-DD');
                const countAnnotations: number = await fetchAnnotatorAnnotates(annotatorId, initialDate, finalDate, selectedBranchId, filter.onlyBestPlaced, filter.onlyWithPartnerId);
                if (annotator.fullAccess)
                    auxHasAccess = true;
                else {
                    const annotatorsHasAccesses: IAnnotatorHasAccess[] = await fetchAnnotatorHasAccessByAnnotatorAndBranch(annotatorId, selectedBranchId);
                    if (annotatorsHasAccesses.length > 0) {
                        const annotatorHasAccess: IAnnotatorHasAccess | undefined =
                            annotatorsHasAccesses.find(item => (
                                moment(item.releasedDate, 'YYYY-MM-DD').format('DD/MM/YYYY') >= moment(auxFilter.initialDate).format('DD/MM/YYYY') &&
                                moment(item.releasedDate, 'YYYY-MM-DD').format('DD/MM/YYYY') <= moment(auxFilter.finalDate).format('DD/MM/YYYY')
                            ));
                        if (annotatorHasAccess) {
                            if ((countAnnotations || 0) < annotatorHasAccess.releasedQuantity)
                                auxHasAccess = true;
                        }
                    }
                }
            }
            setUserHasAccess(auxHasAccess);
            setLoadingAccess(false);
        } catch (err) {
            setLoadingAccess(false);
        }
    }

    const applyFilter = async (_initialDate: Date | null, _finalDate: Date | null, _branches: IBranch[], _classifications: ICarcassLocalClassification[],
        _iaClassifications: ICarcassLocalClassification[],
        onlyBestPlaced: boolean,
        onlyWithPartnerId: boolean) => {
        if (_branches.length == 0) {
            showInformation('Favor informar uma empresa', 'Aviso');
            return;
        }

        const newFilter: IAnnotationFilter = {
            initialDate: _initialDate,
            finalDate: _finalDate,
            branches: _branches,
            classifications: _classifications,
            iaClassifications: _iaClassifications,
            onlyBestPlaced,
            onlyWithPartnerId
        }
        setFilter(newFilter);

        checkAccess(newFilter);
        // loadCarcass(newFilter);
    }

    const sendAnnotation = async () => {
        if (annotator && annotator.id) {
            const newAnnotation: IAnnotation = {
                annotatorId: annotator.id,
                carcassId: carcass?.id,
                classificationId: selectedClassification
            }
            try {
                setSending(true);
                await createNewAnnotation(newAnnotation, false);

                setSending(false);
                setSelectedClassification(-1);
                checkAccess(undefined);
            } catch (e) {
                setSending(false);
                console.log(e);
            }
        }
    }

    const renderAnnotationStats = () => {
        return (
            <Container fluid padded maxWidth="300px" color={theme.palette.primary.main} borderRadius={BORDER_RADIUS / 2} sx={{ zIndex: 10 }}>
                <Grid container width="100%">
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold color={theme.palette.background.default}>Não anotadas:</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text bold color={theme.palette.background.default}>{annotationCount.countUnannotate}</Text>
                    </Grid>
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold color={theme.palette.background.default}>Anotadas:</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text bold color={theme.palette.background.default}>{annotationCount.countAnnotate}</Text>
                    </Grid>
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold color={theme.palette.background.default}>Total:</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text bold color={theme.palette.background.default}>{annotationCount.countAnnotate + annotationCount.countUnannotate}</Text>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    return (
        <Page width='100%' height='87%'>
            <Container fluid color={theme.palette.background.default} width="100%" minHeight="95vh">
                <Container inline flex spacedBetween horizontalCentered padded color={theme.palette.gray.main} maxHeight="25px">
                    <Text bold size={15}>Anotações</Text>
                </Container>
                <Container inline width="100%">
                    <AnnotationsFilter
                        initialDate={filter.initialDate}
                        finalDate={filter.finalDate}
                        branches={filter.branches}
                        classifications={[]}
                        iaClassifications={[]}
                        onApplyFilter={applyFilter}
                    />
                    <Container fluid width="250px" sx={{ paddingTop: VERY_PADDING * 1.7 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked={showExamples} value={showExamples} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowExamples(event.target.checked)} />} label="Mostrar exemplos" />
                        </FormGroup>
                    </Container>
                </Container>

                {!loadingAccess && userHasAccess == false && userHasAccess != undefined &&
                    <Container fluid centered padded color={theme.palette.info.main}>
                        <Text bold size={15} color={theme.palette.background.paper}>Você está sem acesso para efetuar anotações nesta data.</Text>
                    </Container>
                }

                {!loadingAccess && !loadingCarcass && !carcass &&
                    <Container fluid centered height="450px">
                        <Container fluid centered width="500px" height="450px">
                            {userHasAccess == undefined && <Text center bold size={20}>Preencha a data e selecione a empresa para buscar uma nova carcaça para iniciar a anotação.</Text>}
                            {userHasAccess != undefined && annotationCount.countUnannotate > 0 && <Text center bold size={20}>Verifique com o seu gestor sobre a sua permissão de acesso à plataforma de anotação.</Text>}
                            {annotationCount.countUnannotate == 0 && <Text center bold size={20}>Nenhuma carcaça foi encontrada com o filtro selecionado.</Text>}
                            <Spacing top={VERY_PADDING} />
                        </Container>
                    </Container>
                }

                {carcass && <Container fluid flex flexGrow horizontalCentered color='#000' height="100%" sx={{ justifyContent: 'flexStart' }}>
                    <Grid container>
                        <Grid item xs={9} padding={2}>
                            {carcass.photoUri &&
                                <Grid container columnSpacing={2}>
                                    {showExamples &&
                                        <Grid item xs={4}>
                                            <Container fluid minWidth="30%">
                                                <Container fluid centered width="100%" height="30px" color={theme.palette.primary.main}>
                                                    <Text medium color={theme.palette.background.paper}>Exemplo JBS</Text>
                                                </Container>
                                                {selectedClassification > 0 &&
                                                    <Container fluid horizontalCentered width="100%">
                                                        {getExampleJBS(selectedClassification)}
                                                    </Container>}
                                            </Container>
                                        </Grid>
                                    }
                                    <Grid item xs={showExamples ? 4 : 12}>
                                        <Container fluid centered width="100%" height="30px" color={theme.palette.primary.main}>
                                            <Text medium color={theme.palette.background.paper}>Carcaça {carcass.sequence}</Text>
                                        </Container>
                                        <Container fluid horizontalCentered width="100%">
                                            <CarcassPhoto photoUri={carcass.photoUri} />
                                        </Container>
                                    </Grid>
                                    {showExamples &&
                                        <Grid item xs={4}>
                                            <Container fluid minWidth="30%">
                                                <Container fluid centered width="100%" height="30px" color={theme.palette.primary.main}>
                                                    <Text medium color={theme.palette.background.paper}>Exemplo Redsoft</Text>
                                                </Container>
                                                {selectedClassification > 0 && <Container fluid horizontalCentered width="100%">
                                                    {getExampleRedsoft(selectedClassification)}
                                                </Container>}
                                            </Container>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Container fluid veryPadded>
                                {classifications.map((item, index) => (
                                    <Container key={index} fluid sx={{ padding: 3 }}>
                                        <Button variant="contained" color={selectedClassification == item.id ? 'success' : 'primary'} onClick={() => {
                                            setSelectedClassification(item.id)
                                        }} disabled={sending}>
                                            <Text size={13} color={theme.palette.background.paper}>
                                                {item.label.toLocaleUpperCase()}
                                            </Text>
                                        </Button>
                                    </Container>
                                ))}
                                <Spacing top={VERY_PADDING} />
                                <Button variant="contained" onClick={() => sendAnnotation()} disabled={selectedClassification == -1 || sending} sx={{ backgroundColor: theme.palette.primary.dark, opacity: selectedClassification == -1 || sending ? 0.6 : 1 }}>
                                    <Text size={13} color={theme.palette.background.paper}>
                                        Enviar
                                    </Text>
                                </Button>
                                <Spacing top={VERY_PADDING} />
                                {renderAnnotationStats()}
                            </Container>
                        </Grid>
                    </Grid>
                </Container>}
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingAccess || loadingCarcass}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Page>
    )
}

export default AnnotationsFlow;