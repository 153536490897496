import React, { FC } from 'react';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import theme from '../../../theme';
import { usePrivileges } from '../../../../hooks/privileges';
import { IPrivilegeSelect } from '../../../../types';

const PrivilegeSelect: FC<IPrivilegeSelect> = (params: IPrivilegeSelect) => {
    const { privileges } = usePrivileges();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <Autocomplete
                id="privileges"
                required
                options={params.options || privileges as any[]}
                value={params.value}
                defaultValue={params.valueName}
                onChange={params.handleChangeValue}
                placeholder={params.placeholder || 'Escolha o privilégio'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists={params.value != ''} />
        </Container>
    );
}

export default PrivilegeSelect;