import React, { FC } from 'react';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import theme from '../../../theme';
import { useRoutes } from '../../../../hooks/routes';
import { IRouteSelect } from '../../../../types';

const RouteSelect: FC<IRouteSelect> = (params: IRouteSelect) => {
    const { entities } = useRoutes();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <Autocomplete
                id="routes"
                required
                variant={params.variant || 'outlined'}
                options={entities as any[]}
                value={params.value}
                defaultValue={params.valueName}
                onChange={params.handleChangeValue}
                placeholder='Escolha a rota'
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists />
        </Container>
    );
}

export default RouteSelect;