import React, { FC, useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Container from '../../../atoms/container';
import Text from '../../../atoms/text';
import theme from '../../../theme';
import { useMonitoringRecipients } from '../../../../hooks/monitoringRecipient';
import { IMonitoringRecipientRegister } from '../../../../types/components/molecules/monitoringRecipient';
import BranchSelect from '../../branches/select';
import MonitoringTypeSelect from '../select';
import UserSelect from '../../users/select';

const MonitoringRecipientRegister: FC<IMonitoringRecipientRegister> = (params: IMonitoringRecipientRegister) => {
    const { createNewMonitoringRecipient } = useMonitoringRecipients();

    const [userId, setUserId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [monitoringTypeId, setMonitoringTypeId] = useState<string>('');
    const [monitoringTypeName, setMonitoringTypeName] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string | undefined>(undefined);
    const [branchError, setBranchError] = useState<string | undefined>(undefined);
    const [typeError, setTypeError] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setUserId((event as any).target.value);
            setUserName('');
        } else {
            if ((event as any).id) {
                setUserId((event as any).id);
            }
            if ((event as any).label) {
                setUserName((event as any).label);
            }
        }
        setError(null);
    }

    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setBranchError(undefined);
        setError(null);
    }

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setMonitoringTypeId((event as any).target.value);
            setMonitoringTypeName('');
        } else {
            if ((event as any).id) {
                setMonitoringTypeId((event as any).id);
            }
            if ((event as any).label) {
                setMonitoringTypeName((event as any).label);
            }
        }
        setTypeError(undefined);
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (userId == '') {
            setUserNameError('Informe o e-mail do usuario');
            isValid = false;
        }

        if (branchId == '') {
            setBranchError('Informe o nome da filial');
            isValid = false;
        }

        if (monitoringTypeId == '') {
            setTypeError('Informe o tipo de monitoramento');
            isValid = false;
        }

        if (isValid) {
            setBranchError(undefined);
            setUserNameError(undefined);
            setTypeError(undefined);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {

            let payload: any = {
                branchId: branchId,
                userId: userId,
                monitoringTypeId: monitoringTypeId,
            };
            await createNewMonitoringRecipient(payload, true);

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Novo monitoramento cadastrado com sucesso.'

            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setUserName('');
        setUserNameError(undefined);
        setBranchId('');
        setBranchName('');
        setBranchError(undefined);
        setMonitoringTypeName('');
        setTypeError(undefined);
    }

    return (
        <Container veryPadded width="25%" fluid relative hasShadow centered  color={theme.palette.background.default}>
            <Text light size={35} color={theme.palette.grey[900]}>Cadastrar</Text>
            <Container verticalPadded  fluid flex flexGrow width="95%" minHeight="100px">
                   <Grid paddingBottom="15px" item xs={3}>
                        <Text light size={15} color={theme.palette.grey[900]}>Usuario</Text>
                        <Container fluid width={'100%'}>
                            <UserSelect
                                    value={userId}
                                    valueName={userName}
                                    handleChangeValue={handleChangeUser}
                                    variant="outlined"
                                    placeholder='Escolha o usuario'                                    
                                    error={userNameError}/>
                        </Container>
                    </Grid>
                    <Grid paddingBottom="15px" paddingTop="15px" item xs={3}>
                        <Text light size={15} color={theme.palette.grey[900]}>Filial</Text>
                        <Container fluid width={'100%'}>
                            <BranchSelect
                                value={branchId}
                                valueName={branchName}
                                handleChangeValue={handleChangeBranch}
                                variant="outlined"
                                placeholder='Escolha a filial'
                                error={branchError}/>
                        </Container>
                    </Grid>
                    <Grid paddingBottom="15px" paddingTop="15px" item xs={3}>
                        <Text light size={15} color={theme.palette.grey[900]}>Tipo de monitoramento</Text>
                        <Container fluid width={'100%'}>
                            <MonitoringTypeSelect
                                value={monitoringTypeId}
                                valueName={monitoringTypeName}
                                handleChangeValue={handleChangeType}
                                variant="outlined"
                                placeholder='Escolha o monitoramento'
                                error={typeError}/>
                        </Container>
                    </Grid>
                    <Grid item xs={3}>
                        {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                        <Container inline spacedAround veryPadded>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => confirmRegistration()}>
                                <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                            </Button>
                        </Container>
                    </Grid>
                
            </Container>
        </Container>
    )
}

export default MonitoringRecipientRegister;