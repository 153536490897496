import React, { FC, createContext, useState, useContext } from 'react'

import { IDrawer } from '../types'

const DrawerContext = createContext<IDrawer>({} as IDrawer);

export const DrawerProvider: FC<any> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    return (
        <DrawerContext.Provider value={{ isOpen, setIsOpen }}>{children}</DrawerContext.Provider>
    );
}

export function useDrawer() {
    const context = useContext(DrawerContext);

    if (!context) {
        throw new Error('useDrawer must be used within an DrawerProvider')
    }

    return context;
}