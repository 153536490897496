import React, { FC } from 'react';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import theme from '../../../theme';
import { useUsers } from '../../../../hooks/users';
import { IUserSelect } from '../../../../types';

const UserSelect: FC<IUserSelect> = (params: IUserSelect) => {
    const { users } = useUsers();

    return (
        <Container fluid sx={{ flexGrow: 1 }}>
            <Autocomplete
                id="users"
                required
                options={params.options || users.map(item => {
                    return {
                        id: item.id,
                        name: `${item.firstName} ${item.lastName}`
                    }
                }) as any[]}
                value={params.value}
                defaultValue={params.valueName}
                onChange={params.handleChangeValue}
                placeholder={params.placeholder || 'Escolha o usuário'}
                backgroundColor={theme.palette.grayLight.light}
                disabled={params.disabled}
                error={params.error}
                requiredExists />
        </Container>
    );
}

export default UserSelect;