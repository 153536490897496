import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Button, Divider, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { IDashboardWeightMultiSelect } from "../../../../types";
import Text from '../../../atoms/text';
import { theme } from '../../../../components'
import { IDashboardFilter, IBranch, IDashboardFilterData } from '../../../../types';
import { PADDING } from '../../../../utils/consts';


export interface IWeightInterval {
    label: string;
    label_arroba: string;
    value: number;
}

const DashboardWeightMultiSelect: FC<IDashboardWeightMultiSelect> = (params: IDashboardWeightMultiSelect) => {
    
    const weightOptions: IWeightInterval[] = [
        {
            label: '< 184.50kg',
            label_arroba: '< 12.30@',
            value: 0,
        },
        {
            label: '< 225kg',
            label_arroba: '< 15@',
            value: 1,
        },
        {
            label: '< 270kg',
            label_arroba: '< 18@',
            value: 2,
        },
        {
            label: '< 315kg',
            label_arroba: '< 21@',
            value: 3,
        },
        {
            label: '< 345kg',
            label_arroba: '< 23@',
            value: 4,
        },
        {
            label: '>= 345kg',
            label_arroba: '>= 23@',
            value: 5,
        },
    ]

    return (
        <Select
            multiple
            displayEmpty
            value={params.weightIntervals}
            onChange={params.onChangeWeightIntervals}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <p style={{color: '#C8C8C8'}}>Peso</p>;
                }
                return selected.join(', ');
            }}
            sx={{
                minWidth:"100%",
                height: '37px',
                backgroundColor: '#FFF',
                boxShadow: 'rgba(0, 0, 0, 0.06) 0px 3px 8px',
                borderRadius: '4px',
                marginTop: '0px'
            }}
        >   
            <MenuItem disabled value="">
                <em>Peso</em>
            </MenuItem>
            {weightOptions.map((weightInterval) => (
                <MenuItem   key={params.isUnitKg ? weightInterval.label : weightInterval.label_arroba} 
                            value={params.isUnitKg ? weightInterval.label : weightInterval.label_arroba} >
                    <Checkbox checked={params.weightIntervals.indexOf(params.isUnitKg ? weightInterval.label : weightInterval.label_arroba) > -1} />
                    <ListItemText primary={params.isUnitKg ? weightInterval.label : weightInterval.label_arroba} />
                </MenuItem>
            ))}
            {params.weightIntervals.length > 0 && (
                <>
                    <Divider sx={{ width: "100%" }} />
                    <MenuItem value="" >
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }}/>}
                            onClick={() => params.setWeightIntervals([])}
                            sx={{width: '100%', justifyContent: 'end'}}
                        >
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </MenuItem>
                </>
            )}
        </Select>
    )
}

export default DashboardWeightMultiSelect