import React, { FC, useEffect, useState } from 'react';
import { FilterAltOutlined as FilterOutlined, FilterAltOffOutlined as FilterOffOutlined } from '@mui/icons-material';
import { Pagination ,IconButton } from '@mui/material';

import { Container, Page, Spacing, Text, theme, LotsFilter } from '../../components';
import { useLots } from '../../hooks/lots';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { ILotsFilterParams } from '../../types';
import LotItem from '../../components/molecules/lots/lotItem';


const Lots: FC = () => {
    const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
    const { fetchLots, filter, loading, lots, totalNumberOfLots } = useLots();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [elementPerPage] = useState<number>(Math.floor(window.innerHeight / 150));

    useEffect(() => {
        if (!loading) {
            fetchLots(filter, currentPage, elementPerPage);
        }
    }, [currentPage]);

    const onFilterIconClick = () => {
        setFilterExpanded(!filterExpanded);
    }

    const onApplyFilter = (filter: ILotsFilterParams) => {
        fetchLots(filter, currentPage, elementPerPage);
    }

    const onChangePage = (event: any, newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <Page width='auto' height='auto' color={theme.palette.background.default} style={{minHeight: "100%"}}>
            <Container fluid flex padded verticalCentered color={theme.palette.gray.main} maxHeight="30px">
                <Text bold size={15}>Lotes</Text>
            </Container>
            <Spacing top={VERY_PADDING} />
            <Container fluid flex horizontalCentered height='auto' width="100%">
                <Container inline width="95%" spacedBetween> 
                    <Text semiBold size={18} >Listagem de animais por lotes</Text>
                    <IconButton size='medium' style={{}} onClick={onFilterIconClick}>
                        {
                            filterExpanded ? <FilterOffOutlined style={{ color: theme.palette.primary.dark }}/>:<FilterOutlined style={{ color: theme.palette.primary.dark }}/>
                        }
                    </IconButton>
                </Container>
                <Spacing top={PADDING} />
                <Container fluid width="95%">
                    {
                        filterExpanded &&
                        (
                            <>  
                                <Text bold size={14} style={{ marginBottom: PADDING / 2 }}>Filtrar lotes</Text>
                                <Spacing top={PADDING} />
                                <LotsFilter onApply={onApplyFilter} />
                            </>
                        )
                    }
                    
                </Container>
                <Spacing top={PADDING*3} />
                <Container fluid hasShadow height="100%" width="95%" minHeight="75vh" color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}>
                    <Container fluid centered width="100%" height="30px" maxHeight="30px" color={theme.palette.primary.main} borderTopRadius={BORDER_RADIUS}>
                        <Text semiBold size={15} color={theme.palette.background.paper}>Lotes cadastrados</Text>
                    </Container>
                    
                  
                    {lots.map((lot, index) => (
                        <LotItem
                            key={index}
                            id={lot.id}
                            branchId={lot.branchId}
                            lotNum={lot.lotNum}
                            farm={lot.farm}
                            carcassCount={lot.carcassCount}
                            sequencesCount={lot.sequencesCount}
                            uniqueDocNums={lot.uniqueDocNums}
                            averageWeight={lot.averageWeight}
                            totalWeight={lot.totalWeight}
                            classificationDate={lot.classificationDate}
                            carcassCountPerClass={lot.carcassCountPerClass}
                            photoUri={lot.photoUri}
                        />
                    ))}


                    {
                    (!lots || lots.length === 0) &&

                    <Container width="100%" height="75vh" sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Text color={theme.palette.grey[800]}>
                            Nenhum lote foi encontrado, tente modificar os filtros.
                        </Text>
                    </Container>
                    }
                    <Spacing top={PADDING} />
                    <Container height="auto" inline flex padded style={{ justifyContent: "flex-end", alignItems: "end"}}>
                        <Pagination count={Math.ceil(totalNumberOfLots/elementPerPage)}  size="large" sx={{
                            '& 	.MuiPagination-ul > li > .Mui-selected':{
                                'backgroundColor': `${theme.palette.primary.main}`,
                                'color': `${theme.palette.grey[100]}`
                            },
                            '& 	.MuiPagination-ul > li > button:hover':{
                                'backgroundColor': `${theme.palette.primary.main}`,
                                'color': `${theme.palette.grey[100]}`
                            }
                        }}
                        
                        onChange={onChangePage}
                        />
                    </Container>
                </Container>
                
            </Container>
            
        </Page>
        
    )
}

export default Lots;