import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fab, Grow, IconButton } from '@mui/material';
import { Search as SearchIcon, Store as StoreIcon, Delete as DeleteIcon, } from '@mui/icons-material';

import { BranchHasUserRegisterModal, Container, List, Page, Spacing, Text, theme } from '../../components';
import { useConfirmation } from '../../hooks/confirmation';
import { useInformation } from '../../hooks/information';
import { useBranchesHasUser } from '../../hooks/branchesHasUser';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts'
import { IListAction, IListHeader, IBranchHasUser, IBranch } from '../../types';
import Input from '../../components/atoms/input';
import { normalizeString } from '../../utils/stringFormatters';

const BranchesHasUser: FC = () => {
    const { showConfirmation, hideConfirmation } = useConfirmation();
    const { showInformation } = useInformation();
    const navigate = useNavigate();
    const { fetchBranchesHasUser, loading, paginate, branchesHasUser, branchesHasUserPerRow, setPaginate, deleteBranchHasUser } = useBranchesHasUser();

    const [branchHasUser, setCompanyToEdit] = useState<IBranchHasUser | undefined>(undefined);
    const [search, setSearch] = useState<string>('')
    const [newCompanyModalVisible, setNewCompanyModalVisible] = useState<boolean>(false);
    const [filteredBranchesHasUser, setFilteredBranchesHasUser] = useState<IBranchHasUser[]>([]);
    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        if (!loading) {
            fetchBranchesHasUser();
        }
    }, []);

    useEffect(() => {
        if (!newCompanyModalVisible)
            setCompanyToEdit(undefined);
    }, [newCompanyModalVisible]);

    useEffect(() => {
        filterBranchesHasUser(search);
    }, [branchesHasUser, branchesHasUserPerRow, paginate, search]);

    const filterBranchesHasUser = (_search: string = '') => {
        setFilteredBranchesHasUser([])
        let auxBranchesHasUser: IBranchHasUser[] = [];
        for (let i = 0; i < branchesHasUser.length; i++) {
            let data: IBranchHasUser = branchesHasUser[i];
            let branch: IBranch | undefined = data.branch;
            if (branch) {
                let aux: IBranchHasUser | undefined = auxBranchesHasUser.find(item =>
                    (item.userId == data.userId));
                if (aux && aux.branch) {
                    aux.branch.name += ', ' + branch.name;
                } else {
                    auxBranchesHasUser.push(data);
                }
            }

        }

        setTimeout(() => {
            if (_search != '') {
                auxBranchesHasUser = auxBranchesHasUser.filter((item: IBranchHasUser) =>
                    (item.branch && item.branch.name && normalizeString(item.branch.name).toLowerCase().includes(normalizeString(_search).toLowerCase())) ||
                    (item.user && item.user.firstName && normalizeString(item.user.firstName).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredBranchesHasUser([...auxBranchesHasUser])
        }, 0)
    }

    const headers: IListHeader[] = [
        { label: "Usuário", fieldName: "userName", xs: 3, isString: true, sortable: true },
        { label: "Fliais", fieldName: "branchName", xs: 8, isString: true, sortable: true },
        {
            label: "Ações", xs: 1,
            isString: true,
            showActionIfFieldIsNull: ' ',
            actions: (_id: string) => [
                <IconButton key={2} size='small' onClick={() => branchesHasUserListAction(_id, { type: 'delete' })} color="error" style={{ height: '18px', backgroundColor: 'transparent' }}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            ]
        }
    ];

    const editCompany = (_branchHasUserId: string) => {
        navigate(`/branches-has-users/${_branchHasUserId}`)
    }

    const onDeleteCompany = async (_branchHasUserId: string) => {
        await deleteBranchHasUser(_branchHasUserId);

        hideConfirmation();
        showInformation('Vínculo excluído com sucesso.', 'Aviso');
    }

    const branchesHasUserListAction = (_branchHasUserId: string, _actionType: IListAction) => {
        if (_actionType.type == 'delete') {
            showConfirmation("Deseja realmente excluir este vínculo?", "Excluir empresa", "Confirma", "Cancelar",
                () => {
                    onDeleteCompany(_branchHasUserId);
                },
                () => {
                    hideConfirmation();
                });
        }
        else if (_actionType.type == 'edit')
            editCompany(_branchHasUserId);
    }

    const convertDataToItem = (_data: any) => {
        return {
            "id": _data.id,
            "userName": _data.user.firstName,
            "branchName": _data.branch.name
        }
    }

    return (
        <Page
            width='auto'
            height='87%'
            color={theme.palette.background.default}>
            <Container height="auto"  fluid flex flexGrow color={theme.palette.background.default} width="100%">
                <Container fluid flex flexGrow padded verticalCentered color={theme.palette.gray.main} maxHeight="30px">
                    <Text bold size={15}>Acessos - Vínculo de empresa com usuário</Text>
                </Container>
                <Spacing top={VERY_PADDING} />
                <Container fluid flex centered width="100%">
                    <Container fluid width="95%">
                        <Text bold size={14} style={{ marginBottom: PADDING / 2 }}>Buscar empresa</Text>
                        <Container fluid flex>
                            <Input
                                id="search"
                                autoFocus
                                variant="outlined"
                                value={search}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                                placeholder=''
                                backgroundColor={theme.palette.background.default}
                                endAdornment={<SearchIcon color='primary' />}
                                sx={{
                                    height: '30px',
                                    backgroundColor: '#FFF',
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                    borderRadius: '4px'
                                }} />
                        </Container>
                        <Spacing top={PADDING} />
                    </Container>

                    <Container fluid hasShadow width="95%" height="100%" color={theme.palette.background.paper} borderRadius={BORDER_RADIUS}>
                        <Container fluid centered maxHeight="30px"  width="100%" height="30px" color={theme.palette.primary.main} borderTopRadius={BORDER_RADIUS}>
                            <Text semiBold size={15} color={theme.palette.background.paper}>Vínculos cadastrados</Text>
                        </Container>
                        <List
                            headers={headers}
                            data={filteredBranchesHasUser}
                            loading={loading}
                            total={branchesHasUser.length}
                            perPage={parseInt(branchesHasUserPerRow)}
                            paginate={paginate}
                            onPreviousPage={() => setPaginate(paginate - 1)}
                            onNextPage={() => setPaginate(paginate + 1)}
                            onAction={branchesHasUserListAction}
                            dataToItem={(_data: any) => {
                                return convertDataToItem(_data)
                            }}
                            onRefresh={() => {
                                fetchBranchesHasUser();
                            }}
                            hideSearch
                            search={search}
                            searchOnChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                            searchplaceholder=''
                        />
                    </Container>
                </Container>
            </Container>

            <Grow in={!fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/branches-has-users/register`)}
                    variant="circular"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    <StoreIcon />
                </Fab>
            </Grow>
            <Grow in={fabHover}>
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => navigate(`/branches-has-users/register`)}
                    variant="extended"
                    size="large"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        height: 56,
                        borderRadius: '28px'
                    }}
                    onMouseEnter={() => setFabHover(true)}
                    onMouseLeave={() => setFabHover(false)}>
                    {fabHover &&
                        <Container inline>
                            <Text size={14} color={theme.palette.background.paper}>Novo vínculo</Text>
                            <Spacing left={PADDING} />
                        </Container>}
                    <StoreIcon />
                </Fab>
            </Grow>

            <BranchHasUserRegisterModal
                branchHasUserToEdit={branchHasUser}
                open={newCompanyModalVisible}
                onClose={() => setNewCompanyModalVisible(false)}
                onCancel={() => setNewCompanyModalVisible(false)}
                onConfirm={(_informationTitle: string, _informationMessage: string) => {
                    showInformation(_informationMessage, _informationTitle);
                }} />
        </Page >
    )
}

export default BranchesHasUser;