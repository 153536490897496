import React, { FC } from 'react';
import { AppBar, Avatar, Box, Grow, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { Menu as MenuIcon, Settings as SettingsIcon, Person as PersonIcon } from '@mui/icons-material';

import Container from '../../atoms/container'

import { useNavigate } from 'react-router-dom';

import Image from '../../atoms/image'
import Spacing from '../../atoms/spacing'
import Text from '../../atoms/text'
import theme from '../../theme'
import logo from '../../../assets/logo.png'
import { useAuth } from '../../../hooks/auth'
import { useDrawer } from '../../../hooks/drawer'
import { PADDING, VERY_PADDING } from '../../../utils/consts';
import { API_URL } from '../../../services';

const AppHeader: FC = () => {
    const settingsId = 'settings-button';

    const { user, logout } = useAuth();
    const drawer = useDrawer();
    const navigate = useNavigate();

    const [anchorSettings, setAnchorSettings] = React.useState<null | HTMLElement>(null);
    const isSettingsOpen = Boolean(anchorSettings)
    const handleSettingsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorSettings(event.currentTarget);
    };

    const handleSettingsMenuClose = () => {
        setAnchorSettings(null);
    };

    return (
        <AppBar color="transparent" position="fixed" elevation={0} style={{ backgroundColor: theme.palette.background.paper, zIndex: 10 }}>
            <Toolbar variant="dense">
                <Image
                    source={logo}
                    height={50}
                    width={50 * 1.6666} />

                <Container fluid color={theme.palette.background.paper} centered borderRadius={10}>
                    <IconButton size="large" color="primary" style={{ backgroundColor: 'transparent' }}
                        onClick={() => drawer.setIsOpen(!drawer.isOpen)}>
                        <MenuIcon style={{ fontSize: 25 }} />
                    </IconButton>
                </Container>
                <Box sx={{ flexGrow: 1 }} />
                <Container inline centered>
                    {user && <Text size={16} color={theme.palette.primary.main}>Bem vindo(a), {user.firstName}</Text>}
                    <Spacing left={PADDING} />
                    <Avatar id={settingsId} style={{ backgroundColor: theme.palette.primary.main }} onClick={handleSettingsMenuOpen} sx={{ 'cursor': 'pointer' }}>
                        <PersonIcon style={{ fontSize: 25 }} />
                    </Avatar>
                </Container>
                <Menu
                    id={settingsId}
                    anchorEl={anchorSettings}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                    open={isSettingsOpen}
                    onClose={handleSettingsMenuClose}
                    onClick={handleSettingsMenuClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}>

                    <MenuItem onClick={() => navigate('/settings')}>
                        <Container fluid width='100%'>
                            <Text size={13}>Configurações</Text>
                        </Container>
                    </MenuItem>
                    <MenuItem onClick={logout}>
                        <Container fluid width='100%'>
                            <Text size={13}>Sair</Text>
                        </Container>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar >
    )
}

export default AppHeader;
