import React from 'react';
import { Page } from '../../components';
import { Box, Paper, Tabs, Tab, Typography, AppBar } from "@mui/material";
import MonitoringSettings from './monitoring'
import ProfileSettings from './profile'
import { useAuth } from '../../hooks/auth';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index} = props;
  
  return (
    <div
    role="settings-panel"
    hidden={value !== index}
    id="settings-panel"
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SettingsPanel() {
  const { hasRights } = useAuth();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Page>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          { 
            hasRights!("/settings@profile") &&
            <Tab label="Perfil" id="settings-profile-tab" />
          }
          {
            hasRights!("/settings@monitoring") && 
            <Tab label="Monitoramento" id="settings-monitoring-tab" />
          }
        </Tabs>
      </Paper>
      {/* Profile Page  */}
      {
      hasRights!("/settings@profile") &&
        <TabPanel value={value} index={0}>
          <ProfileSettings/>
        </TabPanel> 
      }
      {
      hasRights!("/settings@monitoring") &&       
      <TabPanel value={value} index={1}>
        <MonitoringSettings/>
      </TabPanel> 
      }
    </Page>
  );
}