import React, { FC, useEffect, useState } from "react";

import AnnotatorListItem from "../listItem";
import Container from "../../../atoms/container";
import Text from "../../../atoms/text";
import { Pagination } from "../../../../components";
import { useAnnotators } from "../../../../hooks/annotators";
import { useResponsive } from "../../../../hooks/responsive";
import { IAnnotator, IAnnotatorList } from "../../../../types";

const AnnotatorsList: FC<IAnnotatorList> = (params: IAnnotatorList) => {
    const annotatorsHooks = useAnnotators();
    const { screenHeight } = useResponsive();

    const pageCount = Math.ceil((screenHeight * 0.6) / 120);
    const annotatorsCount = annotatorsHooks.filteredAnnotators.length;

    const [currPage, setCurrPage] = useState<number>(0);

    useEffect(() => {
        setCurrPage(0);
    }, [annotatorsHooks.searchAnnotator]);

    return (
        <Container fluid flex width="100%" minHeight="73vh">
            <Container fluid width="100%" minHeight="73vh">
                {annotatorsHooks.filteredAnnotators.length == 0 && <Text center>Nenhum anotador encontrado.</Text>}
                {annotatorsHooks.filteredAnnotators.sort((a: IAnnotator, b: IAnnotator) => {
                    if (a.user && b.user && a.user.firstName > b.user.firstName) return 1;
                    if (a.user && b.user && a.user.firstName < b.user.firstName) return -1;
                    return 0;
                }).slice((currPage * pageCount), ((currPage * pageCount) + pageCount))
                    .map((item: IAnnotator, index: number) =>
                        <Container fluid verticalPadded width="100%" key={item.user?.email + String(index)}>
                            <AnnotatorListItem
                                annotator={item}
                                loading={annotatorsHooks.loading}
                                onOpenPermissions={params.onOpenPermissions}
                                onEditAnnotator={params.onEditAnnotator}
                                onDeleteAnnotator={params.onDeleteAnnotator} />
                        </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(annotatorsCount / pageCount)} setPage={setCurrPage} />
        </Container>
    );
};

export default AnnotatorsList;