import React, { FC } from 'react'
import { Autocomplete as MuiAutoComplete, TextField } from '@mui/material'

import Text from '../text';
import theme from '../../theme';
import { IAutocomplete } from '../../../types';

interface OptionType {
    label: string;
    id: string;
    isOptionEqualToValue?: boolean;
}

const Autocomplete: FC<IAutocomplete> = (params: IAutocomplete) => {
    const options: OptionType[] = params.options.map((_item: any) => {
        let label: string = 'Desconhecido';
        let id: string = '';
        if (_item.id) id = _item.id;
       
        //Se for uma branch e tiver company name, exibir o company name
        if (_item.company && _item.company.name && _item.name) label = `${_item.company.name} - ${_item.name}`;
        else if (_item.name) label = _item.name;
        else if (_item.description) label = _item.description;
        else if (_item.fullName) label = _item.fullName;
        else if (_item.path) label = _item.path;

        const option: OptionType = {
            label: label,
            id: id,
            isOptionEqualToValue: true
        };
        return option;
    });

    const onChange = (event: any, values: any) => {
        if (params.onChange)
            params.onChange(values);
    }

    return (
        <>
            <MuiAutoComplete
                id={params.id}
                freeSolo={!params.requiredExists}
                onChange={onChange}
                disableClearable
                placeholder={params.placeholder}
                options={options}
                disabled={params.disabled}
                defaultValue={{ label: params.defaultValue || '', id: params.value || '', isOptionEqualToValue: true }}
                inputValue={params.defaultValue}
                renderInput={(_params: any) => (
                    <TextField
                        {..._params}
                        id={params.id}
                        autoFocus={params.autoFocus}
                        required={params.required}
                        label={params.label}
                        placeholder={params.placeholder}
                        value={params.value}
                        error={params.error}
                        disabled={params.disabled}
                        onChange={params.onChange}
                        variant={params.variant || "outlined"}
                        size='small'
                        style={{
                            minHeight: params.multiline ? '50px' : 'auto',
                            ...params.sx,
                        }}
                        InputProps={{
                            ..._params.InputProps,
                            type: 'search',
                            startAdornment: params.startAdornment,
                            endAdornment: params.endAdornment,
                        }}
                    />
                )}
            />
            {params.error && <Text size={13} color={theme.palette.error.main} >{params.error}</Text>}
        </>
    )
}

export default Autocomplete;