import styled from "styled-components";

import { IText } from "../../../types";

const TextStyled = styled.span`
  color: #242424;

  color: ${(props: IText) => `${props.color}`};
  opacity: ${(props: IText) => props.muted && '60%'};
  font-size: ${(props: IText) => `${props.size}px`};

  font-weight: ${(props: IText) => props.thin && 100};
  font-weight: ${(props: IText) => props.extraLight && 200};
  font-weight: ${(props: IText) => props.light && 300};
  font-weight: ${(props: IText) => props.medium && 500};
  font-weight: ${(props: IText) => props.semiBold && 600};
  font-weight: ${(props: IText) => props.bold && 700};
  font-weight: ${(props: IText) => props.extraBold && 800};
  font-weight: ${(props: IText) => props.black && 900};
  text-align: ${(props: IText) => props.center && "center"};
  justify-content: ${(props: IText) => props.center && "center"};
  justify-content: ${(props: IText) => props.right && "right"};
  text-align: ${(props: IText) => props.right && "right"};

  text-transform: initial;
  display: flex;
`;

export default TextStyled;
