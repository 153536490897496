import React, { FC, useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Fab, Grid, Grow } from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'

import { Container, Page, RevisionFilter, RevisionContinuosGrid, Spacing, Text, theme, RevisionDownloadModal, RevisionAnnotationsFromIA } from '../../components'
import { useAnnotations } from '../../hooks/annotations';
import { useAuth } from '../../hooks/auth';
import { useInformation } from '../../hooks/information';
import { useRevisions } from '../../hooks/revisions';
import { IAnnotation, IAnnotator, IRevision, IRevisionFilter } from '../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../utils/consts';
import { useAnnotators } from '../../hooks/annotators';

const RevisionsFlow: FC = () => {
    const { user } = useAuth();
    const { fetchAnnotatorByUserId } = useAnnotators();
    const {
        annotationsForRevisions, fetchAnnotationsForRevisions,
        loadingAnnotationForRevisions
    } = useAnnotations();
    const {
        createNewRevision,
        filter, setFilter,
        revisionCount, fetchRevisionCount,
    } = useRevisions();
    const { hideInformation, showInformation } = useInformation();

    const [annotator, setAnnotator] = useState<IAnnotator | undefined>(undefined);
    const [filterIsOpen, setFilterIsOpen] = useState<boolean>(true);
    const [selectedAnnotations, setSelectedAnnotations] = useState<IAnnotation[]>([]);
    const [showContinuosGrid, setShowContinuosGrid] = useState<boolean>(true);
    const [showSeparatedClasses, setShowSeparatedClasses] = useState<boolean>(false);
    const [revisionDownloadIsOpen, setRevisionDownloadIsOpen] = useState<boolean>(false);
    const [annotationsFromIAIsOpen, setAnnotationsFromIAIsOpen] = useState<boolean>(false);

    const [fabHover, setFabHover] = useState<boolean>(false);

    useEffect(() => {
        fetchAnnotatorData();
        fetchAnnotationsForRevisions(filter);
    }, []);

    useEffect(() => {
        fetchRevisionCount(filter);
    }, []);

    const fetchAnnotatorData = async () => {
        if (user) {
            const userId: string = user.id;
            const auxAnnotator: IAnnotator | undefined = await fetchAnnotatorByUserId(userId);
            if (auxAnnotator) setAnnotator(auxAnnotator);
        }
    }

    const onSelectAnnotation = async (_annotation: IAnnotation) => {
        if (!selectedAnnotations.find(item => item.id == _annotation.id)) {
            setSelectedAnnotations([...selectedAnnotations, _annotation]);
        } else {
            const aux: IAnnotation[] = [];
            for (let i = 0; i < selectedAnnotations.length; i++) {
                if (selectedAnnotations[i].id == _annotation.id) {
                    if (selectedAnnotations[i].classificationId != _annotation.classificationId && _annotation.classificationId != -1)
                        aux.push(_annotation);
                } else
                    aux.push(selectedAnnotations[i]);
            }
            setSelectedAnnotations(aux);
        }
    }

    const setContinuosGridMode = () => {
        setSelectedAnnotations([]);
        setShowContinuosGrid(true);
        setShowSeparatedClasses(false);
    }

    const setSeparateClassesMode = () => {
        setSelectedAnnotations([]);
        setShowContinuosGrid(false);
        setShowSeparatedClasses(true);
    }

    const applyFilter = async (_filter: IRevisionFilter) => {
        setFilter(_filter);
        setSelectedAnnotations([]);
        fetchAnnotationsForRevisions(_filter);
        fetchRevisionCount(_filter)
    }

    const selectAllAnnotations = async () => {
        const aux: IAnnotation[] = [...selectedAnnotations];

        for (let i = 0; i < annotationsForRevisions.length; i++) {
            const annotation: IAnnotation = annotationsForRevisions[i];
            if (selectedAnnotations.find(item => item.id == annotation.id))
                continue;
            aux.push(annotation);
        }
        setSelectedAnnotations(aux);
    }

    const sendRevisions = async () => {
        if (!annotator) return;
        if (selectedAnnotations.length == 0) {
            showInformation('Selecione pelo menos uma anotação.', 'Aviso', undefined, () => {
                hideInformation();
            })
            return;
        }

        for (let i = 0; i < selectedAnnotations.length; i++) {
            if (!selectedAnnotations[i].id) continue;

            const revision: IRevision = {
                annotatorId: annotator.id,
                annotationId: selectedAnnotations[i].id,
                classificationId: selectedAnnotations[i].classificationId
            }
            await createNewRevision(revision, false);
        }

        showInformation('Revisões criadas com sucesso.', 'Aviso', undefined, () => {
            hideInformation();
            fetchAnnotationsForRevisions(filter);
            fetchRevisionCount(filter);
            setSelectedAnnotations([]);
        });
    }

    const renderRevisionStats = () => {
        return (
            <Container style={{ paddingLeft: 20 }}>

                <Grid width="100%">
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                        <Text bold>Não revisadas: </Text>
                        <Text bold >{revisionCount.countUnrevised}</Text>
                        <Spacing left={PADDING * 3} />
                        <Text bold >Revisadas: </Text>
                        <Text bold >{revisionCount.countRevised}</Text>
                        <Spacing left={PADDING * 3} />
                        <Text bold >Total: </Text>
                        <Text bold>{revisionCount.countRevised + revisionCount.countUnrevised}</Text>

                    </Grid>
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                    </Grid>
                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-start', paddingRight: PADDING }}>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    return (
        <Page width='100%' height='auto' style={{ minHeight: '95vh', }}>
            <Container fluid color={theme.palette.background.default} width="100%" minHeight="95vh">
                <Container inline flex spacedBetween horizontalCentered padded color={theme.palette.gray.main} maxHeight="25px">
                    <Text bold size={15}>Revisões</Text>
                </Container>

                <Container inline width="100%" height="auto">
                    <RevisionFilter
                        filter={filter}
                        onApplyFilter={applyFilter}
                        onCleanFilter={() => setFilter({ initialDate: new Date(), finalDate: new Date(), annotators: [], branches: [], classificationsId: [], iaClassificationsId: [], limit: 10 })}
                        onDownloadData={() => setRevisionDownloadIsOpen(true)}
                        onAnnotationsFromIA={() => setAnnotationsFromIAIsOpen(true)}
                    />
                </Container>
                {renderRevisionStats()}
                {annotationsForRevisions.length == 0 &&
                    <Container fluid centered height="450px">
                        <Container fluid centered width="500px" height="450px">
                            <Text bold center size={20}>Nenhuma anotação foi encontrada no filtro selecionado.</Text>
                        </Container>
                    </Container>
                }
                {annotationsForRevisions.length > 0 &&
                    <Container fluid flex horizontalCentered color='#000' width="100%" height="100%">
                        <Container fluid flex width="99%">
                            <Container inline centered spacedBetween width="100%" sx={{ marginTop: PADDING }}>
                                <Container inline>
                                    <Spacing left={PADDING} />
                                    <Text size={13} color={theme.palette.background.paper}>Anotações selecionadas: {selectedAnnotations.length}</Text>
                                </Container>
                                <Container inline>
                                    <Button size="small" variant="contained" color="info" onClick={() => selectAllAnnotations()}>
                                        <Text medium size={13} color={theme.palette.background.paper}>Selecionar todas</Text>
                                    </Button>
                                    <Spacing left={PADDING} />
                                    <Button size="small" variant="contained" color="info" onClick={() => setSelectedAnnotations([])}>
                                        <Text medium size={13} color={theme.palette.background.paper}>Selecionar nenhuma</Text>
                                    </Button>
                                </Container>
                            </Container>
                            <RevisionContinuosGrid
                                fromIA={filter.annotators.length == 0 ? true : false}
                                annotations={annotationsForRevisions}
                                selectedAnnotations={selectedAnnotations}
                                onSelectAnnotation={(auxAnnotation: IAnnotation) => onSelectAnnotation(auxAnnotation)}
                            />
                        </Container>
                    </Container>
                }

                <Grow in={!fabHover}>
                    <Fab
                        onClick={() => sendRevisions()}
                        variant="circular"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        <CheckIcon sx={{ color: theme.palette.background.paper }} />
                    </Fab>
                </Grow>
                <Grow in={fabHover}>
                    <Fab
                        onClick={() => sendRevisions()}
                        variant="extended"
                        size="large"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 16,
                            height: 56,
                            borderRadius: '28px',
                            zIndex: 100,
                        }}
                        onMouseEnter={() => setFabHover(true)}
                        onMouseLeave={() => setFabHover(false)}>
                        {fabHover &&
                            <Container inline>
                                <Text size={14} color={theme.palette.background.paper}>Enviar revisão</Text>
                                <Spacing left={PADDING} />
                            </Container>}
                        <CheckIcon sx={{ color: theme.palette.background.paper }} />
                    </Fab>
                </Grow>
            </Container>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingAnnotationForRevisions}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <RevisionDownloadModal
                open={revisionDownloadIsOpen}
                onClose={() => setRevisionDownloadIsOpen(!revisionDownloadIsOpen)}
            />
            <RevisionAnnotationsFromIA
                open={annotationsFromIAIsOpen}
                onClose={() => setAnnotationsFromIAIsOpen(!annotationsFromIAIsOpen)}
            />
        </Page >
    )
}

export default RevisionsFlow;