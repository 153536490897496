import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PasswordRecoveryRealmsRedirect = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams) {
            const key = searchParams.get("key")
            navigate(`/password-recovery/key/${key}`);
        }
        else
            navigate("/");
    }, []);
    return (
        <></>
    );
}

export default PasswordRecoveryRealmsRedirect;