import { theme } from '../../components'

export const WEIGHT_RANGE_EQUIVALENCE = [
  { arroba: "<12,3", quilos: "<184.5kg" },
  { arroba: "<12,3-15", quilos: "<184.5kg-225kg" },
  { arroba: "<15-18", quilos: "<225kg-270kg" },
  { arroba: "<18-21", quilos: "<270kg-315kg" },
  { arroba: "<21-23", quilos: "<315kg-345kg" },
  { arroba: ">23", quilos: ">345kg" },
];
export const COLORS = [theme.palette.primary.main, '#875352', '#FFAE00', '#FF8042', '#E58A00', '#4D5A80', '#009966'];

export interface IDashboardPage {
  filterExpanded: boolean;
  setFilterExpanded: any;
  isKgChecked: boolean;
  setIsKgChecked: any;
  weightUnitLabel: string;
  setWeightUnitLabel: any;
}