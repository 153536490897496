import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Divider, IconButton } from "@mui/material";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Spacing from "../../../atoms/spacing";
import Text from "../../../atoms/text";
import theme from "../../../theme";
import { useAnnotators } from "../../../../hooks/annotators";
import { IAnnotator, IAnnotatorMultiSelect } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import { normalizeString } from "../../../../utils/stringFormatters";

const AnnotatorsMultiSelect: FC<IAnnotatorMultiSelect> = (params: IAnnotatorMultiSelect) => {
    const { annotators, fetchAnnotators } = useAnnotators();

    const [search, setSearch] = useState<string>('')
    const [filteredAnnotators, setFilteredAnnotators] = useState<IAnnotator[]>([]);

    useEffect(() => {
        fetchAnnotators();
    }, []);

    useEffect(() => {
        setFilteredAnnotators([...annotators]);
    }, [annotators]);

    useEffect(() => {
        filterAnnotators(search);
    }, [search]);

    const filterAnnotators = (_search: string) => {
        setFilteredAnnotators([])
        let auxAnnotators = [...annotators]

        setTimeout(() => {
            if (_search != '') {
                auxAnnotators = auxAnnotators.filter((item: IAnnotator) =>
                    (item.user && item.user.firstName && normalizeString(item.user.firstName).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredAnnotators([...auxAnnotators])
        }, 0)
    }

    const handleSelectAnnotator = (_annotator: IAnnotator, _isChecked: boolean) => {
        const auxAnnotators: IAnnotator[] = [];
        for (let i = 0; i < params.selectedAnnotators.length; i++) {
            const auxAnnotator: IAnnotator = params.selectedAnnotators[i];
            if (auxAnnotator.id == _annotator.id && !_isChecked) continue;
            auxAnnotators.push(auxAnnotator);
        }
        if (_isChecked) auxAnnotators.push(_annotator);

        params.setSelectedAnnotators([...auxAnnotators]);
    }

    const renderAnnotatorItem = (_annotator: IAnnotator, key: number) => {
        const isChecked: boolean = params.selectedAnnotators.find(item => item.id == _annotator.id) != undefined;

        return (
            <Container key={key} className="container-hover" inline spacedBetween horizontalCentered width="100%"
                onClick={() => handleSelectAnnotator(_annotator, !isChecked)}
                sx={{ cursor: 'pointer' }}>
                <Checkbox size="small" checked={isChecked} />
                <Container fluid flexStart flex>
                    {_annotator.user && <Text>{_annotator.user.firstName}</Text>}
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth="130px" height="30px">
            <Container inline horizontalCentered spacedBetween minWidth="130px" height="30px" onClick={() => params.setExpanded(!params.expanded)}
                style={{ cursor: 'pointer', paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedAnnotators.length == 0 && <Text size={15}>Anotadores</Text>}
                {params.selectedAnnotators.length > 0 && <Text bold size={14}>Anotadores +{params.selectedAnnotators.length}</Text>}
                <IconButton style={{ backgroundColor: 'transparent' }}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Collapse in={params.expanded} timeout="auto" unmountOnExit>
                <Container fluid absolute hasShadow padded borderRadius={BORDER_RADIUS} color={theme.palette.background.paper} sx={{ zIndex: 100, marginTop: 10 }}>
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder='Pesquisar anotador'
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color='primary' />}
                        sx={{ height: '35px' }} />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: 'auto' }}>
                        {filteredAnnotators.map((item, index) => renderAnnotatorItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSelectedAnnotators([])}>
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Collapse>
        </Container>
    );
}

export default AnnotatorsMultiSelect