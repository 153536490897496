import React, { FC, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, SelectChangeEvent } from '@mui/material';
import { Close as CloseIcon, PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';


import BranchSelect from '../../companies/select';
import PrivilegeSelect from '../../privileges/select';
import Container from '../../../atoms/container';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import Input from '../../../atoms/input';
import theme from '../../../theme';
import { useAuth } from '../../../../hooks/auth';
import { useCompanies } from '../../../../hooks/companies';
import { useBranchesHasUser } from '../../../../hooks/branchesHasUser';
import { usePrivileges } from '../../../../hooks/privileges';
import { useUsers } from '../../../../hooks/users';
import { IBranchHasUser, IPrivilege, IUser, IUserRegisterModal } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';

const UserRegisterModal: FC<IUserRegisterModal> = (params: IUserRegisterModal) => {
    const isEdit = params.userToEdit != undefined;

    const { isSuperAdmin } = useAuth();
    const { privileges, fetchPrivileges } = usePrivileges();
    const { fetchCompanies } = useCompanies();
    const { createNewBranchHasUser } = useBranchesHasUser();
    const { createNewUser, editUser, createLinkWithPrivilege, fetchUsers } = useUsers();

    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | null>(null);
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [privilege, setPrivilege] = useState<string>('');
    const [privilegeDescription, setPrivilegeDescription] = useState<string>('');
    const [privilegeError, setPrivilegeError] = useState<string | null>(null);
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchError, setBranchError] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        fetchCompanies();
    }, []);

    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(null);
        setError(null);
    }
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(null);
        setError(null);
    }
    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setError(null);
    }

    const handleChangePrivilege = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setPrivilege((event as any).target.value);
            setPrivilegeDescription('');
        } else {
            if ((event as any).id) {
                setPrivilege((event as any).id);
            }
            if ((event as any).label) {
                setPrivilegeDescription((event as any).label);
            }
        }
        setPrivilegeError(null);
        setError(null);
    }
    const handleChangeBranch = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setBranchId((event as any).target.value);
            setBranchName('');
        } else {
            if ((event as any).id) {
                setBranchId((event as any).id);
            }
            if ((event as any).label) {
                setBranchName((event as any).label);
            }
        }
        setError(null);
    }

    function validate() {
        let isValid = true;
        if (name == '') {
            setNameError('Informe o nome completo do usuário');
            isValid = false;
        }
        if (email == '') {
            setEmailError('Informe o email do usuário');
            isValid = false;
        }
        if (!isEdit && branchId == '') {
            setBranchError('Selecione a filial');
            isValid = false;
        }



        if (isValid) {
            setNameError(null);
            setEmailError(null);
            setPrivilegeError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let userId: string = '';
            // let payload: IUser = {
            //     name,
            //     email: email.trim(),
            //     password,
            //     status: 1
            // };
            // if (isEdit && params.userToEdit && params.userToEdit.id) {
            //     const editedUser: IUser = await editUser(params.userToEdit.id, payload);
            //     if (editedUser.id) userId = editedUser.id;
            // } else {
            //     const newUser: IUser = await createNewUser(payload, false);
            //     if (newUser.id) userId = newUser.id;
            // }

            // if (!isEdit) {
            //     let payloadBranchHasUser: IBranchHasUser = {
            //         userId,
            //         branchId
            //     };
            //     await createNewBranchHasUser(payloadBranchHasUser, true);
            // }
            // if (!isEdit || (params.userToEdit?.privilege && params.userToEdit?.privilege.id != privilege))
            //     await createLinkWithPrivilege(userId, privilege);

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!isEdit) informationMessage = 'O novo usuário foi cadastrado com sucesso.';
            setTimeout(() => {
                fetchUsers();
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setName('');
        setNameError(null);
        setEmail('');
        setEmailError(null);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid relative hasShadow centered color={theme.palette.background.default} maxWidth="800px">
                <Container inline centered width="100%" color={theme.palette.background.default} borderTopRadius={BORDER_RADIUS}>
                    <Container fluid flex veryPadded>
                        <Text size={30} thin color={theme.palette.primary.main}>{!isEdit ? 'Novo usuário' : 'Editar usuário'}</Text>
                    </Container>

                    <Container absolute sx={{ top: 0, right: 0 }}>
                        <IconButton style={{}} onClick={close}>
                            <CloseIcon style={{ color: theme.palette.primary.light }} />
                        </IconButton>
                    </Container>
                </Container>
                <Divider sx={{ width: "95%" }} />
                <Spacing top={VERY_PADDING} />
                <Container fluid flex flexGrow width="95%" minHeight="100px">
                    <Grid container rowSpacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Nome:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="name"
                                    autoFocus
                                    required
                                    variant="outlined"
                                    value={name}
                                    onChange={handleChangeName}
                                    placeholder='Informe o nome do usuário'
                                    error={nameError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {nameError && <Text size={13} color={theme.palette.error.main} >{nameError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>E-mail:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <Input
                                    id="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={handleChangeEmail}
                                    placeholder='Informe o e-mail do usuário'
                                    error={emailError != null}
                                    backgroundColor={theme.palette.grayLight.light}
                                    disabled={loading} />
                                {emailError && <Text size={13} color={theme.palette.error.main} >{emailError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2} alignItems="center" style={{ display: 'flex' }}>
                            <Text light size={15} color={theme.palette.grey[600]} style={{ marginBottom: 15 }}>Senha:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width="100%">
                                <Input
                                    id="passw"
                                    required
                                    value={password}
                                    autoComplete={false}
                                    onChange={handleChangePassword}
                                    variant="outlined"
                                    placeholder='Insira a senha de acesso do usuário'
                                    backgroundColor={theme.palette.grayLight.light}
                                    isPassword
                                    showPassword={showPassword}
                                    handleClickShowPassword={() => setShowPassword(!showPassword)}
                                    disabled={loading} />
                                {params.userToEdit != undefined && <Text size={13} color={theme.palette.error.main} style={{ paddingTop: PADDING / 2 }}>Editando a senha, o usuário perderá o acesso.</Text>}
                                {params.userToEdit == undefined && password == '' && <Text light size={13} color={theme.palette.info.main} style={{ paddingTop: PADDING / 2 }}>Será enviada uma senha aleatória para o e-mail {email == '' ? 'informado' : email}</Text>}
                            </Container>
                        </Grid>
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.info.main, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default UserRegisterModal;