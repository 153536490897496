import { FC, useEffect, useState } from 'react';
import { Grid, Pagination } from '@mui/material';

import 'react-image-lightbox/style.css';

import Container from '../../../atoms/container';
import { Spacing, theme, Text } from '../../../../components'
import LotCarcasseCard from '../lotCarcasseCard';
import { ICarcass } from '../../../../types';
import { BORDER_RADIUS, PADDING } from '../../../../utils/consts';

interface LotBodyProps {
    carcasses: ICarcass[];
    setCarcasses: (currPage: number, carcassesPerPage: number) => void;
    sequencesCount: number | undefined;
}

const LotBody: FC<LotBodyProps> = ({ carcasses, setCarcasses, sequencesCount }) => {
    const [carcassesCurrentPage, setCarcassesCurrentPage] = useState<number>(1);
    const [carcassesPerPage] = useState<number>(4);

    useEffect(() => {
        setCarcasses(carcassesCurrentPage, carcassesPerPage);
    }, [carcassesCurrentPage]);

    const onChangePage = (event: any, newPage: number) => {
        setCarcassesCurrentPage(newPage);
    };

    const renderCarcasseCards = () => {
        const groupedCarcasses: ICarcass[][] = [];

        carcasses.forEach((carcass) => {
          const lastGroup = groupedCarcasses.length ? groupedCarcasses[groupedCarcasses.length - 1] : null;
          if (lastGroup && lastGroup[0].sequence === carcass.sequence && lastGroup.length < 2) {
            lastGroup.push(carcass);
          } else {
            groupedCarcasses.push([carcass]);
          }
        });
        return groupedCarcasses.map((group, index) => (
            <Grid key={index} item xs={12} sm={12} md={12} lg={8} xl={6} style={{justifyContent: 'center'}}>
                <LotCarcasseCard carcass={group[0]} carcass2={group[1]} />
            </Grid>
        ));
      };

    return (
        <Container fluid centered width="99%" maxHeight="auto" minHeight="30vh" padded color="white" borderRadius={BORDER_RADIUS} sx={{padding: 30}}>
            {
                (carcasses && carcasses.length > 0) &&
                <>
                    <Grid container spacing={4} >
                        {renderCarcasseCards()}
                    </Grid>
                    <Spacing top={PADDING} />
                    <Container height="auto" inline flex padded style={{ justifyContent: "flex-end", alignItems: "end"}}>
                        <Pagination count={sequencesCount ? Math.ceil(sequencesCount / carcassesPerPage) : 6}  size="large" sx={{
                            '& 	.MuiPagination-ul > li > .Mui-selected':{
                                'backgroundColor': `${theme.palette.primary.main}`,
                                'color': `${theme.palette.grey[100]}`
                            },
                            '& 	.MuiPagination-ul > li > button:hover':{
                                'backgroundColor': `${theme.palette.primary.main}`,
                                'color': `${theme.palette.grey[100]}`
                            }
                        }}
                        
                        onChange={(event: any, newPage: number) => onChangePage(event, newPage)}
                        />
                    </Container>
                </>
            }

            {
                (!carcasses || carcasses.length === 0) &&
                <Text color={theme.palette.grey[800]}>Procurando carcaças para esse lote, caso essa mensagem persista, tente novamente.</Text>
            }
         </Container>
    );
}

export default LotBody;
