import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Divider, IconButton, Menu } from "@mui/material";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Search as SearchIcon } from "@mui/icons-material";

import Input from "../../input";
import Container from "../../../atoms/container";
import Text from "../../../atoms/text";
import { useCarcassesLocalClassifications } from "../../../../hooks/carcassesLocalClassifications";
import { ICarcassesLocalClassificationsMultiSelect, ICarcassLocalClassification } from "../../../../types";
import { BORDER_RADIUS, PADDING } from "../../../../utils/consts";
import theme from "../../../theme";
import Spacing from "../../../atoms/spacing";
import { normalizeString } from "../../../../utils/stringFormatters";

const CarcassesLocalClassificationsMultiSelect: FC<ICarcassesLocalClassificationsMultiSelect> = (params: ICarcassesLocalClassificationsMultiSelect) => {
    const optionsId = "options-button";

    const { carcassesLocalClassifications, fetchCarcassesLocalClassifications } = useCarcassesLocalClassifications();

    const [search, setSearch] = useState<string>("")
    const [possibleClassifications, setPossibleClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [filteredClassifications, setFilteredClassifications] = useState<ICarcassLocalClassification[]>([]);
    const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
    const isOptionsOpen = Boolean(anchorOptions)

    useEffect(() => {
        fetchCarcassesLocalClassifications();
    }, []);

    useEffect(() => {
        if (params.selectedBranchesId.length == 0) {
            setPossibleClassifications([]);
            setFilteredClassifications([]);
        } else {
            if (carcassesLocalClassifications.length > 0) {
                let auxClassifications = [...carcassesLocalClassifications.filter(
                    item => params.selectedBranchesId.includes(item.branchId)
                )]
                auxClassifications.sort((a, b) => a.localId - b.localId);
                setPossibleClassifications(auxClassifications);
                setFilteredClassifications(auxClassifications);
            } else {
                setPossibleClassifications([]);
                setFilteredClassifications([]);
            }
        }

        params.setSelectedClassifications([]);
    }, [...params.selectedBranchesId, ...carcassesLocalClassifications]);

    useEffect(() => {
        setFilteredClassifications([...possibleClassifications]);
    }, [...possibleClassifications]);

    useEffect(() => {
        filterClassifications(search);
    }, [search]);

    const handleOptionsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorOptions(event.currentTarget);
    };

    const handleOptionsMenuClose = () => {
        setAnchorOptions(null);
    };

    const filterClassifications = (_search: string) => {
        setFilteredClassifications([])
        let auxClassifications = [...possibleClassifications]

        setTimeout(() => {
            if (_search != "") {
                auxClassifications = auxClassifications.filter((item: ICarcassLocalClassification) =>
                    (item.name && normalizeString(item.name).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredClassifications([...auxClassifications])
        }, 0)
    }

    const handleSelectClassification = (_classification: ICarcassLocalClassification, _isChecked: boolean) => {
        if (params.singleSelect) {
            params.setSelectedClassifications([_classification]);
            return;
        }

        const auxClassifications: ICarcassLocalClassification[] = [];
        for (let i = 0; i < params.selectedClassifications.length; i++) {
            const auxClassification: ICarcassLocalClassification = params.selectedClassifications[i];
            if (auxClassification.name == _classification.name && !_isChecked) continue;
            auxClassifications.push(auxClassification);
        }
        if (_isChecked) auxClassifications.push(_classification);

        params.setSelectedClassifications([...auxClassifications]);
    }

    const renderClassificationItem = (_classification: ICarcassLocalClassification, key: number) => {
        const isChecked: boolean = params.selectedClassifications.find(item => item.id == _classification.id) != undefined;

        return (
            <Container key={key} inline spacedBetween horizontalCentered width="100%"
                onClick={() => handleSelectClassification(_classification, !isChecked)}
                sx={{ cursor: "pointer" }}>
                <Checkbox size="small" checked={isChecked} />
                <Container fluid flexStart flex>
                    <Text>{_classification.name}</Text>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid minWidth={"170px"} height="30px">
            <Container inline horizontalCentered spacedBetween minWidth="130px" height="30px" onClick={handleOptionsMenuOpen}
                style={{ cursor: "pointer", paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedClassifications.length == 0 && <Text size={15}>{params.placeholder}</Text>}
                {params.selectedClassifications.length > 0 &&
                    <Text bold size={14}>
                        {`${params.selectedClassifications[0].name} ${!params.singleSelect && params.selectedClassifications.length > 1 ? " +" + (params.selectedClassifications.length - 1) : ""}`}
                    </Text>
                }
                <IconButton style={{ backgroundColor: "transparent" }}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Menu
                id={optionsId}
                anchorEl={anchorOptions}
                anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                transformOrigin={{ vertical: "top", horizontal: "right", }}
                open={isOptionsOpen}
                onClose={handleOptionsMenuClose}>
                <Container fluid padded maxWidth="250px" borderRadius={BORDER_RADIUS} >
                    <Input
                        id="search"
                        autoFocus
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder="Pesquisar classificação"
                        backgroundColor={theme.palette.background.default}
                        endAdornment={<SearchIcon color="primary" />}
                        sx={{ height: "35px" }} />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: "auto" }}>
                        {params.selectedBranchesId.length == 0 && filteredClassifications.length == 0 &&
                            <Text>Por favor, selecione pelo menos uma empresa.</Text>
                        }
                        {params.selectedBranchesId.length > 0 && filteredClassifications.length == 0 &&
                            <Text>Não foi encontrado nenhuma classificação para as empresas selecionadas.</Text>
                        }
                        {filteredClassifications.map((item, index) => renderClassificationItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button startIcon={<CloseIcon style={{ color: theme.palette.text.primary }} />}
                            onClick={() => params.setSelectedClassifications([])}>
                            <Text size={15}>Limpar</Text>
                        </Button>
                    </Container>
                </Container>
            </Menu>
        </Container>
    );
}

export default CarcassesLocalClassificationsMultiSelect;