import React, { FC, useState } from 'react'
import { Button, Grid } from '@mui/material'

import Container from '../../atoms/container'
import Input from '../../atoms/input';
import Text from '../../atoms/text'
import theme from '../../theme'

import { ILotsFilterParams, ILotsFilter, IBranch } from '../../../types'
import BranchesMultiSelect from '../branches/multiSelect'
import DateRangePicker from "../../atoms/dateRangePicker";
import { useLots } from '../../../hooks/lots';

const LotsFilter: FC<ILotsFilter> = (params: ILotsFilter) => {
    const { filter } = useLots();
    const [initialDate, setInitialDate] = useState<Date | null>(filter.initialDate);
    const [finalDate, setFinalDate] = useState<Date | null>(filter.finalDate);
    const [selectedBranches, setSelectedBranches] = useState<IBranch[]>(filter.branches);
    const [farms, setFarms] = useState<string[] | null>(filter.farms);
    const [docNums, setDocumentNumbers] = useState<string[] | null>(filter.docNums);
    const [branchesExpanded, setBranchesExpanded] = useState<boolean>(false);

    const onApply = () => {
        setBranchesExpanded(false);
        const filterParams: ILotsFilterParams = {
            initialDate,
            finalDate,
            branches: selectedBranches,
            docNums: docNums,
            farms: farms,
            lotNums: null,
            dentitions: null,
            weightIntervals: null,
            sexes: null,
        };

        setTimeout(() => {
            params.onApply(filterParams);
        }, 300);
    }

    const onChangeFarms = (event: React.ChangeEvent<HTMLInputElement>) => {
        let farms: string = event.target.value;
        let farmsList: string[] = farms.split(",");
        let trimmedList = farmsList
            .map(item => item.trim())
            .filter(item => item !== '');
        setFarms(trimmedList);
    }

    const onChangeContract = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = event.target.value.replace(/[^0-9,]/g, '');
        let contracts: string = event.target.value;
        let contractsList: string[] = contracts.split(",");
        let trimmedList = contractsList
            .map(item => item.trim())
            .filter(item => item !== '');
        setDocumentNumbers(trimmedList);     
    }

    return (
        <Container fluid flex>
            <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                <Grid item xs={3} sm={5} md={2} lg={2} xl={2}>
                <DateRangePicker
                            initialDate={initialDate}
                            finalDate={finalDate}
                            placeholder={"Escolha o intervalo de data"}
                            setInitialDate={setInitialDate}
                            setFinalDate={setFinalDate}
                            disabled={false}
                            maxDaysSelect={0}
                            sx={{
                                backgroundColor: '#FFF',
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                                borderRadius: '4px',
                                height: '37px',
                            }}
                        />
                </Grid>
                <Grid item xs={5} sm={5} lg={2} xl={2}>
                    <BranchesMultiSelect
                        selectedBranches={selectedBranches}
                        setSelectedBranches={setSelectedBranches}
                        expanded={branchesExpanded}
                        setExpanded={setBranchesExpanded}
                        sx={{
                            backgroundColor: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
                            borderRadius: '4px',
                            height: '37px',
                            marginTop: -5
                        }}
                    />
                </Grid>
                <Grid item xs={5} sm={5} lg={2} xl={2}>
                    <Input
                        id="farms"
                        autoFocus
                        variant="outlined"
                        onChange={onChangeFarms}
                        placeholder='Nome da fazenda'
                        backgroundColor={theme.palette.background.default}
                        sx={{
                            height: '37px',
                            backgroundColor: '#FFF',                                        
                            boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                            borderRadius: '4px',
                            width: '100%',
                        }} />
                </Grid>
                <Grid item xs={5} sm={5} lg={2} xl={2}>
                    <Input
                        id="docNums"
                        autoFocus
                        variant="outlined"
                        onChange={onChangeContract}
                        placeholder='Número do contrato'
                        backgroundColor={theme.palette.background.default}
                        sx={{
                            height: '37px',
                            backgroundColor: '#FFF',                                        
                            boxShadow: 'rgba(0, 0, 0, 0.07) 0px 3px 8px',
                            borderRadius: '4px',
                            width: '100%',
                        }} />
                </Grid>
                <Grid item xs={2} sm={2} lg={2} xl={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => onApply()}><Text size={12} color={theme.palette.background.paper}>Aplicar</Text>
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default LotsFilter