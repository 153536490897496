import React, { FC, createContext, useState, useContext } from "react";
import moment from "moment";

import { theme } from "../components";
import {
    IAnnotationFilter,
    ICarcass,
    ICarcassClassification,
    ICarcassContext,
    ICarcassFilterParams,
    ICarcassLocalClassification,
    ICompany,
    IVerdictFilter,
} from "../types";
import {
    fetchCarcassesGroupSides,
    getCarcassesDownload,
    getCarcassesDownloadFilePath,
    getCarcassesForVerdicts,
    getCarcassForAnnotation,
    getCarcassesSheet,
} from "../services/carcasses";

const CarcassContext = createContext<ICarcassContext>({} as ICarcassContext);

export const CarcassProvider: FC<any> = ({ children }) => {
    const [carcasses, setCarcasses] = useState<ICarcass[]>([]);

    const [paginate, setPaginate] = useState<number>(0);
    const [carcassesPerRow, setCarcassesPerRow] = useState<string>("15");
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<ICarcassFilterParams>({
        initialDate: moment().toDate(),
        finalDate: moment().toDate(),
        branchId: null,
        docNums: [],
        farms: [],
        lotNums: [], 
    });
    const [classifications, setClassifications] = useState<
        ICarcassClassification[]
    >(defaultClassifications);

    const [carcassesForVerdicts, setCarcassesForVerdicts] = useState<ICarcass[]>([]);
    const [loadingCarcassesForVerdicts, setLoadingCarcassesForVerdicts] = useState<boolean>(false);

    const fetchCarcasses = async (_filter: ICarcassFilterParams) => {
        setLoading(true);
        setCarcasses([]);

        const carcassses: ICarcass[] = await fetchCarcassesGroupSides(_filter);
        setCarcasses(carcassses);
        setFilter(_filter);

        setTimeout(() => {
            setCarcasses([...carcassses]);

            setPaginate(0);
        }, 300);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const fetchCarcassForAnnotation = async (_filter: IAnnotationFilter) => {
        let query: string = `?initial_date=${moment(_filter.initialDate).format("YYYY-MM-DD")}`;
        query += `&final_date=${moment(_filter.finalDate).format("YYYY-MM-DD")}`;
        if (_filter.branches.length > 0)
            query += `&branches_id=${_filter.branches.map((item) => `'${item.id}'`).join(",")}`;
        if (_filter.classifications.length > 0)
            query += `&local_classifications_id=${_filter.classifications.map(item => `'${item.id}'`).join(',')}`;
        if (_filter.iaClassifications.length > 0)
            query += `&classifications=${_filter.iaClassifications.map(item => `'${item.localId}'`).join(',')}`;
        if (_filter.onlyBestPlaced)
            query += `&best_placed=1`;
        if (_filter.onlyWithPartnerId)
            query += `&with_partner_id=1`;

        const carcass: ICarcass | null = await getCarcassForAnnotation(query);
        return carcass;
    };

    const downloadCarcasses = async (
        _date: Date,
        _companyId: string,
        _classificationsId: ICarcassClassification[],
        _onlyBestPlaced: boolean,
        _onlyWithPartnerId: boolean
    ) => {
        try {
            let query: string = `?date=${moment(_date).format("YYYY-MM-DD")}`;
            query += `&branchId=${_companyId}`;
            if (_classificationsId.length > 0)
                query += `&classifications=${_classificationsId
                    .map((item) => item.id)
                    .join(",")}`;
            if (_onlyBestPlaced)
                query += `&best_placed=1`;
            if (_onlyWithPartnerId)
                query += `&only_with_partner_id=1`;

            const responseDownload: any = await getCarcassesDownload(query);
            return responseDownload;
        } catch (err) {
            throw err;
        }
    };

    const downloadCarcassesFilePath = async (_filePath: string) => {
        try {
            const responseDownload: any = await getCarcassesDownloadFilePath(_filePath);
            return responseDownload;
        } catch (err) {
            throw err;
        }
    };

    const fetchCarcassesForVerdicts = async (_filter: IVerdictFilter) => {
        setLoadingCarcassesForVerdicts(true);
        try {
            let query: string = `?initial_date=${moment(_filter.initialDate).format('YYYY-MM-DD')}`;
            query += `&final_date=${moment(_filter.finalDate).format('YYYY-MM-DD')}`;
            if (_filter.branches.length > 0)
                query += `&branches_id=${_filter.branches.map(item => `'${item.id}'`).join(',')}`;
            if (_filter.classificationsId.length > 0)
                query += `&classifications=${_filter.classificationsId.map(item => item.id).join(',')}`;
            query += `&limit=${_filter.limit}`;

            setCarcassesForVerdicts([]);
            const revisionsForVerdicts: ICarcass[] = await getCarcassesForVerdicts(query);
            setCarcassesForVerdicts([...revisionsForVerdicts]);

            setTimeout(() => {
                setLoadingCarcassesForVerdicts(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoadingCarcassesForVerdicts(false);
        }
    }

    const downloadCarcassesSheet = async (
        _initialDate: Date,
        _finalDate: Date,
        _branchId: string
    ) => {
        try {
            let query: string = `?initial_date=${moment(_initialDate).format('YYYY-MM-DD')}`;
            query += `&final_date=${moment(_finalDate).format('YYYY-MM-DD')}`;
            if (_branchId)
                query += `&branch_id=${_branchId}`;
        
            const result: any = await getCarcassesSheet(query);

            return result
        } catch (_err) {
            console.log(_err);
            return
        }
    }

    return (
        <CarcassContext.Provider
            value={{
                carcasses,
                loading,
                paginate,
                setPaginate,
                carcassesPerRow,
                setCarcassesPerRow,
                fetchCarcasses,
                fetchCarcassForAnnotation,
                getCarcassClassification,
                getCarcassClassificationColor,
                filter,
                setFilter,
                downloadCarcasses,
                downloadCarcassesFilePath,
                downloadCarcassesSheet,

                classifications,

                carcassesForVerdicts, loadingCarcassesForVerdicts, fetchCarcassesForVerdicts
            }}
        >
            {children}
        </CarcassContext.Provider>
    );
};

export function useCarcass() {
    const context = useContext(CarcassContext);

    if (!context) {
        throw new Error("useCarcass must be used within an CarcassProvider");
    }

    return context;
}

export function getCarcassClassification(
    _classificationIA: number | string | null | boolean,
    _isMarfrig?: boolean
) {
    if (!_classificationIA) {
        return "Não modelado";
    }
    if (typeof _classificationIA == "string") {
        return "Não modelado";
    }

    if (_isMarfrig) {
        switch (_classificationIA) {
            case 1:
                return "Ausente";
            case 2:
                return "Escassa";
            case 3:
                return "Mediana"
            case 4:
                return "Uniforme"
            case 5:
                return "Excessiva"
            default:
                return "Não modelado";
        }
    } else {
        switch (_classificationIA) {
            case 1:
                return "Ausente";
            case 2:
                return "Escassa";
            case 3:
                return "Mediana";
            case 4:
                return "Uniforme";
            case 5:
                return "Excessiva";

            default:
                return "Não modelado";
        }
    }
}

export function getCarcassTypification(_classificationIA: number | string | null | boolean) {
    if (!_classificationIA) {
        return "Não modelado";
    }
    if (typeof _classificationIA == "string") {
        return "Não modelado";
    }

    switch (_classificationIA) {
        case 1:
            return "Ausente";
        case 2:
            return "Escassa";
        case 3:
            return "Mediana"
        case 4:
            return "Uniforme"
        case 5:
            return "Excessiva"
        default:
            return "Não modelado";
    }
}

export function getCarcassClassificationClassName(
    _classificationIA: number | string | null | boolean,
) {
    if (!_classificationIA) return "Indefinido";
    if (typeof _classificationIA == "string") return "Indefinido";

    switch (_classificationIA) {
        case 1:
            return "Classe 1";
        case 2:
            return "Classe 2 (-)";
        case 3:
            return "Classe 2 (=)";
        case 4:
            return "Classe 2 (+)";
        case 5:
            return "Classe 3 (-)";
        case 6:
            return "Classe 3 (=)";
        case 7:
            return "Classe 3 (+)";
        case 8:
            return "Classe 4";
        case 9:
            return "Classe 5";
        default:
            return "Indefinido";
    }
}

export function getCarcassClassificationColor(
    _classificationIA: number | string | null,
) {
    if (!_classificationIA) return theme.palette.text.primary;
    if (typeof _classificationIA == "string") return theme.palette.text.primary;

    switch (_classificationIA) {
        case 1:
            return theme.palette.error.main;
        case 2:
        case 3:
        case 4:
            return theme.palette.warning.main;
        case 5:
        case 6:
        case 7:
            return theme.palette.success.main;
        case 8:
            return theme.palette.success.main;
        case 9:
            return theme.palette.error.main;
        default:
            return theme.palette.text.primary;
    }
}

const defaultClassifications: ICarcassClassification[] = [
    {
        id: 1,
        label: "Classe 1",
    },
    {
        id: 2,
        label: "Classe 2 (-)",
    },
    {
        id: 3,
        label: "Classe 2 (=)",
    },
    {
        id: 4,
        label: "Classe 2 (+)",
    },
    {
        id: 5,
        label: "Classe 3 (-)",
    },
    {
        id: 6,
        label: "Classe 3 (=)",
    },
    {
        id: 7,
        label: "Classe 3 (+)",
    },
    {
        id: 8,
        label: "Classe 4",
    },
    {
        id: 9,
        label: "Classe 5",
    },
    {
        id: 0,
        label: "Indefinido",
    },
];
