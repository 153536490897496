import React, { FC } from "react";

import { AnalysisAnnotationsProvider } from "./analysisAnnotations";
import { AnnotationsProvider } from "./annotations";
import { AnnotatorsProvider } from "./annotators";
import { AnnotatorHasAccesssProvider } from "./annotatorsHasAccess";
import { BranchesProvider } from "./branches";
import { BranchesHasUserProvider } from "./branchesHasUser";
import { CarcassProvider } from "./carcasses";
import { CarcassesLocalClassificationsProvider } from "./carcassesLocalClassifications";
import { CompaniesProvider } from "./companies";
import { ConfirmationProvider } from "./confirmation";
import { DashboardProvider } from "./dashboard";
import { DrawerProvider } from "./drawer";
import { InformationProvider } from "./information";
import { LoadingProvider } from "./loading";
import { LotProvider } from "./lots";
import { MonitoringEmailsProvider } from "./monitoringEmails";
import { MonitoringRecipientsProvider } from "./monitoringRecipient";
import { MonitoringTypesProvider } from "./monitoringTypes";
import { PrivilegesProvider } from "./privileges";
import { RevisionsProvider } from "./revisions";
import { RoutesProvider } from "./routes";
import { UsersProvider } from "./users";
import { VerdictsProvider } from "./verdicts";
import { ResponsiveProvider } from "./responsive";

const AnnotateProvider: FC<any> = ({ children }) => {
    return (
        <AnnotationsProvider>
            <RevisionsProvider>
                <VerdictsProvider>{children}</VerdictsProvider>
            </RevisionsProvider>
        </AnnotationsProvider>
    );
};

const RegistrationProvider: FC<any> = ({ children }) => {
    return (
        <AnnotatorsProvider>
            <AnalysisAnnotationsProvider>
                <AnnotatorHasAccesssProvider>
                    <BranchesProvider>
                        <CarcassProvider>
                            <CarcassesLocalClassificationsProvider>
                                <CompaniesProvider>
                                    <LotProvider>
                                        <BranchesHasUserProvider>
                                            <PrivilegesProvider>
                                                <RoutesProvider>
                                                    <UsersProvider>
                                                        {children}
                                                    </UsersProvider>
                                                </RoutesProvider>
                                            </PrivilegesProvider>
                                        </BranchesHasUserProvider>
                                    </LotProvider>
                                </CompaniesProvider>
                            </CarcassesLocalClassificationsProvider>
                        </CarcassProvider>
                    </BranchesProvider>
                </AnnotatorHasAccesssProvider>
            </AnalysisAnnotationsProvider>
        </AnnotatorsProvider>
    );
};

export const MonitoringProvider: FC<any> = ({ children }) => {
    return (
        <MonitoringEmailsProvider>
            <MonitoringRecipientsProvider>
                <MonitoringTypesProvider>
                    {children}
                </MonitoringTypesProvider>
            </MonitoringRecipientsProvider>
        </MonitoringEmailsProvider>
    )
}

const HooksProvider: FC<any> = ({ children }) => {
    return (
        <DrawerProvider>
            <DashboardProvider>
                <RegistrationProvider>
                    <AnnotateProvider>
                        <MonitoringProvider>

                            {children}
                        </MonitoringProvider>
                    </AnnotateProvider>
                </RegistrationProvider>
            </DashboardProvider>
        </DrawerProvider>
    );
};

export const FeedbackProvider: FC<any> = ({ children }) => {
    return (
        <ResponsiveProvider>
            <LoadingProvider>
                <InformationProvider>
                    <ConfirmationProvider>{children}</ConfirmationProvider>
                </InformationProvider>
            </LoadingProvider>
        </ResponsiveProvider>
    );
};

export default HooksProvider;
