import React, { FC } from 'react'
import { Select as MuiSelect, MenuItem } from '@mui/material'

import Text from '../text'
import { ISelect, ISelectValue } from '../../../types'
import theme from '../../theme'

const Select: FC<ISelect> = (params: ISelect) => {
    return (
        <MuiSelect
            autoFocus={params.autoFocus}
            required={params.required}
            label={params.label}
            placeholder={params.placeholder}
            value={params.value}
            onChange={params.onChange}
            disabled={params.disabled}
            displayEmpty
            variant={params.variant || "standard"}
            size='small'            
            inputProps={params.inputProps}
            renderValue={params.value !== "" ? undefined : () => <Text color={params.fontColor || theme.palette.gray.main}>{params.placeholder}</Text>}
            sx={{ ...params.sx }}
        >
            {params.items.map((item: ISelectValue, index: number) => (
                <MenuItem key={index} value={item.value} color={params.fontColor || undefined}
                    style={{ backgroundColor: params.backgroundColor || '#FAFAFA' }}>
                    <Text color={params.fontColor || undefined}>{item.label}</Text>
                </MenuItem>))}
        </MuiSelect>
    )
}

export default Select