import React, { FC, useEffect, useState } from 'react';
import { Divider, Grid, IconButton, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Close as CloseIcon, PersonAddAlt1 as PersonAddAlt1Icon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import Autocomplete from '../../../atoms/autocomplete';
import Container from '../../../atoms/container';
import Modal from '../../../atoms/modal';
import Spacing from '../../../atoms/spacing';
import Text from '../../../atoms/text';
import Input from '../../../atoms/input';
import { useAnnotators } from '../../../../hooks/annotators';
import { useUsers } from '../../../../hooks/users';
import theme from '../../../theme';
import { IUser, IAnnotator, IAnnotatorRegisterModal } from '../../../../types';
import { BORDER_RADIUS, PADDING, VERY_PADDING } from '../../../../utils/consts';
import UserSelect from '../../users/select';

const AnnotatorRegisterModal: FC<IAnnotatorRegisterModal> = (params: IAnnotatorRegisterModal) => {
    const isEdit = params.annotatorToEdit != undefined;

    const { createNewAnnotator, editAnnotator } = useAnnotators();
    const { users, fetchUsers } = useUsers();

    const [user, setUser] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [userError, setUserError] = useState<string | null>(null);
    const [fullAccess, setFullAccess] = useState<boolean | undefined>(undefined);
    const [fullAccessError, setFullAccessError] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (params.open == true) {
            fetchUsers();
        }
    }, [params.open]);

    useEffect(() => {
        if (params.annotatorToEdit && params.annotatorToEdit.userId && params.annotatorToEdit.user) {
            setUser(params.annotatorToEdit.userId);
            setUserName(params.annotatorToEdit.user.firstName);
            setFullAccess(params.annotatorToEdit.fullAccess);
        }
    }, [params.annotatorToEdit]);

    const handleChangeUser = (event: React.ChangeEvent<HTMLInputElement> | string) => {
        if ((event as any).target) {
            setUser((event as any).target.value);
            setUserName('');
        } else {
            if ((event as any).id) {
                setUser((event as any).id);
            }
            if ((event as any).label) {
                setUserName((event as any).label);
            }
        }
        setUserError(null);
        setError(null);
    }
    const handleChangeFullAccess = (
        event: React.MouseEvent<HTMLElement>,
        newFullAccess: boolean,
    ) => {
        setFullAccess(newFullAccess);
    };

    function validate() {
        let isValid = true;
        if (user == '') {
            setUserError('Informe o usuário');
            isValid = false;
        }
        if (fullAccess == undefined) {
            setFullAccessError('Informe o nível de acesso do usuário');
            isValid = false;
        }


        if (isValid) {
            setUserError(null);
        }
        return isValid;
    }

    const confirmRegistration = async () => {
        if (!validate()) return;

        setLoading(true);
        try {
            let annotatorId: string = '';
            let payload: IAnnotator = {
                userId: user,
                fullAccess: fullAccess || false
            };
            if (isEdit && params.annotatorToEdit && params.annotatorToEdit.id) {
                const editedAnnotator: IAnnotator = await editAnnotator(params.annotatorToEdit.id, payload);
                if (editedAnnotator.id) annotatorId = editedAnnotator.id;
            } else {
                const newAnnotator: IAnnotator = await createNewAnnotator(payload);
                if (newAnnotator.id) annotatorId = newAnnotator.id;
            }

            const informationTitle: string = 'Cadastro efetuado'
            let informationMessage: string = 'Informações alteradas com sucesso.'
            if (!isEdit) informationMessage = 'O novo anotador foi cadastrado com sucesso.';
            setTimeout(() => {
                setLoading(false);
                cleanData();
                params.onClose();

                params.onConfirm(informationTitle, informationMessage);
            }, 1000);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const cleanData = () => {
        setUser('');
        setUserName('');
        setUserError(null);
        setFullAccess(undefined);
        setFullAccessError(null);
    }

    const close = () => {
        cleanData();
        params.onClose();
    }

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid relative hasShadow centered color={theme.palette.background.default} minWidth="500px" maxWidth="800px">
                <Container inline centered width="100%" color={theme.palette.background.default} borderTopRadius={BORDER_RADIUS}>
                    <Container fluid flex veryPadded>
                        <Text size={30} thin color={theme.palette.primary.main}>{!isEdit ? 'Novo anotador' : 'Editar anotador'}</Text>
                    </Container>

                    <Container absolute sx={{ top: 0, right: 0 }}>
                        <IconButton style={{}} onClick={close}>
                            <CloseIcon style={{ color: theme.palette.primary.light }} />
                        </IconButton>
                    </Container>
                </Container>
                <Divider sx={{ width: "95%" }} />
                <Spacing top={VERY_PADDING} />
                <Container fluid flex flexGrow width="95%" minHeight="100px">
                    <Grid container rowSpacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Usuário:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <Container fluid width={'100%'}>
                                <UserSelect
                                    value={user}
                                    valueName={userName}
                                    // options={isEdit ? undefined : users.filter(item => item.annotatorId == undefined)}
                                    options={undefined}
                                    disabled={loading || isEdit}
                                    handleChangeValue={handleChangeUser}
                                    variant="outlined" />
                                {userError && <Text size={13} color={theme.palette.error.main} >{userError}</Text>}
                            </Container>
                        </Grid>
                        <Grid item xs={2}>
                            <Text light size={15} color={theme.palette.grey[600]}>Acesso:</Text>
                        </Grid>
                        <Grid item xs={10}>
                            <ToggleButtonGroup color="primary" value={fullAccess} exclusive onChange={handleChangeFullAccess} disabled={loading}>
                                <ToggleButton value={true}><Text size={13}>Completo</Text></ToggleButton>
                                <ToggleButton value={false}><Text size={13}>Por liberação</Text></ToggleButton>
                            </ToggleButtonGroup>
                            {fullAccessError && <Text size={13} color={theme.palette.error.main} >{fullAccessError}</Text>}
                        </Grid>
                    </Grid>
                </Container>

                {error && <Text size={13} color={theme.palette.error.main} >Erro: {error}</Text>}
                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading || loading}
                        disabled={params.loading || loading}
                        style={{ backgroundColor: (params.loading || loading) ? theme.palette.grayLight.light : theme.palette.info.main, width: 250, height: 35 }}
                        onClick={() => confirmRegistration()}>
                        <Text light size={16} color={theme.palette.background.paper}>Salvar</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal>
    )
}

export default AnnotatorRegisterModal;