import React, { FC, createContext, useContext, useState } from 'react'

import { createMonitoringRecipient, updateMonitoringRecipient, getMonitoringRecipients, destroyMonitoringRecipient, getMonitoringRecipient } from '../services/monitoringRecipient';
import { IMonitoringRecipient, IMonitoringRecipientContext } from '../types'

const MonitoringRecipientsContext = createContext<IMonitoringRecipientContext>({} as IMonitoringRecipientContext);

export const MonitoringRecipientsProvider: FC<any> = ({ children }) => {
    const [monitoringRecipients, setMonitoringRecipients] = useState<IMonitoringRecipient[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchMonitoringRecipient, setSearchMonitoringRecipient] = useState<string>('');
    const [monitoringRecipientPerRow, setMonitoringRecipientsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchMonitoringRecipients = async () => {
        setLoading(true);
        try {
            const monitoringRecipients: IMonitoringRecipient[] = await getMonitoringRecipients();
            setMonitoringRecipients([...monitoringRecipients]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchMonitoringRecipient = async (_id: string) => {
        const monitoringRecipient: IMonitoringRecipient = await getMonitoringRecipient(_id);
        return monitoringRecipient;
    }

    const createNewMonitoringRecipient = async (_monitoringRecipient: IMonitoringRecipient, _reload: boolean = true) => {
        const monitoringRecipient = await createMonitoringRecipient(_monitoringRecipient);
        if (_reload)
            fetchMonitoringRecipients();
        return monitoringRecipient;
    }

    const editMonitoringRecipient = async (_id: string, _payload: IMonitoringRecipient) => {
        try {
            const monitoringRecipient = await updateMonitoringRecipient(_id, _payload);

            fetchMonitoringRecipients();
            return monitoringRecipient;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteMonitoringRecipient = async (_id: string) => {
        const monitoringRecipient = await destroyMonitoringRecipient(_id);
        fetchMonitoringRecipients();
        return monitoringRecipient;
    }

    return (
        <MonitoringRecipientsContext.Provider value={{
            monitoringRecipients, loading, fetchMonitoringRecipients, fetchMonitoringRecipient,
            createNewMonitoringRecipient, editMonitoringRecipient, deleteMonitoringRecipient,
            searchMonitoringRecipient, setSearchMonitoringRecipient, monitoringRecipientPerRow, setMonitoringRecipientsPerRow, paginate, setPaginate
        }}>{children}</MonitoringRecipientsContext.Provider>
    )
}

export function useMonitoringRecipients() {
    const context = useContext(MonitoringRecipientsContext);

    if (!context) {
        throw new Error('useMonitoringRecipients must be used within an MonitoringRecipientsProvider')
    }

    return context;
}
