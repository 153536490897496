import React, { FC } from "react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, Store as BranchIcon } from "@mui/icons-material";

import Container from "../../../atoms/container";
import theme from "../../../theme";
import Text from "../../../atoms/text";
import { useResponsive } from "../../../../hooks/responsive";
import { ICompanyListItem } from "../../../../types";
import { PADDING } from "../../../../utils/consts";
import Spacing from "../../../atoms/spacing";

const CompanyListItem: FC<ICompanyListItem> = (params: ICompanyListItem) => {
    const { mdDown } = useResponsive();

    return (
        <Container fluid flex padded hasShadow color={theme.palette.background.default} sx={{ border: `solid 2px ${theme.palette.grayLight.light}` }} borderRadius={2}>
            <Grid container rowSpacing={2} columnSpacing={2} display="flex" justifyContent="center">
                <Grid item xs={12} md={5} display="flex" justifyContent="center">
                    <Container fluid centered={mdDown} width="100%">
                        <Text bold size={17} color={theme.palette.primary.main}>
                            {params.company.name}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            E-mail: {params.company.email}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            CNPJ: {params.company.cnpj}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Nome fantasia: {params.company.tradeName}
                        </Text>
                    </Container>
                </Grid>
                <Grid item xs={12} md={4} display="flex" alignItems="flex-end" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Container fluid centered={mdDown}>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Criada em: {params.company.createdAt}
                        </Text>
                        <Text size={12} color={theme.palette.text.disabled}>
                            Atualizada em: {params.company.updatedAt}
                        </Text>
                    </Container>
                </Grid>
                <Grid item xs={12} md={3} display="flex" justifyContent={mdDown ? "center" : "flex-end"}>
                    <Button variant="contained" color="primary" size='medium' sx={{ maxHeight: "35px" }}
                        startIcon={<BranchIcon fontSize="medium" sx={{ color: theme.palette.background.paper }} />}
                        onClick={() => params.company.id && params.onOpenBranches(params.company.id)}>
                        <Text color={theme.palette.background.paper}>Frigoríficos</Text>
                    </Button>
                    <Spacing left={PADDING} />
                    <Tooltip title="Editar empresa">
                        <IconButton size='medium' sx={{ maxHeight: "35px" }}
                            onClick={() => params.company.id && params.onEditCompany(params.company.id)}
                            disableFocusRipple>
                            <EditIcon color="info" fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                    <Spacing left={PADDING} />
                    <Tooltip title="Excluir empresa">
                        <IconButton size='medium' sx={{ maxHeight: "35px" }}
                            onClick={() => params.company.id && params.onDeleteCompany(params.company.id)}>
                            <DeleteIcon color="error" fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Container>
    );
}

export default CompanyListItem;