import React, { FC } from 'react'
import { IconButton, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import './classes.css'
import { IInput } from '../../../types';

const Input: FC<IInput> = (params: IInput) => {
    return (
        <TextField
            id={params.id}
            autoFocus={params.autoFocus}
            required={params.required}
            label={params.label}
            placeholder={params.placeholder}
            value={params.value}
            error={params.error}
            disabled={params.disabled}
            type={!params.isPassword ? params.type || "text" : params.showPassword ? 'text' : 'password'}
            onChange={params.onChange}
            multiline={params.multiline}
            minRows={params.rows || 1}
            maxRows={params.rows || 1}
            variant={params.variant || 'outlined'}
            size='small'
            autoComplete={params.isPassword ? 'new-password' : ''}
            sx={{ ...params.sx }}
            InputProps={{
                startAdornment: params.startAdornment,
                endAdornment: params.isPassword ?
                    <IconButton
                        size="small"
                        aria-label="passw-togle-visibility"
                        onClick={params.handleClickShowPassword}
                        onMouseDown={params.handleClickShowPassword}
                        edge="end"
                    >
                        {params.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    : params.endAdornment,
                ...params.inputProps,
                style: { ...params.sx }
            }} />
    )
}

export default Input;