import React, { FC, createContext, useContext, useState } from 'react'

import { createMonitoringEmail, updateMonitoringEmail, getMonitoringEmails, destroyMonitoringEmail, getMonitoringEmail } from '../services/monitoringEmails';
import { IMonitoringEmail, IMonitoringEmailContext } from '../types'

const MonitoringEmailsContext = createContext<IMonitoringEmailContext>({} as IMonitoringEmailContext);

export const MonitoringEmailsProvider: FC<any> = ({ children }) => {
    const [monitoringEmails, setMonitoringEmails] = useState<IMonitoringEmail[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchMonitoringEmail, setSearchMonitoringEmail] = useState<string>('');
    const [monitoringEmailsPerRow, setMonitoringEmailsPerRow] = useState<string>('25');
    const [paginate, setPaginate] = useState<number>(0);

    const fetchMonitoringEmails = async () => {
        setLoading(true);
        try {
            const monitoringEmails: IMonitoringEmail[] = await getMonitoringEmails();
            setMonitoringEmails([...monitoringEmails]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    }

    const fetchMonitoringEmail = async (_id: string) => {
        const monitoringEmail: IMonitoringEmail = await getMonitoringEmail(_id);
        return monitoringEmail;
    }

    const createNewMonitoringEmail = async (_monitoringEmail: IMonitoringEmail, _reload: boolean = true) => {
        const monitoringEmail = await createMonitoringEmail(_monitoringEmail);
        if (_reload)
            fetchMonitoringEmails();
        return monitoringEmail;
    }

    const editMonitoringEmail = async (_id: string, _payload: IMonitoringEmail) => {
        try {
            const monitoringEmail = await updateMonitoringEmail(_id, _payload);

            fetchMonitoringEmails();
            return monitoringEmail;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    }

    const deleteMonitoringEmail = async (_id: string) => {
        const monitoringEmail = await destroyMonitoringEmail(_id);
        fetchMonitoringEmails();
        return monitoringEmail;
    }

    return (
        <MonitoringEmailsContext.Provider value={{
            monitoringEmails, loading, fetchMonitoringEmails, fetchMonitoringEmail,
            createNewMonitoringEmail, editMonitoringEmail, deleteMonitoringEmail,
            searchMonitoringEmail, setSearchMonitoringEmail, monitoringEmailsPerRow, setMonitoringEmailsPerRow, paginate, setPaginate
        }}>{children}</MonitoringEmailsContext.Provider>
    )
}

export function useMonitoringEmails() {
    const context = useContext(MonitoringEmailsContext);

    if (!context) {
        throw new Error('useMonitoringEmails must be used within an MonitoringEmailsProvider')
    }

    return context;
}
