import React from 'react';

import SpacingStyled from './styled';
import { ISpacing } from '../../../types';

const Spacing: React.FC<ISpacing> = (params: ISpacing) => {
    const topSize = params.top || params.vertical;
    const bottomSize = params.bottom || params.vertical;
    const leftSize = params.left || params.horizontal;
    const rightSize = params.right || params.horizontal;

    return (
        <SpacingStyled
            top={topSize}
            right={rightSize}
            bottom={bottomSize}
            left={leftSize}
            width={params.width}
        >
            {params.children}
        </SpacingStyled>
    );
};

export default Spacing;
